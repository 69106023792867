import { DeleteMarketingLink, DeleteMarketingLinkVariables } from './__generated__/DeleteMarketingLink';
import { gql, useApi } from './Provider';

const DELETE_MARKETING_LINK = gql`
  mutation DeleteMarketingLink($input: DeleteMarketingLinkInput!) {
    deleteMarketingLink(input: $input) {
      id
      slug
      redirectionLocation
      type
      status
      agent {
        id
        fullName
        vanityStub
      }
    }
  }
`;

export const useDeleteMarketingLink = () => {
  const { useMutation } = useApi();
  return useMutation<DeleteMarketingLink, DeleteMarketingLinkVariables>(DELETE_MARKETING_LINK);
};
