import { Bar, LabelList, BarChart as RechartBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { ReactChild, ReactChildren, ReactElement, useState } from 'react';

import { Title } from '../../components';
import { View } from 'react-native';
import { font } from '../../../utils/styles';
import styled from 'styled-components';

interface IChartData {
  label: string;
  index: number;
  number: number;
}

interface IBarChart {
  data: Array<IChartData>;
  title: string;
  renderTooltip: (row: IChartData) => ReactChild | ReactChild[] | ReactChildren | ReactChildren[] | string;
  xHeight?: number;
  xTick?: ReactElement | boolean;
}

interface ChartContainerProps {
  activeIndex: number;
}

export const BarChart = (props: IBarChart) => {
  const { data, title, renderTooltip, xHeight, xTick } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const maxVisits = 0;

  if (data?.length) {
    const maxDomain = maxVisits ? 'dataMax + ' + maxVisits / 10 : 'dataMax + 2';
    return (
      <Styled.ChartContainer activeIndex={activeIndex}>
        <Title size="xl" weight="light">
          {title}
        </Title>
        <ResponsiveContainer width="100%" height={365}>
          <RechartBarChart stackOffset="expand" barSize={25} data={data}>
            <XAxis
              dataKey="label"
              tickCount={0}
              interval={0}
              tick={xTick || true}
              tickLine={false}
              axisLine={false}
              height={xHeight || 15}
            />
            <YAxis domain={[0, maxDomain]} tickLine={true} axisLine={true} tickCount={0} width={0} />
            <Tooltip
              cursor={<View />}
              active={true}
              content={() => {
                if (data && activeIndex > -1) {
                  const row = data[activeIndex];
                  if (row.number) {
                    return <Styled.Tooltip>{renderTooltip(row)}</Styled.Tooltip>;
                  }
                }
                return null;
              }}
            />
            <Bar
              cursor={'pointer'}
              onMouseEnter={(e) => setActiveIndex(e.payload?.index)}
              onMouseLeave={() => setActiveIndex(-1)}
              dataKey="number"
              fill="#000"
              background={false}
              minPointSize={1}
            >
              <LabelList dataKey="number" position="top" />
            </Bar>
          </RechartBarChart>
        </ResponsiveContainer>
      </Styled.ChartContainer>
    );
  }

  return null;
};

// @TODO: Abstract this out into a component or two
const Styled = {
  ChartContainer: styled.div<ChartContainerProps>`
    box-sizing: border-box;
    height: 436px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ececec;
    .recharts-layer.recharts-bar-rectangle:hover {
      fill: #c9985e;
    }
    .recharts-layer.recharts-bar-rectangle path {
      fill: inherit;
    }
    .recharts-label-list text {
      color: #292929;
      font-family: ${font.regular};
      font-size: 18px;
      font-weight: 900;
      &:nth-child(${(props) => props.activeIndex + 1}) {
        fill: #c9985e;
      }
    }
    .recharts-cartesian-axis text {
      color: #919191;
      font-family: ${font.regular};
      font-size: 15px;
      font-weight: 600;
    }
  `,
  Tooltip: styled.div`
    height: 50px;
    width: 170px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 10px 25px 0 rgba(77, 77, 77, 0.1);
    position: relative;
    text-align: center;

    color: #c9985e;
    font-family: ${font.regular};
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
