export const LIMIT_TABLE = 10;
export const ENTER_KEY = 13;
export const TAB_KEY = 9;
export const BROKERAGE_NAME = 'Brokerage';
export const PROPERTY_ADMIN_NAME = 'Developer';

// Roles
export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
export const PROPERTY_ADMIN_ROLE = 'PROPERTY_ADMIN';
export const AGENT_ROLE = 'AGENT';
export const ADMIN_ROLE = 'ADMIN';
export const BUYER_ROLE = 'BUYER';
export const CLIENT_ROLE = 'CLIENT';
export const LISTING_ADMIN_ROLE = 'LISTING_ADMIN';

// types auto suggest
export const NAME = 'NAME';
export const USER_FULL_NAME = 'USER_FULL_NAME';
export const ORGANIZATION_WITH_TYPE = 'ORGANIZATION_WITH_TYPE';

export const PUBLIC_ROUTES = [
  '/about',
  '/register',
  '/create',
  '/forgot-password',
  '/reset-password',
  '/confirm-signup',
  '/search',
  '/contact',
  '/privacy-policy',
  '/cookies-policy',
  '/terms-of-service',
];
export const LOAD_ALL_LIMIT = -1;

export const CURRENT_YEAR = new Date().getFullYear();

export const DOMAIN_NAME = `${process.env.REACT_APP_BASE_URL}`.split('://')[1];

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MAP_CAPS = [
  '3D1F12_653821_070404_22130B-64px',
  '3B4235_7B9395_5B716C_70847B-64px',
  '3E3D39_D6CEAF_91BAC1_897966-64px',
  '3E3E36_72726D_63645D_0E0F0C-64px',
  '3B4235_7B9395_5B716C_70847B-64px',
  '3B3B3B_C7C7C7_878787_A4A4A4-64px',
  '3A3D37_7E7F75_BCBFB6_9C9C94-64px',
  '3E2335_D36A1B_8E4A2E_2842A5-64px',
  '2E2E2D_7D7C76_A3A39F_949C94-64px',
  '3B3B3B_C7C7C7_878787_A4A4A4-64px',
  '5C4E41_CCCDD6_9B979B_B1AFB0-64px',
  '5C5C5C_A2A2A2_8C8C8C_848484-64px',
  '5F5F5F_BDBDBD_A4A4A4_9C9C9C-64px',
  '6D6050_C8C2B9_A2998E_B4AA9F-64px',
  '7A7A7A_D0D0D0_BCBCBC_B4B4B4-64px',
  '8D8D8D_DDDDDD_CCCCCC_B7B7B7-64px',
];

export const MAPBOX_THEME = {
  buildingMatcapUrl: `matcaps/${MAP_CAPS[15]}.png`,
  propertyMatcapUrl: `matcaps/${MAP_CAPS[11]}.png`,
  groundColor: '#FEFFFF',
  waterColor: '#619EB0',
  grassColor: '#95C794',
  roadColor: '#AFB0B1',
};

export const MAPBOX_STYLE_ID = process.env.REACT_APP_MAPBOX_STYLE_ID;

export const REACT_APP_APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;
export const REACT_APP_BROCHURE_DOMAIN = process.env.REACT_APP_BROCHURE_DOMAIN;
export const REACT_APP_CHESS_PIECE_BUCKET = process.env.REACT_APP_CHESS_PIECE_BUCKET;

export const DEFAULT_LATITUDE = +process.env.REACT_APP_DEFAULT_LATITUDE || -86.4882998;
export const DEFAULT_LONGITUDE = +process.env.REACT_APP_DEFAULT_LONGITUDE || 35.8967538;
export const DEFAULT_ZOOM = +process.env.REACT_APP_DEFAULT_ZOOM || 13;
export const ENABLE_ZENDESK = localStorage.enableZendesk || process.env.REACT_APP_ENABLE_ZENDESK === 'TRUE';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const RESPONSIVE = {
  XXX_SMALL: '315px',
  XX_SMALL: '400px',
  EXTRA_SMALL: '575px',
  SMALL: '640px',
  MEDIUM: '970px',
  LARGE: '1024px',
  EXTRA_LARGE: '1500px',
  XX_LARGE: '2000px',
};

export const RESPONSIVE_HEIGHT = {
  EXTRA_SMALL: '375px',
  MEDIUM: '830px',
};

export const OVER_FETCH_AREA = 0.1; // Roughly 6 miles in each direction

export const PASSWORD_MINIMUM = +process.env.REACT_APP_PASSWORD_MINIMUM || 6;

export const QUICK_LINKS = [
  { value: 'Miami, FL, USA', text: 'Miami' },
  { value: 'Broward', text: 'Broward' },
  { value: 'Palm Beach', text: 'Palm Beach' },
];
