import { Spacer, Text, Touchable } from '../';

import React from 'react';
import styled from 'styled-components/native';

interface IStyledCheckBox {
  checked: boolean;
}

interface ICheckBox {
  onValueChange: (checked: boolean) => void;
  testID: string;
  checked: boolean;
  label?: string;
}

const Styled = {
  Container: styled.View`
    display: flex;
    flex-direction: row;
  `,
  CheckBox: styled.View`
    width: 20px;
    height: 20px;
    border: solid 1px ${(props) => props.theme.color.gray1};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  CheckBoxInner: styled.View<IStyledCheckBox>`
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.color.gray1};
    opacity: ${(props) => (props.checked ? 1 : 0)};
  `,
};

export const CheckBox = (props: ICheckBox) => {
  return (
    <Touchable
      onPress={() => {
        props.onValueChange(!props.checked);
      }}
      testID={`checkbox:${props.testID}`}
    >
      <Styled.Container>
        <Styled.CheckBox>
          <Styled.CheckBoxInner checked={props.checked} />
        </Styled.CheckBox>
        {props.label && (
          <>
            <Spacer width={9} />
            <Text size="md" color="gray">
              {props.label}
            </Text>
          </>
        )}
      </Styled.Container>
    </Touchable>
  );
};
