import { Button, Form, Input, Spacer } from '../../../components';
import React, { useEffect, useState } from 'react';
import {
  useContactSalesCenter,
  useFavoriteUnit,
  useGetPropertyUnitLazy,
  usePropertyAudit,
  useUnFavoriteUnit,
} from '../../../api';

import { ContactSheetAgentExpanded as ContactSheetAgentExpandedType } from '../types';
import { font } from '../../../utils/styles';
import { handle } from 'cartesian-js';
import styled from 'styled-components';
import { useFavoriteProperty } from '../../../hooks/useFavoriteProperty';
import { useMobileContactSheet } from '../useMobileContactSheet';
import { useToasts } from 'react-toast-notifications';
import useTranslations from '../../../translations/useTranslations';

export interface SetShareProperty {
  propertyId: string;
  unitId?: string;
}

interface ContactSheetAgentExpandedProps {
  contactSheet: ContactSheetAgentExpandedType;
  setShareProperty: (data: SetShareProperty) => void;
}

interface ContainerProps {
  hasSalesCenter: boolean;
}

const Styled = {
  Container: styled.div<ContainerProps>`
    position: fixed;
    overflow: hidden;
    width: 100vw;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    z-index: 9999;
    padding: 0px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    flex-direction: column;
    height: ${(props) => (props.hasSalesCenter ? '548' : '180')}px;

    input {
      height: 45px;
      padding-left: 12px;
    }

    textarea {
      height: 195px;
    }
  `,
  ContainerInner: styled.div`
    padding: 14px;
    position: relative;
  `,
  Close: styled.button`
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      height: 20px;
    }
  `,
  Title: styled.div`
    font-family: ${font.title};
    font-size: 20px;
    font-weight: 600;
    height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
  `,
  SubTitle: styled.div`
    font-family: ${font.regular};
    font-size: 10px;
    width: 100%;
    text-align: center;
  `,
  Background: styled.div`
    position: fixed;
    z-index: 9998;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.6;
  `,
  Questions: styled.div`
    font-family: ${font.regular};
    font-size: 16px;
    width: 100%;
    text-align: center;
  `,
};

export const ContactSheetAgentExpanded = (props: ContactSheetAgentExpandedProps) => {
  const { addToast } = useToasts();
  const [fetchPropertyUnit, { data }] = useGetPropertyUnitLazy();
  const [contactSalesCenter] = useContactSalesCenter();
  const [auditProperty] = usePropertyAudit();
  const [showErrors, setShowErrors] = useState(false);
  const [isFavorite, setIsFavorite] = useState(props.contactSheet.isFavorite);
  const { toggleFavorite: toggleFavoriteProperty } = useFavoriteProperty();
  const [favoriteUnit] = useFavoriteUnit();
  const [unfavoriteUnit] = useUnFavoriteUnit();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const { setContactSheet } = useMobileContactSheet();
  const translations = useTranslations();
  const close = () => {
    setContactSheet({
      ...props.contactSheet,
      type: 'agentCollapsed',
    });
  };
  const t = translations.ContactSheet;
  const hasSalesCenter = props.contactSheet.hasCenterEmail;

  useEffect(() => {
    if (props.contactSheet.unitId) {
      const fetchUnit = async () => {
        await fetchPropertyUnit({
          variables: {
            unitId: +props.contactSheet.unitId,
          },
        });
      };

      fetchUnit();
    }
  }, [props.contactSheet.unitId, fetchPropertyUnit]);

  const unitName = data?.unit?.name || '';

  const handleSendMessage = async () => {
    setShowErrors(true);

    if (!subject || !message) {
      return;
    }

    const [error] = await handle(
      contactSalesCenter({
        variables: {
          input: {
            propertyId: +props.contactSheet.propertyId,
            title: subject,
            body: message,
          },
        },
      }),
    );

    if (error) {
      return addToast(translations.FormErrors.generic, {
        appearance: 'error',
      });
    }

    await handle(
      auditProperty({
        variables: {
          propertyId: +props.contactSheet.propertyId,
          type: 'CONTACT_SALES_CENTER',
        },
      }),
    );

    addToast(t.salesCenterSent, {
      appearance: 'success',
    });

    close();
  };

  const handleFavorite = async () => {
    if (!props.contactSheet.unitId) {
      toggleFavoriteProperty(props.contactSheet.propertyId, isFavorite);
      setIsFavorite((isFavorite) => !isFavorite);
      return;
    }

    if (data?.unit?.isFavorite) {
      return await handle(
        unfavoriteUnit({
          variables: {
            unitId: +props.contactSheet.unitId,
          },
        }),
      );
    }

    await handle(
      favoriteUnit({
        variables: {
          unitId: +props.contactSheet.unitId,
        },
      }),
    );
  };

  const handleShareProperty = () => {
    props.setShareProperty({
      propertyId: props.contactSheet.propertyId,
      unitId: props.contactSheet.unitId,
    });
    close();
  };

  return (
    <>
      <Styled.Background onClick={close} />
      <Styled.Container hasSalesCenter={hasSalesCenter}>
        <Styled.ContainerInner>
          <Styled.Title>{props.contactSheet.propertyName}</Styled.Title>
          <Styled.SubTitle>{props.contactSheet.developerName}</Styled.SubTitle>
          <Spacer height={18} />
          <Button
            width="100%"
            testId="share-button"
            height="45px"
            fontSize="16px"
            onClick={handleShareProperty}
            icon="share-white"
            iconType="svg"
            iconSize={24}
          >
            {props.contactSheet.unitId ? `${t.share} ${unitName} ${t.withClient}` : t.shareWithClient}
          </Button>
          <Spacer height={8} />
          <Button
            type="button"
            width="100%"
            testId="share-button"
            height="45px"
            fontSize="16px"
            onClick={handleFavorite}
            icon="favorite-light"
            iconType="svg"
            iconSize={24}
          >
            {props.contactSheet.unitId
              ? !data?.unit?.isFavorite
                ? t.favoriteThisUnit
                : t.unFavoriteThisUnit
              : isFavorite
              ? t.unFavoriteProperty
              : t.favoriteProperty}
          </Button>
          {hasSalesCenter && (
            <Form onSubmit={handleSendMessage}>
              <Spacer height={22} />
              <Styled.Questions>{t.questions}</Styled.Questions>
              <Spacer height={22} />
              <Input
                type="text"
                placeholder={t.subject}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                error={showErrors && !subject}
              />
              <Spacer height={13} />
              <Input
                error={showErrors && !message}
                type="textArea"
                placeholder={t.message}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Spacer height={13} />
              <Button
                type="submit"
                width="100%"
                color="#000"
                testId="share-button"
                height="45px"
                fontSize="16px"
                icon="chat"
                iconType="svg"
                iconSize={24}
              >
                {t.messageSalesCenter}
              </Button>
            </Form>
          )}
          <Styled.Close test-id="mobile-contact-close" onClick={close}>
            <img src="/icons/close.svg" alt="close" />
          </Styled.Close>
        </Styled.ContainerInner>
      </Styled.Container>
    </>
  );
};
