import { GetMe, GetMe_me } from './__generated__/GetMe';
import { gql, useApi } from './Provider';

export type CurrentUser = GetMe_me;

const GET_ME = gql`
  query GetMe {
    me {
      id
      identityId
      status
      vanityStub
      firstName
      lastName
      fullName
      profileAvatarUrl
      profileBannerUrl
      profileDescription
      primaryAddress {
        id
        city
        state
      }
      primaryEmail {
        id
        address
      }
      secondaryEmail {
        id
        address
      }
      homePhone {
        id
        number
      }
      workPhone {
        id
        number
      }
      emails {
        id
        address
        emailType {
          name
        }
      }
      phoneNumbers {
        id
        number
        phoneNumberType {
          name
        }
      }
      primaryAddress {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
      }
      agent {
        id
        vanityStub
        firstName
        lastName
        fullName
        profileAvatarUrl
        profileBannerUrl
        profileDescription
        organizations {
          id
          name
          organizationTypes {
            id
            name
          }
        }
      }
      organizations {
        id
        name
        organizationTypes {
          id
          name
        }
      }
      userRoles {
        id
        property {
          id
        }
        role {
          id
          baseName
        }
      }
    }
  }
`;

export const useGetMe = () => {
  const { useQuery } = useApi();
  return useQuery<GetMe>(GET_ME);
};

export const useGetMeLazy = () => {
  const { useLazyQuery } = useApi();
  return useLazyQuery<GetMe>(GET_ME);
};
