import React from 'react';
import ImageCropper from './ImageCropper';
import Modal from '../Modal';
import { CroppedImage } from '../../types/croppedImage';
import useTranslations from '../../translations';

interface ImageCropperModalProps {
  onCrop: (image: CroppedImage) => void;
  isOpen: boolean;
  onClose: () => void;
  aspectRatio?: number;
  file: string;
  filename?: string;
  fileType?: string;
  initialCropArea?: number;
  useRatioToolbar?: boolean;
}

const ImageCropperModal: React.FC<ImageCropperModalProps> = ({
  aspectRatio,
  initialCropArea,
  onCrop,
  isOpen = false,
  onClose = () => {},
  file,
  fileType,
  filename,
  useRatioToolbar,
}) => {
  const translations = useTranslations();
  return (
    <Modal
      width={900}
      variant="center"
      isOpen={isOpen}
      title={translations.ImageCropper.editImage}
      onClose={onClose}
      renderContent={() => {
        return (
          <ImageCropper
            file={file}
            aspectRatio={aspectRatio}
            onCrop={onCrop}
            onCancel={onClose}
            fileType={fileType}
            filename={filename}
            initialCropArea={initialCropArea}
            useRatioToolbar={useRatioToolbar}
          />
        );
      }}
    />
  );
};

export default ImageCropperModal;
