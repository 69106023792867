import React, { ReactNode, useRef, useState } from 'react';
import { always, compose } from 'lodash/fp';
import { color, font } from '../utils/styles';

import { Align } from '../types';
import { Icon } from './Icon';
import { RESPONSIVE } from '../utils/constants';
import styled from 'styled-components';
import useOutsideClick from '../hooks/useOutsideClick';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';

interface DropdownButtonProps {
  testId?: string;
  label?: string;
  topOffset?: number;
  rightOffset?: number;
  contentWidth?: number;
  align?: Align;
  value?: string | number;
  placeholder?: string;
  children: ({ closeDropdown: CloseDropdown }) => ReactNode;
  iconButton?: ReactNode;
  containerWidth?: string;
  contentPosition?: string;
}

interface DropDownContainerProps {
  isActive: boolean;
  topOffset: number;
  rightOffset: number;
  align: Align;
  contentWidth: number;
  contentPosition?: string;
}

export const DropdownButton = (props: DropdownButtonProps) => {
  const [isActive, setIsActive] = useState(false);
  const closeDropdown = compose(setIsActive, always(false));
  const { isLarge: isTablet } = useResponsiveSizes();
  const ref = useRef();
  useOutsideClick(ref, () => setIsActive(false));
  return (
    <Styled.Container ref={ref}>
      {isTablet && <Styled.DropDownLabel>{props.label}</Styled.DropDownLabel>}
      {props.iconButton ? (
        <Styled.IconContainer
          data-testid={props.testId}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          {props.iconButton}
        </Styled.IconContainer>
      ) : (
        <Styled.DropdownButton
          data-testid={props.testId}
          type="button"
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <Styled.DropdownButtonInner>
            {!isTablet && <Styled.InsideLabel>{props.label}</Styled.InsideLabel>}
            {isTablet && <Styled.Placeholder>{props.value || props.placeholder}</Styled.Placeholder>}
            <Styled.Icon type="arrow_drop_down" color={!isTablet ? '#0F232B' : '#747a7e'} size={16} />
          </Styled.DropdownButtonInner>
        </Styled.DropdownButton>
      )}

      <Styled.DropDownContainer
        contentWidth={props.contentWidth || 330}
        isActive={isActive}
        topOffset={props.topOffset || 42}
        align={props.align || 'left'}
        contentPosition={props.contentPosition}
        rightOffset={props.rightOffset}
      >
        <Styled.DropDownContent>
          {props.children({
            closeDropdown,
          })}
        </Styled.DropDownContent>
      </Styled.DropDownContainer>
    </Styled.Container>
  );
};

const Styled = {
  IconContainer: styled.button`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px !important;
    margin: 0px !important;
  `,
  Container: styled.div`
    position: relative;
    width: fit-content;
  `,
  DropdownButton: styled.button`
    min-height: auto;
    box-shadow: none;
    border: 1px solid #000;
    padding: 10px 20px;
    width: 100%;
    background-color: white;

    white-space: nowrap;
    display: flex;
    align-items: center;
    border-radius: 0px;
    color: ${color.black};
    font-family: ${font.regular};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.38px;
    cursor: pointer;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      width: 95% !important;
      padding: 10px 5px;
    }
  `,
  Placeholder: styled.div`
    @media (max-width: ${RESPONSIVE.LARGE}) {
      width: 50% !important;
      text-align: left;
      font-family: ${font.regular};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  `,
  Icon: styled(Icon)`
    @media (max-width: ${RESPONSIVE.LARGE}) {
      width: 50% !important;
      text-align: right;
    }
  `,
  DropdownButtonInner: styled.div`
    color: ${color.black};
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    @media (max-width: ${RESPONSIVE.LARGE}) {
      font-family: ${font.regular};
      font-weight: 300;
      font-size: 16px;
      width: 100%;
    }
  `,
  DropDownContainer: styled.div<DropDownContainerProps>`
    position: ${(props) => (props.contentPosition ? props.contentPosition : 'absolute')};
    top: ${(props) => props.topOffset}px;
    display: ${(props) => (props.isActive ? 'flex' : 'none')};
    ${(props) =>
      props.align === 'right'
        ? 'right: 0px;'
        : props.align === 'center'
        ? `left: calc(50% - ${props.contentWidth / 2}px);`
        : ''}
    z-index: 1000;
    margin: 0px;
    font-size: 1rem;
    color: ${color.dark};
    text-align: left;
    list-style: none;
    background-color: ${color.white};
    background-clip: padding-box;
    width: ${(props) => props.contentWidth}px;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      ${(props) =>
        props.align === 'right'
          ? `right: ${props.rightOffset ? props.rightOffset : 25}px;`
          : props.align === 'center'
          ? `left: calc(50% - ${props.contentWidth / 2}px);`
          : ''}
    }

    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      ${(props) =>
        props.align === 'right'
          ? `right: ${props.rightOffset ? props.rightOffset : 18}px;`
          : props.align === 'center'
          ? `left: calc(50% - ${props.contentWidth / 2}px);`
          : ''}
    }

    @media (max-width: ${RESPONSIVE.SMALL}) {
      ${(props) =>
        props.align === 'right'
          ? `right: ${props.rightOffset ? props.rightOffset : 8}px;`
          : props.align === 'center'
          ? `left: calc(50% - ${props.contentWidth / 2}px);`
          : ''}
    }
  `,
  DropDownContent: styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-width: 1px;
    border-style: solid;
    border-color: #000;
    border-image: initial;
    border-radius: 0px;
    padding: 15px;
  `,
  InsideLabel: styled.span`
    text-align: left;
    min-width: 77px;
  `,
  DropDownLabel: styled.span`
    text-align: left;
    font-weight: bold;
    text-transform: none;
    font-family: ${font.regular};
    font-size: 16px;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
    }
  `,

  DropDownControls: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
  `,
};
