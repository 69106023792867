import { Input, Modal } from '../../components';
import React, { useState } from 'react';
import { useGetAgentClients, useInviteClientToSystem, useSharePropertyToClient } from '../../api';

import { ContactsTagInput } from '../../components/ContactsTagInput';
import { RESPONSIVE } from '../../utils/constants';
import styled from 'styled-components';
import { useCanInviteClients } from '../../hooks/useCanInviteClients';
import { useToasts } from 'react-toast-notifications';
import useTranslations from '../../translations';

interface SharePropertyModalProps {
  onClose: () => void;
  onComplete: () => void;
  propertyId: number;
  unitId?: number;
}

interface ButtonContainerProps {
  showMessageInput: boolean;
}

interface SharePropertyButtonProps {
  disabled: boolean;
}

export const SharePropertyModal = (props: SharePropertyModalProps) => {
  const [clientIds, setClientIds] = useState([]);
  const [newEmails, setNewEmails] = useState([]);
  const translations = useTranslations();
  const [showMessageInput, setShowMessageInput] = useState(false);
  const [message, setMessage] = useState('');
  const { addToast } = useToasts();
  const [error, setError] = useState(null);
  const [hasSent, setHasSent] = useState(false);
  const [sharePropertyToClient] = useSharePropertyToClient();
  const [inviteClient] = useInviteClientToSystem();
  const canInviteClients = useCanInviteClients();
  const { data, refetch } = useGetAgentClients();

  if (!data) {
    return null;
  }
  const clients = data.me.clients;
  const handleShareProperty = async () => {
    let failed = false;

    if (!canInviteClients()) {
      return;
    }

    setHasSent(true);

    for (const clientId of clientIds) {
      try {
        await sharePropertyToClient({
          variables: {
            input: {
              clientId,
              propertyId: props.propertyId,
              message,
              unitId: props.unitId,
            },
          },
        });
      } catch (error) {
        const currentClient = clients?.users.find((client) => +client.id === clientId);
        addToast(error.toString() + `(${currentClient.fullName})`, {
          appearance: 'error',
          autoDismiss: true,
        });
        failed = true;
        break;
      }
    }
    if (failed) {
      return;
    }
    for (const newEmail of newEmails) {
      try {
        const inviteResult = await inviteClient({
          variables: {
            input: {
              firstName: ' ',
              lastName: ' ',
              emailAddress: newEmail,
              sendInvitationEmail: false,
            },
          },
        });

        await refetch();

        await sharePropertyToClient({
          variables: {
            input: {
              clientId: +inviteResult?.data?.inviteClient?.user?.id,
              propertyId: props.propertyId,
              message,
              unitId: props.unitId,
            },
          },
        });
      } catch (error) {
        addToast(error.toString() + `(${newEmail})`, {
          appearance: 'error',
          autoDismiss: true,
        });
        failed = true;
        break;
      }
    }
    if (!failed) {
      addToast(props.unitId ? translations.ShareProperty.unitSuccess : translations.ShareProperty.success, {
        appearance: 'success',
        autoDismiss: true,
      });
      setHasSent(false);
      props.onClose();
    }
  };

  return (
    <Modal
      isOpen
      insetCloseIcon={true}
      variant={'center'}
      title={props.unitId ? translations.ShareProperty.unitTitle : translations.ShareProperty.title}
      testid="modal:share-property"
      width={410}
      withCloseIcon={true}
      onClose={props.onClose}
      renderContent={() => (
        <Styled.Container>
          <Styled.Content>
            <Styled.SendText>{translations.ShareProperty.sendToClient}</Styled.SendText>
            <ContactsTagInput
              handleSetError={(error) => {
                setError(error);
              }}
              possibleItems={clients?.users.map((client) => ({
                id: +client.id,
                name: client.fullName,
                email: client.primaryEmail.address,
              }))}
              newEmails={newEmails}
              handleSetItems={(items) => {
                setClientIds(items.filter((item) => item.id).map((item) => item.id));
                setNewEmails(items.filter((item) => !item.id && item.email).map((item) => item.email));
              }}
              placeholder={translations.ShareProperty.inputPlaceholder}
            />

            <Styled.SendText>{translations.ShareProperty.separateMessage}</Styled.SendText>
            <Styled.ButtonContainer showMessageInput={showMessageInput}>
              {showMessageInput ? (
                <Input
                  id="message"
                  name="message"
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  value={message}
                  type="textArea"
                />
              ) : (
                <Styled.AddPersonalNote onClick={() => setShowMessageInput(true)}>
                  {translations.ShareProperty.messagePrompt}
                </Styled.AddPersonalNote>
              )}

              <Styled.SharePropertyButton
                onClick={handleShareProperty}
                disabled={(clientIds.length === 0 && newEmails.length === 0) || hasSent}
                data-testid="share-property-modal-share-button"
              >
                <Styled.SendIcon src="/icons/share.svg" />
                {translations.ShareProperty.send}
              </Styled.SharePropertyButton>
            </Styled.ButtonContainer>

            {error && <Styled.Error>{error}</Styled.Error>}
          </Styled.Content>
        </Styled.Container>
      )}
    />
  );
};

const Styled = {
  PropertyLinkContainer: styled.div`
    display: flex;
    border: 1px solid #dadada;
    height: 38px;
    justify-content: space-between;
    padding: 5px;
  `,
  SharePropertyButton: styled.button<SharePropertyButtonProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    height: 30px;
    width: 80px;
    background: linear-gradient(180deg, #08101e 0%, #000000 100%);
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0px 7px;
    align-self: flex-end;
  `,
  ButtonContainer: styled.div<ButtonContainerProps>`
    align-items: ${(props) => (props.showMessageInput ? 'flex-end' : 'center')};
    flex-direction: ${(props) => (props.showMessageInput ? 'column' : 'row')};
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
  `,
  SendIcon: styled.img`
    margin-right: 8px;
  `,
  AddPersonalNote: styled.div`
    color: #c9985e;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  `,
  Error: styled.div`
    height: 24px;
    width: 182px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      width: 100%;
    }
    color: #ec1b38;
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 12px;
  `,
  Container: styled.div``,
  Content: styled.div`
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      padding: 1rem;
    }
    padding: 40px;
  `,
  SendText: styled.div`
    color: #747a7e;
    font-size: 12px;
  `,
  Footer: styled.div`
    height: 82px;
    background-color: #f5f5f5;
    padding: 20px 40px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      padding: 1rem;
    }
  `,
  PropertyLinkArea: styled.input`
    width: 100%;
    border: 0px;
    background: transparent;
    font-size: 14px;
    color: #747a7e;
    &:focus {
      outline: none;
    }
  `,
  CopyLinkButton: styled.div`
    cursor: pointer;
    width: 80px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #c9985e;
  `,
  AutosuggestFormInput: styled.div`
    input[type='text'] {
      padding-right: 35px;
    }
  `,
  AutosuggestSearchIcon: styled.div`
    width: 30px;
    background: pink;
    position: relative;
    height: 0;
    left: calc(100% - 30px);
    bottom: 32px;
  `,
};
