import { MetaData, RequestState, Tag, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface TagsResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Tag>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetTags = (variables: RequestVariables = defaultVariables) => {
  const [tags, refetch] = useApi.get({
    url: `/tag`,
    variables,
  });

  return formatApiResult(tags, refetch) as TagsResponse;
};
