import { useApi, gql } from './Provider';
import {
  GetPropertySalesCenterEmail,
  GetPropertySalesCenterEmailVariables,
} from './__generated__/GetPropertySalesCenterEmail';

const HAS_PROPERTY_SALES_CENTER_EMAIL = gql`
  query GetPropertySalesCenterEmail($id: Int!) {
    property(id: $id) {
      id
      hasSalesCenterEmail
    }
  }
`;

export const useGetSalesCenterEmail = (props: GetPropertySalesCenterEmailVariables) => {
  const { useQuery } = useApi();
  return useQuery<GetPropertySalesCenterEmail, GetPropertySalesCenterEmailVariables>(HAS_PROPERTY_SALES_CENTER_EMAIL, {
    variables: {
      id: props.id,
    },
  });
};
