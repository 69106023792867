import * as React from 'react';
import useTranslations from '../translations/useTranslations';

import styled from 'styled-components';
import { Input } from './Input';
import { Button } from './Button';
import { Form } from './Elements';
import { Icon } from './Icon';

const Styled = {
  Container: styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
  `,
  SearchContainer: styled.div`
    padding-left: 14px;
    position: relative;
    top: -2px;
  `,
  ClearButton: styled.button`
    position: absolute;
    top: 12px;
    left: 10px;
    cursor: pointer;
  `,
};

interface TableSearchProps {
  placeholder?: string;
  value: string;
  onValueChange: (value: string) => void;
}

export const TableSearch = (props: TableSearchProps) => {
  const translations = useTranslations();
  const [search, setSearch] = React.useState(props.value || '');

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        props.onValueChange(search);
      }}
    >
      <Styled.Container>
        <Input
          placeholder={props.placeholder || translations.Common.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          style={{
            paddingLeft: props.value ? 50 : 14,
          }}
        />
        {!!props.value && (
          <Styled.ClearButton
            type="button"
            onClick={() => {
              setSearch('');

              setTimeout(() => {
                props.onValueChange('');
              }, 1);
            }}
          >
            <Icon type="clear" color="gray" />
          </Styled.ClearButton>
        )}
        <Styled.SearchContainer>
          <Button color="gray" type="submit">
            {translations.Common.search}
          </Button>
        </Styled.SearchContainer>
      </Styled.Container>
    </Form>
  );
};
