import {
  BulkCreateClientInvitation,
  BulkCreateClientInvitationVariables,
} from './__generated__/BulkCreateClientInvitation';
import { gql, useApi } from './Provider';

const BULK_CREATE_CLIENT_INVITATION = gql`
  mutation BulkCreateClientInvitation($input: [InviteClientInput!]!) {
    inviteClients(input: $input) {
      invitations {
        id
        emailAddress
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const useBulkCreateClientInvitations = () => {
  const { useMutation } = useApi();
  return useMutation<BulkCreateClientInvitation, BulkCreateClientInvitationVariables>(BULK_CREATE_CLIENT_INVITATION);
};
