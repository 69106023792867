import { GetMarketingLinkBySlug, GetMarketingLinkBySlugVariables } from './__generated__/GetMarketingLinkBySlug';
import { gql, useApi } from './Provider';

const GET_MARKETING_LINK_BY_SLUG = gql`
  query GetMarketingLinkBySlug($input: MarketingLinkBySlugInput!) {
    marketingLinkBySlug(input: $input) {
      id
      slug
      type
      status
      name
      html
      redirectionLocation
      agent {
        id
        vanityStub
      }
    }
  }
`;

export const useGetMarketingLinkBySlug = (input) => {
  const { useQuery } = useApi();
  return useQuery<GetMarketingLinkBySlug, GetMarketingLinkBySlugVariables>(GET_MARKETING_LINK_BY_SLUG, {
    variables: {
      input,
    },
  });
};
