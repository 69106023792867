import * as React from 'react';

import { COLOR } from '../../theme';
import styled from 'styled-components/native';

interface IInput {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  onFocus?: () => void;
  name: string;
  type?: 'text' | 'number' | 'password';
  multiline?: boolean;
  placeholder?: string;
  endIcon?: React.ReactChild;
}

interface IStyledTextInput {
  disabled?: boolean;
}

const Styled = {
  Container: styled.View`
    flex: 1;
    position: relative;
  `,
  Input: styled.TextInput<IStyledTextInput>`
    flex: 1;
    font-family: '${(props) => {
      return props.theme.font.body500;
    }}';
    font-size: ${(props) => props.theme.fontSize.md}px;
    color: ${(props) => (props.disabled ? props.theme.color.gray : props.theme.color.black)};
    border: 0px !important;
  `,
  EndIcon: styled.View`
    position: absolute;
    right: 12px;
    top: 25%;
  `,
};

export const Input = (props: IInput) => {
  return (
    <Styled.Container>
      <Styled.Input
        secureTextEntry={props.type === 'password'}
        testID={`input:${props.name}`}
        onFocus={props.onFocus}
        disabled={props.disabled}
        value={props.value}
        multiline={props.multiline}
        placeholder={props.placeholder}
        placeholderTextColor={COLOR.gray1}
        onChangeText={(text) => {
          if (!props.disabled) {
            props.onValueChange(text);
          }
        }}
      />
      {props.endIcon && <Styled.EndIcon>{props.endIcon}</Styled.EndIcon>}
    </Styled.Container>
  );
};
