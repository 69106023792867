import { useAuthorization } from './useAuthorization';
import { useToasts } from 'react-toast-notifications';

export const useCanInviteClients = () => {
  const { currentUser } = useAuthorization();
  const { addToast } = useToasts();

  const canInviteClients = () => {
    if (!currentUser?.firstName?.trim() || !currentUser?.lastName?.trim()) {
      addToast('You cannot invite clients until you go to "My Account" and add your first and last name', {
        appearance: 'error',
      });
      return false;
    }

    if (!currentUser.vanityStub) {
      addToast('You cannot invite clients until you go to "My Account" and add you custom portal URL', {
        appearance: 'error',
      });
      return false;
    }

    return true;
  };

  return canInviteClients;
};
