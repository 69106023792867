import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  padding: 0px;
  border: 0px;
  background: transparent;
  cursor: pointer;
  appearance: button;
  -webkit-appearance: button;
  -moz-appearance: button;
`;

export const TouchableDiv = styled.div`
  padding: 0px;
  border: 0px;
  background: transparent;
  cursor: pointer;
  appearance: button;
  -webkit-appearance: button;
  -moz-appearance: button;
`;

export const Touchable = styled(ButtonWrapper as any)`
  outline: none;
  cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};
`;
