import api from '../../utils/api';

export const batchRemoveUnits = (propertyId: string, unitIds: Array<string>) => {
  return api.delete({
    url: `/property/${propertyId}/unit/batch`,
    variables: {
      unitIds,
    },
  });
};
