import { RemoteKeyPlan, MetaData, RequestState, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface KeyPlanResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<RemoteKeyPlan>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetPropertyKeyPlans = (propertyId: number | string, variables: RequestVariables = defaultVariables) => {
  const [keyPlans, refetch] = useApi.get({
    url: `/property/${propertyId}/keyplans`,
    variables,
  });

  return formatApiResult(keyPlans, refetch) as KeyPlanResponse;
};
