import { MetaData, RequestState, RemoteUnitModel, RequestVariables, UnitModel } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

export interface UnitModelsResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<RemoteUnitModel>;
  metadata: MetaData;
  refetch: () => void;
  setData: (data: Array<UnitModel>) => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetPropertyUnitModels = (
  propertyId: number | string,
  variables: RequestVariables = defaultVariables,
) => {
  const [unitModels, refetch, setData] = useApi.get({
    url: `/property/${propertyId}/unit-models`,
    variables,
    options: {
      cachePolicy: 3,
    },
  });

  return formatApiResult(unitModels, refetch, setData) as UnitModelsResponse;
};
