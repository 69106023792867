import { IColor } from '../../theme';

export enum IIconType {
  Favorite = 'favorite',
  FavoriteFilled = 'favorite-filled',
  Stories = 'stories',
  Share = 'share',
  Chevron = 'chevron',
  Close = 'close',
  Message = 'message',
  Tour = 'tour',
  Building3D = 'building-3d',
  Edit = 'edit',
  CheckCircle = 'checkCircle',
  Delete = 'delete',
  Check = 'check',
  Search = 'search',
  Idea = 'idea',
  Question = 'question',
  CloudUpload = 'cloudUpload',
  Send = 'send',
  AddUser = 'addUser',
  Copy = 'copy',
  Filter = 'filter',
}

export type IIconSize = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export interface IIcon {
  type: IIconType;
  size?: IIconSize;
  color?: IColor;
  backgroundColor?: IColor;
  padding?: number;
  exactSize?: number;
  rotate?: number;
}

export interface IStyledWrapper {
  size: IIconSize;
  color: IColor;
  exactSize?: number;
}

export interface IStyledBackgroundWrapper {
  backgroundColor?: IColor;
  size?: IIconSize;
  padding?: number;
  exactSize?: number;
}

export interface IStyledImage {
  size?: IIconSize;
  exactSize?: number;
  rotate?: number;
}
