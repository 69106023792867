import { conformToMask } from 'react-text-mask';

export const phoneNumberMask = [
  '+',
  /[1-9]/,
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const getPhoneRawValue = (value: string) => (value ? value.replace(/\D+/g, '').slice(0, value.length) : '');

export const getMaskedValue = (value: string) =>
  value && value !== '0' ? conformToMask(value, phoneNumberMask, {}).conformedValue : '';

export const prettyPhoneNumber = (value: number) => getMaskedValue(`${value}`);
