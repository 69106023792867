import * as React from 'react';

import { ITr } from './types';
import styled from 'styled-components';

const Styled = {
  Tr: styled.tr``,
};

export const Tr = (props: ITr) => {
  return <Styled.Tr>{props.children}</Styled.Tr>;
};
