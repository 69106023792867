import { KeyPlan, MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface KeyPlanResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: KeyPlan;
  metadata: MetaData;
}

export const useGetKeyPlan = (id: number | string) => {
  const [keyPlan] = useApi.get({
    url: `/keyplans/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(keyPlan) as KeyPlanResponse;
};
