import { useApi, gql } from './Provider';

import { GetAgentUsers, GetAgentUsers_role_users } from './__generated__/GetAgentUsers';

export const GET_AGENT_USERS = gql`
  query GetAgentUsers {
    role(name: "AGENT") {
      id
      name
      baseName
      users {
        id
        status
        lastLogin
        fullName
        emailTemplates(type: "INVITE_CLIENT") {
          id
          name
          templateId
        }
        primaryEmail {
          id
          address
        }
      }
    }
  }
`;

export const useGetAgentUsers = () => {
  const { useQuery } = useApi();
  return useQuery<GetAgentUsers>(GET_AGENT_USERS);
};

useGetAgentUsers.query = () => GET_AGENT_USERS;

export type IGetAgentUsersRoleUsers = GetAgentUsers_role_users;
