import styled from 'styled-components/native';

interface IConstrain {
  width?: number;
  height?: number;
  zIndex?: number;
}

export const Constrain = styled.View<IConstrain>`
  ${(props) => (props.width ? `width: ${props.width}px` : '')}
  ${(props) => (props.height ? `height: ${props.height}px` : '')}
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex}` : '')}
`;
