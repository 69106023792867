import { endSession, getTimeoutLimit } from '../audits/session';

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const sessionShouldEnd = (lastSeen) => {
  if (lastSeen) {
    const rightNow = Date.now();
    const diff = rightNow - parseInt(lastSeen);
    return diff > getTimeoutLimit();
  }
  return false;
};

export const useSession = (currentUser) => {
  const history = useHistory();

  // Effect to Listen for when the auth token in local storage gets emptied
  useEffect(() => {
    const lastSeen = localStorage.getItem('lastSeen');
    if (sessionShouldEnd(lastSeen)) {
      endSession();
      return;
    }
    const checkAuthToken = () => {
      const token = localStorage.getItem('vysta:authToken');
      if (!token) {
        history.push('/login');
      }
    };
    const storeLastSeen = () => localStorage.setItem('lastSeen', Date.now().toString());

    if (currentUser) {
      localStorage.removeItem('lastSeen');
      window.addEventListener('storage', checkAuthToken);
      window.addEventListener('beforeunload', storeLastSeen);
    }

    return () => {
      window.removeEventListener('storage', checkAuthToken);
      window.removeEventListener('beforeunload', storeLastSeen);
    };
  }, [currentUser, history]);
};
