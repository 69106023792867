import { Unit } from '../../types';
import api from '../../utils/api';

export const batchUpdateUnits = (propertyId: string, units: Array<Unit>) => {
  return api.put({
    url: `/property/${propertyId}/unit/batch`,
    variables: {
      units,
    },
  });
};
