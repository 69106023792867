import {
  BulkCreateAgentInvitation,
  BulkCreateAgentInvitationVariables,
} from './__generated__/BulkCreateAgentInvitation';
import { gql, useApi } from './Provider';

const BULK_CREATE_AGENT_INVITATION = gql`
  mutation BulkCreateAgentInvitation($input: [InviteInput!]!) {
    inviteAgents(input: $input) {
      invitations {
        id
        emailAddress
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const useBulkInviteAgent = () => {
  const { useMutation } = useApi();
  return useMutation<BulkCreateAgentInvitation, BulkCreateAgentInvitationVariables>(BULK_CREATE_AGENT_INVITATION);
};
