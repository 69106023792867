import { Category, MetaData, RequestState, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface CategoriesResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Category>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetCategories = (variables: RequestVariables = defaultVariables) => {
  const [categories, refetch] = useApi.get({
    url: `/categories`,
    variables,
  });

  return formatApiResult(categories, refetch) as CategoriesResponse;
};
