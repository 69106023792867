import {
  CompleteMultiPartMediaUploadMutation,
  CompleteMultiPartMediaUploadMutationVariables,
} from './__generated__/CompleteMultiPartMediaUploadMutation';
import {
  CreateMediaMutation,
  CreateMediaMutationVariables,
  CreateMediaMutation_createMedia_media,
} from './__generated__/CreateMediaMutation';
import { UpdateMediaImageMutation, UpdateMediaImageMutationVariables } from './__generated__/UpdateMediaImageMutation';
import { SignMediaUploadMutation, SignMediaUploadMutationVariables } from './__generated__/SignMediaUploadMutation';

import {
  SignMultiPartMediaUploadMutation,
  SignMultiPartMediaUploadMutationVariables,
} from './__generated__/SignMultiPartMediaUploadMutation';
import { convertMediaToBlob, readFile } from '../../utils/file';

import Axios from 'axios';
import { client } from '../../api/Provider/apiLink';
import { gql } from '@apollo/client';

interface Payload {
  media: File;
  propertyId: number;
  uploadType?: string;
  uuid?: string;
  mediaId?: string;
  width?: number;
  height?: number;
}

const SIGN_MEDIA_UPLOAD_MUTATION = gql`
  mutation SignMediaUploadMutation($mediaType: String!) {
    signMediaUpload(mediaType: $mediaType) {
      signedUrl
      location
    }
  }
`;

const SIGN_MULTI_PART_MEDIA_UPLOAD_MUTATION = gql`
  mutation SignMultiPartMediaUploadMutation($input: SignedMultiPartMediaInput!) {
    signMultiPartMediaUpload(input: $input) {
      signedUrls
      location
      uploadId
    }
  }
`;

const COMPLETE_MULTI_PART_MEDIA_UPLOAD_MUTATION = gql`
  mutation CompleteMultiPartMediaUploadMutation($input: CompleteMultiPartMediaInput!) {
    completeMultiPartMediaUpload(input: $input) {
      success
    }
  }
`;

const CREATE_MEDIA_MUTATION = gql`
  mutation CreateMediaMutation($input: CreateMediaInput!) {
    createMedia(input: $input) {
      media {
        id
        name
        location
        createdAt
        updatedAt
        categories {
          id
          name
        }
        mediaType {
          id
          name
        }
        url
      }
    }
  }
`;

const UPDATE_MEDIA_IMAGE_MUTATION = gql`
  mutation UpdateMediaImageMutation($input: UpdateMediaImageInput!) {
    updateMediaImage(input: $input) {
      media {
        id
        name
        location
        createdAt
        updatedAt
        categories {
          id
          name
        }
        mediaType {
          id
          name
        }
        url
      }
    }
  }
`;

const FILE_CHUNK_SIZE = 10_000_000;
const doUpload = async (payload: Payload): Promise<string> => {
  let location = '';

  if (payload.media.size > FILE_CHUNK_SIZE) {
    const signedResponse = await client.mutate<
      SignMultiPartMediaUploadMutation,
      SignMultiPartMediaUploadMutationVariables
    >({
      mutation: SIGN_MULTI_PART_MEDIA_UPLOAD_MUTATION,
      variables: {
        input: {
          mediaType: payload.media.type,
          mediaSize: payload.media.size,
        },
      },
    });
    const { signedUrls, uploadId } = signedResponse.data.signMultiPartMediaUpload;
    location = signedResponse.data.signMultiPartMediaUpload.location;
    const promises = signedUrls.map((signedUrl, index) => {
      const start = index * FILE_CHUNK_SIZE;
      const end = (index + 1) * FILE_CHUNK_SIZE;
      const blob = payload.media.slice(start, end);
      const axios = Axios.create();
      delete axios.defaults.headers.put['Content-Type'];
      return axios.put(signedUrl, blob);
    });

    const resParts = await Promise.all(promises);
    const parts = resParts.map((part, index) => {
      return {
        ETag: part.headers.etag,
        PartNumber: index + 1,
      };
    });
    await client.mutate<CompleteMultiPartMediaUploadMutation, CompleteMultiPartMediaUploadMutationVariables>({
      mutation: COMPLETE_MULTI_PART_MEDIA_UPLOAD_MUTATION,
      variables: {
        input: {
          uploadId,
          key: location,
          parts,
        },
      },
    });
  } else {
    const mediaFile = await readFile(payload.media);
    const formData = await convertMediaToBlob(mediaFile);

    const signedResponse = await client.mutate<SignMediaUploadMutation, SignMediaUploadMutationVariables>({
      mutation: SIGN_MEDIA_UPLOAD_MUTATION,
      variables: {
        mediaType: payload.media.type,
      },
    });

    const signedUrl = signedResponse?.data?.signMediaUpload?.signedUrl;
    location = signedResponse?.data?.signMediaUpload?.location;

    if (!signedUrl) {
      throw new Error('Unable to get a signedUrl from S3');
    }

    await fetch(signedUrl, {
      method: 'PUT',
      body: formData,
    });
  }

  return location;
};

export const replacePropertyUpload = async (payload: Payload) => {
  const location = await doUpload(payload);

  const updateMediaImageResponse = await client.mutate<UpdateMediaImageMutation, UpdateMediaImageMutationVariables>({
    mutation: UPDATE_MEDIA_IMAGE_MUTATION,
    variables: {
      input: {
        location,
        id: payload.mediaId,
        width: payload.width,
        height: payload.height,
        isPortrait: payload.height > payload.width,
      },
    },
  });

  return updateMediaImageResponse?.data?.updateMediaImage?.media || [];
};

export const createPropertyMedia = async (payload: Payload): Promise<Array<CreateMediaMutation_createMedia_media>> => {
  const location = await doUpload(payload);
  const createMediaResponse = await client.mutate<CreateMediaMutation, CreateMediaMutationVariables>({
    mutation: CREATE_MEDIA_MUTATION,
    variables: {
      input: {
        mediaType: payload.media.type,
        propertyId: +payload.propertyId,
        location,
        name: payload.media.name,
        uploadType: payload.uploadType,
        width: payload.width,
        height: payload.height,
        isPortrait: payload.height > payload.width,
      },
    },
  });

  return createMediaResponse?.data?.createMedia?.media || [];
};
