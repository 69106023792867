import * as Sentry from '@sentry/react';

import { color, font } from './utils/styles';

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const ErrorScreen = () => {
  const supportEmail = 'info@getvysta.com';
  const history = useHistory();

  return (
    <Styled.Container>
      <Styled.Content>
        <h1>Sorry.</h1>
        <h2>
          Looks like something went wrong on our end. <br /> Please feel free to contact us at{' '}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>, if you need assistance.
        </h2>
        <button id="go-back-btn" onClick={history.goBack}>
          Go Back
        </button>
      </Styled.Content>
    </Styled.Container>
  );
};

const ErrorBoundary = props => <Sentry.ErrorBoundary fallback={<ErrorScreen />}>{props.children}</Sentry.ErrorBoundary>;

export default ErrorBoundary;

const Styled = {
  Container: styled.div`
    width: 100%;
    position: relative;
    text-align: center;
    margin: 0 auto;
  `,
  Content: styled.div`
    margin-top: 200px;
    button {
      border-radius: 6px;
      background-color: ${color.primary};
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
      font-family: ${font.regular};
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.38px;
      line-height: 18px;
      text-align: center;
      padding: 10px;
      margin: 10px;
      color: ${color.white};
      cursor: pointer;
    }
  `,
};
