import { MetaData, RequestState, Unit } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface UnitResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Unit;
  metadata: MetaData;
}

export const useGetUnit = (id: number | string) => {
  const [unit] = useApi.get({
    url: `/unit/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(unit) as UnitResponse;
};
