import * as React from 'react';

import { ITouchable } from './types';
import { TouchableOpacity } from 'react-native';

interface ITouchableExtended extends ITouchable {
  width?: number;
  disabled?: boolean;
}

export const Touchable = (props: ITouchableExtended) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      testID={props.testID}
      activeOpacity={0.75}
      disabled={props.disabled}
      style={{
        width: props.width,
      }}
    >
      {props.children}
    </TouchableOpacity>
  );
};
