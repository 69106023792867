import { CreateAgentInvitation, CreateAgentInvitationVariables } from './__generated__/CreateAgentInvitation';
import { gql, useApi } from './Provider';

const INVITE_AGENT = gql`
  mutation CreateAgentInvitation($input: InviteInput!) {
    inviteAgent(input: $input) {
      id
      emailAddress
      user {
        id
        fullName
      }
    }
  }
`;

export type ICreateAgentInvitationVariables = CreateAgentInvitationVariables;
export type ICreateAgentInvitation = CreateAgentInvitation;

export const useInviteAgent = () => {
  const { useMutation } = useApi();
  return useMutation<CreateAgentInvitation, CreateAgentInvitationVariables>(INVITE_AGENT);
};
