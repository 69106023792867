import * as React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid';
import { Media, MediaTypeName } from '../types';

import { Icon } from './Icon';
import { ImageGallery } from './ImageGallery';
import { PdfThumbnail } from './PdfThumbnail';
import { VideoThumbnail } from './VideoThumbnail';
import { font } from '../utils/styles';
import { formatImage } from '../utils/formatImage';
import styled from 'styled-components';

interface ImagePickerProps {
  value: Array<Media>;
  onValueChange: (media: Array<Media>) => void;
  propertyId: number;
  height?: number;
  mediaTypes?: Array<MediaTypeName>;
  limit?: number;
}

interface ContainerProps {
  height?: number;
}

export const Styled = {
  MediaImage: styled.img`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  `,
  Container: styled.div<ContainerProps>`
    position: relative;
    display: flex;
    word-break: break-word;
    flex-direction: column;
    border: solid 1px #ccc;
    width: 100%;
    border-radius: 4px;
    align-items: center;
    height: ${(props) => props.height || 150}px;
    margin-bottom: 14px;
  `,
  RemoveButton: styled.button`
    position: absolute;
    right: 7px;
    top: 7px;
    color: #fff;
    cursor: pointer;
    font-family: ${font.regular};
    font-weight: bold;
    background: #000;
    padding: 1px;
    height: 18px;
    width: 18px;
    border-radius: 45px;

    &:focus,
    &hover {
      color: #ccc;
    }
  `,
  AddButton: styled.button`
    cursor: pointer;
    background: #eee;
    flex: 1;
    width: 100%;
  `,
  Title: styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    bottom: 0px;
    padding: 7px;
    color: #fff;
    font-family: ${font.regular};
    font-size: 12px;
    width: 100%;
    cursor: default;
  `,
};

export const ImagePicker = (props: ImagePickerProps) => {
  const [isSelecting, setIsSelecting] = React.useState(false);

  return (
    <>
      {isSelecting ? (
        <ImageGallery
          limit={props.limit}
          mediaTypes={props.mediaTypes || ['image']}
          onClose={() => setIsSelecting(false)}
          excludeMediaIds={props.value.map((media) => media.id)}
          onComplete={(newMedia) => {
            props.onValueChange([...(props.value || []), ...(newMedia || [])]);
            setIsSelecting(false);
          }}
          propertyId={props.propertyId}
        />
      ) : null}
      <Grid>
        <Row>
          {props.value.map((media) => (
            <Col key={media.id} sm={6} md={4} lg={3} xl={2}>
              <Styled.Container height={props.height}>
                {(media?.mediaType?.name || '').includes('video') ||
                (props?.mediaTypes?.length === 1 && props.mediaTypes.includes('video')) ? (
                  <VideoThumbnail video={media} />
                ) : (media?.mediaType?.name || '').includes('pdf') ? (
                  <PdfThumbnail pdf={media} />
                ) : (
                  <Styled.MediaImage
                    src={formatImage(media.url || media.location, {
                      width: 280,
                      height: 280,
                    })}
                  />
                )}
                <Styled.Title>{media.name}</Styled.Title>
                <Styled.RemoveButton
                  type="button"
                  onClick={() => {
                    props.onValueChange(props.value.filter((record) => record !== media));
                  }}
                >
                  <Icon type="remove_circle" color="white" size={16} />
                </Styled.RemoveButton>
              </Styled.Container>
            </Col>
          ))}
          {((props.limit && props.value.length < props.limit) || !props.limit) && (
            <Col sm={6} md={4} lg={3} xl={2}>
              <Styled.Container height={props.height}>
                <Styled.AddButton
                  type="button"
                  onClick={() => {
                    setIsSelecting(true);
                  }}
                >
                  <Icon type="add_circle" color="#CCC" size={48} />
                </Styled.AddButton>
              </Styled.Container>
            </Col>
          )}
        </Row>
      </Grid>
    </>
  );
};
