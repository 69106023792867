import styled from 'styled-components';
import Button from '../Button';

export const Controls = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-top: 1px solid #dfe1e6;
  margin-top: 10px;
`;

export const Toolbar = styled.div`
  margin-top: 20px;
  justify-content: center;
  display: flex;
`;

export const ZoomButton = styled(Button as any)`
  margin-right: 10px;
  padding: 4px;
`;

export const RatioButton = styled(Button as any)`
  padding: 4px;
  margin-right: 1px;
`;

export const CropContainer = styled.div`
  position: relative;
  width: 75%;
  height: 410px;
  background: #333;
  margin-bottom: 80px;
`;

// NOTE: First 3 are required by cropper.js
// https://stackoverflow.com/questions/29788922/not-able-to-see-the-preview-for-cropper-js
export const Preview = styled.div`
  width: 25%;
  height: 400px;
  overflow: hidden;
  margin: 5px 0px 5px 5px;
`;

// NOTE: first 2 are required by cropper.js
// https://www.npmjs.com/package/cropperjs
export const CropedImage = styled.img`
  display: block;
  max-width: 100%;
  visibility: hidden;
`;

export const Container = styled.div`
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
