import { Link, useHistory } from 'react-router-dom';
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { color, font } from '../utils/styles';

import { CurrentUser } from './CurrentUser';
import { DropdownButton } from './';
import { HeaderLinks } from './HeaderLinks';
import { PoweredByVystaLogo } from './PoweredByVystaLogo';
import { RESPONSIVE } from '../utils/constants';
import { RESPONSIVE_HEIGHT } from '../utils/constants';
import { SearchBar } from './SearchBar';
import { Spacer } from '../@components';
import { getUserBrokerage } from '../utils/user';
import styled from 'styled-components';
import { useAuthorization } from '../hooks/useAuthorization';
import { useEmulateUser } from '../hooks/useEmulateUser';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';
import { useSearch } from '../hooks/useSearch';
import useTranslations from '../translations/useTranslations';

interface SearchInput {
  propertyId?: number;
  location?: string;
  searchTerm: string;
  cityId?: string;
  provinceId?: string;
  countryId?: string;
  localeId?: string;
}

interface AuthenticatedHeaderProps {
  onRemoveLocation?: () => void;
  localeId?: string | null;
  setSearch?: (input: SearchInput) => void;
  children?: ReactNode;
  disableLogo?: boolean;
  width?: string;
  searchTerm?: string;
  resetFilters?: () => void;
  hideSearch?: boolean;
  disablePoweredBy?: boolean;
  backText?: string;
  onBackClick?: () => void;
  setShowMobileFilters?: Dispatch<SetStateAction<boolean>>;
  showMobileFilters?: boolean;
  unset?: boolean;
  showHeader?: boolean;
}

interface ContainerProps {
  width?: string;
  unset?: boolean;
}

interface MobileExpandedProps {
  isExpanded?: boolean;
}

export const AuthenticatedHeader = (props: AuthenticatedHeaderProps) => {
  const { currentUser, logout, getHomeRoute } = useAuthorization();
  const { showHeader = true } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const history = useHistory();
  const translations = useTranslations();
  const { suggestionSelected } = useSearch();
  const agent = currentUser?.agent;
  const organizationName = getUserBrokerage(agent || currentUser);
  const { isLarge: isTablet, isMedium: isMobile } = useResponsiveSizes();
  const componentTestId = 'authenticated-header';

  const emulateUser = useEmulateUser();

  const homeUrl = getHomeRoute();

  const search = (
    <SearchBar
      value={props.searchTerm}
      placeholder="Search Properties"
      data-testid="searchbar"
      id="nav-searchbar"
      filters={{ localeId: props.localeId }}
      isRemote={!props.setSearch}
      onReset={props.resetFilters}
      onSetFilter={(filter) => {
        if (props.setSearch) {
          props.setSearch({
            searchTerm: filter?.name,
            localeId: filter?.__typename === 'Locale' ? `${filter.id}` : null,
            propertyId: filter?.__typename === 'SearchProperty' ? +filter.id : null,
            cityId: filter?.__typename === 'City' ? `${filter.id}` : null,
            provinceId: filter?.__typename === 'Province' ? `${filter.id}` : null,
            countryId: filter?.__typename === 'Country' ? `${filter.id}` : null,
          });
          return;
        }

        suggestionSelected(filter);
      }}
      onRemoveFilter={() => {
        if (props.onRemoveLocation) {
          props.onRemoveLocation();
        }
      }}
    />
  );

  const RenderEmulateUserLogo = () => {
    return emulateUser ? (
      <Styled.UnLogo>
        <Spacer width={14} />
        <Styled.SubTitle>{agent ? `${agent?.fullName}` : currentUser && `${currentUser?.fullName}`}</Styled.SubTitle>
        <Styled.Org>{organizationName}</Styled.Org>
      </Styled.UnLogo>
    ) : (
      <Styled.Logo id="agent-btn" to={homeUrl} title={translations.Common.home}>
        <Spacer width={14} />
        <Styled.SubTitle>{agent ? `${agent?.fullName}` : currentUser && `${currentUser?.fullName}`}</Styled.SubTitle>
        <Styled.Org>{organizationName}</Styled.Org>
      </Styled.Logo>
    );
  };

  const RenderBackText = () => {
    return props.backText ? (
      <Styled.BackButton>
        <Styled.BackArrow
          alt="Back"
          src="/icons/back-gold.svg"
          onClick={() => {
            if (props.onBackClick) {
              return props.onBackClick();
            }

            return history.push(homeUrl);
          }}
        />{' '}
        {props.backText}
      </Styled.BackButton>
    ) : (
      <Spacer />
    );
  };

  const RenderDesktopMenu = () => {
    return (
      !isMobile && (
        <>
          {!props.disablePoweredBy && (
            <>
              <PoweredByVystaLogo />
              <Spacer width={40} />
            </>
          )}
          {!isTablet && search}
          {!isTablet && props.children}
          {!isTablet && <Spacer width={7} />}
          {isTablet && <Spacer width={10} />}
          {!isTablet && (
            <>
              <Styled.Divider />
              <CurrentUser
                id="my-account-btn"
                data-test-id={`${componentTestId}-my-account`}
                onClick={() => {
                  history.push(currentUser?.agent ? `/preferences` : `/account/settings`);
                }}
              />
              <DropdownButton
                contentWidth={130}
                align="right"
                topOffset={28}
                testId={`${componentTestId}-toggle-menu`}
                iconButton={<Styled.MenuIcon src="/icons/navigation-menu-horizontal.svg" />}
              >
                {() => (
                  <Styled.LinkContainer>
                    <HeaderLinks userRoles={currentUser?.userRoles} />
                    <Styled.LogoutButton onClick={logout}>
                      <Styled.LogoutIcon src="/icons/logout-1-alternate.svg" />
                      Logout
                    </Styled.LogoutButton>
                  </Styled.LinkContainer>
                )}
              </DropdownButton>
            </>
          )}
        </>
      )
    );
  };

  const RenderHeader = () => {
    return (
      <>
        <Styled.Container width={props.width} unset={props.unset}>
          {!props.disableLogo ? <RenderEmulateUserLogo /> : <RenderBackText />}
          {emulateUser ? (
            <Styled.Right></Styled.Right>
          ) : (
            <Styled.Right>
              <RenderDesktopMenu />
              {isMobile && !props.disablePoweredBy && (
                <>
                  <Styled.PoweredByVysta src="/icons/powered-by-vysta-dark.png" />
                  <Spacer width={40} />
                </>
              )}
            </Styled.Right>
          )}
        </Styled.Container>
        {(isTablet || emulateUser) && (
          <Styled.MobileExpanded
            isExpanded={isExpanded}
            onClick={() => {
              setIsExpanded((isExpanded) => {
                const helpIcon = document.getElementById('launcher');
                if (!helpIcon) {
                  return !isExpanded;
                }

                if (isExpanded) {
                  helpIcon.setAttribute('class', ``);
                } else {
                  helpIcon.setAttribute('class', `visible`);
                }
                return !isExpanded;
              });
            }}
          >
            <Styled.MobileExpandedInner>
              <Styled.PoweredByVystaExpanded src="/icons/powered-by-vysta-dark.png" />
              {emulateUser ? (
                <>
                  <Link to={`/clients?emulateUser=${emulateUser}`}>Clients</Link>
                  <Link to={`/account/marketing?emulateUser=${emulateUser}`}>Marketing</Link>
                </>
              ) : (
                <>
                  <Link to="/">{translations.Common.home}</Link>
                  <Link to="/search">{translations.Common.search}</Link>
                  {currentUser && <HeaderLinks userRoles={currentUser.userRoles} />}
                  <Link
                    data-test-id={`${componentTestId}-my-account`}
                    title={translations.Common.myAccount}
                    id="my-account-btn"
                    to={currentUser?.agent ? `/preferences` : `/account/settings`}
                  >
                    {translations.Common.myAccount}
                  </Link>
                  <button type="button" id="logout-btn" title={translations.Common.logout} onClick={logout}>
                    {translations.Common.logout}
                  </button>
                </>
              )}
            </Styled.MobileExpandedInner>
          </Styled.MobileExpanded>
        )}
        {isTablet && !props.hideSearch && (
          <>
            <Styled.MobileSearch>
              {search}
              {props.children && (
                <Styled.FiltersButton
                  isExpanded={props.showMobileFilters}
                  onClick={() => {
                    props.setShowMobileFilters((showMobileFilters) => !showMobileFilters);
                  }}
                />
              )}
              {props.showMobileFilters && <Styled.MobileFilters>{props.children}</Styled.MobileFilters>}
            </Styled.MobileSearch>
          </>
        )}
        {(isTablet || emulateUser) && (
          <>
            <Styled.Hamburger
              onClick={() => {
                setIsExpanded((isExpanded) => {
                  const helpIcon = document.getElementById('launcher');

                  if (!helpIcon) {
                    return !isExpanded;
                  }

                  if (isExpanded) {
                    helpIcon?.setAttribute('class', ``);
                  } else {
                    helpIcon?.setAttribute('class', `visible`);
                  }
                  return !isExpanded;
                });
              }}
            >
              <Styled.Line1 isExpanded={isExpanded} />
              <Styled.Line2 isExpanded={isExpanded} />
              <Styled.Line3 isExpanded={isExpanded} />
            </Styled.Hamburger>
          </>
        )}
      </>
    );
  };

  return (
    <Styled.HeaderContainer>
      {showHeader && <RenderHeader />}
      <div id="portal-container-property-hero-property-nav"></div>
    </Styled.HeaderContainer>
  );
};

const Styled = {
  HeaderContainer: styled.div<ContainerProps>`
    position: sticky;
    top: 0;
    z-index: 12;
    width: ${(props) => props.width || '100%'};

    @media (max-width: ${RESPONSIVE.LARGE}) {
      z-index: 1000;
    }
  `,
  LinkContainer: styled.div``,
  LogoutButton: styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 65px;
    justify-content: space-between;
    font-size: 14px;
  `,
  SubTitle: styled.h3`
    font-size: 18px;
    font-weight: bold;
    color: ${color.black};
    font-family: ${font.title};
    padding: 0px;
    margin: 0px;
    @media (min-width: ${RESPONSIVE.LARGE}) {
      max-width: 200px !important;
    }
    @media (min-width: ${RESPONSIVE.EXTRA_LARGE}) {
      max-width: 400px !important;
    }
    @media (min-width: ${RESPONSIVE.XX_LARGE}) {
      max-width: none !important;
    }
  `,
  Logo: styled(Link)`
    padding: 0px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    height: 100%;
    align-items: center;
    text-align: left;
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100vw - 160px);
    }
  `,
  UnLogo: styled.div`
    padding: 0px;
    border: 0px;
    background: transparent;
    cursor: default;
    height: 100%;
    align-items: center;
    text-align: left;
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100vw - 160px);
    }
  `,
  Org: styled.p`
    font-family: ${font.regular};
    font-weight: normal;
    font-size: 10px;
    color: ${color.black};
    text-transform: uppercase;
  `,
  MobileSearchBackground: styled.div`
    position: fixed;
    width: 100vw;
    height: calc(100vh + 100px);
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    top: -100px;
    z-index: 99998;
    pointer-events: none;
  `,
  MobileExpandedInner: styled.div`
    display: block;
    position: relative;
    top: 120px;
  `,
  MobileExpanded: styled.div<MobileExpandedProps>`
    position: fixed;
    width: calc(100vw - 54px);
    max-width: 350px;
    height: calc(100vh + 100px);
    right: ${(props) => (props.isExpanded ? '0px' : '-100vw')};
    background-color: ${color.white};
    top: -100px;
    z-index: 999998;
    transition: right 0.25s;
    color: ${color.black};
    box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.5);

    a,
    button {
      color: ${color.black};
      display: block;
      width: calc(100% - 28px);
      padding: 0px !important;
      font-size: 12px;
      line-height: 32px;
      text-align: left;
      margin-left: 14px;
      padding-left: 7px !important;
      cursor: pointer;
      margin-bottom: 0px !important;
      padding-right: 7px !important;
      z-index: 999999;

      &:focus {
        color: ${color.borderLight};
      }
    }
  `,
  Line1: styled.div<MobileExpandedProps>`
    height: 1px;
    width: 20px;
    background-color: ${color.black};
    position: absolute;
    top: 12px;
    left: 10px;
    ${(props) =>
      props.isExpanded
        ? `transform: rotate(45deg);
    top: 18px;`
        : ''}
    transition: transform 1s, top 0.25s;
  `,
  Line2: styled.div<MobileExpandedProps>`
    height: 1px;
    width: 20px;
    background-color: ${color.black};
    top: 18px;
    position: absolute;
    left: 10px;
    opacity: ${(props) => (props.isExpanded ? 0 : 1)};
    transition: opacity 1s;
  `,
  Line3: styled.div<MobileExpandedProps>`
    height: 1px;
    width: 20px;
    background-color: ${color.black};
    top: 24px;
    position: absolute;
    left: 10px;
    ${(props) =>
      props.isExpanded
        ? `transform: rotate(-45deg);
      top: 18px;`
        : ''}
    transition: transform 1s, top 0.25s;
  `,
  ContainerSticky: styled.div`
    height: 50px;
    width: 100%;
    @media (max-width: ${RESPONSIVE.LARGE}) {
      height: 85px;
    }
  `,
  Container: styled.div<ContainerProps>`
    box-shadow: 0px 0px 7px ${color.borderLight};
    display: flex;
    justify-content: space-between;
    background-color: ${color.white};
    height: 50px;
    position: relative;
    z-index: 12;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      border-bottom: solid 1px ${color.borderLight};
      box-shadow: none;
      height: 50px;
    }
  `,
  FiltersButton: styled.button<MobileExpandedProps>`
    background-image: ${(props) => (props.isExpanded ? `url('icons/close.svg')` : `url('/icons/filters.svg')`)};
    background-repeat: no-repeat;
    height: ${(props) => (props.isExpanded ? '18px' : '37px')};
    width: ${(props) => (props.isExpanded ? '18px' : '40px')};
    margin-right: ${(props) => (props.isExpanded ? '15px' : '7px')};
    cursor: pointer;

    &:focus {
      opacity: 0.8;
    }
  `,
  MobileSearch: styled.div`
    height: 40px;
    width: 100vw;
    position: fixed;
    top: 50px;
    z-index: 99998;
    display: flex;
    align-items: center;
    background-color: ${color.white};

    #nav-searchbar {
      flex: 1;
    }
  `,
  MobileFilters: styled.div`
    position: absolute;
    width: 100vw;
    padding: 14px;
    background-color: ${color.white};
    top: 41px;
    left: 0px;

    border-bottom: solid 1px ${color.borderLight};
    & > div {
      width: 100%;

      & > div {
        width: 100%;
        left: 0px;
      }
    }

    button {
      width: 100%;
      margin-bottom: 7px;
    }

    @media (max-height: ${RESPONSIVE_HEIGHT.EXTRA_SMALL}) {
      overflow-y: scroll;
    }
  `,
  Hamburger: styled.button`
    background-color: transparent;
    width: 40px;
    height: 37px;
    cursor: pointer;
    position: relative;
    position: fixed;
    z-index: 999998;
    right: 5px;
    top: 6px;
  `,
  HamburgerIcon: styled.div`
    background-image: url('/icons/hamburger-light.svg');
    width: 28px;
    height: 25px;
  `,
  CloseIcon: styled.div`
    background-image: url('/icons/close.svg');
    width: 28px;
    height: 25px;
    z-index: 99999;
  `,
  Right: styled.div`
    display: flex;
    align-items: center;

    button {
      font-size: 12px;
      max-height: 30px;
      padding: 5px 10px;

      i {
        position: relative;
        left: 8px;
      }
    }
    @media (max-width: ${RESPONSIVE.LARGE}) {
      button {
        font-size: inherit;
        max-height: inherit;
        padding: initial i {
          position: relative;
          left: 8px;
        }
      }
    }
  `,
  MenuIcon: styled.img`
    height: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  `,
  LogoutIcon: styled.img`
    height: 16px;
  `,
  Divider: styled.div`
    width: 1px;
    height: 100%;
    background-color: #f0f0f0;
  `,
  PoweredByVysta: styled.img`
    width: 90px;
    height: 13px;
    margin-right: 7px;
  `,
  PoweredByVystaExpanded: styled.img`
    width: 90px;
    height: 13px;
    margin-left: 20px;
    margin-bottom: 25px;
  `,
  BackButton: styled.button`
    font-size: 14px;
    font-family: ${font.regular};
    cursor: pointer;
  `,
  BackArrow: styled.img`
    height: 12px;
    width: 24px;
    margin-right: 3px;
    margin-left: 14px;
  `,
};
