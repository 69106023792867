import { MessageClient, MessageClientVariables } from './__generated__/MessageClient';

import { useApi, gql } from './Provider';

const MESSAGE_CLIENT = gql`
  mutation MessageClient($input: MessageClientInput!) {
    messageClient(input: $input) {
      id
      subject
      message
      from {
        id
        fullName
      }
      to {
        id
        fullName
      }
    }
  }
`;

export const useMessageClient = () => {
  const { useMutation } = useApi();
  return useMutation<MessageClient, MessageClientVariables>(MESSAGE_CLIENT);
};
