import { RemoteAmenity, MetaData, RequestState, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface AmenitiesResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<RemoteAmenity>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetPropertyAmenities = (
  propertyId: number | string,
  variables: RequestVariables = defaultVariables,
) => {
  const [amenities, refetch] = useApi.get({
    url: `/property/${propertyId}/amenities`,
    variables,
  });

  return formatApiResult(amenities, refetch) as AmenitiesResponse;
};
