import { UpdateMarketingLink, UpdateMarketingLinkVariables } from './__generated__/UpdateMarketingLink';
import { gql, useApi } from './Provider';

const UPDATE_MARKETING_LINK = gql`
  mutation UpdateMarketingLink($input: UpdateMarketingLinkInput!) {
    updateMarketingLink(input: $input) {
      id
      slug
      redirectionLocation
      type
      status
      html
      agent {
        id
        fullName
      }
    }
  }
`;

export const useUpdateMarketingLink = () => {
  const { useMutation } = useApi();
  return useMutation<UpdateMarketingLink, UpdateMarketingLinkVariables>(UPDATE_MARKETING_LINK);
};
