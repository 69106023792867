import { CreatePropertyAudit, CreatePropertyAuditVariables } from './__generated__/CreatePropertyAudit';

import { useApi, gql } from './Provider';

export const CREATE_PROPERTY_AUDIT = gql`
  mutation CreatePropertyAudit($propertyId: Int!, $type: String) {
    createPropertyAudit(propertyId: $propertyId, type: $type) {
      id
    }
  }
`;

export const usePropertyAudit = () => {
  const { useMutation } = useApi();
  return useMutation<CreatePropertyAudit, CreatePropertyAuditVariables>(CREATE_PROPERTY_AUDIT);
};
