import { Component, MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface ComponentResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Component;
  metadata: MetaData;
}

export const useGetComponent = (id: number | string) => {
  const [component] = useApi.get({
    url: `/component/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(component) as ComponentResponse;
};
