import { PointOfInterest } from '../../types';
import api from '../../utils/api';

export const getPointsOfInterest = (propertyId: string): Promise<Array<PointOfInterest>> =>
  api
    .get({
      url: `/property/${propertyId}/points-of-interest`,
      variables: {
        offset: 0,
        limit: -1,
      },
    })
    .then((res) => res?.data?.data || []);
