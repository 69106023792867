import * as React from 'react';

import styled from 'styled-components';

const Styled = {
  Wrapper: styled.div`
    height: 100%;
    position: relative;
  `,
  Upload: styled.input`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  `,
};

interface UploadWrapperProps {
  children: React.ReactNode;
  onChange: (event: any) => void;
}

export const UploadWrapper = (props: UploadWrapperProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Upload
        type="file"
        onChange={(event) => {
          if (event.target.files) {
            props.onChange(event);
          }
        }}
      />
      {props.children}
    </Styled.Wrapper>
  );
};
