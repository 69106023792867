import { HandleMarketingLinkFlow, HandleMarketingLinkFlowVariables } from './__generated__/HandleMarketingLinkFlow';
import { gql, useApi } from './Provider';

const HANDLE_MARKETING_LINK_FLOW = gql`
  mutation HandleMarketingLinkFlow($input: HandleMarketingLinkFlowInput!) {
    handleMarketingLinkFlow(input: $input) {
      state
      password
      userId
    }
  }
`;

export const useHandleMarketingLinkFlow = () => {
  const { useMutation } = useApi();
  return useMutation<HandleMarketingLinkFlow, HandleMarketingLinkFlowVariables>(HANDLE_MARKETING_LINK_FLOW);
};
