import React from 'react';
import styled from 'styled-components';
import { useTranslations } from '../translations';
import { REACT_APP_BROCHURE_DOMAIN } from '../utils/constants';
import { font } from '../utils/styles';

export const PoweredByVystaLogo = () => {
  const translations = useTranslations();
  return (
    <a target="_blank" rel="noreferrer" href={REACT_APP_BROCHURE_DOMAIN}>
      <Styled.Container>
        {translations.Common.poweredBy}
        <Styled.PoweredByVysta src="/icons/vysta.svg" />
      </Styled.Container>
    </a>
  );
};
const Styled = {
  PoweredByVysta: styled.img`
    height: 15px;
  `,
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 19px;
    width: 95px;
    color: #000000;
    font-family: ${font.title};
    font-size: 13px;
    font-style: italic;
  `,
};
