import React, { ReactNode, forwardRef } from 'react';

import { CustomIcon } from './CustomIcon';
import Icon from './Icon';
import { RESPONSIVE } from '../utils/constants';
import Spinner from './Spinner';
import { SvgIcon } from './SvgIcon';
import { font } from '../utils/styles';
import styled from 'styled-components';

interface ButtonProps {
  className?: string;
  customStyle?: string;
  iconOnly?: boolean;
  children?: ReactNode;
  icon?: string | ReactNode;
  iconSize?: 18 | 24 | 48 | 96 | 512;
  iconWidth?: string;
  iconHeight?: string;
  iconType?: 'material' | 'custom' | 'svg';
  colorIcon?: 'black' | 'white' | 'primary';
  color?: string;
  textColor?: string;
  family?: 'regular' | 'bold' | 'light';
  width?: string;
  height?: string;
  letterSpacing?: string;
  disabled?: boolean;
  isWorking?: boolean;
  isActive?: boolean;
  onClick?: any;
  testId?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  justifyContent?: string;
  title?: string;
  fontSize?: string;
}

const defaultProps: ButtonProps = {
  className: undefined,
  customStyle: '',
  children: undefined,
  iconSize: 24,
  disabled: false,
  isWorking: false,
  onClick: () => {},
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      customStyle,
      children,
      color,
      icon,
      colorIcon,
      iconSize,
      iconWidth,
      iconHeight,
      iconType,
      family,
      disabled,
      isWorking,
      onClick,
      width,
      letterSpacing,
      fontSize,
      testId,
      ...buttonProps
    } = defaultProps,
    ref,
  ) => {
    const handleClick = (e) => {
      if (!disabled && !isWorking) {
        if (onClick) onClick(e);
      }
    };

    return (
      <StyledButton
        data-testid={testId}
        className={className}
        customStyle={customStyle}
        color={color}
        width={width}
        fontSize={fontSize}
        letterSpacing={letterSpacing}
        onClick={handleClick}
        disabled={disabled || isWorking}
        isWorking={isWorking}
        iconOnly={!children}
        ref={ref}
        {...buttonProps}
      >
        {isWorking && <Spinner size={26} color="#000" />}

        {!isWorking && icon && typeof icon === 'string' ? (
          iconType === 'material' ? (
            <Icon type={icon} color={colorIcon} size={iconSize} />
          ) : iconType === 'svg' ? (
            <SvgIcon type={icon} size={iconSize} width={iconWidth && +iconWidth} height={iconHeight && +iconHeight} />
          ) : (
            <CustomIcon
              type={icon}
              color={colorIcon}
              size={iconSize}
              family={family}
              cssWidth={iconWidth}
              cssHeight={iconHeight}
            />
          )
        ) : (
          icon
        )}
        {children && icon ? <TextWithIcon>{children}</TextWithIcon> : <Text>{children}</Text>}
      </StyledButton>
    );
  },
);

export const StyledButton = styled.button<ButtonProps>`
  border: 0px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.height ? `${props.height}` : '50.33px')};
  min-width: ${(props) => (props.width ? `${props.width}` : '150px')};
  background-color: ${(props) => (props.color ? `${props.color}` : '#c9985e')};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  color: ${(props) => (props.textColor ? `${props.textColor}` : '#ffffff')};
  font-family: ${font.regular};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : '16px')};
  letter-spacing: ${(props) => (props.letterSpacing ? `${props.letterSpacing}` : '-0.38px')};
  cursor: pointer;

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${(props) => (props.customStyle ? props.customStyle : '')}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    min-height: ${(props) => (props.height ? `${props.height}` : '40px')};
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : '14px')};
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: relative;
  top: 1px;
`;

export const TextWithIcon = styled.div`
  padding-left: 7px;
`;

export const Text = styled.div`
  padding: 0;
`;

export default Button;
