import { PointOfInterest } from '../../types';
import api from '../../utils/api';
import { omit } from 'lodash/fp';

export const updatePointOfInterest = (poi: PointOfInterest) => {
  const { id, ...variables } = poi;

  return api.put({
    url: `/point-of-interest/${id}`,
    variables: omit(['id'], variables),
  });
};
