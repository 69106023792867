import { MetaData, PropertyType, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface PropertyTypeResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<PropertyType>;
  metadata: MetaData;
}

export const useGetPropertyTypes = () => {
  const [propertyTypes] = useApi.get({
    url: `/property-type`,
  });

  return formatApiResult(propertyTypes) as PropertyTypeResponse;
};
