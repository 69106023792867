import { BuyerProfile, MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface BuyerProfileResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: BuyerProfile;
  metadata: MetaData;
}

export const useGetBuyerProfile = (userId: number | string) => {
  const [buyerProfile] = useApi.get({
    url: `/buyer-profile/${userId}`,
  });

  return formatApiResult(buyerProfile) as BuyerProfileResponse;
};
