import React, { useRef } from 'react';

import styled from 'styled-components/native';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface IDropDown {
  setShow: (show: boolean) => void;
  width?: number;
  height?: number;
  show: boolean;
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  optionsOffset?: number;
}

interface IStyledDropDown {
  optionsOffset?: number;
  height?: number;
}

interface IStyledContainer {
  width?: number;
}

const Styled = {
  Container: styled.View<IStyledContainer>`
    position: absolute;
    left: 0px;
    top: 38px;
    ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  `,
  DropDown: styled.View<IStyledDropDown>`
    position: relative;
    width: calc(100% + 2px);
    max-height: 300px;
    background-color: ${(props) => props.theme.color.white};
    border: solid 1px ${(props) => props.theme.color.gray1};
    padding: 9px;
    border-radius: 0px 0px 6px 6px;
    ${(props) => (props.height ? `height: ${props.height}px;` : '')}
  `,
};

export const DropDown = (props: IDropDown) => {
  const ref = useRef();

  useOutsideClick(ref, () => {
    props.setShow(false);
  });

  return (
    <Styled.Container width={props.width}>
      {props.show && (
        <Styled.DropDown ref={ref} optionsOffset={props.optionsOffset} height={props.height}>
          {props.children}
        </Styled.DropDown>
      )}
    </Styled.Container>
  );
};
