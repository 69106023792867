import { useApi, gql } from './Provider';
import { VerifyClient, VerifyClientVariables } from './__generated__/VerifyClient';

const VERIFY_CLIENT = gql`
  mutation VerifyClient($input: VerifyUserInput!) {
    verifyUser(input: $input) {
      id
    }
  }
`;

export const useVerifyClient = () => {
  const { useMutation } = useApi();
  return useMutation<VerifyClient, VerifyClientVariables>(VERIFY_CLIENT);
};
