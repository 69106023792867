import { useCallback, useEffect, useRef, useState } from 'react';

export async function processUpload(files, uploadType) {
  const pendingUploads = files.map(
    (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const upload = {
            kind: 'client',
            file,
            uri: reader.result,
            uploadType,
          };

          resolve(upload);
        };

        reader.readAsDataURL(file);
      }),
  );

  return Promise.all(pendingUploads);
}

const useImageUpload = (onUpload, allowMultiple, uploadType = (_e) => {}) => {
  const inputRef = useRef();
  const dragLock = useRef(0);
  const [previewUri, setPreviewUri] = useState();

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    dragLock.current = 0;
    // const { uri } = props;
    // setPreviewUri(uri);
  }, []);

  const uploadFiles = async (files) => {
    const uploads = await processUpload(files, uploadType);
    onUpload(uploads);
  };

  const handleClickUpload = (evt) => {
    evt.preventDefault();
    const files = Array.from(evt.target.files);
    uploadFiles(files);
  };

  const handleDrag = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleDragIn = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.dataTransfer.items && evt.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }

    dragLock.current++;
  };

  const handleDragOut = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (--dragLock.current <= 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    setIsDragging(false);

    if (!allowMultiple && evt.dataTransfer.files.length > 1) {
      return;
    }

    if (evt.dataTransfer.files && evt.dataTransfer.files.length > 0) {
      const files = Array.from(evt.dataTransfer.files);
      uploadFiles(files);
      evt.dataTransfer.clearData();
      dragLock.current = 0;
    }
  };

  const initiateUpload = () => {
    if (!inputRef) {
      return;
    }

    const input = inputRef?.current as any;

    return input && input.click();
  };

  // eslint-disable-next-line
  const handleDropCallback = useCallback(handleDrop, []);
  const handleDragCallback = useCallback(handleDrag, []);
  const handleDragOutCallback = useCallback(handleDragOut, []);
  const handleDragInCallback = useCallback(handleDragIn, []);

  useEffect(() => {
    document.addEventListener('dragenter', handleDragInCallback);
    document.addEventListener('dragleave', handleDragOutCallback);
    document.addEventListener('dragover', handleDragCallback);
    document.addEventListener('drop', handleDropCallback);

    return () => {
      document.removeEventListener('dragenter', handleDragInCallback);
      document.removeEventListener('dragleave', handleDragOutCallback);
      document.removeEventListener('dragover', handleDragCallback);
      document.removeEventListener('drop', handleDropCallback);
    };
  }, [handleDropCallback, handleDragCallback, handleDragOutCallback, handleDragInCallback]);

  return {
    dragLock,
    inputRef,
    uploadFiles,
    initiateUpload,
    handleClickUpload,
    handleDrop,
    handleDragOut,
    handleDragIn,
    handleDrag,
    previewUri,
    setPreviewUri,
    isDragging,
    setIsDragging,
  };
};

export default useImageUpload;
