import * as React from 'react';

import Icon from './Icon';
import { Link } from 'react-router-dom';
import styled from '../styled-components';
import { font } from '../utils/styles';

interface ListingCardAddProps {
  to: string;
  label?: string;
  testId?: string;
}

const S = {
  Container: styled.div`
    height: 100%;
    min-height: 320px;
    flex: 1;

    & > a {
      display: flex;
      flex: 1;
    }
  `,
  Link: styled(Link)`
    height: 100%;
    text-decoration: none;
    flex: 1;
    display: flex;
  `,
  Box: styled.div`
    background-color: #f5f5f5;
    flex: 1;
    border: dashed 2px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;

    &:hover,
    &:focus {
      background-color: #f3f3f3;
    }
  `,
  Circle: styled.div`
    width: 80px;
    height: 80px;
    background: #e8e8e8;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Label: styled.div`
    padding-top: 14px;
    font-family: ${font.regular};
    font-size: 16px;
    font-weight: bold;
    color: #000;
  `,
};

export const ListingCardAdd = (props: ListingCardAddProps) => {
  return (
    <S.Container>
      <S.Link to={props.to} data-testid={props.testId || 'listing-card-add'}>
        <S.Box>
          <S.Circle>
            <Icon type="add" color="#000" size={52} />
          </S.Circle>
          {props.label ? <S.Label>{props.label}</S.Label> : null}
        </S.Box>
      </S.Link>
    </S.Container>
  );
};
