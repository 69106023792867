import * as React from 'react';

import { ITh } from './types';
import { Text } from '../Text';
import styled from 'styled-components';

interface IStyledTh {
  width?: number;
  hidden?: boolean;
}

const Styled = {
  Th: styled.th<IStyledTh>`
    text-align: left;
    border-bottom: solid 1px ${(props) => props.theme.color.gray1};
    padding: 7px;
    background-color: ${(props) => props.theme.color.white};
    position: relative;
    ${(props) => (props.width ? `width: ${props.width}px;` : '')}
    ${(props) => (props.hidden ? 'display: none;' : '')}
  `,
};

export const Th = (props: ITh) => {
  return (
    <Styled.Th width={props.width} hidden={props.hidden}>
      <Text weight="black" size="sm">
        {props.children}
      </Text>
    </Styled.Th>
  );
};
