import styled from 'styled-components';
import { font } from '../../utils/styles';

export const Container = styled.div`
  flex: 1;
  border: solid 1px #ccc;
  padding: 14px;
  border-radius: 7px;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  color: #000;
  font-family: ${font.regular};
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0px;
  margin: 0px;
`;

export const SubTitle = styled.h3`
  color: #000;
  font-family: ${font.regular};
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  font-weight: normal;
  line-height: 1.6rem;
`;

export const Image = styled.div`
  height: 200px;
  width: 100%;
  background: #ccc;
  background-size: cover;
  background-position: center;
  margin-top: 14px;
  margin-bottom: 14px;
`;

export const Tag = styled.div`
  font-family: ${font.regular};
  font-size: 0.8rem;
  padding: 7px 16px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 14px;
`;

export const Detail = styled.div`
  color: #000;
  font-family: ${font.regular};
  font-size: 0.8rem;
  width: 50%;
  text-align: left;

  &:nth-child(even) {
    text-align: right;
  }
`;

export const DetailKey = styled.span`
  color: #969696;
`;

export const DetailValue = styled.span`
  font-weight: bold;
  padding-left: 7px;
`;
