import { IStyledWrapper } from './IconTypes';
import { getIconSize } from './getIconSize';
import styled from 'styled-components/native';

const colorFilters = {
  black: 'invert(0%) sepia(6%) saturate(7476%) hue-rotate(328deg) brightness(94%) contrast(106%)',
  white: 'invert(100%) sepia(0%) saturate(7434%) hue-rotate(300deg) brightness(113%) contrast(103%)',
  gold: 'invert(61%) sepia(11%) saturate(1619%) hue-rotate(352deg) brightness(101%) contrast(90%)',
  gray1: 'invert(100%) sepia(0%) saturate(971%) hue-rotate(179deg) brightness(93%) contrast(88%)',
  gray2: 'invert(100%) sepia(3%) saturate(64%) hue-rotate(170deg) brightness(112%) contrast(92%)',
};

export const IconWrapper = styled.View<IStyledWrapper>`
  width: ${getIconSize}px;
  height: ${getIconSize}px;
  filter: ${(props) => colorFilters[props.color] || 'none'};
`;
