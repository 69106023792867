import { parse, stringify } from 'query-string';
import { omit } from 'lodash';

export const queryStringToObject = (str: string, options: Record<string, any> = {}) =>
  parse(str, {
    arrayFormat: 'bracket',
    ...options,
  });

export const objectToQueryString = (obj: Record<string, any>, options: Record<string, any> = {}) =>
  stringify(obj, {
    arrayFormat: 'bracket',
    ...options,
  });

export const omitFromQueryString = (str: string, keys: Array<string>) =>
  objectToQueryString(omit(queryStringToObject(str), keys));

export const addToQueryString = (str: string, fields: Record<string, any>) =>
  objectToQueryString({
    ...queryStringToObject(str),
    ...fields,
  });
