import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

export interface SeparatorProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  noVerticalMargin?: boolean;
  onClick?: (event: React.MouseEvent) => any;
}

const defaultProps: SeparatorProps = {
  className: undefined,
  children: undefined,
  title: undefined,
  noVerticalMargin: false,
  onClick: () => {},
};

const Separator = (props: SeparatorProps = defaultProps) => {
  const { className, children, title, onClick, noVerticalMargin } = props;

  return (
    <StyledSeparator className={`${className ? className : ''}`} onClick={onClick} noVerticalMargin={noVerticalMargin}>
      {children ? children : null}
      {title ? <span>{title}</span> : null}
    </StyledSeparator>
  );
};

export const StyledSeparator = styled.div<SeparatorProps>`
  width: calc(100% - 10rem);
  ${(props) => (props.noVerticalMargin ? `margin: 1rem 5rem;` : 'margin: 5rem 5rem;')}
  display: flex;
  align-items: center;
  text-align: left;
  font-family: ${font.title};
  font-size: 2rem;
  font-weight: bold;

  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e6e6e6;
  }

  span {
    padding-right: 30px;
  }
`;

export default Separator;
