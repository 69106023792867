export enum InviteStatus {
  ACCEPTED = 'ACCEPTED',
  CREATED = 'CREATED',
  INVITED = 'INVITED',
}

export enum MarketingLinkStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum MarketingLinkType {
  LandingPage = 'LandingPage',
  Registration = 'Registration',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PENDING_INVITATION = 'PENDING_INVITATION',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
}

export enum MarketingLinkFlowRole {
  Agent = 'Agent',
  Client = 'Client',
}
