import { useHistory } from 'react-router-dom';

export const useSearch = () => {
  const history = useHistory();

  const suggestionSelected = (selection) => {
    if (!selection) {
      return;
    }
    let params: string = '';
    if (selection.__typename === 'Locale') {
      params = `localeId=${encodeURI(selection.id)}&searchTerm=${encodeURI(selection.name)}`;
    }

    if (selection.__typename === 'City') {
      params = `cityId=${encodeURI(selection.id)}&searchTerm=${encodeURI(selection.name)}&latitude=${
        selection.longitude
      }&longitude=${selection.latitude}&zoom=${selection.zoom || 11}`;
    }

    if (selection.__typename === 'Province') {
      params = `provinceId=${encodeURI(selection.id)}&searchTerm=${encodeURI(selection.name)}&latitude=${
        selection.longitude
      }&longitude=${selection.latitude}&zoom=${selection.zoom || 6}`;
    }

    if (selection.__typename === 'Country') {
      params = `countryId=${encodeURI(selection.id)}&searchTerm=${encodeURI(selection.name)}&latitude=${
        selection.longitude
      }&longitude=${selection.latitude}&zoom=${selection.zoom || 4}`;
    }

    if (selection.__typename === 'SearchProperty') {
      // must be a building since it is an object and locations are just strings
      params = `propertyId=${encodeURI(selection.id)}&searchTerm=${encodeURI(selection.name)}`;
    }
    history.push(`/search?${params}`);
  };

  return {
    suggestionSelected,
  };
};
