import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';

import { getItem } from '../../utils/localStorage';
import { onError } from '@apollo/client/link/error';
import { queryStringToObject } from '../../utils/url';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = getItem('vysta:authToken');
  const auditSessionId = getItem('vysta:auditSessionId');
  const emulateUser = queryStringToObject(window.location.search)?.emulateUser;

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
      'audit-session-id': auditSessionId ? auditSessionId : undefined,
      ...(emulateUser
        ? {
            'X-Emulate-User': +emulateUser,
          }
        : {}),
    },
  };
});

const errorLink = onError(() => {});

const link = from([errorLink, authLink.concat(httpLink)]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          clients: {
            keyArgs: [],
            merge(existing = {}, incoming) {
              // Remove duplicates
              const incomingUsers = incoming.users.filter(
                (user) => !(existing?.users || []).find((existingUser) => existingUser.__ref === user.__ref),
              );

              return {
                pagination: incoming.pagination,
                users: [...(existing?.users || []), ...incomingUsers],
              };
            },
          },
        },
      },
    },
  }),
});

export { ApolloProvider };
