import React from 'react';
import Spinner from './Spinner';
import styled from 'styled-components';

interface Props {
  className?: string;
}

export const PageLoader = (props: Props) => (
  <StyledPageLoader className={props.className}>
    <Spinner size={40} />
  </StyledPageLoader>
);

const StyledPageLoader = styled.div`
  width: 100%;
  padding: 200px 0;
  text-align: center;
`;

export default PageLoader;
