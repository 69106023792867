export interface IconButton {
  id: string;
  testId?: string;
  label: string;
  icon: string;
  actionHandler: () => void;
  isVisible: boolean;
}

export enum UserStatus {
  PENDING_INVITATION = 'PENDING_INVITATION',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  ACTIVE = 'ACTIVE',
}

export enum InviteStatus {
  ACCEPTED = 'ACCEPTED',
  CREATED = 'CREATED',
  INVITED = 'INVITED',
}
