import { Column, SortingRule, TableState, useSortBy, useTable } from 'react-table';

import React from 'react';
import { font } from '../utils/styles';
import styled from 'styled-components';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';
import { RESPONSIVE } from '../utils/constants';

interface ReportTableProps {
  columns: Array<Column>;
  data: Array<object>;
  sortBy?: Array<SortingRule<object>>;
  mobileColumns?: Array<Column>;
}

interface IconSortProps {
  up?: boolean;
}

export const ReportTable = (props: ReportTableProps) => {
  const { isSmall } = useResponsiveSizes();
  const { columns, data, sortBy, mobileColumns } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: isSmall && mobileColumns ? mobileColumns : columns,
      data,
      initialState: {
        sortBy: sortBy,
      } as TableState,
    },
    useSortBy,
  );

  return (
    <Styled.Container>
      <Styled.Table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup: any) =>
              headerGroup.headers.map((column: any) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                  width={column.width}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        <Styled.IconSort src="/icons/icon-drop-down-arrow.svg" up />
                      ) : (
                        <Styled.IconSort src="/icons/icon-drop-down-arrow.svg" />
                      ))}
                  </span>
                </th>
              )),
            )}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row: any) => {
              prepareRow(row);
              return (
                <tr key={row.id}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td title={cell.value?.toString()} key={cell.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>No data for this filter.</td>
            </tr>
          )}
        </tbody>
      </Styled.Table>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    width: 100%;
    overflow: auto;
    border: 1px solid #cecece;
    border-radius: 4px;
    border-collapse: collapse;
  `,
  Table: styled.table`
    width: 100%;
    min-width: 100px;
    overflow-x: auto;
    background-color: white;
    border: 1px solid #cecece;
    border-radius: 4px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 0 1px #cecece;

    th {
      padding: 12px;
      font-size: 12px;
      color: #000;
      font-weight: bold;
      letter-spacing: 0;
      text-transform: uppercase;

      &:nth-child(1) {
        padding-left: 17px;
        text-align: left;
        width: 100px;
      }
      @media (max-width: ${RESPONSIVE.SMALL}) {
        &:last-child {
          text-align: right;
        }
      }
    }

    tr {
      border: 1px solid #cecece;
      height: 50px;

      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    td {
      color: #000000;
      font-family: ${font.regular};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 49px;
      text-align: center;
      max-width: 12rem;
      padding: 0px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-child(1) {
        padding-left: 17px;
        text-align: left;
        font-weight: bold;
        width: 100px;
        display: block;
      }
      @media (max-width: ${RESPONSIVE.SMALL}) {
        &:last-child {
          text-align: right;
          padding-right: 10px;
          color: #c9985e;
          font-weight: 600;
        }
      }
    }
  `,
  IconSort: styled.img<IconSortProps>`
    height: 10px;
    margin-left: 10px;
    transform: ${(props) => (props.up ? 'rotate(180deg)' : 'rotate(0deg)')};
  `,
};
