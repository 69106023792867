import * as React from 'react';

import { RESPONSIVE } from '../utils/constants';
import { Title } from '../@components';
import styled from 'styled-components';

interface PortalLayoutProps {
  title?: string;
  header?: string | React.ReactNode;
  children: React.ReactNode;
  transparentBackground?: boolean;
}

const Styled = {
  PortalPage: styled.div`
    background: #fafafa;
    width: 100%;
    height: 100%;
    padding: 0px 2rem;
    font-weight: bold;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      padding: 0px;
    }
  `,
  PageHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      margin-bottom: 0;
    }
  `,
  DetailsContainer: styled.div`
    padding: 1rem;
    border-radius: 6px;
    background: white;
    border: 1px solid #dadada;
    overflow: auto;
    margin-bottom: 0px;
  `,
  DetailsContainerTransparent: styled.div`
    margin-bottom: 110px;

    .container {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    button {
      margin-left: 7px;
    }

    @media (max-width: 900px) {
      width: 100%;
      flex-direction: column;

      button {
        min-width: 100% !important;
        margin-bottom: 7px;
        margin-left: 0px;
      }
    }
  `,
};

export const PortalLayout = (props: PortalLayoutProps) => {
  return (
    <Styled.PortalPage>
      <Styled.PageHeader>
        {!!props.title && (
          <Title testID="myAccount:title" size="h2">
            {props.title}
          </Title>
        )}
        <Styled.Header>{props.header ? props.header : null}</Styled.Header>
      </Styled.PageHeader>
      {props.transparentBackground ? (
        <Styled.DetailsContainerTransparent>{props.children}</Styled.DetailsContainerTransparent>
      ) : (
        <Styled.DetailsContainer>{props.children}</Styled.DetailsContainer>
      )}
    </Styled.PortalPage>
  );
};
