import { MetaData, PropertyConstructionStatus, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface PropertyConstructionStatusResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<PropertyConstructionStatus>;
  metadata: MetaData;
}

export const useGetPropertyConstructionStatuses = () => {
  const [propertyConstructionStatuses] = useApi.get({
    url: `/construction-status`,
  });

  return formatApiResult(propertyConstructionStatuses) as PropertyConstructionStatusResponse;
};
