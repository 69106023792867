import * as React from 'react';

import styled from 'styled-components';
import { font } from '../utils/styles';
import { Icon } from './Icon';

interface Column {
  key: string;
  label: string | React.ReactNode;
  onLabelClick?: () => void;
  width?: number;
  render?: (column: string | any, row?: any) => React.ReactNode | string;
  align?: 'left' | 'right' | 'center';
  editable?: boolean;
  editor?: React.ReactNode;
}

interface TableProps {
  sortable?: boolean;
  showHeader?: boolean;
  columns: Array<Column>;
  data: Array<Record<string, any>>;
  empty?: string;
  minWidth?: number;
}

function calcCellId(i: number, j: number) {
  return `${i * j + j}`;
}

export const SmartTable = (props: TableProps) => (
  <Styled.Table
    style={{
      minWidth: props.minWidth,
    }}
  >
    <thead>
      <tr>
        {props.columns &&
          props.columns.map((c) => (
            <Styled.HeaderCell
              key={c.key}
              style={{
                width: c.width,
                textAlign: c.align,
              }}
            >
              {c.onLabelClick ? (
                <Styled.ClickableHeader onClick={c.onLabelClick}>
                  {c.label}
                  <Icon type="sort" color="gray" size={12} />
                </Styled.ClickableHeader>
              ) : (
                c.label
              )}
            </Styled.HeaderCell>
          ))}
      </tr>
    </thead>
    <tbody>
      {(!props.data || props.data.length === 0) && props.empty && (
        <tr>
          <Styled.EmptyCell colSpan={props.columns.length}>{props.empty}</Styled.EmptyCell>
        </tr>
      )}
      {props.data &&
        props.data.length > 0 &&
        props.data.map((row, rowIndex) => {
          return (
            <tr key={`${rowIndex}`}>
              {props.columns.map((c, colIndex) => (
                <Styled.TableCell
                  key={calcCellId(rowIndex, colIndex)}
                  style={{
                    width: c.width,
                    textAlign: c.align,
                  }}
                >
                  {c.render ? c.render(row[c.key], row) : row[c.key]}
                </Styled.TableCell>
              ))}
            </tr>
          );
        })}
    </tbody>
  </Styled.Table>
);

const Styled = {
  ClickableHeader: styled.button`
    padding: 0px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    font-weight: bold;
    position: relative;

    i {
      position: absolute;
      left: -20px;
      top: 2px;
      display: none;
    }

    &:hover i {
      display: block;
    }
  `,
  Table: styled.table`
    width: 100%;
    background: #fff;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 0.5em;
    overflow: hidden;
  `,
  HeaderCell: styled.th`
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    font-family: ${font.regular};
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.5rem;

    :first-child {
      border-left: 1px solid #d8d8d8;
    }

    :last-child {
      border-right: 1px solid #d8d8d8;
    }
  `,
  EmptyCell: styled.td`
    font-family: ${font.regular};
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 0.5rem;

    :first-child {
      border-left: 1px solid #d8d8d8;
    }

    :last-child {
      border-right: 1px solid #d8d8d8;
    }
  `,
  TableCell: styled.td`
    font-family: ${font.regular};
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 0.5rem;

    :first-child {
      border-left: 1px solid #d8d8d8;
    }

    :last-child {
      border-right: 1px solid #d8d8d8;
    }
  `,
};

export default SmartTable;
