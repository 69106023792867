import * as Sentry from '@sentry/react';

import React, { ReactNode } from 'react';
import { color, font } from '../utils/styles';

import Icon from '../components/Icon';
import Logo from '../components/Logo';
import { RESPONSIVE } from '../utils/constants';
import styled from 'styled-components';
import useTranslations from '../translations';

interface PageErrorProps {
  title?: string;
  description?: string;
  content?: ReactNode;
  error?: any;
}

const PageError = (props: PageErrorProps) => {
  const translations = useTranslations();

  React.useEffect(() => {
    if (props.error) {
      if (typeof props.error === 'string') {
        try {
          throw new Error(props.error);
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        Sentry.captureException(props.error);
      }
    }
  }, [props.error]);

  return (
    <ErrorPage>
      <ErrorPageInner>
        <ErrorBox>
          <div>
            <Icon type="bug_report" />
            <Title>{props.title || translations.ErrorPage.title}</Title>
            <p>{props.description || translations.ErrorPage.description}</p>
            {props.content}
          </div>
          <LogoContainer>
            <Logo variant="accent" height={20} />
          </LogoContainer>
        </ErrorBox>
      </ErrorPageInner>
    </ErrorPage>
  );
};

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const ErrorPage = styled.div`
  padding: 64px;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    padding: 1rem;
    height: 100vh;
  }
`;

export const ErrorPageInner = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 200px 0;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    padding: 0px;
    height: 100%;
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  max-width: 480px;
  padding: 32px;
  border-radius: 3px;
  border: 1px solid ${color.borderLight};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.9);
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    padding: 1rem;
    height: 100%;
  }
`;

export const StyledIcon = styled(Icon)<{ type: string }>`
  position: absolute;
  top: 32px;
  left: 32px;
  font-size: 30px;
  color: ${color.primary};
`;

export const Title = styled.h1`
  margin-bottom: 16px;
  padding-left: 42px;
  ${font.size(29)}

  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    margin-bottom: 1rem;
    padding: 0px;
    font-size: 20px;
  }
`;

export default PageError;
