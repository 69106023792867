export const getStoredAuthToken = () => {
  return localStorage.getItem('vysta:authToken');
};

export const getAuthToken = () => {
  const accessToken = getStoredAuthToken();

  if (!accessToken) {
    return '';
  }

  return `Bearer ${accessToken}`;
};
