import { MobileContactSheet } from './types';
import { createContext } from 'react';

export const MobileContactSheetContext = createContext<MobileContactSheet>({
  contactSheet: {
    type: 'hidden',
  },
  setContactSheet: (_contactSheet) => {},
  hideContactSheet: () => {},
  showContactSheet: (_contactSheet) => {},
  setHasPropertyScope: (_hasPropertyScope) => {},
});
