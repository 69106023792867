import { useCallback, useEffect, useRef } from 'react';

import { Method } from 'axios';
import api from '../utils/api';
import { isEqual } from 'lodash';
import useDeepCompareMemoize from './deepCompareMemoize';
import useMergeState from './mergeState';

const cache: Record<string, any> = {};

export interface IApiOptions {
  lazy?: boolean;
  cachePolicy?: ICachePolicy;
}

export enum ICachePolicy {
  CACHE_FIRST = 1,
  NO_CACHE = 2,
  CACHE_ONLY = 3,
}

export interface IApiRequest {
  url: string;
  variables?: Record<string, any> | Array<Record<string, any>>;
  options?: IApiOptions;
}

const useQuery = (method: Method, url: string, propsVariables = {}, options: IApiOptions = {}) => {
  const { lazy = false, cachePolicy = ICachePolicy.CACHE_FIRST } = options;

  const wasCalled = useRef(false);
  const propsVariablesMemoized = useDeepCompareMemoize(propsVariables);

  const isSleeping = lazy && !wasCalled.current;
  const isCacheAvailable = cache[url] && isEqual(cache[url].apiVariables, propsVariables);
  const canUseCache = isCacheAvailable && cachePolicy !== ICachePolicy.NO_CACHE && !wasCalled.current;

  const [state, mergeState] = useMergeState({
    data: canUseCache ? cache[url].data : null,
    error: null,
    isLoading: !lazy && !canUseCache,
    variables: {},
  });

  const makeRequest = useCallback(
    (newVariables?: any) => {
      const variables = { ...state.variables, ...(newVariables || {}) };
      const apiVariables = { ...propsVariablesMemoized, ...variables };

      const skipLoading = canUseCache && cachePolicy === ICachePolicy.CACHE_FIRST;

      if (!skipLoading) {
        mergeState({ isLoading: true, variables });
      } else if (newVariables) {
        mergeState({ variables });
      }

      try {
        api.get({ url, variables: apiVariables }).then(
          (data: any) => {
            cache[url] = { data: data.data, apiVariables };
            mergeState({ data: data.data, error: null, isLoading: false });
          },
          (error: Error) => {
            mergeState({ error, data: null, isLoading: false });
          },
        );
      } catch (error) {
        mergeState({ error, data: null, isLoading: false });
      }

      wasCalled.current = true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propsVariablesMemoized],
  );

  useEffect(() => {
    if (isSleeping) return;
    if (canUseCache && cachePolicy === ICachePolicy.CACHE_ONLY) return;

    makeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeRequest]);

  const setLocalData = useCallback(
    (getUpdatedData) =>
      mergeState(({ data }: any) => {
        const updatedData = getUpdatedData(data);
        cache[url] = { ...(cache[url] || {}), data: updatedData };
        return { data: updatedData };
      }),
    [mergeState, url],
  );

  const setData = (data: any) => {
    mergeState({
      ...state,
      data: {
        ...state.data,
        data,
      },
      error: null,
      isLoading: false,
    });
  };

  return [
    {
      ...state,
      variables: { ...propsVariablesMemoized, ...state.variables },
      setLocalData,
      refetch: makeRequest,
    },
    makeRequest,
    setData,
  ];
};

/* eslint-disable react-hooks/rules-of-hooks */
const apiHooks = {
  useQuery,
  get: ({ url, variables, options }: IApiRequest) => useQuery('get', url, variables, options),
  post: ({ url, variables, options }: IApiRequest) => useQuery('post', url, variables, options),
  put: ({ url, variables, options }: IApiRequest) => useQuery('put', url, variables, options),
  patch: ({ url, variables, options }: IApiRequest) => useQuery('patch', url, variables, options),
  delete: ({ url, variables, options }: IApiRequest) => useQuery('delete', url, variables, options),
};

export default apiHooks;
