import 'react-hot-loader';

import * as Sentry from '@sentry/react';
import * as smoothscroll from 'smoothscroll-polyfill';

import App from './App';
import LogRocket from 'logrocket';
import PageError from './views/PageError';
import React from 'react';
import ReactDOM from 'react-dom';
import { initialiseAnalytics } from './track';

if (process.env.REACT_APP_LOGROCKET_KEY) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
}

smoothscroll.polyfill();
initialiseAnalytics();

let renderApp = true;

try {
  localStorage.setItem('vystaApiEndpoint', process.env.REACT_APP_GRAPHQL_ENDPOINT);
} catch (e) {
  renderApp = false;
  ReactDOM.render(
    <PageError
      title="Please Enable LocalStorage"
      description="You have a browser setting preventing you from using Vysta. If you are using the Brave browser, try taking your shields down on this site to enable cross-domain localStorage access."
    />,
    document.getElementById('content'),
  );
}

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_STAGE || 'dev',
    release: `frontend@${process.env.REACT_APP_RELEASE_VERSION || 'test'}`,
  });
}

// Verbose logging for dev
if (process.env.REACT_APP_STAGE !== 'prod') {
  localStorage.debug = '*';
}

if (renderApp) {
  ReactDOM.render(<App />, document.getElementById('content'));
}
