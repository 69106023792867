import React, { useEffect } from 'react';
import { Floor } from '../../types';

import api from '../../utils/api';

interface FloorsResponse {
  isLoading: boolean;
  error?: string;
  data: Array<Floor>;
  refetch: (componentId: number) => void;
}

export const useGetComponentFloors = (componentId?: string | number) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState();

  useEffect(() => {
    if (!componentId) {
      return;
    }

    setIsLoading(true);
    setError(undefined);
    api
      .get({
        url: `/component/${componentId}/floors?limit=-1`,
      })
      .then((res) => {
        setData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [componentId]);

  const refetch = (componentId: number) => {
    if (!componentId) {
      return;
    }

    setIsLoading(true);
    setError(undefined);
    api
      .get({
        url: `/component/${componentId}/floors?limit=-1`,
      })
      .then((res) => {
        setData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return {
    data,
    isLoading,
    refetch,
    error,
  } as FloorsResponse;
};
