import { IIconType, Icon } from '../Icon';
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Input } from '../Input';
import { Spacer } from '../Spacer';
import { Text } from '../Text';
import { Touchable } from '../Touchable';
import styled from 'styled-components/native';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { SelectOptions } from '../SelectOptions';

interface IComboBoxOption {
  key: string;
  value: string;
  [x: string]: string | number | null | boolean;
}

interface IComboBox {
  value?: string | null;
  onValueChange?: (value: string | null) => void;
  values?: Array<string>;
  onValuesChange?: (values: Array<string>) => void;
  disabled?: boolean;
  options: Array<IComboBoxOption>;
  optionsOffset?: number;
  disableFilter?: boolean;
  name: string;
  renderSelected?: (
    option: IComboBoxOption,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  renderOption?: (
    option: IComboBoxOption,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  renderFirstOption?: (
    value: string,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}

const Styled = {
  ComboBox: styled.View`
    position: relative;
    height: 26px;
    display: flex;
    flex-direction: row;
  `,
  Icon: styled.View`
    position: absolute;
    right: 12px;
  `,
  SelectedValue: styled.View`
    margin-right: 7px;
    margin-top: 3px;
    background-color: ${(props) => props.theme.color.gray1};
    display: flex;
    flex-direction: row;
    padding: 3px 7px;
    border-radius: 3px;
    align-items: center;
  `,
};

export const ComboBox = (props: IComboBox) => {
  const ref = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState<string>(
    props.value ? props.options.find((option) => option.key === props.value)?.value || '' : '',
  );
  useOutsideClick(ref, () => {
    setShowOptions(false);
  });
  const options = (props.disableFilter
    ? props.options
    : props.options.filter((option) => option.value.toLowerCase().includes(inputValue.toLowerCase()))
  ).filter((option) => {
    return !props.values || !props.values.includes(option.key);
  });

  const updateValue = useCallback(() => {
    setInputValue(props.value ? props.options.find((option) => option.key === props.value)?.value || '' : '');
  }, [props.options, props.value]);

  useEffect(() => {
    updateValue();
  }, [updateValue, props.value]);

  const showCloseIcon = !!props.value;

  const renderSelected =
    props.renderSelected ||
    ((option: IComboBoxOption) => {
      return (
        <Styled.SelectedValue>
          <Text size="sm">{option?.value}</Text>
          <Spacer width={3} />
          <Icon type={IIconType.Close} size="xxxs" />
        </Styled.SelectedValue>
      );
    });

  return (
    <SelectOptions
      show={showOptions}
      setShow={setShowOptions}
      value={props.value}
      onValueChange={props.onValueChange}
      values={props.values}
      onValuesChange={props.onValuesChange}
      disabled={(options.length || props.renderFirstOption) && showOptions}
      options={options}
      optionsOffset={props.optionsOffset}
      disableFilter={props.disableFilter}
      inputValue={inputValue}
      setInputValue={setInputValue}
      renderOption={props.renderOption}
      renderFirstOption={props.renderFirstOption}
    >
      <Styled.ComboBox>
        {props.values && (
          <>
            {props.values.map((val) => (
              <Touchable
                key={val}
                testID={`combobox:remove:${val}`}
                onPress={() => {
                  props.onValuesChange(props.values.filter((value) => value !== val));
                }}
              >
                {renderSelected((props.options || []).find((option) => option.key === val))}
              </Touchable>
            ))}
          </>
        )}
        <Input
          onFocus={() => {
            setShowOptions(true);
          }}
          value={inputValue}
          disabled={props.disabled}
          onValueChange={(value) => {
            setInputValue(value);
            setShowOptions(true);
          }}
          name={props.name}
        />
        {!props.disabled && props.onValueChange && (
          <Styled.Icon>
            <Touchable
              testID={`combo:toggle:${props.name}`}
              onPress={() => {
                if (props.disabled) {
                  return;
                }

                if (showCloseIcon) {
                  props.onValueChange('');
                  setInputValue('');
                  setShowOptions(false);
                } else {
                  setShowOptions(true);
                }
              }}
            >
              <Icon
                type={showCloseIcon ? IIconType.Close : IIconType.Chevron}
                size="xxs"
                color={showCloseIcon ? 'gray' : 'gray1'}
              />
            </Touchable>
          </Styled.Icon>
        )}
      </Styled.ComboBox>
    </SelectOptions>
  );
};
