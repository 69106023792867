import * as React from 'react';

import styled from 'styled-components';

import { formatImage } from '../utils/formatImage';

interface ThumbnailProps {
  src?: string | null;
}

const Styled = {
  Thumbnail: styled.div<any>`
    width: 50px;
    height: 50px;
    background-color: #eee;
    background-size: cover;
    background-position: center;
  `,
};

export const Thumbnail = (props: ThumbnailProps) => {
  const src = formatImage(props.src, {
    width: 100,
    height: 100,
  });

  return (
    <Styled.Thumbnail
      style={{
        backgroundImage: src ? `url("${src}")` : undefined,
      }}
    />
  );
};
