import { ImageProps } from 'react-native';
import React from 'react';
import styled from 'styled-components/native';

type IAvatarSize = 'sm' | 'md' | 'lg' | 'xl';

type IAvatarShape = 'square' | 'round';

interface IAvatar {
  size?: IAvatarSize;
  src?: string;
  shape?: IAvatarShape;
}

interface IStyledAvatar extends ImageProps {
  size: IAvatarSize;
  shape: IAvatarShape;
}

const AVATAR_SIZES = {
  sm: 40,
  md: 70,
  lg: 81,
  xl: 120,
};

const Styled = {
  Avatar: styled.Image<IStyledAvatar>`
    object-fit: cover;
    height: ${(props) => AVATAR_SIZES[props.size]}px;
    width: ${(props) => AVATAR_SIZES[props.size]}px;
    ${(props) => (props.shape === 'round' ? `border-radius: 50%;` : '')}
  `,
};

export const Avatar = (props: IAvatar) => {
  return (
    <Styled.Avatar
      source={{
        uri: props.src || '/user-profile.svg',
      }}
      size={props.size || 'md'}
      shape={props.shape || 'square'}
    />
  );
};
