import { MessageAgent, MessageAgentVariables } from './__generated__/MessageAgent';

import { useApi, gql } from './Provider';

const MESSAGE_AGENT = gql`
  mutation MessageAgent($input: MessageAgentInput!) {
    messageAgent(input: $input) {
      id
    }
  }
`;

export const useMessageAgent = () => {
  const { useMutation } = useApi();
  return useMutation<MessageAgent, MessageAgentVariables>(MESSAGE_AGENT);
};
