import 'video-react/dist/video-react.css';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const { Player } = require('video-react') as any;

interface VideoPlayerProps {
  src: string;
  height?: number;
  width?: number;
  onEnded?: () => void;
  onStarted?: () => void;
  autoPlay?: boolean;
  isPaused?: boolean;
}

const Styled = {
  Wrapper: styled.div`
    position: relative;
  `,
};

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { onEnded, onStarted, isPaused } = props;
  const [isEnded, setIsEnded] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [firedOnStart, setFiredOnStart] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (isEnded) {
      if (onEnded) {
        onEnded();
      }
      setIsEnded(false);
    }
  }, [isEnded, onEnded]);

  useEffect(() => {
    if (hasStarted && !firedOnStart) {
      if (onStarted) {
        onStarted();
      }
      setFiredOnStart(true);
      setHasStarted(false);
    }
  }, [hasStarted, firedOnStart, onStarted, setFiredOnStart]);

  useEffect(() => {
    if (isPaused) {
      ref.current?.pause();
    }
  }, [isPaused]);

  useEffect(() => {
    if (ref.current?.video) {
      ref.current?.video?.props.manager.store.subscribe(() => {
        const state = ref.current?.video?.props.manager.store.getState();
        if (state.player.ended) {
          setIsEnded(true);
        } else if (state.player.paused) {
          setFiredOnStart(false);
          setHasStarted(false);
        } else if (state.player.hasStarted) {
          setHasStarted(true);
        }
      });
    }
  }, [ref, setIsEnded, setHasStarted, setFiredOnStart]);

  return (
    <Styled.Wrapper
      style={{
        height: props.height,
      }}
    >
      <Player
        ref={ref}
        autoPlay={props.autoPlay}
        playsInline
        src={props.src}
        height={props.height}
        width={props.width}
        fluid={!props.height && !props.width}
      />
    </Styled.Wrapper>
  );
};
