import { MetaData, Organization, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface OrganizationResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Organization>;
  metadata: MetaData;
}

export const useGetOrganizations = () => {
  const [organizations] = useApi.get({
    url: `/organization`,
    variables: {
      offset: 0,
      limit: -1,
    },
  });

  return formatApiResult(organizations) as OrganizationResponse;
};
