import styled from 'styled-components/native';

interface IPadding {
  size?: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  zIndex?: number;
}

export const Padding = styled.View<IPadding>`
  ${(props) => (props.size ? `padding: ${props.size}px;` : '')}
  ${(props) => (props.left ? `padding-left: ${props.left}px;` : '')}
  ${(props) => (props.right ? `padding-right: ${props.right}px;` : '')}
  ${(props) => (props.top ? `padding-top: ${props.top}px;` : '')}
  ${(props) => (props.bottom ? `padding-bottom: ${props.bottom}px;` : '')}
  position: relative;
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : '')}
`;
