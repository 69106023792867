import React, { useRef } from 'react';

import { ScrollView } from '../ScrollView';
import { Text } from '../Text';
import { Touchable } from '../Touchable';
import styled from 'styled-components/native';
import { useOutsideClick } from '../../hooks/useOutsideClick';

interface ISelectOption {
  key: string;
  value: string;
  [x: string]: string | number | null | boolean;
}

interface ISelectOptions {
  setShow: (show: boolean) => void;
  width?: number;
  show: boolean;
  options: Array<ISelectOption>;
  value?: string | null;
  onValueChange?: (value: string | null) => void;
  values?: Array<string>;
  onValuesChange?: (values: Array<string>) => void;
  disabled?: boolean;
  optionsOffset?: number;
  disableFilter?: boolean;
  inputValue?: string;
  setInputValue?: (value?: string) => void;
  renderOption?: (
    option: ISelectOption,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  renderFirstOption?: (
    value: string,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}

interface IStyledOptions {
  optionsOffset?: number;
}

interface IStyledContainer {
  width?: number;
}

const Styled = {
  Container: styled.View<IStyledContainer>`
    position: relative;
    ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  `,
  Options: styled.View<IStyledOptions>`
    position: absolute;
    width: calc(100% + 2px);
    top: ${(props) => props.optionsOffset || 0}px;
    left: -1px;
    max-height: 300px;
    background-color: ${(props) => props.theme.color.white};
    border: solid 1px ${(props) => props.theme.color.gray1};
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 0px 0px 6px 6px;
  `,
  Option: styled.View`
    padding: 3px 12px;
    width: 100%;
  `,
};

export const SelectOptions = (props: ISelectOptions) => {
  const ref = useRef();
  const inputValue = props.inputValue || '';

  useOutsideClick(ref, () => {
    props.setShow(false);
  });

  const renderOption =
    props.renderOption ||
    ((option: ISelectOption) => {
      return <Text>{option.value}</Text>;
    });

  return (
    <>
      {props.children}
      <Styled.Container width={props.width}>
        {(!!props.options.length || props.renderFirstOption) && props.show && (
          <Styled.Options ref={ref} optionsOffset={props.optionsOffset}>
            <ScrollView>
              {props.renderFirstOption && props.renderFirstOption(inputValue)}
              {props.options.map((option) => (
                <Touchable
                  key={option.key}
                  testID={`combo:${option.key}`}
                  onPress={() => {
                    if (props.onValueChange) {
                      if (props.setInputValue) {
                        props.setInputValue(option.value);
                      }
                      props.onValueChange(option.key);
                    }

                    if (props.onValuesChange) {
                      if (props.setInputValue) {
                        props.setInputValue('');
                      }
                      props.onValuesChange([...(props.values || []), `${option.key}`]);
                    }

                    props.setShow(false);
                  }}
                >
                  <Styled.Option>{renderOption(option)}</Styled.Option>
                </Touchable>
              ))}
            </ScrollView>
          </Styled.Options>
        )}
      </Styled.Container>
    </>
  );
};
