import api from '../../utils/api';

interface Association {
  organizationId: number;
}

export const associateOrganizationWithProperty = (propertyId: number, variables: Association) =>
  api.post({
    url: `/property/${propertyId}/organization`,
    variables,
  });
