import { CreateMarketingLinkVisit, CreateMarketingLinkVisitVariables } from './__generated__/CreateMarketingLinkVisit';
import { gql, useApi } from './Provider';

const CREATE_MARKETING_LINK_VISIT = gql`
  mutation CreateMarketingLinkVisit($input: CreateMarketingLinkVisitInput!) {
    createMarketingLinkVisit(input: $input) {
      id
      agentId
      marketingLinkId
      createdAt
    }
  }
`;

export const useCreateMarketingLinkVisit = () => {
  const { useMutation } = useApi();
  return useMutation<CreateMarketingLinkVisit, CreateMarketingLinkVisitVariables>(CREATE_MARKETING_LINK_VISIT);
};
