import { CroppedImage } from '../../types/croppedImage';

/**
 * @param {HTMLCanvasElement} canvas - Canvas containing the cropped iamge.
 * @param {string} filename - name of the cropped iamge.
 * @param {string} fileType - type of the copped image (image/png, image/jpeg etc.).
 */
export default async function getCroppedImg(canvas: HTMLCanvasElement, filename: string, fileType: string) {
  // As Base64 string
  //const uri = canvas.toDataURL(fileType);

  // As a blob
  return new Promise<CroppedImage>((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        const fileObj = new File([file], filename, { lastModified: Date.now(), type: fileType });
        resolve({ url: URL.createObjectURL(file), blob: file, file: fileObj });
      } else {
        reject('Cannot find image');
      }
    }, fileType);
  });
}
