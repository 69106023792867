import {
  QueryAutoCompleteSuggestions,
  QueryAutoCompleteSuggestionsVariables,
} from './__generated__/QueryAutoCompleteSuggestions';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { ListDefaultLocales } from './__generated__/ListDefaultLocales';
import { debounce } from 'lodash';

const QUERY_AUTOCOMPLETE_SUGGESTIONS = gql`
  query QueryAutoCompleteSuggestions($input: SearchInput!) {
    search(input: $input) {
      listLocales(take: 3) {
        locales {
          ... on Country {
            id
            name
            displayName
          }
          ... on Province {
            id
            name
            country {
              id
              isoCode
              isoCode3
            }
          }
          ... on City {
            id
            name
            province {
              id
              isoCode
            }
            country {
              id
              isoCode
              isoCode3
            }
          }
          __typename
        }
        pagination {
          skip
          take
          total
        }
      }
      searchProperties(take: 5) {
        id
        name
      }
    }
  }
`;

const QUERY_DEFAULT_LOCATIONS = gql`
  query ListDefaultLocales {
    listDefaultCities {
      id
      name
      province {
        id
        isoCode
      }
      country {
        id
        isoCode
        isoCode3
      }
    }
  }
`;

export const useAutocomplete = () => {
  const [isCleared, setIsCleared] = useState(true);
  const [doSearch, { data }] = useLazyQuery<QueryAutoCompleteSuggestions, QueryAutoCompleteSuggestionsVariables>(
    QUERY_AUTOCOMPLETE_SUGGESTIONS,
  );
  const { data: defaultData } = useQuery<ListDefaultLocales>(QUERY_DEFAULT_LOCATIONS);

  const fetchSuggestions = useCallback(
    (searchTerm: string) => {
      setIsCleared(false);
      doSearch({
        variables: {
          input: {
            searchTerm,
          },
        },
      });
    },
    [setIsCleared, doSearch],
  );

  const clearSuggestions = useCallback(() => {
    setIsCleared(true);
  }, [setIsCleared]);

  const suggestions = isCleared
    ? [
        {
          title: 'Locations',
          options: (defaultData?.listDefaultCities || []).map((locale) => {
            return {
              ...locale,
              name: `${locale.name}, ${locale.province.isoCode}, ${locale.country.isoCode3 || locale.country.isoCode}`,
            };
          }),
        },
        {
          title: 'Building',
          options: [],
        },
      ]
    : [
        {
          title: 'Location',
          options: (data?.search?.listLocales?.locales || []).map((locale) => {
            if (locale.__typename === 'Province') {
              return {
                ...locale,
                name: `${locale.name}, ${locale.country.isoCode3 || locale.country.isoCode}`,
              };
            }

            if (locale.__typename === 'City') {
              return {
                ...locale,
                name: `${locale.name}, ${locale?.province?.isoCode ? `${locale.province.isoCode}, ` : ''}${
                  locale.country.isoCode3 || locale.country.isoCode
                }`,
              };
            }

            return locale;
          }),
        },
        {
          title: 'Building',
          options: data?.search?.searchProperties || [],
        },
      ];

  const debouncedLoadSuggestions = useCallback(
    (value) =>
      debounce(
        async (val: string) => {
          if (val?.length >= 3) {
            fetchSuggestions(val);
          } else {
            clearSuggestions();
          }
        },
        50,
        {
          leading: false,
          trailing: true,
        },
      )(value),
    [clearSuggestions, fetchSuggestions],
  );

  return {
    suggestions,
    fetchSuggestions: debouncedLoadSuggestions,
    clearSuggestions,
  };
};
