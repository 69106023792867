import React from 'react';
import styled from 'styled-components';

import { Select } from '.';
import useTranslations from '../translations';
import { font } from '../utils/styles';

interface ReportFilterSelectProps {
  currentFilter: string;
  onChange: Function;
}

export const filterOptions = [
  { name: 'This Week', value: 'week' },
  { name: 'Past Week', value: 'pastWeek' },
  { name: 'This Month', value: 'month' },
  { name: 'Past Month', value: 'pastMonth' },
  { name: 'Past 2 Months', value: 'past2Months' },
  { name: 'This Year', value: 'year' },
  { name: 'Last Year', value: 'lastYear' },
];

export const ReportFilterSelect = (props: ReportFilterSelectProps) => {
  const translations = useTranslations();
  const { currentFilter, onChange } = props;
  return (
    <Styled.SelectContainer>
      <Styled.FilterTitle>{translations.AgentReports.filterTitle}</Styled.FilterTitle>
      <Select
        hideBlankOption={true}
        value={currentFilter}
        onChange={(e) => onChange(e)}
        options={filterOptions.map((opt) => ({
          id: opt.value,
          name: opt.name,
        }))}
      />
      <Styled.IconCaret src="/icons/icon-drop-down-arrow.svg" />
    </Styled.SelectContainer>
  );
};

const Styled = {
  IconCaret: styled.img`
    height: 10px;
    top: 3px;
    right: 15px;
    position: relative;
    z-index: 4;
  `,
  SelectContainer: styled.div`
    color: #000000;
    font-family: ${font.regular};
    font-size: 14px;
    line-height: 18px;
    text-align: right;

    display: flex;
    margin-top: 15px;
    select {
      font-weight: bold;
      border: 0px;
      background: inherit;
      cursor: pointer;
      height: inherit;
      width: auto;
      padding-right: 15px;
      text-transform: uppercase;
      -webkit-appearance: none;
      -moz-appearance: none;
      z-index: 5;
    }
    select::-ms-expand {
      display: none;
    }
  `,
  FilterTitle: styled.div`
    width: 85px;
    text-transform: uppercase;
  `,
};
