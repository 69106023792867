import { RESPONSIVE } from '../../utils/constants';
import { createGlobalStyle } from 'styled-components';

const ResponsiveStyles = createGlobalStyle`
 
.show-only-mobile {
  display: none;
}
@media (max-width: ${RESPONSIVE.MEDIUM}) {
  .hide-mobile {
    display:none;
  }
  .show-only-mobile {
    display: inherit;
  }
}
`;

export default ResponsiveStyles;
