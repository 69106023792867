export const readFile = (media: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(media);

    reader.onload = (event: any) => {
      if (event) {
        return resolve(event.target.result);
      }

      return reject();
    };

    reader.onerror = reject;
  });

export const convertMediaToBlob = (media: any) =>
  fetch(media)
    .then((res) => res.blob())
    .then((blob) => new File([blob], 'media'));
