import React, { ReactNode } from 'react';

import styled from 'styled-components';
import { font } from '../utils/styles';

interface CheckboxFieldProps {
  checked?: boolean;
  onValueChange?: (e: boolean) => void;
  onChange?: (e: any) => void;
  label: string | ReactNode;
  id?: string;
  name?: string;
}

const S = {
  Container: styled.div``,
  Input: styled.input`
    appearance: checkbox;
  `,
  Label: styled.label`
    font-family: ${font.regular};
    color: #82878b;
    font-size: 14px;
    padding-left: 7px;
  `,
};

export const CheckboxField = (props: CheckboxFieldProps) => (
  <S.Container>
    <S.Input
      data-testid={`checkbox:${props.name}`}
      name={props.name}
      id={props.id}
      type="checkbox"
      checked={!!props.checked}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
        if (props.onValueChange) {
          props.onValueChange(!props.checked);
        }
      }}
    />
    <S.Label>{props.label}</S.Label>
  </S.Container>
);
