import * as React from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';
import useTranslations from '../translations/useTranslations';

interface CurrentUserProps {
  onClick: () => void;
  id?: string;
}

export const CurrentUser = (props: CurrentUserProps) => {
  const translations = useTranslations();
  const componentTestId = 'current-user';
  return (
    <Styled.CurrentUser id={props.id} onClick={props.onClick} data-test-id={`${componentTestId}-my-account`}>
      <Styled.UserName>{translations.CurrentUser.title}</Styled.UserName>
    </Styled.CurrentUser>
  );
};

const Styled = {
  UserName: styled.div`
    letter-spacing: 0.4px;
    font-size: 16px;
    color: black;
    font-family: ${font.title};
    width: 100%;
    text-align: center;
    font-weight: bold;
  `,
  CurrentUser: styled.button`
    width: 100px;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    padding: 0px !important;
    margin: 0px;
  `,
};
