import { ReSendAgentInvite, ReSendAgentInviteVariables } from './__generated__/ReSendAgentInvite';
import { gql, useApi } from './Provider';

const RESEND_AGENT_INVITATION = gql`
  mutation ReSendAgentInvite($emailAddress: String!, $templateId: String) {
    sendAgentInvite(emailAddress: $emailAddress, templateId: $templateId) {
      id
      emailAddress
      user {
        id
        fullName
      }
    }
  }
`;

export const useResendAgentInvite = () => {
  const { useMutation } = useApi();
  return useMutation<ReSendAgentInvite, ReSendAgentInviteVariables>(RESEND_AGENT_INVITATION);
};
