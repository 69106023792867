import React, { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { color, font, mixin, zIndexValues } from '../utils/styles';
import styled, { css } from 'styled-components';

import Icon from './Icon';
import { RESPONSIVE } from '../utils/constants';
import ReactDOM from 'react-dom';
import useOnEscapeKeyDown from '../hooks/onEscapeKeyDown';
import useOnOutsideClick from '../hooks/onOutsideClick';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';

interface IModalRenderParams {
  close: () => void;
}
interface IModalRenderLinkParams {
  open: () => void;
}
type IModalRenderFunc = (params: IModalRenderParams) => ReactElement;
type IModalRenderLinkFunc = (params: IModalRenderLinkParams) => ReactElement;

interface IStyledContainer {
  fullScreen: boolean;
}

interface IModalProps {
  className?: string;
  testid?: string;
  padding?: number;
  offset?: number; //margin-top
  marginBottom?: number;
  variant: 'center' | 'aside' | 'top';
  width?: number | string;
  withCloseIcon?: boolean; // @deprecated
  title?: string;
  subtitle?: string;
  isOpen: boolean;
  disableCloseOnOutsidClick?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  renderLink?: IModalRenderLinkFunc;
  renderContent: IModalRenderFunc;
  insetCloseIcon?: boolean;
}

interface ICloseIconProps {
  type: string;
  variant: string;
  onClick?: (event: React.MouseEvent) => any;
  fullScreen?: boolean;
}

interface IOverlayProps {
  variant: string;
  fullScreen?: boolean;
}

interface IStyledModalProps {
  className?: string;
  testid?: string;
  offset?: number; //margin-top
  marginBottom?: number;
  variant: string; //center, aside
  width?: number | string;
  fullScreen?: boolean;
}

interface IScrollOverlayProps {
  fullScreen: boolean;
}

const defaultProps: IModalProps = {
  className: undefined,
  testid: 'modal',
  variant: 'center',
  width: 600,
  withCloseIcon: true,
  isOpen: true,
  disableCloseOnOutsidClick: false,
  fullScreen: false,
  onClose: () => {},
  renderLink: () => <a href="/">Link</a>,
  renderContent: () => <div></div>,
};

export const Modal = ({
  className,
  testid,
  variant,
  title,
  subtitle,
  width,
  offset,
  padding,
  insetCloseIcon,
  fullScreen,
  marginBottom,
  isOpen: propsIsOpen,
  disableCloseOnOutsidClick,
  onClose: tellParentToClose,
  renderLink,
  renderContent,
}: IModalProps = defaultProps) => {
  const [stateIsOpen, setStateOpen] = useState(false);
  const isControlled = typeof propsIsOpen === 'boolean';
  const isOpen = isControlled ? propsIsOpen : stateIsOpen;
  const { isExtraSmall } = useResponsiveSizes();

  const $modalRef = useRef<HTMLDivElement>(null);
  const $clickableOverlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);
  const closeModal = useCallback(() => {
    document.body.style.overflowY = 'auto';
    if (!isControlled) {
      setStateOpen(false);
    } else {
      if (tellParentToClose) {
        tellParentToClose();
      }
    }
  }, [isControlled, tellParentToClose]);

  useOnOutsideClick([$modalRef], isOpen && !disableCloseOnOutsidClick, closeModal, $clickableOverlayRef);
  useOnEscapeKeyDown(isOpen, closeModal);

  const $root = document.getElementById('content');

  if (!$root) {
    return <div></div>;
  }
  return (
    <Fragment>
      {!isControlled && renderLink && renderLink({ open: () => setStateOpen(true) })}

      {isOpen &&
        ReactDOM.createPortal(
          <ScrollOverlay fullScreen={fullScreen} className={className}>
            <ClickableOverlay variant={variant} fullScreen={fullScreen} ref={$clickableOverlayRef}>
              <ContentContainer data-id="contant-container">
                <StyleContainer fullScreen={fullScreen}>
                  <StyledModal
                    fullScreen={fullScreen}
                    className={className}
                    variant={variant}
                    offset={offset}
                    marginBottom={marginBottom}
                    width={width}
                    data-testid={testid}
                    ref={$modalRef}
                    style={{
                      padding,
                    }}
                    data-id="styled-modal"
                  >
                    {insetCloseIcon && (
                      <CloseIconInset
                        data-testid="modal-close"
                        type="close"
                        variant={variant}
                        onClick={() => closeModal()}
                        fullScreen={fullScreen}
                      />
                    )}
                    {title && (
                      <ModalHeader>
                        <ModalHeaderContainer>
                          <ModalHeaderTitle>{title}</ModalHeaderTitle>
                          {subtitle && (
                            <SubtitleContainer>
                              {!isExtraSmall && <ModalHeaderSubtitleSeparator>/</ModalHeaderSubtitleSeparator>}
                              <ModalHeaderSubtitle>{subtitle}</ModalHeaderSubtitle>
                            </SubtitleContainer>
                          )}
                        </ModalHeaderContainer>
                      </ModalHeader>
                    )}
                    {renderContent({ close: closeModal })}
                  </StyledModal>
                </StyleContainer>
                {!insetCloseIcon && (
                  <CloseIcon
                    data-testid="modal-close"
                    type="close"
                    variant={variant}
                    fullScreen={fullScreen}
                    onClick={() => closeModal()}
                  />
                )}
              </ContentContainer>
            </ClickableOverlay>
          </ScrollOverlay>,
          $root,
        )}
    </Fragment>
  );
};

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
`;

export const StyleContainer = styled.div<IStyledContainer>`
  padding: ${(props) => (props.fullScreen ? 0 : 50)}px 0;
`;

export const SubtitleContainer = styled.div`
  display: flex;
`;

export const ScrollOverlay = styled.div<IScrollOverlayProps>`
  z-index: ${zIndexValues.modal};
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (!props.fullScreen ? 'height: 100%;' : '')}
  width: 100%;
  overflow: hidden;
  @media (max-width: ${RESPONSIVE.LARGE}) {
    .margin-left-turnaround {
      margin-left: 0px;
    }
  }
`;

export const ClickableOverlay = styled.div<IOverlayProps & React.BaseHTMLAttributes<HTMLDataElement>>`
  background: rgba(9, 30, 66, 0.54);
  height: 100%;

  ${(props) => clickOverlayStyles[props.variant]}
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    padding: ${(props) => (props.fullScreen ? '0' : '1rem')};
  }
`;

const clickOverlayStyles: Record<string, any> = {
  top: css`
    display: flex;
    justify-content: center;
    align-items: start;
  `,
  center: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  aside: '',
};

export const StyledModal = styled.div<IStyledModalProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${color.white};
  margin: auto;
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : '0px')};
  margin-top: ${(props) => (props.offset ? `${props.offset}px` : '0px')};
  max-width: ${(props) => (props.fullScreen ? '100vw' : '90vw')};
  width: ${(props) => (typeof props.width === 'number' ? `${props.width}px` : props.width)};
  ${(props) => modalStyles[props.variant]};
`;

const modalStyles: Record<string, any> = {
  top: css`
    vertical-align: top;
    ${mixin.boxShadowMedium}
  `,
  center: css`
    vertical-align: middle;
    ${mixin.boxShadowMedium}
  `,
  aside: css`
    min-height: 100vh;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  `,
};

export const ModalHeader = styled.header`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid ${color.borderLightest};
`;

export const ModalHeaderContainer = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;
  @media (max-width: ${RESPONSIVE.EXTRA_SMALL}) {
    flex-direction: column;
  }
`;

export const ModalHeaderTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-transform: none;
  font-family: ${font.title};
`;

export const ModalHeaderSubtitle = styled.div`
  font-weight: normal;
  margin-top: 5px;
  color: ${color.textLight};
  font-size: 16px;
  text-transform: none;
  font-family: ${font.regular};
`;

export const ModalHeaderSubtitleSeparator = styled<React.ElementType>(ModalHeaderSubtitle)`
  margin: 5px 10px 0 10px;
`;

export const CloseIcon = styled(Icon)<ICloseIconProps>`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'sticky')};
  font-size: 25px;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
  ${(props) => closeIconStyles[props.variant]};
  align-self: flex-start;
  margin: 10px;
  color: ${color.black};
  background-color: ${color.white};
  text-align: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding-top: 4px;

  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    right: 0px;
    top: 0px;
  }
`;

const CloseIconInset = styled(Icon)<ICloseIconProps>`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'absolute')};
  font-size: 20px;
  color: ${color.textMedium};
  transition: all 0.1s;
  ${mixin.clickable}
  ${(props) => closeIconStyles[props.variant]};
  right: 0px;
  top: 0px;
  color: ${color.textDark};
  padding-bottom: 3px;
  z-index: 1000;
`;

const closeIconStyles: Record<string, any> = {
  top: css`
    top: 10px;
    right: 12px;
    padding: 3px 5px 0px 5px;
    border-radius: 4px;
    &:hover {
      background: ${color.backgroundLight};
    }
  `,
  center: css`
    top: 10px;
    right: 12px;
    padding: 3px 5px 0px 5px;
    border-radius: 4px;
    &:hover {
      background: ${color.backgroundLight};
    }
  `,
  aside: css`
    top: 10px;
    right: -30px;
    width: 50px;
    height: 50px;
    padding-top: 10px;
    border-radius: 3px;
    text-align: center;
    background: ${color.white};
    border: 1px solid ${color.borderLightest};
    ${mixin.boxShadowMedium};
    &:hover {
      color: ${color.primary};
    }
  `,
};

export default Modal;
