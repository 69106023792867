import Button from './Button';
import { RESPONSIVE } from '../utils/constants';
import React from 'react';
import ReactDOM from 'react-dom';
import { font } from '../utils/styles';
import { setItem } from '../utils/localStorage';
import styled from 'styled-components';

interface GdprBannerProps {
  text?: string;
  policy?: string;
  accept?: string;
  onAccept?: () => void;
}

const GdprBanner = (props: GdprBannerProps) => {
  const { text, policy, accept, onAccept } = props;

  const rememberSetting = () => {
    setItem('cookies', 'accepted');
    if (onAccept) {
      onAccept();
    }
  };

  const $root = document.getElementById('content');
  if (!$root) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <GdprBannerContainer>
      {text}
      <ActionsContainer>
        <span dangerouslySetInnerHTML={{ __html: policy || '' }} />
        <AcceptButton data-testid="gdpr:accept" color="black" onClick={rememberSetting}>
          {accept}
        </AcceptButton>
      </ActionsContainer>
    </GdprBannerContainer>,
    $root,
  );
};

const AcceptButton = styled(Button)`
  @media (max-width: ${RESPONSIVE.SMALL}) {
    margin-top: 5px;
    width: 150px;
  }
`;

export const ActionsContainer = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  font-family: ${font.regular};
  @media (max-width: ${RESPONSIVE.SMALL}) {
    text-align: center;
    margin-top: 1rem;
    padding-left: 10px;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
`;

export const GdprBannerContainer = styled.div`
  width: 100%;
  padding: 2rem 2rem;
  text-align: left;
  border-radius: 0;
  font-size: 1rem;
  color: #000;
  background: #fff;
  font-family: ${font.regular};
  font-weight: 300;
  z-index: 9000;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 6px -4px 10px #000;

  a {
    text-decoration: underline !important;
  }
`;

export const Actions = styled.div`
  float: right;
  display: inline-block;

  button {
    width: 150px;
    margin-top: -15px;
  }
`;

export default GdprBanner;
