import { CreateClientInvitation, CreateClientInvitationVariables } from './__generated__/CreateClientInvitation';
import { gql, useApi } from './Provider';

const CREATE_CLIENT_INVITATION = gql`
  mutation CreateClientInvitation($input: InviteClientInput!) {
    inviteClient(input: $input) {
      id
      emailAddress
      user {
        id
        fullName
        status
      }
    }
  }
`;

export const useCreateClientInvitation = () => {
  const { useMutation } = useApi();
  return useMutation<CreateClientInvitation, CreateClientInvitationVariables>(CREATE_CLIENT_INVITATION);
};
