import React from 'react';
import { DefaultToastContainer } from 'react-toast-notifications';
import styled from 'styled-components';
import { RESPONSIVE } from '../utils/constants';

export const ToastContainerStyle = styled.span`
  & > div {
    top: 90px;
    right: 24px;
    z-index: 99999;

    @media (max-width: ${RESPONSIVE.SMALL}) {
      top: 50px;
      right: 10px;
      width: 80%;

      & > div {
        height: 100%;
        & > div {
          width: 100%;
        }
      }
    }
  }
`;
export const CustomToastContainer = (props) => (
  <ToastContainerStyle>
    <DefaultToastContainer {...props} />
  </ToastContainerStyle>
);
