import { FavoriteProperty, FavoritePropertyVariables } from '../api/__generated__/FavoriteProperty';
import { useApi, gql } from './Provider';

const FAVORITE_PROPERTY = gql`
  mutation FavoriteProperty($propertyId: BigInt!) {
    favoriteProperty(propertyId: $propertyId) {
      id
      isFavorite
    }
  }
`;

export const useFavoriteProperty = () => {
  const { useMutation } = useApi();
  return useMutation<FavoriteProperty, FavoritePropertyVariables>(FAVORITE_PROPERTY);
};
