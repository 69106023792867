import { SharePropertyToClient, SharePropertyToClientVariables } from './__generated__/SharePropertyToClient';
import { gql, useApi } from './Provider';

export const SHARE_PROPERTY_TO_CLIENT = gql`
  mutation SharePropertyToClient($input: SharePropertyInput!) {
    shareProperty(input: $input) {
      id
    }
  }
`;

export const useSharePropertyToClient = () => {
  const { useMutation } = useApi();
  return useMutation<SharePropertyToClient, SharePropertyToClientVariables>(SHARE_PROPERTY_TO_CLIENT);
};
