import { color, font, mixin } from './utils/styles';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<any>`
  html {
    touch-action: manipulation;
    height: 100vh;
    min-height: 100%;
    scroll-behavior: smooth;
  }

  body {
    color: ${color.textDarkest};
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background-color: white;
    height: 100%;
    min-height: 100%;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.2;
    ${font.size(13)}
    font-family: ${font.regular};
  }

  #content {
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    ${font.regular}
  }

  button {
      outline: 0;
  }

  *, *:after, *:before, input[type="search"] {
    box-sizing: border-box;
  }

  a {
    color: ${color.black};
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  ul, li, ol, dd, h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6, strong {
    ${font.bold}
  }

  button {
    background: none;
    border: none;
  }

  /* Workaround for IE11 focus highlighting for select elements */
  select::-ms-value {
    background: none;
    color: #42413d;
  }

  [role="button"], button, input, select, textarea {
    &:disabled {
      opacity: 1;
    }
  }
  [role="button"], button, input, textarea {
    appearance: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  select::-ms-expand {
    display: none;
  }
  select option {
    color: ${color.textDarkest};
  }

  p {
    line-height: 1.4285;
    a {
      ${mixin.link()}
    }
  }

  textarea {
    line-height: 1.4285;
  }

  body, select {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    touch-action: manipulation;
  }

  .float-left {
    float: left;
  }

  .marginL20 {
    margin-left: 20px;
  }

  canvas:focus {
      outline: none;
  }

  .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
      display: none !important;
  }

  .mapboxgl-popup {
      z-index: 50 !important;
  }

  .overlays {
      pointer-events: none;
  }

  .visible {
    visibility: visible !important;
  }

  ${mixin.placeholderColor(color.textLight)}
`;

export default GlobalStyle;
