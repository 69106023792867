import { Category } from '../../types';
import api from '../../utils/api';

export const updateCategory = (category: Category) => {
  const { id, ...variables } = category;

  return api.put({
    url: `/category/${id}`,
    variables,
  });
};
