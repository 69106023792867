import { Component } from '../../types';
import api from '../../utils/api';

export const batchCreateComponents = (components: Array<Component>) =>
  api.post({
    url: `/component/batch`,
    variables: {
      components,
    },
  });
