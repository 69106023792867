import { Link } from 'react-router-dom';
import { Small } from './Typography';
import { REACT_APP_BROCHURE_DOMAIN, RESPONSIVE, ENABLE_ZENDESK } from '../utils/constants';
import React, { useRef } from 'react';
import { color, font } from '../utils/styles';
import { getUserBrokerage } from '../utils/user';

import { CurrentAgent } from '../hooks/useAuthorization';
import Logo from './Logo';
import styled from 'styled-components';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';
import useTranslations from '../translations/useTranslations';
import useOnScroll from '../hooks/useOnScroll';

interface FooterProps {
  agent?: CurrentAgent;
  mobileBottomMargin?: number; // this is used in case the page that the footer is on has a fixed element at the bottom
}

interface FooterContainerProps {
  mobileBottomMargin?: number;
}

const CURRENT_YEAR = new Date().getFullYear();

export const Footer = (props: FooterProps) => {
  const translations = useTranslations();
  const { isLarge } = useResponsiveSizes();
  const footerRef = useRef(null);
  const containerRef = useRef(null);

  const { agent } = props;
  const organizationName = agent ? getUserBrokerage(agent) : null;

  const copyright = (
    <Small>
      {translations.Common.copyright} &copy; {CURRENT_YEAR}
      {isLarge ? <br /> : <span>&nbsp;&middot;&nbsp;</span>}
      {translations.Common.vystaInc} | {translations.Common.evolutionVirtual}
    </Small>
  );

  const FOOTER_LINKS = [
    { name: translations.Common.aboutVysta, url: `${REACT_APP_BROCHURE_DOMAIN}` },
    { name: translations.Common.dataPolicy, url: `${REACT_APP_BROCHURE_DOMAIN}/data-policy` },
    { name: translations.Common.contactVysta, url: `${REACT_APP_BROCHURE_DOMAIN}/contact` },
    { name: translations.Common.privacyPolicy, url: `${REACT_APP_BROCHURE_DOMAIN}/privacy-policy` },
    { name: translations.Common.termsOfUse, url: `${REACT_APP_BROCHURE_DOMAIN}/terms-of-service` },
  ];

  const zenDeskHelp = ENABLE_ZENDESK && document.getElementById('launcher');
  useOnScroll(!!zenDeskHelp, () => {
    const footerOffset = isLarge ? containerRef.current?.getBoundingClientRect().height : footerRef.current?.scrollTop;

    if (
      window.pageYOffset > 0 &&
      window.pageYOffset + 1 >= document.documentElement.scrollHeight - window.innerHeight - footerOffset
    ) {
      zenDeskHelp.style.display = 'none';
    } else {
      zenDeskHelp.style.display = 'block';
    }
  });

  return (
    <Styled.Container mobileBottomMargin={props.mobileBottomMargin} ref={containerRef}>
      <Styled.Left>
        <Styled.LogoTop>
          {agent ? (
            <Styled.AgentInfo>
              <Styled.AgentName>
                {agent.firstName} {agent.lastName}
              </Styled.AgentName>
              {organizationName && <Styled.OrganizationName>{organizationName}</Styled.OrganizationName>}
            </Styled.AgentInfo>
          ) : (
            <a target="_blank" rel="noreferrer" href={`${REACT_APP_BROCHURE_DOMAIN}`}>
              <Logo variant="accent" height={20} />
            </a>
          )}
        </Styled.LogoTop>
        {isLarge ? (
          <a target="_blank" rel="noreferrer" href={`${REACT_APP_BROCHURE_DOMAIN}`}>
            <Styled.PoweredByVysta src="/icons/powered-by-vysta-dark.png" />
          </a>
        ) : (
          <Styled.Copyright>
            <a target="_blank" rel="noreferrer" href={`${REACT_APP_BROCHURE_DOMAIN}`}>
              <Styled.PoweredByVysta src="/icons/powered-by-vysta-dark.png" />
            </a>
            {copyright}
          </Styled.Copyright>
        )}
        {isLarge && <Styled.Copyright>{copyright}</Styled.Copyright>}
      </Styled.Left>
      <Styled.Right ref={footerRef}>
        {FOOTER_LINKS.map((link, index) => (
          <a target="_blank" rel="noreferrer" key={index} href={link.url}>
            {link.name}
          </a>
        ))}
      </Styled.Right>
    </Styled.Container>
  );
};

const Styled = {
  AgentInfo: styled.div`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: baseline;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      flex-direction: column;
    }
  `,
  Container: styled.div<FooterContainerProps>`
    width: 100%;
    height: 99px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 10px ${color.borderLight};
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: #fff;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      display: flex;
      flex-direction: row;
      height: auto;
      padding: 1rem 0;
      text-align: center;
      margin-bottom: ${(props) => (props.mobileBottomMargin ? props.mobileBottomMargin : 0)}px;
    }
  `,
  Left: styled.div`
    text-align: center;
    position: sticky;
    bottom: 0px;
    left: 0px;
    background-color: ${color.white};

    @media (max-width: ${RESPONSIVE.LARGE}) {
      margin-left: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;

      p {
        text-align: left;
      }
    }
  `,
  LogoTop: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: ${RESPONSIVE.LARGE}) {
      width: 100%;
      align-items: flex-start;
    }
  `,
  AgentName: styled.div`
    font-size: 24px;
    font-family: ${font.title};
    margin-right: 10px;
  `,
  OrganizationName: styled.div`
    position: relative;
    margin-top: 3px;
  `,
  Copyright: styled.div`
    display: flex;
    margin-top: 7px;
    position: relative;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      margin-top: 20px;
      color: ${color.black};
      font-family: ${font.regular};
      font-size: 13px;
    }
  `,
  PoweredByVysta: styled.img`
    width: 90px;
    height: 13px;
    top: 2px;
    position: relative;
    margin-right: 14px;

    @media (max-width: ${RESPONSIVE.LARGE}) {
      // margin: 1rem;
    }
  `,
  Right: styled.div`
    text-align: right;
    font-size: 12px;
    a {
      margin-right: 15px;
    }
    @media (max-width: ${RESPONSIVE.LARGE}) {
      margin-top: 20px;
      width: 30%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      justify-content: left;
      align-items: flex-start;
      a {
        margin-bottom: 5px;
      }
    }
  `,
  A: styled.a`
    text-decoration: none;
  `,
  Link: styled(Link)`
    text-decoration: none;
  `,
  ChatBubble: styled.button`
    background: #000;
    position: absolute;
    top: -28px;
    right: 7px;
    z-index: 10;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      background-color: #333;
    }
  `,
  ChatBubbleGlow: styled.div`
    background: rgba(250, 250, 250, 0.25);
    position: absolute;
    top: -38px;
    right: -3px;
    z-index: 9;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `,
  LogoContainer: styled.div`
    margin-top: 0px;
    position: relative;
    left: 7px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      left: 0px;
      margin-top: 1rem;
    }
  `,
};

export default Footer;
