import * as React from 'react';
import styled from 'styled-components';

interface SpacerProps {
  height?: number;
  width?: number;
}

export const Spacer = (props: SpacerProps) => (
  <Styled.Spacer className="spacer" height={props.height} width={props.width} />
);

const Styled = {
  Spacer: styled.div<SpacerProps>`
    height: ${(props) => (props.height ? `${props.height}px` : '1px')};
    width: ${(props) => (props.width ? `${props.width}px` : '1px')};
  `,
};
