import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import './sass/app.scss';

import { ApiContext, apiAccessors } from './api/Provider';
import { ApolloProvider, client } from './api/Provider/apiLink';
import React, { useEffect, useState } from 'react';

import BrowserGuard from './views/BrowserGuard';
import { ComponentThemeProvider } from './@components';
import { CustomToastContainer } from './components/ToastContainer';
import ErrorBoundary from './ErrorBoundary';
import GlobalStyles from './GlobalStyles';
import { LanguageContext } from './translations';
import Layout from './Layout';
import NormalizeStyles from './NormalizeStyles';
import ResponsiveStyles from './components/responsive/ResponsiveStyles';
import { Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TrackingEvent } from './types';
import { browserHistory } from './browserHistory';
import { getItem } from './utils/localStorage';
import { hot } from 'react-hot-loader/root';
import { objectToQueryString } from './utils/url';
import { setConfig } from 'react-hot-loader';
import { trackEvent } from './track';

const isUnsupportedBrowser = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

setConfig({
  reloadHooks: false,
});

function App() {
  const nav = window.navigator as any;
  const browserLanguage = nav.userLanguage || nav.language;
  const [language, setLanguage] = useState<string>(browserLanguage.split('-')[0]);

  useEffect(() => {
    const language = getItem('language');
    if (language) {
      trackEvent(`language/change/${language}` as TrackingEvent);
      setLanguage(language);
    }
    const { search, pathname } = window.location;

    const { firstName, lastName, email: emailAddress, ...otherParams } = search
      .replace('?', '')
      .split('&')
      .reduce((prev, current) => {
        const [key, value] = current.split('=');

        return {
          ...prev,
          [key]: value,
        };
      }, {}) as {
      [key: string]: string;
    };

    //we want to replace any personal information from the url search query
    browserHistory.replace(`${pathname}?${objectToQueryString(otherParams)}`);
    window['fbq']('track', 'PageView');

    // Put the history back
    browserHistory.replace(`${pathname}${search}`);
  }, []);

  return (
    <ApolloProvider client={client}>
      <ApiContext.Provider value={apiAccessors}>
        <ComponentThemeProvider>
          <Router history={browserHistory}>
            <LanguageContext.Provider value={{ language, setLanguage }}>
              <NormalizeStyles />
              <GlobalStyles />
              <ResponsiveStyles />
              <ErrorBoundary>
                <ToastProvider
                  autoDismiss
                  autoDismissTimeout={4000}
                  placement="top-right"
                  components={{ ToastContainer: CustomToastContainer }}
                >
                  {isUnsupportedBrowser ? <BrowserGuard /> : <Layout />}
                </ToastProvider>
              </ErrorBoundary>
            </LanguageContext.Provider>
          </Router>
        </ComponentThemeProvider>
      </ApiContext.Provider>
    </ApolloProvider>
  );
}

export default hot(App);
