import * as React from 'react';

import { ITd } from './types';
import { Text } from '../';
import styled from 'styled-components';

interface IStyledTd {
  width?: number;
  hidden?: boolean;
}

const Styled = {
  Td: styled.td<IStyledTd>`
    text-align: left;
    border-bottom: solid 1px ${(props) => props.theme.color.gray1};
    padding: 7px;
    ${(props) => (props.width ? `width: ${props.width}px;` : '')}
    ${(props) => (props.hidden ? 'display: none;' : '')}
  `,
};

export const Td = (props: ITd) => {
  return (
    <Styled.Td width={props.width} hidden={props.hidden}>
      {typeof props.children === 'string' ? (
        <Text weight="medium" size="md">
          {props.children}
        </Text>
      ) : (
        props.children
      )}
    </Styled.Td>
  );
};
