import styled from 'styled-components/native';

interface IPosition {
  position?: 'absolute' | 'relative';
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

export const Position = styled.View<IPosition>`
  position: ${(props) => props.position || 'relative'};
  ${(props) => (props.top ? `top: ${props.top}px;` : '')}
  ${(props) => (props.left ? `left: ${props.left}px;` : '')}
  ${(props) => (props.right ? `right: ${props.right}px;` : '')}
  ${(props) => (props.bottom ? `bottom: ${props.bottom}px;` : '')}
`;
