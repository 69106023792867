import * as React from 'react';
import Select from 'react-select';

type SelectValue = string | number;

interface SelectOption {
  id: SelectValue;
  name: string;
}

interface SelectProps {
  placeholder?: string;
  values?: Array<SelectValue>;
  onValueChange?: (values: Array<SelectValue>) => void;
  options: Array<SelectOption>;
}

export const MultiSelect = (props: SelectProps) => {
  const options = props.options.map(
    (type) =>
      ({
        label: type.name,
        value: type.id,
      } || []),
  );

  const values = options.filter((type: any) => (props.values || []).includes(type.value));

  return (
    <Select
      placeholder={props.placeholder}
      options={options}
      isMulti={true}
      onChange={(selections: any) => {
        props.onValueChange(selections.map((option) => option.value));
      }}
      value={values}
    />
  );
};
