import { RESPONSIVE } from '../utils/constants';
import styled from 'styled-components';

export const Page = styled.main`
  margin: 50px;
  margin-bottom: 80px;
  @media screen and (max-width: ${RESPONSIVE.MEDIUM}) {
    margin: 1rem;
  }
`;

export const FullPage = styled.div`
  height: 100vh;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
`;

export const PageInner = styled.div`
  padding-left: 5rem;
  padding-right: 5rem;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: ${RESPONSIVE.LARGE}) {
    padding: 1rem;
  }
  @media (max-width: ${RESPONSIVE.SMALL}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const PageInnerMaxed = styled(PageInner as any)`
  max-width: 1500px;
  margin: auto;
  width: 100%;
`;
