import {
  QueryPropertyPage,
  QueryPropertyPageVariables,
  QueryPropertyPage_property,
  QueryPropertyPage_property_amenities,
  QueryPropertyPage_property_brochure,
  QueryPropertyPage_property_factsheet,
  QueryPropertyPage_property_galleries,
  QueryPropertyPage_property_orbitReelExternalIds,
  QueryPropertyPage_property_videos,
} from './__generated__/QueryPropertyPage';
import { gql, useApi } from './Provider';

export const QUERY_PROPERTY_PAGE = gql`
  query QueryPropertyPage($propertyId: Int!) {
    property(id: $propertyId) {
      id
      name
      address
      unitsCount
      orbitReelExternalIds {
        id
        externalId
        label
      }
      floorsCount
      hasOrbitReel
      isFavorite
      maximumPrice(unitStatusName: "Available")
      minimumPrice(unitStatusName: "Available")
      tagline
      galleries {
        id
        name
        media {
          id
          url
          isPortrait
        }
      }
      heroImages {
        id
        url
      }
      unitsAvailabeCount: unitsCount(unitStatusName: "Available")
      propertyType {
        id
        name
      }
      organizations {
        id
        name
        organizationTypes {
          id
          name
        }
      }
      salesStatus {
        id
        name
      }
      constructionStatus {
        id
        name
      }
      amenities(where: { showOnPage: true }) {
        id
        name
        description
        media {
          id
          url
        }
      }
      latitude
      longitude
      availableUnits: units(unitStatusName: "Available") {
        id
        name
        price
        isPublished
        unitStatus {
          id
          name
        }
        unitModel {
          id
          name
          interiorSquarefeet
          exteriorSquarefeet
          halfBathrooms
          bedrooms
          bathrooms
        }
      }
      pointsOfInterest {
        id
        name
        latitude
        longitude
        address
        category {
          id
          name
        }
      }
      description
      totalUnitsOverride
      totalFloorsOverride
      displayPriceStartAt
      videos {
        id
        url
      }
      featureVideo {
        id
        url
        mediaType {
          name
        }
      }
      brochure {
        id
        url
      }
      factsheet {
        id
        url
      }
    }
  }
`;

export type IPropertyBrochure = QueryPropertyPage_property_brochure;
export type IPropertyFactSheet = QueryPropertyPage_property_factsheet;
export type IPropertyAmenities = QueryPropertyPage_property_amenities;
export type IPropertyOrbitReelExternalIds = QueryPropertyPage_property_orbitReelExternalIds;
export type IProperty = QueryPropertyPage_property;
export type IPropertyGalleries = QueryPropertyPage_property_galleries;
export type IPropertyVideos = QueryPropertyPage_property_videos;

export const useProperty = (variables: QueryPropertyPageVariables) => {
  const { useQuery } = useApi();
  return useQuery<QueryPropertyPage, QueryPropertyPageVariables>(QUERY_PROPERTY_PAGE, {
    variables,
  });
};
