import { ResetPassword, ResetPasswordVariables } from './__generated__/ResetPassword';
import { gql, useApi } from './Provider';

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
      reason
    }
  }
`;

export const useResetPassword = () => {
  const { useMutation } = useApi();
  return useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD);
};

export type IResetPassword = ResetPassword;
export type IResetPasswordVariables = ResetPasswordVariables;
