import { InputField, InputTextArea } from './styles';
import React, { InputHTMLAttributes, useRef } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> {
  className?: string;
  type: 'text' | 'textArea' | 'number' | 'password';
  error?: boolean;
  testId?: string;
  placeholder?: string;
  onBlur?: (e: any) => void;
}

export const Input: React.FC<InputProps> = (props) => {
  const inputRef = useRef(null);
  const className = `${props.className ? props.className : ''}${props.error ? ' error' : ''}`;
  const testId = props.testId || props.name;

  return props.type === 'textArea' ? (
    <InputTextArea type="textarea" rows={5} ref={inputRef} data-testid={testId} {...props} className={className} />
  ) : (
    <InputField type={props.type} ref={inputRef} data-testid={testId} {...props} className={className} />
  );
};

export { InputField };

export default Input;
