import React, { useState, useCallback, useRef, useEffect } from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

import getCroppedImg from './cropImage';
import { CroppedImage } from '../../types/croppedImage';
import { Button, Spacer } from '../';
import * as S from './styles';
import useTranslations from '../../translations';

interface ImageCropperProps {
  aspectRatio?: number;
  initialCropArea?: number;
  onCrop?: (image: CroppedImage) => void;
  file?: string;
  filename?: string;
  fileType?: string;
  onCancel: () => void;
  useRatioToolbar?: boolean;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  aspectRatio,
  onCrop = () => {},
  file = '',
  initialCropArea = 0.8,
  filename = 'file_name',
  fileType = 'image/jpeg',
  onCancel = () => {},
  useRatioToolbar,
}) => {
  const translations = useTranslations();
  const imageRef = useRef(null);
  const cropperRef = useRef(null);
  const previewRef = useRef(null);
  const [usedRatio, setUsedRatio] = useState(useRatioToolbar ? 16 / 9 : aspectRatio);

  useEffect(() => {
    if (cropperRef.current) {
      cropperRef.current.destroy();
    }
    if (imageRef.current && previewRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {
        aspectRatio: usedRatio,
        autoCropArea: initialCropArea,
        dragMode: 'move',
        toggleDragModeOnDblclick: false,
        preview: previewRef.current,
        checkCrossOrigin: false,
      });
    }
  }, [imageRef, previewRef, usedRatio, file, initialCropArea]);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImageTmp = await getCroppedImg(cropperRef.current.getCroppedCanvas(), filename, fileType);

      if (croppedImageTmp) {
        onCrop(croppedImageTmp);
      }
    } catch (e) {
      console.error(e);
    }
  }, [onCrop, fileType, filename]);

  const onReset = () => {
    if (useRatioToolbar) {
      setUsedRatio(16 / 9);
    } else {
      cropperRef.current.reset();
    }
  };

  const doZoom = (value) => {
    cropperRef.current.zoom(value);
  };

  return (
    <div data-testid="image-cropper:container">
      <S.Container>
        <S.CropContainer>
          <S.CropedImage id="image" src={file} alt="" ref={imageRef} crossOrigin="anonymous" />
          <S.Toolbar>
            <S.ZoomButton
              icon="zoom-in"
              color="#000000"
              colorIcon="white"
              width="50px"
              height="50px"
              onClick={() => doZoom(0.1)}
            />
            <S.ZoomButton
              icon="zoom-out"
              color="#000000"
              colorIcon="white"
              width="50px"
              height="50px"
              onClick={() => doZoom(-0.1)}
            />
            {useRatioToolbar && (
              <>
                <S.RatioButton
                  color={usedRatio === 16 / 9 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(16 / 9);
                  }}
                >
                  {translations.ImageCropper.sixteenByNine}
                </S.RatioButton>
                <S.RatioButton
                  color={usedRatio === 4 / 3 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(4 / 3);
                  }}
                >
                  {translations.ImageCropper.fourByThree}
                </S.RatioButton>
                <S.RatioButton
                  color={usedRatio === 1 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(1);
                  }}
                >
                  {translations.ImageCropper.oneByOne}
                </S.RatioButton>
                <S.RatioButton
                  color={usedRatio === 2 / 3 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(2 / 3);
                  }}
                >
                  {translations.ImageCropper.twoByThree}
                </S.RatioButton>
                <S.RatioButton
                  color={usedRatio === 3 / 2 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(3 / 2);
                  }}
                >
                  {translations.ImageCropper.threeByTwo}
                </S.RatioButton>
                <S.RatioButton
                  color={usedRatio === 3 / 1 ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(3 / 1);
                  }}
                >
                  {translations.ImageCropper.threeByOne}
                </S.RatioButton>
                <S.RatioButton
                  color={!usedRatio ? '' : '#000000'}
                  width="50px"
                  height="50px"
                  onClick={() => {
                    setUsedRatio(null);
                  }}
                >
                  {translations.ImageCropper.custom}
                </S.RatioButton>
              </>
            )}
          </S.Toolbar>
        </S.CropContainer>
        <S.Preview ref={previewRef}></S.Preview>
      </S.Container>
      <S.Controls>
        <S.ActionsContainer>
          <Button data-testid="image-cropper:reset" color="black" onClick={onReset}>
            {translations.ImageCropper.reset}
          </Button>
          <Spacer width={10} />
          <Button data-testid="image-cropper:cancel" color="black" onClick={onCancel}>
            {translations.ImageCropper.cancel}
          </Button>
          <Spacer width={10} />
          <Button data-testid="image-cropper:crop" color="black" onClick={saveCroppedImage}>
            {translations.ImageCropper.save}
          </Button>
        </S.ActionsContainer>
      </S.Controls>
    </div>
  );
};

export default ImageCropper;
