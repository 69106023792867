import * as React from 'react';

import { ITable } from './types';
import styled from 'styled-components';

const Styled = {
  Table: styled.table`
    width: 100%;
    border-spacing: 0px;
    border: solid 1px ${(props) => props.theme.color.gray1};
  `,
};

export const Table = (props: ITable) => {
  return <Styled.Table>{props.children}</Styled.Table>;
};
