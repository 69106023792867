import * as React from 'react';

import { IIcon, IStyledBackgroundWrapper, IStyledImage } from './IconTypes';

import { IconWrapper } from './IconWrapper';
import { getIconSize } from './getIconSize';
import styled from 'styled-components/native';

const wrappedIconSize = (props: IStyledBackgroundWrapper) => {
  const size = getIconSize(props);

  if (props.backgroundColor) {
    return size + (props.padding || 0);
  }

  return size;
};

const Styled = {
  Image: styled.Image<IStyledImage>`
    width: ${getIconSize}px;
    height: ${getIconSize}px;
    ${(props) => (props.rotate ? `transform: rotate(${props.rotate}deg);` : '')}
    transition: transform 0.5s;
  `,
  BackgroundWrapper: styled.View<IStyledBackgroundWrapper>`
    width: ${wrappedIconSize}px;
    height: ${wrappedIconSize}px;
    ${(props) =>
      props.backgroundColor
        ? `
    background-color: ${props.theme.color[props.backgroundColor]};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    `
        : ``}
  `,
};

export const Icon = (props: IIcon) => {
  const size = props.size || 'md';

  return (
    <Styled.BackgroundWrapper
      backgroundColor={props.backgroundColor}
      size={size}
      padding={props.padding}
      exactSize={props.exactSize}
    >
      <IconWrapper exactSize={props.exactSize} size={size} color={props.color || 'black'}>
        <Styled.Image
          size={size}
          exactSize={props.exactSize}
          rotate={props.rotate}
          source={{
            uri: `/@icons/${props.type}.svg`,
          }}
          fadeDuration={0}
        />
      </IconWrapper>
    </Styled.BackgroundWrapper>
  );
};
