import { Col, Grid } from 'react-flexbox-grid';

import { Link } from 'react-router-dom';
import { RESPONSIVE } from '../../utils/constants';
import { font } from '../../utils/styles';
import styled from 'styled-components';

interface INavBarOuterProps {
  background?: string;
  opacity?: number;
  pos?: string;
  width?: string;
}
interface INavToggledMenuProps {
  background?: string;
  opacity?: number;
  color?: string;
}
interface INavLinkProps {
  color?: string;
  activecolor?: string;
  isactive?: any;
}
interface INavBarTitleProps {
  color?: string;
}

export const NavBarOuter = styled.div<INavBarOuterProps>`
  display: block;
  background: ${(props) =>
    props.background
      ? `${props.background}`
      : props.opacity && props.opacity > 0
      ? `rgba(0,0,0,${props.opacity})`
      : `rgba(0,0,0,${props.opacity})`};
  color: #fff;
  width: ${(props) => props.width || '100%'};
  position: ${(props) => props.pos || 'fixed'};
  z-index: 999;

  .navbar-toggle {
    display: none;
  }

  @media (max-width: 1027px) {
    .navbar-toggle {
      display: block;
    }
  }
`;

export const NavBarCol = styled(Col)`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 770px) {
    display: none;
  }
`;

export const NavBarTitleContainer = styled(Col)`
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const NavBarContainer = styled(Grid)`
  z-index: 9999;
  position: relative;
  height: 80px;
  padding-left: 58px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 770px) {
    display: none;
    padding: 0 !important;
  }
`;

export const NavBarTitle = styled.div<INavBarTitleProps>`
  white-space: nowrap;
  margin-left: 15px;
  color: ${(props) => (props.color ? `${props.color}` : '#fff')};
  font-family: ${font.title};
  font-size: 18px;
  line-height: 13px;
  text-transform: uppercase;
  letter-spacing: 6.5px;

  @media (max-width: 1027px) {
    display: none;
  }

  @media (max-width: 770px) {
    display: block;
  }
`;

export const LogoImg = styled.img`
  width: 165px;
  height: 45px;
`;

export const NavLink = styled(Link)<INavLinkProps>`
  color: ${(props) =>
    props.activecolor && props.isactive ? `${props.activecolor}` : props.color ? `${props.color}` : '#fff'};
  white-space: nowrap;
  font-family: ${font.regular};
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-left: 32px;
  text-decoration: none;
  cursor: pointer;
`;

export const NavLinksContainer = styled(Col)`
  padding-right: 20px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;

  a {
    margin-left: 25px;
  }

  @media (max-width: 770px) {
    display: none;
  }
`;

export const NavBarToggle = styled.div`
  display: none;
  cursor: pointer;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  float: right;

  @media (max-width: 770px) {
    display: flex;
    margin: 1rem !important;
    float: none !important;
    justify-content: center;
    height: 3rem;
  }
`;

export const NavToggledMenu = styled.div<INavToggledMenuProps>`
  background: ${(props) =>
    props.background
      ? `${props.background}`
      : props.opacity && props.opacity > 0
      ? `rgba(0,0,0,${props.opacity})`
      : `rgba(57,71,97,1)`};
  width: 100%;
  text-align: center;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  color: ${(props) => (props.color ? props.color : '#cda263')} @media (min-width: 771px) {
    display: none;
  }

  @media (min-width: 771px) {
    display: none;
  }
`;

export const NavBarToggleSpan = styled.span<INavLinkProps>`
  color: ${(props) =>
    props.activecolor && props.isactive ? `${props.activecolor}` : props.color ? `${props.color}` : '#ffffff'};
  display: block;
  padding: 1.25rem 0;
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const NavBarToggledLink = styled(Link)<INavLinkProps>`
  color: ${(props) =>
    props.activecolor && props.isactive ? `${props.activecolor}` : props.color ? `${props.color}` : '#ffffff'};
  display: block;
  padding: 1.25rem 0;
  font-size: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const NavBarMenuDivider = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: 1px;
  height: 80px;
  border-left: 1px solid #ccc;
`;

export const NavBarMenuItemLogout = styled.div`
  cursor: pointer;
`;

export const NavBarMenuAvatar = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
`;

export const NavBarAvatarImg = styled.img`
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

  border-radius: 8px;
`;

export const NavBarAvatarName = styled.span`
  padding-left: 1rem;
  letter-spacing: 0.4px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
  font-family: ${font.regular};
`;

export const AutoSuggestInputContianer = styled.div`
  width: 100%;
  background-color: inherit;
`;
export const AutoSuggestItemContainer = styled.div`
  display: block;
  width: 100%;
  min-width: 200px;
  position: absolute;
  background-color: #fff;
  box-shadow: inset 0px 0px 1px #000;
  font-family: ${font.regular};
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  border-radius: 0px;
  left: 0;
  max-height: 347px;
  overflow: auto;
`;
export const AutoSuggestInput = styled.input`
  width: 100%;
  position: relative;
  display: flex;
  height: 100%;
  background-color: #f5f5f5;
  border: none;
  outline: none;
  height: 30px;
  padding: 6px;
  font-family: ${font.regular};
  font-weight: 300;
  font-size: 14px;
  border-radius: 4px;

  &::-webkit-input-placeholder {
    /* Edge */
    color: #747a7e !important;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #747a7e !important;
  }

  &::placeholder {
    color: #747a7e !important;
  }
`;

export const AutoSuggestItem = styled.div`
  width: 100%;
  font-size: 12px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-left: 21px;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent;
  color: #000000;
  font-family: ${font.regular};
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;

  b {
    font-weight: 300 !important;
  }

  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 11px;
  }
`;

export const SectionTitle = styled.div`
  padding-top: 8px;
  height: 40px;
  color: #000000;
  font-family: ${font.title};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  font-style: italic;
  border-bottom: 2px solid #e1e1e1;
  display: flex;
  align-items: center;
  margin-right: 21px;
  margin-left: 21px;
`;
export const AutoSuggestSection = styled.div``;
export const ItemTitle = styled.b`
  font-weight: bold;
  padding-right: 5px;
`;

export const ItemAddress = styled.span`
  display: flex;
  padding: 0px;
`;

export const AutoSuggestItemContent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
