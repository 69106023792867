import styled from 'styled-components/native';

export const CenterView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const CenterViewBlack = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.black};
  width: 100%;
  height: 100%;
  align-items: center;
`;
