import React from 'react';
import styled from 'styled-components';
import Separator from './Separator';
import { font } from '../utils/styles';
import { RESPONSIVE } from '../utils/constants';

interface PageTitleProps {
  children?: string;
  rightButton?: string;
  onClick?: () => any;
  breadCrumb?: Array<string>;
  href?: string;
}

interface TitleProps {
  children?: string;
  subtitle?: string;
}

const PageTitle = (props: PageTitleProps) => {
  const { children, rightButton, onClick, breadCrumb, href } = props;

  return (
    <>
      <TitleRow>
        <div>
          <h2>{children}</h2>
          {breadCrumb &&
            breadCrumb.map((item: string) => (
              <>
                <h5>{'  /  '}</h5>
                <h3>{item}</h3>
              </>
            ))}
        </div>
        {rightButton && (
          <a onClick={onClick} href={href ? href : undefined}>
            {rightButton}
          </a>
        )}
      </TitleRow>
      <Separator noVerticalMargin />
    </>
  );
};

const DetailsTitle = (props: TitleProps) => {
  const { children, subtitle } = props;

  return (
    <>
      <TitleContainer>
        <h2>{children}</h2>
        {subtitle && <span>{subtitle}</span>}
      </TitleContainer>
    </>
  );
};

const TitleRow = styled.div`
  width: calc(100% - 10rem);
  margin-left: 5rem;
  margin-right: 5rem;
  display: flex;
  justify-content: space-between;
  a {
    font-weight: normal;
    font-family: ${font.regular};
    font-size: 1rem;
    margin-top: 10px;
  }
  div {
    width: 60%;
    flex-direction: row;
  }
  h3 {
    display: inline-block;
    height: 26px;
    color: #000000;
    font-family: ${font.regular};
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
  }
  h2 {
    display: inline-block;
    color: #000000;
    font-family: ${font.title};
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
  h5 {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 28px;
    color: #e1e1e1;
  }
`;

const TitleContainer = styled.div`
  font-family: ${font.title};
  margin-left: 3rem;
  font-size: 1rem;
  font-weight: bold;
  span {
    font-weight: normal;
    font-size: 0.8rem;
    margin-top: 2rem;
    color: #cda263;
  }
  margin-bottom: 1.5rem;
`;

const PaddedTitle = styled.h1`
  font-family: ${font.title};
  white-space: nowrap;
  color: #000;
  font-size: 1.75rem;

  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    padding-bottom: 1rem;
    font-size: 18px;
  }
`;

export { PageTitle, DetailsTitle, PaddedTitle };
