import React, { useEffect, useState } from 'react';

import { IImage } from './types';
import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
  Image: styled.div`
    width: 100%;
    height: 100%;
    inset: 0px;
    background-size: cover;
    background-color: ${(props) => props.theme.color.gray1};
    background-repeat: no-repeat;
    background-position: center;
  `,
};

const ImagePrimitive = window.Image;

export const Image = (props: IImage) => {
  const [url, setUrl] = useState(props.lowRes?.uri || props.source?.uri);
  const isLowRes = url && url === props.lowRes?.uri;

  useEffect(() => {
    if (props.lowRes?.uri && props.source?.uri) {
      const onImageLoad = () => {
        setUrl(props.source?.uri);
      };
      const preload = new ImagePrimitive();

      preload.setAttribute('src', props.source?.uri);
      preload.addEventListener('load', onImageLoad);

      return () => {
        preload.removeEventListener('load', onImageLoad);
      };
    }
  }, [props.lowRes?.uri, props.source?.uri]);

  return (
    <Styled.Container>
      <Styled.Image style={{ backgroundImage: `url(${url})`, filter: isLowRes && 'blur(4px)' }} />
    </Styled.Container>
  );
};
