import { once } from 'lodash';

const logError = once(() => {
  console.warn(
    'Your browser does not allow using local storage. Some settings will not be persisted between sessions.',
  );
});

const _sessionStorage = {};

export const getItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    logError();
    return _sessionStorage[key];
  }
};

export const setItem = (key: string, item: string) => {
  try {
    localStorage.setItem(key, item);
  } catch (error) {
    _sessionStorage[key] = item;
    logError();
  }
};

export const removeItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    delete _sessionStorage[key];
    logError();
  }
};

export const removeItems = (keys: Array<string>) => {
  keys.forEach(removeItem);
};

export const nuke = () => {
  try {
    Object.keys(localStorage)
      // Keep ZendDesk and cookie acceptance keys
      .filter((key) => key !== 'cookies' && !key.includes('ZD-') && !key.includes('resetCode'))
      .forEach(removeItem);
  } catch (error) {
    logError();
  }
};
