import api from '../../utils/api';

interface RemovePropertyMediaProps {
  propertyId: number;
  mediaId: number;
}

export const removePropertyMedia = (props: RemovePropertyMediaProps) =>
  api.delete({
    url: `/property/${props.propertyId}/media/${props.mediaId}`,
  });
