import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { Api } from './types';
import { createContext } from 'react';

export const apiAccessors = {
  useLazyQuery,
  useQuery,
  useMutation,
};

export const ApiContext = createContext<Api>(apiAccessors);
