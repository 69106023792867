import api from '../../utils/api';

interface CategorizeMediaProps {
  categoryId: string;
  mediaIds: Array<string>;
}

export const categorizeMedia = (props: CategorizeMediaProps) => {
  const { categoryId, ...variables } = props;

  return api.post({
    url: `/category/${categoryId}/media`,
    variables,
  });
};
