import { RefreshToken, RefreshTokenVariables } from './__generated__/RefreshToken';
import { gql, useApi } from './Provider';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      accessToken
      idToken
      expiresIn
    }
  }
`;

export const useRefreshToken = () => {
  const { useMutation } = useApi();
  return useMutation<RefreshToken, RefreshTokenVariables>(REFRESH_TOKEN);
};
