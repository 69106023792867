import * as React from 'react';
import { MetaData } from '../types';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { font } from '../utils/styles';
import { decodeUrlSearch, encodeUrlSearch } from '../utils/decodeUrlSearch';

interface PaginationProps {
  metaData: MetaData;
  renderLink?: (offset: number) => string;
}

const Styled = {
  Container: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px;
    margin-top: 24px;
  `,
  PageNumber: styled(Link)`
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-family: ${font.regular};
    font-size: 16px;
    font-weight: bold;

    &.current {
      background-color: #000;
      color: #fff;
      border-radius: 14px;
    }
  `,
};

const getPages = (metaData: MetaData) => {
  const numberOfPages = Math.ceil(metaData.total / metaData.limit);

  const pages = [];

  let i = 0;

  while (i < numberOfPages) {
    pages.push(i);
    i++;
  }

  return pages;
};

export const Pagination = (props: PaginationProps) => {
  const location = useLocation();

  const currentSearch = decodeUrlSearch(location.search);

  if (!props.metaData || props.metaData.limit > props.metaData.total || props.metaData.limit === -1) {
    return null;
  }

  const pages = getPages(props.metaData);

  const renderLinkDefault = (offset: number) => {
    const newSearch = encodeUrlSearch({
      ...currentSearch,
      offset,
    });
    return `${location.pathname}${newSearch}`;
  };

  const renderLink = props.renderLink || renderLinkDefault;

  const prevPage = props.metaData.offset - props.metaData.limit;

  return (
    <Styled.Container>
      {props.metaData.offset ? (
        <Styled.PageNumber
          data-testing="pagination:previous"
          to={renderLink(prevPage > 0 ? prevPage : 0)}
          title="Previous"
        >
          &lt;
        </Styled.PageNumber>
      ) : null}
      {pages.map((page) => (
        <Styled.PageNumber
          key={page}
          data-testid={`pagination:${page}`}
          className={page * props.metaData.limit === props.metaData.offset ? 'current' : undefined}
          to={renderLink(page * props.metaData.limit)}
          title={`Page ${page + 1}`}
        >
          {page + 1}
        </Styled.PageNumber>
      ))}
      {props.metaData.offset + props.metaData.limit < props.metaData.total ? (
        <Styled.PageNumber
          data-testing="pagination:next"
          to={renderLink(props.metaData.offset + props.metaData.limit)}
          title="Next"
        >
          &gt;
        </Styled.PageNumber>
      ) : null}
    </Styled.Container>
  );
};
