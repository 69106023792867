import { UpdateUserAttributes, UpdateUserAttributesVariables } from './__generated__/UpdateUserAttributes';
import { gql, useApi } from './Provider';

const UPDATE_USER_ATTRIBUTES = gql`
  mutation UpdateUserAttributes($input: UpdateUserAttributesInput!) {
    updateUserAttributes(input: $input) {
      result
    }
  }
`;

export const useUpdateUserAttributes = () => {
  const { useMutation } = useApi();
  return useMutation<UpdateUserAttributes, UpdateUserAttributesVariables>(UPDATE_USER_ATTRIBUTES);
};
