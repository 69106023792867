import * as React from 'react';

import styled from 'styled-components';
import { font } from '../utils/styles';

interface LabelProps {
  width?: number;
  standardCase?: boolean;
  normalWeight?: boolean;
  children: string | React.ReactNode;
}

export const Label = styled.label<LabelProps>`
  text-transform: ${(props) => (!props.standardCase ? 'uppercase' : '')};
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: ${(props) => (props.normalWeight ? 'normal' : 'bold')};
  width: ${(props) => (props.width ? `${props.width}px` : 'default')};
`;
