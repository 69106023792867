import { useApi, gql } from './Provider';
import { ListEmailTemplates, ListEmailTemplates_listEmailTemplates } from './__generated__/ListEmailTemplates';

const LIST_EMAIL_TEMPLATES = gql`
  query ListEmailTemplates {
    listEmailTemplates {
      id
      name
      thumbnail
      templateId
      type
      versionName
    }
  }
`;

export const useListEmailTemplates = () => {
  const { useQuery } = useApi();
  return useQuery<ListEmailTemplates>(LIST_EMAIL_TEMPLATES);
};

export type IListEmailTemplate = ListEmailTemplates_listEmailTemplates;
