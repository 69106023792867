import React, { HTMLAttributes } from 'react';

import styled from 'styled-components';

interface IconProps extends HTMLAttributes<HTMLImageElement> {
  type?: string;
  size?: number;
  height?: number;
  width?: number;
  className?: string;
}

export const SvgIcon = (props: IconProps) => {
  return (
    <StyledIcon
      className={props.className}
      size={props.size}
      height={props.height}
      width={props.width}
      src={`/icons/${props.type}.svg`}
    />
  );
};

const StyledIcon = styled.img<IconProps>`
  display: inline-block;
  ${(props) => (props.width ? `width: ${props.width}px;` : props.size ? `width: ${props.size}px;` : 'width: auto;')}
  ${(props) =>
    props.height ? `height: ${props.height}px;` : props.size ? `height: ${props.size}px;` : 'height: auto;'}
`;
