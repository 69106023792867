import * as React from 'react';

import { Text } from '../Text';
import styled from 'styled-components/native';

interface IField {
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
  label?: string;
  error?: string;
  height?: number;
  autoResize?: boolean;
  type?: 'box' | 'rounded';
}

interface IStyledField {
  hasError: boolean;
  height?: number;
  autoResize?: boolean;
  type: 'box' | 'rounded';
}

const Styled = {
  Field: styled.View<IStyledField>`
    width: 100%;
    border: solid 1px ${(props) => (props.type === 'box' ? props.theme.color.black : props.theme.color.gray1)};
    border-radius: ${(props) => (props.type === 'box' ? 0 : 6)}px;
    ${(props) => {
      if (props.autoResize) {
        return '';
      }

      const height = props.height || 62;
      return `height: ${props.hasError ? height + 16 : height}px`;
    }}
    display: flex;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: ${(props) => (props.type === 'box' ? 0 : 7)}px;
    padding-bottom: ${(props) => (props.type === 'box' ? 0 : 7)}px;
    flex-direction: column;
    position: relative;
  `,
};

export const Field = (props: IField) => {
  return (
    <Styled.Field
      hasError={!!props.error}
      height={props.height}
      autoResize={props.autoResize}
      type={props.type || 'rounded'}
    >
      <Text color="gray" size="sm">
        {props.label ? props.label.toUpperCase() : ''}
      </Text>
      {props.children}
      {props.error && (
        <Text color="danger" size="sm" weight="bold">
          {props.error}
        </Text>
      )}
    </Styled.Field>
  );
};
