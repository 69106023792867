import React, { useRef, useState } from 'react';

import Icon from './Icon';
import styled from 'styled-components';
import ImageCropperModal from './ImageCropper';
import { font } from '../utils/styles';

interface IUploadInputProps {
  message?: string;
  href?: string;
  onClick?: (ev: any) => void;
  containerStyle?: any;
  imageSize?: number;
  ratio?: number;
  savedFile?: any;
  shouldCrop: boolean;
  shouldPreview: boolean;
  id?: string;
}

export function UploadInput(props: IUploadInputProps) {
  const { shouldCrop, shouldPreview } = props;
  const ratio = props.ratio || 1;
  const imageSize = props.imageSize || 120;
  const [file, setFile] = useState(props.savedFile ? URL.createObjectURL(props.savedFile) : '');
  const avatarRef = useRef<HTMLInputElement>(null);
  const [isCropOpen, setIsCropOpen] = useState(false);

  const onChange = (ev: any) => {
    setFile(URL.createObjectURL(ev.target.files[0]));
    if (shouldCrop) {
      setIsCropOpen(true);
    } else if (props.onClick) {
      props.onClick(ev.target.files[0]);
    }
  };

  const handleCrop = (crop: any) => {
    setFile(crop.url);
    setIsCropOpen(false);
    if (props.onClick) {
      props.onClick(crop.file);
    }
  };

  const handleCancel = () => {
    setIsCropOpen(false);
    setFile(props.savedFile ? URL.createObjectURL(props.savedFile) : '');
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <div id={props.id} style={props.containerStyle ? props.containerStyle : {}}>
      <input type="file" ref={avatarRef} onChange={onChange} style={{ display: 'none' }} onClick={onInputClick} />
      {(file || props.href) && shouldPreview && (
        <UploadPreview src={(file || props.href) as string} ratio={ratio} imageSize={imageSize} />
      )}
      <FlexRow onClick={() => avatarRef.current && avatarRef.current.click()}>
        <UploadText>{!shouldPreview && file ? 'File Attached' : props.message || 'Change Image'}</UploadText>
        <Icon type="edit" color="#000" size={16} />
      </FlexRow>
      <ImageCropperModal
        file={file as string}
        aspectRatio={ratio}
        onCrop={handleCrop}
        isOpen={isCropOpen}
        onClose={handleCancel}
      />
    </div>
  );
}

interface Preview {
  ratio: number;
  imageSize: number;
}

const UploadPreview = styled.img<Preview>`
  width: ${(props: Preview) => props.imageSize}px;
  height: ${(props: Preview) => props.imageSize / props.ratio}px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const UploadText = styled.span`
  font-family: ${font.regular};
  font-weight: normal;
  font-size: 0.8rem;
  padding-right: 5px;
`;

export default UploadInput;
