import {
  QueryPropertyPageUnit,
  QueryPropertyPageUnitVariables,
  QueryPropertyPageUnit_unit_videos,
} from './__generated__/QueryPropertyPageUnit';
import { gql, useApi } from './Provider';

export const QUERY_PROPERTY_UNIT = gql`
  query QueryPropertyPageUnit($unitId: Int!) {
    unit(id: $unitId, auditType: unitVisit) {
      id
      name
      price
      walkThroughUrl
      isFavorite
      property {
        id
        name
        propertyType {
          id
          name
        }
      }
      floor {
        id
        component {
          id
          name
        }
        keyPlan {
          id
          name
          media {
            id
            url
          }
        }
      }
      media {
        id
        url
        name
      }
      videos {
        id
        name
        url
      }
      unitViews {
        id
        media {
          id
          url
        }
        viewType {
          id
          name
        }
      }
      unitModel {
        id
        description
        bedrooms
        bathrooms
        interiorSquarefeet
        exteriorSquarefeet
        tags {
          id
          name
        }
        floorPlans {
          id
          url
          name
          mediaType {
            id
            name
          }
        }
        media {
          id
          url
          name
        }
        amenities {
          id
          name
        }
      }
    }
  }
`;

export type IPropertyUnitVideos = QueryPropertyPageUnit_unit_videos;

export const useGetPropertyUnit = (variables: QueryPropertyPageUnitVariables) => {
  const { useQuery } = useApi();
  return useQuery<QueryPropertyPageUnit, QueryPropertyPageUnitVariables>(QUERY_PROPERTY_UNIT, {
    variables,
  });
};

export const useGetPropertyUnitLazy = () => {
  const { useLazyQuery } = useApi();
  return useLazyQuery<QueryPropertyPageUnit, QueryPropertyPageUnitVariables>(QUERY_PROPERTY_UNIT);
};
