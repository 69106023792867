import { GetMarketingLink, GetMarketingLinkVariables } from './__generated__/GetMarketingLink';
import { gql, useApi } from './Provider';

const GET_MARKETING_LINK = gql`
  query GetMarketingLink($id: String!) {
    marketingLink(id: $id) {
      id
      slug
      type
      status
      name
      html
      enableNotifications
      redirectionLocation
      agent {
        id
        vanityStub
      }
    }
  }
`;

export const useGetMarketingLink = (id: string) => {
  const { useQuery } = useApi();
  return useQuery<GetMarketingLink, GetMarketingLinkVariables>(GET_MARKETING_LINK, {
    variables: {
      id,
    },
  });
};
