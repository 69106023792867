import { UnFavoriteProperty, UnFavoritePropertyVariables } from '../api/__generated__/UnFavoriteProperty';
import { useApi, gql } from './Provider';

const UNFAVORITE_PROPERTY = gql`
  mutation UnFavoriteProperty($propertyId: BigInt!) {
    unFavoriteProperty(propertyId: $propertyId) {
      id
      isFavorite
    }
  }
`;

export const useUnFavoriteProperty = () => {
  const { useMutation } = useApi();
  return useMutation<UnFavoriteProperty, UnFavoritePropertyVariables>(UNFAVORITE_PROPERTY);
};
