import * as React from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

interface HorizontalTabsProps {
  value: string;
  onValueChange: (value: string) => void;
  tabs: Array<{ key: string; display: string }>;
}

export const HorizontalTabs = (props: HorizontalTabsProps) => (
  <Styled.Tabs>
    {props.tabs.map((tab) => (
      <Styled.Tab
        type="button"
        key={tab.key}
        onClick={() => props.onValueChange(tab.key)}
        className={tab.key === props.value ? 'active' : undefined}
      >
        {tab.display}
      </Styled.Tab>
    ))}
  </Styled.Tabs>
);

const Styled = {
  Tabs: styled.div``,
  Tab: styled.button`
    border-bottom: solid 3px #ddd;
    cursor: pointer;
    margin-left: 0px !important;
    font-size: 1em;
    padding: 10px 22px;
    font-family: ${font.medium};
    font-weight: medium;
    color: #ccc;
    text-transform: uppercase;

    &.active {
      color: #000;
      border-bottom-color: #cca362;
    }
  `,
};
