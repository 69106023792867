import { useApi, gql } from './Provider';
import { UpdateInvitedClient, UpdateInvitedClientVariables } from './__generated__/UpdateInvitedClient';

const UPDATE_INVITED_CLIENT_PROFILE = gql`
  mutation UpdateInvitedClient($input: VerifyUserInput!) {
    updateInvitedClient(input: $input) {
      success
      reason
    }
  }
`;

export const useUpdateInvitedClientProfile = () => {
  const { useMutation } = useApi();
  return useMutation<UpdateInvitedClient, UpdateInvitedClientVariables>(UPDATE_INVITED_CLIENT_PROFILE);
};
