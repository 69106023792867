import * as React from 'react';

import ReactDataGrid from 'react-data-grid';
import styled from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize';
import { memoize } from 'lodash/fp';
import { font } from '../utils/styles';

interface Row {
  rowIdx: number;
}

interface Column {
  key: string;
  name: string;
  editable?: boolean;
  editor?: React.ReactNode;
}

interface GridProps {
  errorsOnly?: boolean;
  columns: Array<Column>;
  data: Array<any>;
  setData: (data: Array<any>) => void;
  schema?: any;
  enableRowSelection?: boolean;
  selectedRows?: Array<number>;
  setSelectedRows?: (rows: Array<number>) => void;
  heightOffset?: number;
}

interface ColumnError {
  errorIndex: number;
  message: string;
}

interface ErrorRowProps {
  errors: Array<ColumnError>;
}

const Styled = {
  Container: styled.div`
    font-family: ${font.regular};

    .react-grid-HeaderRow {
      overflow: hidden;

      &:hover,
      &:focus {
        overflow: visible;
      }
    }

    .react-grid-HeaderCell--frozen {
      padding: 0px;
    }

    .react-grid-Cell--frozen {
      padding: 0px;
    }
  `,
  ErrorRow: styled.div`
    ${(props: ErrorRowProps) => {
      return props.errors.map((error) => {
        return `.react-grid-Cell:nth-child(${error.errorIndex}) {
            color: red;
            background: pink;
            position: relative;
            box-shadow:inset 0px 0px 0px 1px red;

            &:before {
                content: "${error.message}";
                display: none;
            }

            &:hover {
                &:before {
                    background: red;
                    color: #FFF;
                    font-size: 11px;
                    padding-left: 4px;
                    display: block;
                    position: absolute;
                    flex: 1;
                    z-index: 100;
                    padding: 7px;
                    right: 0px;
                    top: 34px;
                }

                &:focus {
                    &:before {
                        display: none;
                    }
                }
            }

            .react-grid-Cell__value > div {
                top: 11px !important;
            }
        }`;
      });
    }}
  `,
};

const checkRowValidation = (columnsArray: any, schema: any) =>
  memoize((row: any) => {
    const rowErrors = [] as Array<ColumnError>;

    columnsArray.forEach((columnName: string, index: number) => {
      try {
        schema.validateSyncAt(columnName, row);
      } catch (err) {
        const errorIndex = index + 1;
        rowErrors.push({
          errorIndex,
          message: err.message,
        });
      }
    });

    return rowErrors;
  });

export const Grid = (props: GridProps) => {
  const windowSize = useWindowSize();
  const columnsArray = props.columns.map((column) => column.key);
  const { schema } = props;

  const heightOffset = props.heightOffset || 240;

  const rowValidation = React.useMemo(() => checkRowValidation(columnsArray, schema), [columnsArray, schema]);

  const data = props.errorsOnly
    ? props.data
        .map((row, i) => ({
          rowKey: i,
          ...row,
        }))
        .filter((row) => {
          const rowErrors = rowValidation(row);
          return !!rowErrors.length;
        })
    : props.data.map((row, i) => ({
        rowKey: i,
        ...row,
      }));

  return (
    <Styled.Container>
      <ReactDataGrid
        minHeight={windowSize?.height ? windowSize?.height - heightOffset : 500}
        columns={props.columns as any}
        rowGetter={(i: number) => data[i]}
        rowsCount={data.length}
        enableCellSelect={true}
        rowRenderer={({ renderBaseRow, ...rowProps }) => {
          if (!props.schema) {
            return renderBaseRow(rowProps);
          }

          const rowErrors = rowValidation(rowProps.row);

          if (rowErrors.length) {
            return <Styled.ErrorRow errors={rowErrors}>{renderBaseRow(rowProps)}</Styled.ErrorRow>;
          }

          return renderBaseRow(rowProps);
        }}
        // Unfortunately, the react-data-grid types are incomplete :(
        onGridRowsUpdated={(event: any) => {
          props.setData(
            props.data.map((row, i) =>
              i === event?.fromRowData?.rowKey
                ? {
                    ...row,
                    ...event.updated,
                  }
                : row,
            ),
          );
        }}
        rowSelection={{
          showCheckbox: !!props.enableRowSelection,
          enableShiftSelect: true,
          onRowsSelected: (rows: Array<Row>) => {
            if (props.setSelectedRows) {
              props.setSelectedRows([...(props.selectedRows || []), ...rows.map((r) => r.rowIdx)]);
            }
          },
          onRowsDeselected: (rows: Array<Row>) => {
            if (props.setSelectedRows) {
              const rowIndexes = rows.map((row) => row.rowIdx);

              props.setSelectedRows(
                (props.selectedRows || []).filter((selectedRow) => rowIndexes.indexOf(selectedRow) === -1),
              );
            }
          },
          selectBy: {
            indexes: props.selectedRows || [],
          },
        }}
      />
    </Styled.Container>
  );
};
