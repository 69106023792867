import { DefaultTheme } from 'styled-components/native';

const FONT_FAMILY_KEY = '7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd';
const FONT_FAMILY_ROOT = 'https://use.typekit.net/af';

export const FONT_FAMILY_URLS = {
  title300: `${FONT_FAMILY_ROOT}/624f00/00000000000000003b9af037/27/a?primer=${FONT_FAMILY_KEY}=n3&v=3`,
  title300Italic: `${FONT_FAMILY_ROOT}/de021d/00000000000000003b9af036/27/a?primer=${FONT_FAMILY_KEY}=i3&v=3`,
  title400: `${FONT_FAMILY_ROOT}/78ca66/00000000000000003b9af038/27/a?primer=${FONT_FAMILY_KEY}=n4&v=3`,
  title400Italic: `${FONT_FAMILY_ROOT}/f0a340/00000000000000003b9af039/27/a?primer=${FONT_FAMILY_KEY}=i4&v=3`,
  title600: `${FONT_FAMILY_ROOT}/8de0c1/00000000000000003b9af03a/27/a?primer=${FONT_FAMILY_KEY}=n6&v=3`,
  title600Italic: `${FONT_FAMILY_ROOT}/0c5f2c/00000000000000003b9af03b/27/a?primer=${FONT_FAMILY_KEY}=i6&v=3`,
  title700: `${FONT_FAMILY_ROOT}/958a5b/00000000000000003b9af03d/27/a?primer=${FONT_FAMILY_KEY}=n7&v=3`,
  title700Italic: `${FONT_FAMILY_ROOT}/2b3e8b/00000000000000003b9af03c/27/a?primer=${FONT_FAMILY_KEY}=i7&v=3`,
  title800: `${FONT_FAMILY_ROOT}/e21330/00000000000000003b9af03f/27/a?primer=${FONT_FAMILY_KEY}=n8&v=3`,
  title800Italic: `${FONT_FAMILY_ROOT}/a11f43/00000000000000003b9af03e/27/a?primer=${FONT_FAMILY_KEY}=i8&v=3`,
  title900: `${FONT_FAMILY_ROOT}/b4db1b/00000000000000003b9af040/27/a?primer=${FONT_FAMILY_KEY}=n9&v=3`,
  title900Italic: `${FONT_FAMILY_ROOT}/caa905/00000000000000003b9af041/27/a?primer=${FONT_FAMILY_KEY}=i9&v=3`,
  body600: `${FONT_FAMILY_ROOT}/8a8abc/00000000000000007735bb07/30/a?primer=${FONT_FAMILY_KEY}=n6&v=3`,
  body600Italic: `${FONT_FAMILY_ROOT}/59286b/00000000000000007735bb0a/30/a?primer=${FONT_FAMILY_KEY}=i6&v=3`,
  body700: `${FONT_FAMILY_ROOT}/92a736/00000000000000007735bb14/30/a?primer=${FONT_FAMILY_KEY}=n7&v=3`,
  body700Italic: `${FONT_FAMILY_ROOT}/8e49dd/00000000000000007735bb17/30/a?primer=${FONT_FAMILY_KEY}=i7&v=3`,
  body400Italic: `${FONT_FAMILY_ROOT}/e07c44/00000000000000007735bb1b/30/a?primer=${FONT_FAMILY_KEY}=i4&v=3`,
  body300: `${FONT_FAMILY_ROOT}/5da8b8/00000000000000007735bb1e/30/a?primer=${FONT_FAMILY_KEY}=n3&v=3`,
  body300Italic: `${FONT_FAMILY_ROOT}/621cef/00000000000000007735bb21/30/a?primer=${FONT_FAMILY_KEY}=i3&v=3`,
  body500: `${FONT_FAMILY_ROOT}/579a71/00000000000000007735bb24/30/a?primer=${FONT_FAMILY_KEY}=n5&v=3`,
  body500Italic: `${FONT_FAMILY_ROOT}/ba81f0/00000000000000007735bb27/30/a?primer=${FONT_FAMILY_KEY}=i5&v=3`,
  body400: `${FONT_FAMILY_ROOT}/d7f1e9/00000000000000007735bb2a/30/a?primer=${FONT_FAMILY_KEY}=n4&v=3`,
  body200: `${FONT_FAMILY_ROOT}/1ae426/00000000000000007735bb2c/30/a?primer=${FONT_FAMILY_KEY}=n2&v=3`,
  body200Italic: `${FONT_FAMILY_ROOT}/6a5d49/00000000000000007735bb2d/30/a?primer=${FONT_FAMILY_KEY}=i2&v=3`,
  body100: `${FONT_FAMILY_ROOT}/f824a5/00000000000000007735bb2f/30/a?primer=${FONT_FAMILY_KEY}=n1&v=3`,
  body100Italic: `${FONT_FAMILY_ROOT}/e3f2eb/00000000000000007735bb30/30/a?primer=${FONT_FAMILY_KEY}=i1&v=3`,
};

export type IFontFamily = typeof FONT_FAMILY_URLS;

export const FONT_FAMILY: IFontFamily = Object.keys(FONT_FAMILY_URLS).reduce(
  (prev, current) => ({
    ...prev,
    [current]: current,
  }),
  {},
) as IFontFamily;

export type IColor = 'black' | 'white' | 'gold' | 'gray' | 'gray1' | 'gray2' | 'danger';

export const COLOR = {
  black: '#000000',
  white: '#FFFFFF',
  gold: '#C9985E',
  gray: '#747a7e',
  gray1: '#E1E1E1',
  gray2: '#F5F5F5',
  danger: '#DB0000',
};

export type IColors = typeof COLOR;

export type IFontSize = 'giant' | 'h1' | 'h2' | 'h3' | 'h4' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

export const FONT_SIZE = {
  giant: 50,
  h1: 36,
  h2: 32,
  h3: 28,
  h4: 24,
  xxl: 21,
  xl: 18,
  lg: 16,
  md: 14,
  sm: 12,
  xs: 10,
  xxs: 9,
};

export const RESPONSIVE = {
  xxxs: 315,
  xxs: 400,
  xs: 575,
  sm: 640,
  md: 970,
  lg: 1024,
  xl: 1500,
  xxl: 2000,
};

declare module 'styled-components' {
  export interface DefaultTheme {
    color: typeof COLOR;
    font: typeof FONT_FAMILY;
    fontSize: typeof FONT_SIZE;
    responsive: typeof RESPONSIVE;
  }
}

export const theme: DefaultTheme = {
  color: COLOR,
  font: FONT_FAMILY,
  fontSize: FONT_SIZE,
  responsive: RESPONSIVE,
};
