import * as React from 'react';

import { LeftNavBar, NavLink } from './LeftNavBar';

import { AuthenticatedHeader } from './AuthenticatedHeader';
import { FullPage } from './Page';
import styled from 'styled-components';

interface LeftNavLayoutProps {
  navLinks: Array<NavLink>;
  children: React.ReactNode;
  title: string;
}

const Styled = {
  Column: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
  `,
  Bottom: styled.div`
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 50px);
    padding-top: 24px;
  `,
};

export const LeftNavLayout = (props: LeftNavLayoutProps) => {
  return (
    <FullPage>
      <LeftNavBar linkColor="#fff" activeLinkColor="#CDA263" navLinks={props.navLinks} title={props.title} />
      <Styled.Column>
        <AuthenticatedHeader disableLogo={true} width="100%" />
        <Styled.Bottom>{props.children}</Styled.Bottom>
      </Styled.Column>
    </FullPage>
  );
};
