import { IColor } from '../../theme';
import styled from 'styled-components/native';

interface IBackground {
  color: IColor;
  borderColor?: IColor;
}

export const Background = styled.View<IBackground>`
  background-color: ${(props) => props.theme.color[props.color]};
  ${(props) => (props.borderColor ? `border: solid 1px ${props.theme.color[props.borderColor]};` : '')}
`;
