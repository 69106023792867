import { useLocation } from 'react-router-dom';
import { queryStringToObject } from '../utils/url';

export const useEmulateUser = () => {
  const location = useLocation();
  const searchParams = queryStringToObject(location.search);
  const emulateUser = searchParams?.emulateUser;

  return emulateUser;
};
