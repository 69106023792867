import { Translation } from './types';

const es = ({
  LanguagePicker: {
    header: 'Elige un idioma',
  },
  Nav: {
    developers: 'Para desarrolladores',
  },
  ErrorPage: {
    title: 'Vaya, algo salió mal ...',
    description: 'No estamos muy seguros de qué salió mal. No dude en contactarnos si necesita ayuda.',
  },
  Login: {
    accessAccount: 'Acceda a su cuenta',
    forgotPassword: '¿Olvidó su contraseña?',
    forgotPasswordTitle: 'Olvidé mi contraseña',
    remindMe: 'Ayuda para acceder a mi cuenta',
    accountDoesntExist: '¿Todavía no tiene una cuenta?',
    verification: 'Hemos confirmado su cuenta. Serás reenviado automáticamente al inicio de sesión.',
  },
  Register: {
    title: 'Crear una cuenta de agente',
    titleClient: 'Crear una cuenta de cliente',
    subtitle: 'Comenzar',
    accountExists: '¿Ya tienes una cuenta?',
    passwordRequirement: {
      length: 'Al menos 8 caracteres de longitud',
      uppercase: 'Al menos un carácter en mayúscula',
      lowercase: 'Al menos un carácter en minúscula',
      numeric: 'Al menos un carácter numérico',
      email: 'No contiene identificador de correo electrónico',
      special: 'Al menos un carácter en especial',
    },
    confirm:
      '¡Gracias por registrarse! Para completar el proceso de registro, revise su correo electrónico y siga las instrucciones proporcionadas.',
    usernameAlreadyExists:
      'Esta dirección de correo electrónico ya está registrada, inicie sesión con su correo electrónico y contraseña.',
    invalidPassword:
      'Su contraseña parece ser inválida. Asegúrese de no volver a utilizar una contraseña utilizada actualmente.',
  },
  ForgotPassword: {
    readyToLogin: '¿Listo para iniciar sesión?',
    confirm: 'Siga las instrucciones en el correo electrónico que se le envió para restablecer su contraseña.',
  },
  PasswordReset: {
    title: 'Restablecimiento de contraseña',
    confirm:
      'Su contraseña se ha restablecido correctamente. Serás reenviado automáticamente al inicio de sesión ahora.',
  },
  Verification: {
    expired:
      'Su enlace de confirmación ha caducado. Le reenviamos automáticamente un nuevo enlace de confirmación. Verifique su correo electrónico y siga las instrucciones proporcionadas.',
  },
  ClientHome: {
    newAroundHere: '¿Nuevo por aquí?',
    fillOutSurvey:
      'para completar su encuesta de compradores para que podamos hacer las mejores recomendaciones adaptadas a sus preferencias.',
    myPreferences: 'Mis Preferencias',
    emailPreferences: 'Email Preferences',
    occupation: 'Ocupación',
    employer: 'Empleador',
    timeZone: 'Zona horaria',
    brokerage: 'Corretaje',
    receiveCriteria: 'Recibir información sobre propiedades que se ajustan a mis criterios de búsqueda',
    none: 'Ninguno',
    address: 'Direccion actual',
    details: 'Detalles de usuario',
    editDetails: 'Editar detalles del usuario',
    myAgent: 'Mi agente',
    realEstate: 'Preferencias inmobiliarias',
    surveyResults: 'Resultados de la encuesta del cliente',
    retake: 'Retomar encuesta',
    firstTimeBuyer: '¿Comprador de casa por primera vez?',
    activelyLooking: '¿Estás buscando activamente?',
    industry: 'Industria',
    priceRange: 'Rango de precio preferido',
    ownershipStatus: 'Actualmente alquiler / propio o ambos?',
    sizeRange: 'Rango de tamaño preferido',
    communicationPreference: 'Preferencias de comunicación del corredor?',
    language: 'Idioma',
    bedroomNumber: '¿Cuántas habitaciones prefieres?',
    bathroomNumber: '¿Cuántos baños prefieres?',
  },
  ClientInvite: {
    title: 'Invitar al cliente',
    subtitle: 'Ingrese los detalles de su cliente a continuación',
    confirm: 'La invitación a su cliente se ha enviado correctamente.',
  },
  Common: {
    edit: 'Editar',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    join: 'Únete ahora',
    createAccount: 'Crear cuenta',
    searchHomes: 'Buscar casas',
    contactUs: 'Contáctenos',
    home: 'Hogar',
    welcome: 'Bienvenido(a)s',
    submit: 'enviar',
    gdpr:
      'Utilizamos cookies y otras tecnologías de seguimiento para mejorar su experiencia de navegación en nuestro sitio web, mostrarle contenido personalizado, analizar el tráfico de nuestro sitio web y comprender de dónde provienen nuestros visitantes. Al navegar por nuestro sitio web, acepta nuestro uso de cookies y otras tecnologías de seguimiento .',
    gdprPolicies:
      'Puede ver nuestra <a href="/privacy-policy"> Política de privacidad </a> y <a href="/cookies-policy"> Política de cookies </a> para obtener más detalles.',
    gdprAccept: 'Acepto',
    recommendedListings: 'Listas recomendadas',
    from: 'De',
    clickHere: 'Haga clic aquí',
    myFavorite: 'Mi favorito(a)',
    features: 'funciones',
    listings: 'Listados',
    recentlyViewed: 'Visto recientemente',
    add: 'Agregar',
    sendInvite: 'Enviar invitación',
    serverError: 'Actualmente estamos experimentando dificultades técnicas. Por favor, intente nuevamente en breve.',
    backToSearch: 'Volver a buscar',
    contactUsForMoreInformation: 'Contáctenos para más información',
    contactAgent: 'Agente de contacto',
    learnMoreAbout: 'Aprender más sobre',
    message: 'Mensaje',
    messages: 'Mensajes',
    more: 'Más',
    save: 'Salvar',
    addNewBrokerage: 'Agregar Nuevo Corretaje',
    profile: 'Perfil',
    help: 'Ayuda',
  },
  Forms: {
    email: 'Correo electrónico',
    firstName: 'nombre',
    lastName: 'Apellido',
    password: 'Contraseña ',
    confirmpassword: 'Vuelva a ingresar su contraseña',
    newPassword: 'Nueva contraseña',
    oldPassword: 'Contraseña actual',
    confirmNewPassword: 'Confirmar nueva contraseña',
    vanitystub: 'parámetro de URL de vanidad para su página de destino única',
    vanitystubShort: 'Portal URL',
    license: 'Licencia inmobiliaria #',
    brokerageName: 'Nombre de Corretaje',
    brokerageOfficeName: 'Nombre de la oficina de Corretaje',
    brokeragePrimaryOfficeEmail: 'Correo electrónico principal de Corretaje',
    brokerageWebsite: 'Sitio web de Corretaje',
    brokeragePhone: 'Teléfono de Corretaje',
    brokerageAddress: 'Dirección de Corretaje',
    brokerageAddressLine1: 'Dirección de Corretaje Línea 1',
    brokerageAddressLine2: 'Dirección de Corretaje Línea 2',
    brokerageAddressCity: 'Dirección de Corretaje Ciudad',
    brokerageAddressState: 'Estado de la dirección de Corretaje',
    brokerageAddressPostalCode: 'Dirección de Corretaje Código Postal',
    bio: 'Bio',
    workPhone: 'Teléfono del trabajo',
    homePhone: 'Teléfono de casa',
    addressLine1: 'Dirección Línea 1',
    addressLine2: 'Dirección Línea 2',
    city: 'Ciudad',
    state: 'Estado',
    postalCode: 'Código postal',
    saveNewBrokerage: 'Guarde la nueva corredora antes de crear una cuenta.',
    authorizedRepresentativeBrokerage: 'Soy un(a) representante autorizado(a) de esta correduría.',
    acceptTermsAndPolicies:
      'He leído y acepto los <a href="/terms-of-service" target="_blank"> Términos de servicios </a> y <a href = "/ privacy-policy" target = "_blank" > Política de privacidad </a> ',
    selectImage: 'Seleccionar imagen',
    selectImageLong: 'Seleccione el tipo de imagen de reconocimiento',
    addAccolade: 'Agregar galardón',
    removeAccolade: 'Eliminar galardón',
    accoladeText: 'Texto de reconocimiento',
  },
  FormErrors: {
    required: 'Este campo es obligatorio',
    email: 'Se requiere un correo electrónico válido',
    passwordConfirm: 'La contraseña no coincide',
    validEmail: 'Debe ser una dirección de correo electrónico válida',
    validPhoneNumber: 'Debe ser un número de teléfono válido que incluya un código de área',
    validWebsite: 'Debe ser un sitio web válido',
    urlParam: 'Debe contener solo letras minúsculas y / o números',
    doesNotMeetRequirements: 'La contraseña no cumple los requisitos mínimos',
    passwordRequirementsLength: 'Al menos un carácter en mayúscula',
    passwordRequirementsUppercase: 'Al menos un carácter en minúscula',
    passwordRequirementsLowercase: 'Al menos un carácter en mayúscula',
    passwordRequirementsNumeric: 'Al menos un carácter numérico',
    passwordRequirementsEmail: 'No contiene identificador de correo electrónico',
    notConfirmed: 'El usuario aún no ha verificado su dirección de correo electrónico.',
    passwordResetRequired: 'Se requiere restablecer la contraseña. Visite la página Olvidé mi contraseña.',
    incorrectCredentials: 'Correo electrónico o contraseña no válidos.',
    usernameExists: 'Dirección de correo electrónico ya está en uso.',
    usernameAlreadyConfirm: 'El usuario ya ha sido confirmado. Vaya a la página de inicio de sesión.',
    serverError:
      'Se ha producido un error desconocido del servidor. Un administrador ha sido notificado. Por favor, intente nuevamente en breve.',
    invalidFormat: 'Debe contener solo caracteres alfanuméricos.',
    invalidLength: (size: number) => `No debe tener más de ${size} caracteres de longitud.`,
    generic: 'Ocurrió un error',
    associationExists: 'Esta organización ya está asociada',
    userExists: 'Este usuario ya está asociado con esta organización',
    tooManySelectedMedia: 'Es posible que no tenga más de {{limit}} elementos multimedia seleccionados.',
  },
  Home: {
    searchTitle: 'Encuentra tu próxima casa de lujo.',
    searchPlaceholder: 'Ingrese dirección, nombre del proyecto, vecindario, ciudad, código postal o país',
    sellTitle: 'Vende tu próxima casa de lujo.',
    sellSubTitle: 'Obtenga su propio sitio de marca blanca y comparta hermosas listas con sus compradores.',
    agents: 'agentes',
    buyers: 'Compradores',
    developers: 'Desarrolladores y agentes de listado',
    agentFeature1: 'Obtenga su propio sitio con etiqueta blanca',
    agentFeature2: 'Comparta desarrollos con sus compradores',
    buyerFeature1: 'Buscar en los nuevos desarrollos más emblemáticos del mundo',
    buyerFeature2: 'Acceso directo a presentaciones de desarrolladores',
    developerFeature1: 'Agregue su nuevo desarrollo a VYSTA ahora',
    developerFeature2: '...',
    createAccount: 'Crear cuenta',
    askAgent: '¿Buscando un hogar? Pídale a su agente que se una a VYSTA',
    shareWithAgent: 'Comparta VYSTA con su agente',
    learn: 'Aprenda sobre VYSTA',
    listingTitle: 'Hermosos listados',
    listingSubTitle: 'Muestra nuevos desarrollos emocionantes de todo el mundo',
  },
  About: {
    title: 'Acerca de VYSTA',
    contact: 'Contacto',
    descTitle: 'La nueva plataforma de desarrollo más poderosa del mundo',
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    question: '¿Tiene una pregunta?',
    questionSubtitle:
      'Algún subtexto que ayuda a validar el encabezado anterior y el valor de cada uno de estos planes a continuación.',
    aboutUs: 'Acerca de nosotros',
    about:
      'Durante 25 años, Jonathan ha desarrollado productos y negocios exitosos en tecnología, servicios financieros, productos farmacéuticos, marketing, publicaciones y energía alternativa en organizaciones como NASA, Intel, Goldman Sachs, Precision Therapies y Eli Lilly. <br/> <br/> Antes de cofundar Evolution Ventures, Jonathan trabajó como Vicepresidente de Planificación Estratégica en Woolbright Development, donde fue responsable de recaudar $ 250 millones en capital institucional y de diseñar soluciones tecnológicas para mejorar el arrendamiento, las adquisiciones y las operaciones. En Goldman Sachs, Jonathan diseñó y emitió nuevos valores y derivados para permitir que los REIT y las corporaciones accedan de manera más eficiente al capital. Sus otras experiencias han variado desde recuperar un medicamento para la osteoporosis de $ 1 mil millones en Eli Lilly hasta crear una firma de investigación y marca en línea líder en el mercado, LucidView, que vendió a Jungle Media. Jonathan tiene una licenciatura en Ciencias de la Computación del MIT y un MBA de The Wharton School de la Universidad de Pennsylvania. Es un asociado de ventas de bienes raíces con licencia en el Estado de Florida.',
    freeTitle: 'Gratis para los agentes y sus compradores',
    freeSubtitle: 'Corredores y desarrolladores, contáctenos para acceder a herramientas avanzadas.',
    contactFormSubtitle: 'Complete los detalles a continuación y nos pondremos en contacto con usted.',
    answer: 'Respuesta',
    faq: [
      {
        key: 0,
        question: 'How do I add a property?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 1,
        question: 'How do I favorite a property?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 2,
        question: '¿Cómo veo mis propiedades vistas recientemente?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 3,
        question: '¿Qué me permite hacer mi cuenta de VYSTA?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 4,
        question: '¿Tengo acceso directo a agentes inmobiliarios a través de VYSTA?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 5,
        question: '¿Cómo contacto una propiedad sobre una unidad?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
    ],
    ourOffices: 'Nuestras oficinas',
    corporateOffices: 'Oficinas corporativas',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    termsOfServices: 'Términos de servicios',
    privacyPolicy: 'Política de privacidad',
  },
  AgentPortal: {
    inviteResentSuccess: 'La invitación del usuario ha sido enviada con éxito.',
    inviteResentError:
      'No hemos podido enviar la invitación al usuario, se ha contactado a un administrador sobre el error.',
    email: 'Email',
    clientName: 'Nombre del cliente',
    status: 'Estado',
    lastLogin: 'Último acceso',
    cityCountry: 'Ciudad, País',
    emailsSent: 'Emails Expedidos',
    inviteSent: 'Invitación Expedidos',
    viewClient: 'Ver Cliente',
    favoriteUnits: 'Unidades favoritas',
    timeSpent: 'Tiempo usado',
    searchClients: 'Buscar clientes',
    newClient: 'Nuevo cliente',
    inviteClient: 'Invitar al cliente',
    bulkClientSuccess: 'Las invitaciones de los usuarios se han enviado correctamente',
    sortBy: 'Ordenar por',
    mostRecent: 'Más reciente',
    leastRecent: 'Menos reciente',
    emptyListClients: 'Sin clientes',
    pendingVerification: 'Verificación pendiente',
    verified: 'Verificado',
    resendInvite: 'Reenviar invitación',
    myClients: 'Mis Cliente(a)s',
    myAccount: 'Mi Cuenta',
    myListings: 'Mis listados',
    myMessages: 'Mis mensajes',
    userDetails: 'Detalles de Usuario',
    settings: 'Configuraciones',
    profile: 'Perfil',
    password: 'Contraseña',
    preferences: 'Preferencias',
    portalDesign: 'Diseño de portal',
    customClientSurvey: 'Encuesta de cliente personalizada',
  },
  AgentHome: {
    emptyFavoriteListings: 'Aún no hay listados favoritos. ¡Comienza a buscar para agregar favoritos!',
    emptyRecommendedListings: 'Esta agente no ha recomendado ningún anuncio todavía.',
    emptyRecentListings: 'No se ha visto recientemente todavía. Cuando vea una propiedad, aparecerá aquí.',
    emptyFavoriteFeatures: 'No hay características favoritas todavía. ¡Comienza a buscar para agregar favoritos!',
  },
  ChangePassword: {
    success: 'Su contraseña se ha actualizado correctamente',
  },
  Profile: {
    changePhoto: 'Cambiar foto de perfil',
    changeLogo: 'Cambiar logotipo',
    changeBanner: 'Cambiar imagen de la página de inicio',
    updateSuccess: 'El perfil de usuario se ha actualizado correctamente',
    updateError: 'Error al actualizar el perfil de usuario',
  },
  Generic: {
    ok: 'bien',
    cancel: 'Cancelar',
  },
  Property: {
    seeIn3D: 'Haga clic aquí para ver en 3D',
    unitsAvailable: 'Unidades Disponibles',
    units: 'Unidades',
    floors: 'Pisos',
    built: 'Construido',
    about: 'Acerca de',
    details: 'Detalles',
    neighborhood: 'Barrio',
    articlesPress: 'Artículos y prensa',
    salesMarketing: 'Ventas y Marketing',
    developer: 'Desarrollador',
    landscapeDesign: 'Diseño de exteriores',
    architect: 'Arquitecto(a)',
    interior: 'Interior',
    all: 'Todas',
    amenities: 'Comodidades',
    exterior: 'Exterior',
    concierge: 'Conserje',
    fitnessCenter: 'Gimnasio',
    kitchen: 'Cocina',
    greatRoom: 'Buena habitación',
    downtown: 'Céntrico',
    imaginationSpace: 'Espacio de imaginación',
    rooftopTerrace: 'Terraza en la azotea',
    saltwaterPool: 'Piscina de agua salada',
    viewFullGallery: 'Ver galería completa',
    buildingAmenities: 'Servicios de construcción',
    floorPlans: 'Planos de planta',
    similarBuildings: 'Edificios similares en',
    foodAndDrink: 'Comida y bebida',
    culture: 'Cultura',
    fitness: 'Aptitud',
    name: 'Nombre',
    beds: 'Dormitorios',
    bedroom: 'Dormitorio',
    bedrooms: 'Dormitorios',
    bath: 'Bañera',
    baths: 'Bañeras',
    view: 'Ver',
    nOfBedrooms: '# de dormitorios',
    sqft: 'pies cuadrados',
    emptySimilarBuildings: 'No hay edificios similares al que estás buscando.',
    emptyOtherRecommendedUnits: 'No hay otras unidades recomendadas para la que está buscando.',
    emptyOtherUnits: 'No hay otras unidades a la que está buscando.',
    price: 'Precio',
    unitInfo: 'Información de la unidad',
    availableUnits: 'Unidades Disponibles',
    emptyFloorPlans: 'No hay planos de planta dentro de esta propiedad con este filtro.',
    emptyAvailableUnits: 'No hay unidades disponibles dentro de esta propiedad con este filtro.',
    floorPlan: 'Planta baja',
    residence: 'Residencia',
  },
  Contact: {
    header: 'Contacta con nosotros',
    title: '¿Tengo una pregunta?',
    subtitle: 'Nos encantaría saber de usted. Envíenos un mensaje y le responderemos lo antes posible.',
    email: 'Correo electrónico',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    phoneNumber: 'Teléfono',
    brokerage: 'Corretaje',
    mlsId: 'ID MLS del agente',
    message: 'Mensaje',
    success: 'Mensaje enviado con éxito',
    failure: 'Se produjo un error al enviar el mensaje. Vuelve a intentarlo más tarde.',
    required: 'Obligatorio',
  },
  TermsOfServices: {
    title: 'Términos y condiciones',
    sections: [
      {
        title: 'Su aceptación de los términos',
        subsections: [
          `Los siguientes Términos de uso ("TOU") son entre usted y Evolution Ventures, LLC ("Evolution") y constituyen un acuerdo legal que rige su uso del producto, software, servicios y sitios web de VYSTA (en conjunto, el "Servicio" ) Debe aceptar estos TOU antes de poder usar el Servicio. Usted acepta estos TOU al usar el Servicio. Si no está de acuerdo con alguno de los siguientes términos, no use el Servicio. Debe imprimir o guardar una copia de estos TOU para sus registros.`,
          `Control completo de Evolution de los servicios. Evolution se reserva el derecho de hacer cualquiera de los siguientes, en cualquier momento, sin previo aviso: (1) para modificar, suspender o finalizar la operación o el acceso al Servicio, o cualquier parte del Servicio, por cualquier motivo; (2) para modificar o cambiar el Servicio, o cualquier parte del Servicio, y las políticas o términos aplicables; y (3) para interrumpir la operación del Servicio, o cualquier parte del Servicio, según sea necesario para realizar mantenimiento de rutina o no, corrección de errores u otros cambios.`,
          `Actualizaciones Evolution puede actualizar o cambiar estos TOU de vez en cuando y recomienda que revise los TOU periódicamente. Puede revisar la versión más reciente de los TOU en cualquier momento en <a href="/terms-of-service">Términos y condiciones</a>. Si Evolution realiza un cambio en el TOU, publicará el TOU revisado en nuestro sitio web en el enlace mencionado anteriormente. Usted comprende y acepta que su uso continuo del Servicio después de que los TOU hayan cambiado constituye su aceptación de los TOU en su versión revisada.`,
        ],
      },
      {
        title: 'El servicio',
        subsections: [
          `Descripción general del servicio. En general, el Servicio le permite buscar y explorar información relacionada con bienes inmuebles, como información de construcción, unidades residenciales disponibles y otra información dentro de un entorno renderizado tridimensional de un área geográfica. El servicio le permite acceder a la información proporcionada por los desarrolladores o sus representantes y, en ocasiones, por los proveedores de datos MLS / IDX. El Servicio proporciona información solo sobre un subconjunto de edificios y unidades disponibles dentro de un área geográfica particular. <span class="red-text">Evolution no proporciona bienes inmuebles, corretaje, tasación u otros servicios relacionados con bienes inmuebles o bienes inmuebles, y no se considera registrado, licenciado o autorizado para realizar dichos servicios. Evolution no brinda ningún asesoramiento sobre bienes inmuebles, bienes inmuebles, transacciones inmobiliarias, corretaje, tasaciones o cualquier otro asunto relacionado con bienes inmuebles o bienes inmuebles. Evolution no respalda, recomienda ni aprueba a ningún agente de bienes raíces o proveedor de servicios relacionados con bienes inmuebles que utilice el Servicio. Debe usar su propio juicio con respecto a los méritos de cualquier individuo, entidad o información que encuentre en oa través del Servicio.`,
          `Requisitos del sistema. El uso del Servicio requiere el uso de sistemas de representación tridimensional intensivos en recursos, cierto software y acceso a Internet para los cuales se pueden aplicar tarifas de proveedor de servicios por separado. Algunas partes del Servicio requieren <b> Google Chrome versión___, Microsoft Explorer versión__, Mozilla Firefox versión __o Safari versión _____ </b> o posterior, disponible por separado. El uso del Servicio puede verse afectado por ciertas combinaciones de hardware, software y / o acceso a Internet. El acceso a Internet de alta velocidad es fuertemente recomendado.`,
          `Limitaciones de uso. Usted acepta usar el Servicio solo para los fines permitidos por estos TOU y cualquier ley, regulación o práctica generalmente aceptada en la jurisdicción aplicable.`,
          `Disponibilidad del servicio. El Servicio, o cualquier característica o parte del mismo, puede no estar disponible en todos los países y Evolution no garantiza que el Servicio, o cualquier característica o parte del mismo, sea apropiado o esté disponible para su uso en cualquier ubicación en particular. En la medida en que elija acceder y utilizar el Servicio, lo hace bajo su propia iniciativa y es responsable del cumplimiento con cualquier ley aplicable, incluidas, entre otras, las leyes locales aplicables. `,
        ],
      },
      {
        title: 'Su uso del servicio y el software',
        subsections: [
          `Cuenta de usuario. Debe registrarse en un Portal de agentes de VYSTA para usar el Servicio. El registro requiere que seleccione una ID de suscriptor única (que puede ser su dirección de correo electrónico) y proporcione su nombre, una dirección de correo electrónico válida y una contraseña.`,
          `Seguridad de cuenta. Al crear su ID de suscriptor, deberá seleccionar una contraseña. Elija una contraseña segura y no revele su contraseña a otros. Usted acepta que es responsable de mantener su contraseña confidencial y segura, y además comprende que es el único responsable de cualquier actividad que ocurra bajo su ID de suscriptor.`,
          `Sin transporte. Nada en estos TOU se interpretará para transmitirle ningún interés, título o licencia en una ID de suscriptor, nombre de dominio o recurso similar utilizado por usted en relación con el Servicio.`,
          `Uso de servicios basados ​​en la ubicación. Evolution puede proporcionar ciertas funciones o servicios a través del Servicio que dependen de la información de ubicación basada en el dispositivo. Para proporcionar tales funciones o servicios, donde estén disponibles, Evolution puede recopilar, usar, transmitir, procesar y mantener sus datos de ubicación, incluida la ubicación geográfica en tiempo real de su dispositivo, y usted por la presente acepta y da su consentimiento a Evolution y sus socios y licenciantes. 'recopilación, uso, transmisión, procesamiento y mantenimiento de dichos datos de ubicación para proporcionar dichos servicios. Además, al habilitar o usar cualquier servicio o característica basada en la ubicación dentro del Servicio, usted acepta y consiente que Evolution recopile, use, procese y mantenga información relacionada con su cuenta y cualquier dispositivo registrado en el mismo, con el fin de proporcionarle dicho servicio o función basada en la ubicación. Dicha información puede incluir, entre otros, su ID de suscriptor, ID y nombre del dispositivo, tipo de dispositivo y ubicación geográfica en tiempo real de su dispositivo al momento de su solicitud. Puede retirar este consentimiento en cualquier momento al no usar las funciones basadas en la ubicación o al desactivar la configuración de las funciones basadas en la ubicación (según corresponda) en su dispositivo. Al usar servicios de terceros que usan o proporcionan datos de ubicación como parte del Servicio, usted está sujeto y debe revisar los términos y la política de privacidad de dichos terceros sobre el uso de los datos de ubicación por dichos servicios de terceros. Ni Evolution ni ninguno de sus proveedores de contenido garantiza la disponibilidad, precisión, integridad, confiabilidad u oportunidad de los datos de ubicación o cualquier otro dato mostrado por el Servicio.`,
          `Exhibición pública: no puede utilizar este Servicio como una exhibición pública o una exhibición permanente como la que se establecería en una oficina, oficina de ventas o lugar similar sin el previo consentimiento por escrito de Evolution. Comuníquese con Evolution en <a href="mailto:info@evolutionv.com">info@evolutionv.com</a> o 561-400-4370 para obtener más información sobre los paquetes de exhibición pública disponibles para la compra.`,
          `Anuncios de clientes. Evolution se reserva el derecho de publicar nuevos registros de clientes y comentarios positivos en nuestro sitio web y en otros foros públicos, como periódicos, sitios web de noticias, folletos, medios impresos y electrónicos comprados, blogs y otros medios de comunicación. Si no desea que Evolution cite sus experiencias positivas o anuncie su uso del Servicio, debe notificar a Evolution con anticipación. Deberá indemnizar y eximir de responsabilidad a Evolution y a sus proveedores externos contra todos y cada uno de los reclamos, pérdidas, daños, responsabilidades, costos y gastos (incluidos los honorarios razonables de abogados) que surjan de estos anuncios o estén relacionados con ellos.`,
        ],
      },
      {
        title: 'Política de privacidad de Evolution',
        subsections: [
          `Usted comprende que al usar el Servicio, acepta y acepta la recopilación y el uso de cierta información sobre usted y su uso del Servicio de acuerdo con la Política de privacidad de Evolution, que puede modificarse ocasionalmente. Además, acepta y acepta que Evolution puede recopilar, usar, transmitir, procesar y mantener información relacionada con su cuenta y cualquier dispositivo registrado en el mismo, con el fin de proporcionarle el Servicio y cualquier función que contenga. La información recopilada por Evolution cuando utiliza el Servicio también puede incluir información técnica o de diagnóstico relacionada con su uso que puede ser utilizada por Evolution para mantener, mejorar y mejorar el Servicio. Además, comprende y acepta que esta información puede ser transferida a los Estados Unidos y / u otros países para su almacenamiento, procesamiento y uso por parte de Evolution y / o sus filiales.`,
          `Al usar el Servicio, usted reconoce y acepta que el Servicio puede acceder, preservar y divulgar la información de su cuenta y cualquier contenido asociado con esa cuenta si así lo exige la ley o de buena fe, cree que dicha preservación o divulgación de acceso es razonablemente necesario para: (a) satisfacer cualquier ley, regulación, proceso legal o solicitud gubernamental aplicable, (b) hacer cumplir las TOU, incluida la investigación de posibles violaciones del presente, (c) detectar, prevenir o abordar de otro modo el fraude, la seguridad o los problemas técnicos (incluido, entre otros, el filtrado de spam), o (d) proteger contra daños inminentes a los derechos, la propiedad o la seguridad del Servicio, sus usuarios o el público, según lo exija o permita la ley.`,
        ],
      },
      {
        title: 'Contenido y tu conducta',
        subsections: [
          `Contenido. "Contenido" significa cualquier información que se pueda generar o encontrar a través del uso del Servicio, como archivos de datos, texto escrito, software, gráficos, fotografías, imágenes, sonidos, videos, mensajes y otros materiales similares, interfaces de usuario, interfaces visuales, marcas comerciales, logotipos, música, obras de arte, modelos de construcción y su segmentación en unidades y código de computadora, incluidos, entre otros, el diseño, la estructura, la selección, la coordinación, la expresión, la "apariencia" y la disposición de los mismos. Todo el Contenido asociado con el Servicio es propiedad, está controlado o licenciado por Evolution, y está protegido por las leyes de imagen comercial, derechos de autor, patentes y marcas registradas, y otros derechos de propiedad intelectual y leyes de competencia desleal, así como estos TOU.`,
          `Limitaciones en el uso del contenido. Salvo lo dispuesto expresamente en los TOU, ninguna parte del Contenido puede copiarse, reproducirse, republicarse, cargarse, publicarse, mostrarse públicamente, codificarse, traducirse, transmitirse o distribuirse de ninguna manera (incluida la "duplicación") a ninguna otra computadora o servidor , sitio web u otro medio para publicación o distribución o para cualquier empresa comercial, sin el previo consentimiento por escrito de Evolution. Puede utilizar la información disponible solo para su propósito informativo personal y no comercial. No puede copiar ni publicar dicha información en ninguna computadora en red ni difundirla en ningún medio, y no realizar modificaciones a dicha información ni eliminar ninguna notificación relacionada con los derechos asociados con dicha información.`,
          `No puede utilizar ningún "enlace profundo", "raspado de página", "robot", "araña" u otro dispositivo automático, programa, algoritmo o metodología, o cualquier proceso manual similar o equivalente, para acceder, adquirir, copiar o monitorear cualquier parte del Servicio, o de cualquier manera reproducir o eludir la estructura de navegación o la presentación del Servicio o cualquier Contenido, para obtener o intentar obtener cualquier información a través de cualquier medio que no esté disponible a propósito a través del Servicio. Evolution se reserva el derecho de prohibir dicha actividad.`,
          `No puede intentar obtener acceso no autorizado a ninguna parte o característica del Servicio, mediante piratería informática, "minería" de contraseñas o cualquier otro medio ilegítimo.`,
          `No puede sondear, escanear o probar la vulnerabilidad del Servicio o de cualquier red asociada con el Servicio, ni violar las medidas de seguridad o autenticación en el Servicio o en cualquier red asociada con el Servicio. No puede revertir la búsqueda, rastrear o buscar rastrear
                  información sobre cualquier otro usuario del Servicio, o cualquier otro cliente de Evolution, incluida cualquier cuenta de Evolution que no sea de su propiedad, a su fuente, o explotar información puesta a disposición u ofrecida por oa través del Servicio, de cualquier manera donde el propósito sea revelar cualquier información, incluida, entre otras, identificación o información personal, que no sea su propia información, según lo dispuesto por el Servicio.`,
          `Usted acepta que no tomará ninguna medida que imponga una carga irrazonable o desproporcionadamente grande en la infraestructura asociada con el Servicio o los sistemas o redes de Evolution.`,
          `Usted acepta no usar ningún dispositivo, software o rutina para interferir o intentar interferir con el funcionamiento adecuado del Servicio o cualquier transacción que se realice en el Servicio, o con el uso del Servicio de cualquier otra persona.`,
          `No puede usar el Servicio ni ningún Contenido para ningún propósito que sea ilegal o prohibido por estos Términos de uso, o para solicitar la realización de cualquier actividad ilegal u otra actividad que infrinja los derechos de Evolution u otros.`,
          `Este Servicio puede incluir contenido del Servicio de Listado Múltiple (MLS) e Intercambio de Datos de Internet (IDX), desarrolladores y sus representantes y contenido generado por usuarios individuales en listados que incluyen imágenes, texto, video, imágenes de 360 ° y otros medios. Usted comprende que al usar el Servicio puede encontrar Contenido que puede encontrar ofensivo, indecente u objetable, y que puede exponer a otros a Contenido que ellos puedan encontrar objetable. Evolution no controla el Contenido publicado a través del Servicio, ni garantiza la precisión, integridad o calidad de dicho Contenido. Usted comprende y acepta que su uso del Servicio y de cualquier Contenido es bajo su propio riesgo.`,
          `Tu conducta Usted acepta que NO utilizará el Servicio para:

                    a. publicar, enviar, transmitir o poner a disposición de cualquier otro modo mensajes de correo electrónico no solicitados o no autorizados, publicidad, materiales promocionales, correo basura, correo no deseado o cartas en cadena, incluidos, entre otros, publicidad comercial masiva y anuncios informativos;
                
                    b. cargar, descargar, publicar, enviar por correo electrónico, transmitir, almacenar o poner a disposición cualquier contenido que sea ilegal, acosador, amenazante, dañino, tortuoso, difamatorio, calumnioso, abusivo, violento, obsceno, vulgar, invasivo de la privacidad de otros, odioso, racial o étnicamente ofensivo, o de otra manera objetable;
                
                    c. pretender ser cualquier persona, o cualquier entidad, usted no lo es; no puede hacerse pasar por otra persona o hacerse pasar por otra persona (Evolution se reserva el derecho de rechazar o bloquear cualquier ID de suscriptor que pueda considerarse una suplantación o tergiversación de su identidad, o una apropiación indebida del nombre o identidad de otra persona);
                    d. participar en cualquier infracción de derechos de autor u otra infracción de propiedad intelectual, o divulgar cualquier secreto comercial o información confidencial en violación de un acuerdo de confidencialidad, empleo o no divulgación;
                
                    e. falsificar cualquier encabezado de paquete TCP-IP o cualquier parte de la información del encabezado en un correo electrónico o publicación de un grupo de noticias, o poner información en un encabezado diseñado para engañar a los destinatarios en cuanto al origen de cualquier Contenido transmitido a través del Servicio ("suplantación de identidad") ;
                
                    f. cargar, publicar, enviar por correo electrónico, transmitir, almacenar o poner a disposición cualquier material que contenga virus o cualquier otro código de computadora, archivos o programas diseñados para dañar, interferir o limitar el funcionamiento normal del Servicio (o cualquier parte del mismo), o cualquier otro software o hardware informático;
                
                    g. interferir o interrumpir el Servicio (incluido el acceso al Servicio a través de cualquier medio automatizado, como scripts o rastreadores web), o cualquier servidor o red conectado al Servicio, o cualquier política, requisito o reglamento de las redes conectadas al Servicio (incluido cualquier no autorizado acceso a, uso o monitoreo de datos o tráfico al respecto);
                
                    h. planificar o participar en cualquier actividad ilegal; o
                
                    i. recopilar y almacenar información personal sobre cualquier otro usuario del Servicio para ser utilizado en relación con cualquiera de las actividades prohibidas anteriores.`,
          `Eliminación de contenido. Usted reconoce que Evolution no es responsable de ninguna manera por el Contenido proporcionado por otros y no tiene la obligación de evaluar previamente dicho Contenido. Sin embargo, Evolution se reserva el derecho en todo momento de determinar si el Contenido es apropiado y cumple con estos TOU, y puede realizar una selección previa, mover, rechazar, modificar o eliminar Contenido en cualquier momento, sin previo aviso y a su exclusivo criterio, si se determina que dicho Contenido infringe estos TOU o es de otra manera objetable.`,
          `Copia de seguridad de su contenido. Usted es responsable de realizar copias de seguridad, en su propia computadora u otro dispositivo, de cualquier documento, imagen u otro Contenido importante que almacene o acceda a través del Servicio. Evolution no garantiza ni garantiza que ningún Contenido que pueda almacenar o acceder a través del Servicio no estará sujeto a daños involuntarios, corrupción o pérdida.`,
          `Acceso a su cuenta y contenido. Usted reconoce y acepta que Evolution puede acceder, usar, preservar o divulgar la información y el Contenido de su cuenta si así lo exige legalmente o si creemos de buena fe que dicho acceso, uso, divulgación o preservación es razonablemente necesario para: (a ) cumplir con el proceso legal o solicitud; (b) hacer cumplir estos TOU, incluida la investigación de cualquier posible violación de los mismos; (c) detectar, prevenir o abordar problemas de seguridad, fraude o técnicos; o (d) proteger los derechos, la propiedad o la seguridad de Evolution, sus usuarios o el público, según lo exija o permita la ley.`,
        ],
      },
      {
        title: 'Materiales de terceros',
        subsections: [
          `Servicio de listado múltiple. Los aspectos del Servicio pueden contener información proporcionada total o parcialmente por Servicios de Listado Múltiple de terceros ("MLS"). Su uso de dicha información es
                         sujeto a los términos y condiciones establecidos por cada proveedor MLS individual, incluidos los requisitos de membresía MLS. Evolution no es responsable de la precisión, oportunidad, integridad, funcionalidad, usabilidad o disponibilidad de la información proporcionada por cualquier proveedor de datos MLS.`,
          `Nuevo contenido de listado de desarrollo. El contenido de la lista de nuevos desarrollos en este sitio web es proporcionado por profesionales inmobiliarios o desarrolladores y / o sus representantes. Los precios, detalles y disponibilidad pueden cambiar; verifique cualquier información con un profesional inmobiliario o el desarrollador. Evolution no es responsable de ningún error u omisión. Esta no es una oferta de venta.`,
          `Información de impuestos públicos. Los aspectos del Servicio pueden contener información recopilada en su totalidad o en parte de recursos de tasación fiscal disponibles públicamente. Evolution no es responsable de la precisión, puntualidad, integridad o disponibilidad de dicha información.`,
        ],
      },
      {
        title: 'Descargo de responsabilidad de garantías',
        subsections: [
          `ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS, COMO TAL, EN LA MEDIDA QUE ESTAS EXCLUSIONES ESTÁN PROHIBIDAS ESPECÍFICAMENTE POR LEY APLICABLE, ALGUNAS DE LAS EXCLUSIONES ESTABLECIDAS A CONTINUACIÓN PUEDEN NO APLICARSE A USTED.`,
          `USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE SU USO DEL SERVICIO ES BAJO SU PROPIO RIESGO Y EL SERVICIO SE PROPORCIONA "TAL CUAL" Y "SEGÚN DISPONIBILIDAD". EVOLUCIÓN Y SUS AFILIADAS, SUBSIDIARIAS, OFICIALES, DIRECTORES, EMPLEADOS, AGENTES, SOCIOS Y LICENCIANTES EXPRESAMENTE RENUNCIAN TODAS LAS GARANTÍAS DE CUALQUIER TIPO, INCLUSO EXPRESAS O IMPLÍCITAS, INCLUYENDO PERO SIN LIMITARSE A LA GARANTÍA IMPLÍCITA Y A LA GARANTÍA DE ATERRIZATIVA DE ATERRIZATIVA DE FERENCIAS. -INFRACCIÓN. EN PARTICULAR, LA EVOLUCIÓN Y SUS AFILIADAS, SUBSIDIARIAS, OFICIALES, DIRECTORES, EMPLEADOS, AGENTES, SOCIOS Y LICENCIANTES NO GARANTIZAN QUE (I) EL SERVICIO CUMPLIRÁ CON SUS REQUISITOS; (II) SU USO DEL SERVICIO SERÁ PUNTUAL, ININTERRUMPIDO, SEGURO O LIBRE DE ERRORES; (III) CUALQUIER INFORMACIÓN OBTENIDA POR USTED COMO RESULTADO DEL SERVICIO SERÁ EXACTA O CONFIABLE; Y (IV) CUALQUIER DEFECTO O ERROR EN EL SOFTWARE PROPORCIONADO A USTED COMO PARTE DEL SERVICIO SERÁ CORREGIDO.`,
          `CUALQUIER MATERIAL DESCARGADO O DE OTRA MANERA OBTENIDO MEDIANTE EL USO DEL SERVICIO SE ACCEDE A SU PROPIA DISCRECIÓN Y RIESGO, Y SERÁ RESPONSABLE POR CUALQUIER DAÑO A SU DISPOSITIVO O PÉRDIDA DE
                     DATOS QUE RESULTAN DE LA DESCARGA DE CUALQUIER MATERIAL. NINGÚN ASESORAMIENTO O INFORMACIÓN, TANTO ORAL O ESCRITO, OBTENIDO POR USTED DE LA EVOLUCIÓN O MEDIANTE EL SERVICIO, CREARÁ NINGUNA GARANTÍA NO EXPRESADA EXPRESAMENTE EN EL TOU.`,
          `LOS TRES ENTORNOS, TERRENOS Y EDIFICIOS DIMENSIONALES DEPENDIDOS DENTRO DEL SERVICIO SON REPRESENTACIONES ARTÍSTICAS Y PUEDEN DIFERENCIAR DEL ENTORNO, TERRENO Y EDIFICIOS REAL. LA UBICACIÓN DE LAS UNIDADES, SERVICIOS O ACCESORIOS DENTRO DEL EDIFICIO ES APROXIMADA Y LOS EDIFICIOS, MARCAS Y UNIDADES PUEDEN FALTARSE OTROS DEL SERVICIO O PUEDEN CAMBIARSE DENTRO DEL SERVICIO SIN AVISO O AVISO. NO SE DEBEN TOMAR DECISIONES DE COMPRA EN BASE A LA INFORMACIÓN DE ESTE SERVICIO.`,
          `EVOLUTION NO REPRESENTA NI GARANTIZA QUE ALGUIEN QUE ACTÚE COMO REALTOR® O PROFESIONAL INMOBILIARIO CON LICENCIA REALMENTE TENGA ESTAS CALIFICACIONES.`,
        ],
      },
      {
        title: 'Limitación de responsabilidad',
        subsections: [
          `ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS INCIDENTALES O CONSECUENTES, COMO TAL, EN DONDE TALES EXCLUSIONES O LIMITACIONES ESTÁN PROHIBIDAS ESPECÍFICAMENTE POR LA LEY APLICABLE, ALGUNAS DE LAS EXCLUSIONES O LIMITACIONES ESTABLECIDAS A CONTINUACIÓN PUEDEN APLICARSE A USTED. USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE LA EVOLUCIÓN Y SUS AFILIADAS, SUBSIDIARIAS, OFICIALES, DIRECTORES, EMPLEADOS, AGENTES, SOCIOS Y LICENCIANTES NO SERÁN RESPONSABLES CON USTED POR CUALQUIER MANERA DIRECTA, INDIRECTA, INCIDENTAL, ESPECIAL, CONSECUENTE O EJEMPLAR, INCLUYENDO UN DAÑO INCLUSIVO, QUE NO INCLUYA, DEBEN SER INCLUIDO A, DAÑOS POR PÉRDIDA DE GANANCIAS, BUENA VOLUNTAD, USO, DATOS, COSTO DE LA ADQUISICIÓN DE BIENES O SERVICIOS SUSTITUTOS, U OTRA PÉRDIDA INTANGIBLE (INCLUSO SI LA EVOLUCIÓN SE HA INFORMADO DE LA POSIBILIDAD DE TALES DAÑOS), RESULTADO DE: (I) EL USO O LA INCAPACIDAD DE UTILIZAR EL SERVICIO (II) CUALQUIER CAMBIO HECHO AL SERVICIO O CUALQUIER CESACIÓN TEMPORAL O PERMANENTE DEL SERVICIO O CUALQUIER PARTE DEL MISMO; (III) EL ACCESO NO AUTORIZADO O LA ALTERACIÓN DE SUS TRANSMISIONES O DATOS; (IV) LA ELIMINACIÓN, CORRUPCIÓN O FALTA DE ALMACENAMIENTO Y / O ENVIAR O RECIBIR SUS TRANSMISIONES O DATOS EN EL SERVICIO O A TRAVÉS DE ELLO; (V) DECLARACIONES O CONDUCTA DE CUALQUIER TERCERO
                        FIESTA EN EL SERVICIO; Y (VI) CUALQUIER OTRO ASUNTO RELACIONADO CON EL SERVICIO.`,
        ],
      },
      {
        title: 'Indemnidad',
        subsections: [
          `Usted acepta defender, indemnizar y eximir a Evolution, sus filiales, subsidiarias, directores, funcionarios, empleados, agentes, socios y licenciantes de cualquier reclamo o demanda, incluidos los honorarios razonables de abogados, realizados por un tercero, relacionados o derivados de : (a) cualquier Contenido que envíe, publique, transmita o ponga a disposición a través del Servicio; (b) su uso del Servicio; (c) cualquier violación por su parte de estos TOU; o (d) su violación de cualquier derecho de otro. Esta obligación sobrevivirá a la terminación o vencimiento de estos TOU o su uso del Servicio. Usted reconoce que es responsable de todo el uso del Servicio que utiliza su cuenta, incluido cualquier uso por Subcuentas, y que estos TOU se aplican a todos los usos de su cuenta, incluido cualquier uso por Subcuentas. Usted acepta cumplir con estos TOU y defender, indemnizar y mantener indemne a Evolution de y contra todos y cada uno de los reclamos y demandas que surjan del uso de su cuenta o subcuenta, ya sea que usted lo autorice expresamente o no.`,
        ],
      },
      {
        title: 'Avisos',
        subsections: [
          `Evolution puede proporcionarle avisos sobre el Servicio, incluidos los cambios a estos TOU, por correo electrónico, por correo ordinario o mediante publicaciones en nuestro sitio web o el Servicio.`,
        ],
      },
      {
        title: 'Ley que rige',
        subsections: [
          `Excepto en la medida expresamente prevista en el siguiente párrafo, estos TOU y la relación entre usted y Evolution se regirán por las leyes del Estado de Florida y sus disposiciones sobre conflictos de leyes. Usted y Evolution acuerdan someterse a la jurisdicción personal y exclusiva de los tribunales ubicados dentro del condado de Miami-Dade, Florida, para resolver cualquier disputa o reclamo que surja de estos TOU.`,
        ],
      },
      {
        title: 'General',
        subsections: [
          `Estos TOU constituyen el acuerdo completo entre usted y Evolution, rigen su uso del Servicio y reemplazan completamente cualquier acuerdo anterior entre usted y Evolution en relación con el Servicio. Sin perjuicio de lo anterior, en caso de ambigüedad o inconsistencia entre los TOU y los términos de cualquier acuerdo de términos de servicio ("TOS") entre usted y Evolution, regirán los términos de dichos TOS. También puede estar sujeto a términos y condiciones adicionales que pueden aplicarse cuando utiliza servicios de afiliados, contenido de terceros o software de terceros. Si alguna parte de estos TOU se considera inválida o inaplicable, esa parte será
                     interpretado de manera consistente con la ley aplicable para reflejar, lo más cerca posible, las intenciones originales de las partes, y las porciones restantes permanecerán en pleno vigor y efecto. El hecho de que Evolution no ejerza o haga cumplir algún derecho o disposición de estos TOU no constituirá una renuncia a dicho derecho o disposición.`,
        ],
      },
    ],
  },
  PrivacyPolicy: {
    title: 'Política de privacidad',
    sections: [
      {
        title: '',
        subsections: [
          `<em>Efectiva: 1 de junio de 2020</em>`,
          `El sitio web de VYSTA getvysta.com y sus subdominios (colectivamente, el "Sitio web") es publicado y mantenido por Evolution Ventures, LLC y sus subsidiarias y afiliadas (denominadas colectivamente en esta Política de Privacidad como "la Compañía", "nosotros", "Nosotros", "nuestro" u otros pronombres similares).`,
          `Respetamos la privacidad de cada usuario del sitio web (denominado "usted" o "un usuario"). Esta Política de privacidad le brinda información específica sobre cómo protegemos su privacidad, cómo tratamos la información que recopilamos en el sitio web que identifica a un usuario individual y cómo utilizamos la información agregada.`,
          `Esta Política de privacidad rige su uso del sitio web en computadoras y dispositivos móviles. Al visitar este sitio web, proporcionar información personal y / o utilizar los servicios ofrecidos a través de este sitio web (los "Servicios"), usted acepta los términos de esta Política de privacidad, ya que pueden modificarse ocasionalmente.`,
          `A medida que actualizamos y ampliamos nuestros servicios, esta Política de privacidad puede cambiar. Cuando realicemos cambios a esta Política de privacidad, publicaremos la nueva Política de privacidad aquí y actualizaremos la Fecha de entrada en vigencia anterior. Vuelva a esta página de vez en cuando. Esta Política de privacidad se incorpora y forma parte de los Términos y condiciones de uso del sitio web de la Compañía, que rige el uso de este sitio web en general.`,
          `Puede optar por no compartir nuestra información personal con terceros para sus fines de marketing siguiendo las instrucciones en la sección "Preferencias de marketing" a continuación.`,
        ],
      },
      {
        title: 'Propósito',
        subsections: [
          `La empresa se toma muy en serio su privacidad. Para que sus visitas a nuestro sitio web y el uso de los servicios disponibles a través de nuestro sitio web sean lo más beneficiosos posible, podemos pedirle personal
                      Información y podemos recopilar cierta información sobre usted y su interacción con este sitio web de usted y su computadora cada vez que visita nuestro sitio web. "Información personal" incluye, por ejemplo, su nombre; título; domicilio y / o domicilio comercial; dirección de correo electrónico; teléfono de casa y / o negocio, número de teléfono móvil y / o fax; información demográfica; tu ubicación; y / u otra información que pueda identificarlo como individuo o permitir el contacto en línea o fuera de línea con usted como individuo.`,
          `Esta Política de privacidad explica, en general, qué información personal y otra información se recopila en nuestro sitio web, cómo se utiliza y con quién podemos compartir dicha información. Dedique unos minutos a leer nuestra Política de privacidad para comprender cómo la Compañía trata su información.`,
        ],
      },
      {
        title: 'Preferencias de marketing',
        subsections: [
          `Tiene derecho a “optar por participar” ya “optar por no participar” de ciertos usos de su información personal y puede cambiar sus preferencias con respecto a dichos usos en cualquier momento. Por ejemplo, en el momento en que se le solicite que proporcione información personal en este sitio web, puede tener la oportunidad de elegir si desea: (1) recibir nuestra correspondencia comercial (ya sea en nuestro nombre o en nombre de un tercero) , o (2) que su información personal se comparta con otras entidades (fuera de la empresa) para fines de marketing. También puede optar por no recibir correos electrónicos promocionales de la Compañía haciendo clic en el enlace de exclusión dentro del correo electrónico que recibe, o de correos postales de la Compañía siguiendo las instrucciones en dichos correos. Tenga en cuenta que la Compañía puede tardar hasta 10 días hábiles en actualizar sus preferencias. Durante ese tiempo, puede continuar recibiendo mensajes de nosotros.`,
          `Tenga en cuenta que si opta por no recibir correspondencia promocional de nuestra parte, es posible que nos comuniquemos con usted en relación con su relación, actividades, transacciones y comunicaciones con nosotros (incluidos, por ejemplo, entre otros, alertas, favoritos e informes de condiciones del mercado, en relación con su uso del sitio web (como se define anteriormente). Usted puede controlar cómo recibe
                     correspondencia no promocional de nuestra parte actualizando las preferencias de su cuenta. Además, una solicitud para que dejemos de compartir su información personal con otras entidades con fines de marketing solo se aplicará a partir de la fecha en que recibamos su solicitud, y no seremos responsables de las comunicaciones que pueda recibir de los afiliados que recibieron su Información personal previa a dicha solicitud. En estos casos, comuníquese directamente con esos afiliados.`,
        ],
      },
      {
        title: 'Información de niños',
        subsections: [
          `Nuestro sitio web y nuestros servicios no están destinados a ser utilizados por niños, ni recopilamos ni procesamos a sabiendas ninguna información personal de usuarios menores de 16 años. Si cree que podemos haber recopilado información de cualquier persona menor de 16 años, notifíquenos en <a href="mailto:info@getvysta.com">info@getvysta.com</a> para que podamos eliminar la información.`,
        ],
      },
      {
        title: '¿Qué información se recopila en este sitio web?',
        subsections: [
          `<u>  Información proporcionada por el usuario  </u>`,
          `Recopilamos información personal que los usuarios proporcionan voluntariamente a través de nuestro sitio web, por ejemplo, a través de las actividades de dichos usuarios, las transacciones y la cumplimentación de formularios en línea en nuestro sitio web. Puede optar por no proporcionar cierta información; sin embargo, ciertas características del sitio web pueden no funcionar correctamente. Recopilamos la siguiente información:`,
          `<ul>`,
          `<li> Cuando se registra para obtener una cuenta en nuestro sitio web, recopilamos su nombre, dirección de correo electrónico y su contraseña creada por el usuario. Si elige crear una cuenta a través de Facebook, recopilamos su nombre de usuario de Facebook. También podemos recopilar otra información de la cuenta, como los criterios de búsqueda de su propiedad o las propiedades que vio o guardó. </li>`,
          `<li> If you contact us using our online contact form, we collect your name, email address, telephone number, and the content of your message.</li>`,
          `<li> Recopilamos información sobre su cuenta de redes sociales cuando comparte nuestro sitio web o comenta en nuestro blog a través de las redes sociales. Puede enviar información sobre nuestros Servicios a sus amigos y familiares a través de nuestro sitio web haciendo clic en el botón "Compartir" o similar en nuestro sitio web o en un correo electrónico que le hayamos enviado. En algunos de estos
             casos (a menos que simplemente reenvíe nuestro correo electrónico por su cuenta), podemos recopilar la dirección de correo electrónico de su amigo o familiar. </li>`,
          `</ul>`,
          `<u>Cookies</u>`,
          `Si ha permitido que su computadora acepte "cookies", podemos usar "cookies" para mantener y, a veces, rastrear información sobre usted. Las cookies son pequeños archivos de datos que se envían a su navegador o software y aplicaciones relacionadas, desde un servidor web y se almacenan en su dispositivo móvil o en el disco duro de la computadora. Las cookies rastrean dónde viaja en nuestro sitio web y lo que mira, realiza transacciones y sus favoritos. Pueden almacenar la información en su cuenta y / o su nombre de usuario y / o contraseña, y podemos usarlos para enviarle mensajes específicos (por ejemplo, para anunciar una nueva propiedad que se agregó desde la última vez que visitó el sitio web ) Una cookie puede permitirnos vincular su uso de nuestro sitio web con otra información sobre usted, incluida su información personal. Todos estos propósitos sirven para mejorar y personalizar su experiencia en nuestro sitio web.`,
          `La mayoría de los navegadores web pueden configurarse para informarle cuando se le ha enviado una cookie y brindarle la oportunidad de rechazarla. Sin embargo, rechazar una cookie puede, en algunos casos, impedirle usar o afectar negativamente la visualización o función del sitio web o ciertas áreas o características del sitio web.`,
          `<u>  Acceder a la información del dispositivo y del navegador  </u>`,
          `Incluso si no se registra con nosotros, cuando accede al sitio web desde un teléfono móvil u otro dispositivo móvil, podemos recopilar información de ese dispositivo sobre su tipo de navegador, ubicación y dirección de Protocolo de Internet (IP), así como el páginas que visita en nuestro sitio web y otros sitios web que visita antes y después de interactuar con nuestro sitio web. En un dispositivo móvil, podemos utilizar identificadores generados por el dispositivo cliente, identificadores estadísticos y su inicio de sesión único.`,
          `Podemos agregar sus datos anónimos de uso del sitio web con los datos de otros usuarios para determinar tendencias y mejorar nuestro sitio web.`,
        ],
      },
      {
        title: 'Cómo usamos su información',
        subsections: [
          `<ul>`,
          `<li><u>Brindarle servicios </u> . Usamos su información para proporcionar los Servicios específicamente solicitados por usted, crear y administrar su cuenta de Usuario, mostrar las propiedades que guardó, proporcionar la información de propiedad que solicitó, proporcionar nuestras herramientas de valoración de viviendas y calculadoras de hipotecas, permitirle compartir información de propiedades y proporcionar otros servicios ofrecidos a través de nuestro sitio web. </li>`,
          `<li><u>Mejorar nuestro sitio web </u> . Utilizamos su información personal y anónima para diagnosticar problemas técnicos, auditar el uso, personalizar y mejorar nuestro sitio web. </li>`,
          `<li><u>Comunicarse contigo </u> . Usamos su información personal para enviarle información sobre nuestro sitio web y servicios, notificarle cualquier actualización o sitio web o servicios, enviarle boletines informativos y responder a cualquier comunicación que nos envíe. </li>`,
          `<li><u>Marketing</u>. Podemos utilizar su información para enviarle anuncios sobre nuevos productos o servicios que ofrecemos o nuevas características de nuestros sitios web o servicios. Es posible que pueda optar por no recibir nuestras comunicaciones de marketing. Consulte la sección "Preferencias de marketing" de esta Política de privacidad más arriba. </li>`,
          `<li><u>Publicidad dirigida </u> . Podemos usar su información para proporcionar anuncios de productos o servicios de terceros que creemos que podrían ser de su interés en función de su historial de navegación. Ver la <u>publicidad dirigida; Sección de seguimiento</u> de esta Política de privacidad para obtener más información. </li>`,
          `<li> Podemos usar su información para analizar la seguridad de nuestro sitio web y ayudar a detectar y resolver un incidente de seguridad. </li>`,
          `<li> Utilizamos su información para auditar el cumplimiento o para hacer cumplir nuestros <a href="/terms-of-service">Términos de uso</a>, otra política de la Compañía o la ley aplicable. </li>`,
          `</ul>`,
        ],
      },
      {
        title: 'Divulgación de su información personal a terceros',
        subsections: [
          `Divulgamos su información personal a terceros, incluidos nuestros afiliados y terceros no afiliados, para fines comerciales legítimos. Podemos divulgar su información personal: `,
          `<ul>`,
          `<li> a organizaciones que brindan servicios en nuestro nombre, como enviarle correos electrónicos, analizar datos, proporcionar marketing, proporcionar resultados de búsqueda y enlaces, mostrar contenido en nuestro sitio web, realizar un seguimiento del análisis del sitio web y proporcionar servicio al cliente. Estas organizaciones solo reciben la información personal necesaria para llevar a cabo el servicio previsto. </li> `,
          `<li> a las autoridades legales cuando sea necesario para cumplir con una orden judicial o citación. </li>`,
          `<li> para proteger nuestro sitio web y nuestros derechos según nuestros Términos de uso, o para detectar actividades fraudulentas o no autorizadas en nuestro sitio web. </li>`,
          `<li> en relación con una venta, fusión, adquisición o reorganización de nuestra Compañía. </li>`,
          `</ul>`,
        ],
      },
      {
        title: 'Sitios web de terceros',
        subsections: [
          `Cuando se encuentra en nuestro sitio web, puede tener la oportunidad de visitar o vincular otros sitios no operados por la Compañía, incluidos otros sitios web operados por afiliados de la Compañía o por terceros no afiliados. Estos sitios pueden recopilar información personal sobre usted. La Compañía no controla los sitios que son operados por estas entidades y no es responsable de las prácticas de información de estos sitios. Esta Política de privacidad no aborda las prácticas de información de esos otros sitios web. Le recomendamos que lea las Políticas de privacidad de los sitios web de terceros que visite. Las preguntas sobre las prácticas de recopilación de información de un sitio web de terceros deben dirigirse al administrador del sitio web de terceros.`,
        ],
      },
      {
        title: 'Publicidad dirigida; seguimiento online',
        subsections: [
          `Podemos, o podemos permitir que terceros muestren publicidad dirigida (también conocida como publicidad conductual) en nuestro sitio web. Mediante el uso de cookies, JavaScript, gifs transparentes, cookies flash o tecnología similar, recopilamos información sobre su actividad de navegación a lo largo del tiempo y en sitios de terceros y usamos esa información para mostrar publicidad personalizada dirigida para productos o servicios que creemos que pueden ser de interés para tú. Para proporcionarle publicidad relevante, nuestro sitio web no responde a la configuración del navegador "No rastrear".`,
        ],
      },
      {
        title: 'Nuestro compromiso de privacidad',
        subsections: [
          `<li> No vendemos su información personal a nadie por ningún motivo. Período. </li> `,
          `<li> Mantenemos su información personal en nuestros registros comerciales mientras usted es un usuario o cliente, o hasta que ya no sea necesaria para fines comerciales, fiscales o legales. Cuando su información personal ya no sea necesaria, eliminaremos o anonimizaremos su información si la ley lo permite. </li> `,
          `<li> Mantendremos su información segura utilizando salvaguardas físicas y técnicas estándar de la industria, como el cifrado u otros controles de seguridad apropiados. Sin embargo, debe saber que ninguna transmisión de datos a través de Internet es 100% segura. Aunque hacemos todo lo posible para proteger su información, no podemos garantizar la seguridad de la información personal que nos transmite, y lo hace bajo su propio riesgo. Le recomendamos que mantenga la contraseña de su cuenta segura y confidencial para ayudar a prevenir el uso no autorizado de su cuenta. </li> `,
        ],
      },
      {
        title: 'Usuarios internacionales',
        subsections: [
          `Nuestro sitio web se opera desde nuestras instalaciones dentro de los Estados Unidos de América (EE. UU.), Pero se puede acceder desde cualquier parte del mundo. No hacemos ninguna representación y no nos hacemos responsables de las leyes y reglamentos aplicables fuera de los EE. UU. Las leyes de seguridad de datos en los EE. UU. Pueden no ofrecer tanta protección como las leyes de su país de residencia. Al proporcionarnos cualquier información, usted acepta la transferencia de su información y el almacenamiento de su información en servidores ubicados en los EE. UU. Las personas que eligen acceder al sitio web desde ubicaciones fuera de los EE. UU. Lo hacen bajo su propio riesgo.`,
        ],
      },
      {
        title: 'Derechos de privacidad de California',
        subsections: [
          `Según la ley de California, los residentes de California tienen ciertos derechos con respecto a su información personal.`,

          `<li> Los residentes de California tienen derecho a solicitar por escrito a las empresas con las que tienen una relación comercial establecida: (1) una lista de categorías de información personal, como nombre, dirección, dirección de correo electrónico y el tipo de servicios prestados al Usuario, que una empresa ha revelado a terceros [incluidas las filiales que son entidades legales separadas] durante el año calendario inmediatamente anterior para fines de marketing directo de terceros y (2) los nombres y direcciones de todos esos terceros . Responderemos a dichas solicitudes dentro de los treinta (30) días posteriores a la recepción. Tenga en cuenta que solo estamos obligados a responder a cada usuario una vez por año calendario. </li>`,
          `<li> Los residentes de California también pueden solicitar a las empresas con las que tienen una relación comercial establecida, durante el período anterior de doce (12) meses: (1) el
                    categorías de información que una empresa ha recopilado sobre el Usuario, (2) las categorías de fuentes de las cuales se recopiló la información, (3) los fines comerciales para recopilar la información del Usuario, (4) las categorías de terceros con los que la información se ha compartido y (5) la información específica que recopiló la empresa. </li> `,
          `Responderemos a las solicitudes verificadas dentro de los cuarenta y cinco (45) días posteriores a la recepción. Tenga en cuenta que solo estamos obligados a responder a cada Usuario dos veces por período de doce (12) meses. Puede solicitar que eliminemos su información personal. Si no existe una base legal para que conservemos su información, la eliminaremos dentro de los cuarenta y cinco (45) días posteriores a la recepción de su solicitud verificable.`,
          `<li> Para realizar cualquiera de las solicitudes anteriores, contáctenos utilizando la información de contacto provista al final de esta Política de privacidad. La información se le proporcionará de forma gratuita. No lo discriminaremos por ejercer sus derechos en virtud de esta sección. </li>`,
        ],
      },
      {
        title: 'Acuerdo a la política',
        subsections: [
          `Al utilizar nuestro sitio web o al enviarnos cualquier información personal, usted acepta esta Política de privacidad, incluido su consentimiento para nuestro uso y divulgación de información sobre usted de la manera descrita en esta Política de privacidad.`,
          `Tenga en cuenta que su uso de nuestro sitio web también se rige por nuestros <a href="/terms-of-service">Términos de uso</a> y <a href="/cookies-policy">Política de cookies</a>.`,
        ],
      },
      {
        title: 'Actualiza tu informacion',
        subsections: [
          `Si desea verificar o cambiar la información en nuestros archivos, puede comunicarse con nosotros en <a href="mailto:info@getvysta.com">info@getvysta.com</a>. Su solicitud debe incluir detalles suficientes para ayudarnos a localizar su información.`,
        ],
      },
      {
        title: 'Contacta con nosotros',
        subsections: [
          `También puede enviarnos un correo con cualquier comentario o pregunta, o para hacer una solicitud según lo permitido por esta Política de privacidad, a:`,
          `<em> Evolution Ventures, LLC </em>`,
          `<em> 1 North State Street, Suite 904 </em>`,
          `<em> Chicago, IL 60614 </em>`,
          `<em> (561) -400-4370 </em>`,
        ],
      },
    ],
  },
  CookiesPolicy: {
    title: 'Política de cookies',
    sections: [
      {
        title: 'INTRODUCCIÓN',
        subsections: [
          `Evolution Ventures, LLC (" Evolution "o" nosotros ") es un sitio web de servicios inmobiliarios. Ofrecemos una búsqueda mejorada en 3D y marketing para propiedades residenciales.`,
          `Este Aviso de privacidad GDPR (este" Aviso ") proporciona información sobre cómo recopilamos, usamos y compartimos su información personal según lo exige el Reglamento general de protección de datos de la Unión Europea (el" GDPR "), incluido el asesoramiento sobre ciertos derechos y opciones que usted tener con respecto a nuestro manejo de esa información. Esto incluye a través de sitios web que operamos ese enlace a este Aviso, en nuestro correo electrónico, teléfono y otras comunicaciones con usted, y en la prestación de nuestros servicios y la operación de nuestro negocio en general. La información personal se refiere a la información que lo identifica o que pertenece y puede vincularse a usted.`,
          `Este Aviso <u> no </u> se aplica a cualquier otro sitio web o aplicación que no esté vinculado a este Aviso. Este Aviso se aplica a las personas cuya información personal se rige por el GDPR, generalmente residentes permanentes del Espacio Económico Europeo.`,
          `Es importante que lea este Aviso junto con cualquier otra política de privacidad o aviso que podamos proporcionar en nuestros sitios web o en ocasiones específicas cuando recopilamos o procesamos información personal sobre usted para que esté plenamente consciente de cómo y por qué estamos utilizando tu información. Este Aviso complementa otros avisos y políticas de privacidad y no tiene la intención de anularlos.`,
        ],
      },
      {
        title: 'CÓMO RECOPILAMOS INFORMACIÓN PERSONAL',
        subsections: [
          `<u> Recopilamos la información que nos proporciona </u> a través de nuestros sitios web, aplicaciones móviles, por teléfono, correo electrónico y otra correspondencia o comunicación. Para dicha información enviada directa y deliberadamente, usted generalmente decide cuánta información compartir con nosotros, pero no proporcionar cierta información puede impedirnos cumplir con su solicitud o realizar el propósito para el cual se recopila la información.`,
          `<u> Podemos obtener información sobre usted de otras fuentes. </u> Por ejemplo, podemos recibir información personal de un Agente que lo enumere como cliente o de fuentes disponibles públicamente. Y, cuando crea una cuenta en nuestro sitio web utilizando sus credenciales de Facebook, recopilamos cierta información de Facebook según lo establecido por sus permisos para nuestros sitios web dentro de su cuenta de Facebook. Además, nuestros sitios web pueden permitirle compartir nuestras publicaciones y otro contenido a través de sus cuentas en sitios de redes sociales como Facebook, LinkedIn, Twitter y otros, en cuyo caso podemos ser notificados de dicha actividad. Debe revisar la política de privacidad de esos servicios, que rige su actividad de intercambio a través de su servicio.`,
          `<u> Recopilamos información automáticamente. </u> Al visitar e interactuar con nuestros sitios web y aplicaciones móviles, recopilamos ciertos datos técnicos por medios automatizados. En la mayoría de los casos, esta información se recopila a través de registros del servidor web o mediante el uso de cookies y tecnologías relacionadas. Puede leer sobre cómo usamos las cookies y sus opciones para limitar nuestro uso en nuestra <a href="/privacy-policy">Política de privacidad</a> general`,
        ],
      },
      {
        title: 'TIPOS DE INFORMACIÓN PERSONAL QUE RECOPILAMOS',
        subsections: [
          `En general, recopilamos cantidades limitadas de información técnica para optimizar y permitir que nuestros sitios web funcionen, su nombre e información de contacto, información que comparte sobre sus intereses de propiedad y criterios de búsqueda.`,
          `Más específicamente, recopilamos la siguiente información personal:`,
          `<li> Cuando visita nuestros sitios web [o descarga y usa aplicaciones móviles], recopilamos datos técnicos como su dirección IP u otros identificadores de dispositivo, tipo y versión de navegador, sistema operativo e información analítica sobre su actividad en el sitio web [o aplicación]. </li> `,
          `<li> Cuando se registra en uno o más de nuestros sitios web para que, por ejemplo, pueda guardar sus búsquedas de propiedades o recibir notificaciones de nuevos listados o cuando solicita otra información, recopilamos su información de contacto básica, como su primera y apellido y dirección de correo electrónico y cualquier otra información de contacto que comparta con nosotros, así como la contraseña que proporcione (que está cifrada o en hash). Al realizar o guardar una búsqueda de propiedades en nuestros sitios web, nos proporciona información sobre sus criterios de búsqueda e intereses. </li> `,
          `<li> Cuando recopilamos su información personal en el contexto de operación de nuestro negocio, como cuando recopilamos su información como contacto de facturación. </li>`,
          `La información personal que recopilamos de terceros, como un Agente o en el transcurso de una transacción, es coherente con la información descrita anteriormente.`,
        ],
      },
      {
        title: 'PROPÓSITOS PARA EL PROCESAMIENTO DE SU INFORMACIÓN',
        subsections: [
          `Cuando visita nuestros sitios web [o utiliza nuestras aplicaciones móviles], procesamos automáticamente los datos técnicos recopilados para que los sitios web funcionen y se optimicen para su dispositivo y también para recopilar datos analíticos agregados sobre el uso de nuestros sitios web mediante el uso de Google Analytics, aunque hemos habilitado la función de anonimato de Google Analytics. Puede leer nuestra Política de privacidad general para obtener más información sobre cómo utilizamos las cookies.`,
          `Cuando busca propiedades o solicita notificaciones de listados basados ​​en ciertos criterios, se suscribe a nuestro boletín o blogs o realiza solicitudes de información similares, procesamos la información recopilada para proporcionarle el servicio o la información solicitada. Cuando recibamos su consentimiento para hacerlo, también podemos usar esta información para enviarle información de marketing, incluidas las listas de propiedades, que creemos que pueden ser de su interés.`,
          `Cuando se registra en nuestro sitio web, recopilamos su información, incluida una contraseña para establecer su cuenta en línea. En ciertos casos, cuando solicita información, podemos generar automáticamente una cuenta y emitir una contraseña temporal, que enviamos por correo electrónico a la dirección de correo electrónico que nos proporciona.`,
          `Cuando servimos como intermediario en su transacción, utilizamos la información recopilada con el fin de realizar nuestros servicios como intermediario en relación con la transacción, incluido el cumplimiento de las obligaciones legales y reglamentarias que podamos tener.`,
          `Además, podemos utilizar su información personal en el funcionamiento de nuestro negocio en general, que puede incluir lo siguiente:`,
          `<li> Para responder y procesar sus consultas específicas, incluidos los servicios de soporte y para proporcionarle información y acceso a los recursos que nos haya solicitado; </li>`,
          `<li> Para administrar, proteger, operar y mantener nuestros sitios web y nuestros sistemas y redes; </li>`,
          `<li> Para mejorar la navegación y el contenido de nuestros sitios web, la administración del sistema y la seguridad; </li>`,
          `<li> Para compilar estadísticas agregadas sobre el uso de nuestros sitios web y comprender mejor las preferencias de los visitantes del sitio web; </li>`,
          `<li> Para ayudar a personalizar y optimizar la experiencia en nuestros sitios web; </li>`,
          `<li> Para ayudar a entregar información sobre nuestras ofertas a nuestros clientes y posibles clientes; </li>`,
          `<li> Para informarle sobre cambios en nuestras prácticas de privacidad, términos de uso y otros asuntos relacionados con nuestra relación con usted; </li>`,
          `<li> Para difundir nuestros blogs, boletines y comunicaciones similares; </li>`,
          `<li> Para analizar y desarrollar nuevos productos, servicios y sitios web o mejorar los existentes; </li>`,
          `<li> Para realizar actividades administrativas de contabilidad, auditoría, facturación, conciliación, cobranza y similares; </li>`,
          `<li> Para prevenir, detectar, identificar, investigar, responder y proteger contra reclamos potenciales o reales, responsabilidades, comportamiento prohibido y actividad criminal; y </li> `,
          `<li> Para cumplir y hacer cumplir los requisitos legales, acuerdos y políticas aplicables. </li>`,
        ],
      },
      {
        title: 'BASE LEGAL PARA EL PROCESAMIENTO DE SU INFORMACIÓN',
        subsections: [
          `Nuestra base legal para recopilar y utilizar su información personal dependerá de la información personal en cuestión, el contexto específico en el que la recopilamos y los fines para los que se utiliza.`,
          `Sin embargo, normalmente recopilaremos y usaremos su información personal solo cuando tengamos su consentimiento para hacerlo (generalmente para enviarle comunicaciones de marketing), donde necesitemos la información personal para crear o realizar un contrato con usted (por ejemplo, donde Servimos como intermediario para su Transacción o cuando usted ha aceptado nuestros Términos de uso al usar nuestros sitios web), o cuando el procesamiento está en nuestros intereses legítimos y no está anulado por sus intereses de protección de datos o derechos y libertades fundamentales (para la mayoría de nuestros actividades no cubiertas por consentimiento o creación o ejecución de un contrato). En algunos casos muy raros, también podemos tener la obligación legal de recopilar o utilizar su información personal para proteger sus intereses vitales o los de otra persona.`,
          `Si tiene preguntas específicas o necesita más información sobre la base legal sobre la que recopilamos y usamos su información personal, comuníquese con nosotros utilizando los datos de contacto proporcionados en el título "Cómo contactarnos" a continuación.`,
        ],
      },
      {
        title: 'SI NO PROPORCIONA INFORMACIÓN PERSONAL',
        subsections: [
          `Cuando necesitemos recopilar información personal por ley, o bajo los términos de un contrato que tenemos con usted, y usted no proporcione esa información cuando se lo solicite, es posible que no podamos cumplir el contrato que tenemos o estamos tratando de celebrar. usted (por ejemplo, para servir como corredor en relación con su Transacción). En este caso, es posible que tengamos que cancelar nuestros servicios, pero le notificaremos si este es el caso en ese momento. Tenga en cuenta que las ventas de propiedades a menudo están sujetas a plazos y plazos estrictos. Si no se proporciona la información personal necesaria cuando se requiera, puede que una transacción no se consuma.`,
        ],
      },
      {
        title: 'CÓMO COMPARTIMOS SU INFORMACIÓN',
        subsections: [
          `Cuando corresponda, compartimos su información personal de las siguientes maneras:`,

          `<li> Dentro de nuestro grupo de empresas afiliadas (por ejemplo, matriz, filiales) de conformidad con este Aviso. </li>`,
          `<li> Con nuestros proveedores de servicios contratados que nos prestan servicios como alojamiento web, proveedores de infraestructura y aplicaciones en la nube, comunicaciones y servicios técnicos, servicios de gestión de relaciones y marketing, servicios de investigación y contabilidad, atención al cliente y servicios de cobro de facturas. Dichos proveedores están obligados a utilizar únicamente la información según sea necesario para proporcionarnos los servicios contratados. </li> `,
          `<li> Si creemos que la divulgación es necesaria y apropiada para evitar daños, lesiones o pérdidas físicas, financieras o de otro tipo, incluso para proteger contra el fraude o el riesgo de crédito o para perseguir esfuerzos de cobranza u otros derechos e intereses legales. </ li > `,
          `<li> Con autoridades legales, gubernamentales o judiciales, según lo instruido o requerido por esas autoridades o las leyes aplicables, o en relación con una actividad legal, como en respuesta a una citación o investigando sospecha de actividad ilícita. Nos reservamos el derecho de informar a las agencias de aplicación de la ley las actividades que de buena fe creemos que son ilegales. </li> `,
          `<li> En relación con, o durante las negociaciones de, una adquisición, fusión, venta de activos u otra transferencia comercial similar que implique sustancialmente todos nuestros activos o funciones donde la información personal se transfiere o comparte como parte de los activos comerciales (siempre que dicha parte acepta utilizar dicha información personal de manera coherente con este Aviso). </li> `,
        ],
      },
      {
        title: 'COOKIES',
        subsections: [
          `Utilizamos cookies, que son pequeños archivos de texto colocados en su dispositivo, para permitirle crear e iniciar sesión en su cuenta en nuestros sitios web. Estas son cookies de sesión y se eliminan automáticamente cuando cierra su navegador. Puede encontrar más información sobre cómo usamos las cookies en nuestra Política de privacidad.`,
        ],
      },
      {
        title: 'LA INFORMACIÓN PERSONAL SE PROCESA EN LOS ESTADOS UNIDOS',
        subsections: [
          `Evolution tiene su sede en los Estados Unidos y la información que recopilamos de usted en otros países se transfiere y procesa en los Estados Unidos. Nos esforzamos por aplicar salvaguardas adecuadas para proteger la privacidad y seguridad de su información personal de acuerdo con las prácticas descritas en este Aviso.`,
        ],
      },
      {
        title: 'SUS DERECHOS Y OPCIONES DE PRIVACIDAD',
        subsections: [
          `Puede evitar que recopilemos y usemos la información personal enviada manualmente al no proporcionarla, aunque esto puede evitar que le brindemos la información y los servicios que usted solicita.`,
          `Dentro del perfil de su cuenta en nuestros sitios web, puede agregar y editar información personal asociada con su cuenta, administrar ciertas suscripciones de correo electrónico, guardar y administrar sus búsquedas de propiedades y propiedades favoritas, conectar su cuenta a su cuenta de Facebook y administrar otra información que usted elija almacenar dentro de su cuenta, que generalmente se referirá a sus búsquedas de propiedades`,
          `Puede darse de baja de nuestros servicios de comunicaciones e información por correo electrónico siguiendo las instrucciones o el mecanismo de cancelación de suscripción en el mensaje de correo electrónico o contactando con nosotros en la información de contacto proporcionada a continuación. Tenga en cuenta que si opta por no recibir dichas comunicaciones, aún podemos retener y utilizar su información personal para fines transaccionales, administrativos u otros.`,
          `Tiene derecho a <b>acceder</b o <u>corregir</u> la información personal que mantenemos sobre usted y, en algunas circunstancias, puede requerir que <u>borremos</u> la información, o puede <u>objetar</u> o solicitar que <u>restrinjamos</u> nuestro procesamiento de la información. Tiene derecho a <u>retirar su consentimiento</u> cuando dependamos de su consentimiento para el uso de su información personal. En ciertos casos, puede tener derecho a que le <u>transfiramos su información</u> personal a usted o a un tercero en un formato electrónico común y utilizable`,
          `Además, puede tener derecho a <u>presentar una queja</u> ante su Autoridad Nacional de Protección de Datos o un organismo regulador equivalente`,
          `Puede ejercer algunos de estos derechos, como corregir cierta información personal, al hacerlo a través de su cuenta en nuestros sitios web. Puede <u>retirar su consentimiento para la comercialización</u> y ciertas comunicaciones automáticas por correo electrónico haciendo clic en el enlace para cancelar la suscripción o siguiendo las instrucciones para cancelar la suscripción en la comunicación o, en ciertos casos, ajustando sus preferencias dentro de la cuenta de su sitio web.`,
          `Para otro ejercicio de sus derechos o para hacer preguntas o transmitir cualquier queja o inquietud sobre nuestra recopilación y uso de su información personal, puede comunicarse con nosotros a la información de contacto a continuación.`,
        ],
      },
      {
        title: 'SEGURIDAD DE SU INFORMACIÓN',
        subsections: [
          `Mantenemos medidas de seguridad razonables y apropiadas para proteger su información personal de pérdidas, mal uso y acceso no autorizado, divulgación, alteración y destrucción.`,
        ],
      },
      {
        title: 'NUESTRA RETENCIÓN DE SU INFORMACIÓN PERSONAL',
        subsections: [
          `Retendremos su información personal durante el período necesario para cumplir con los propósitos descritos en este Aviso a menos que la ley exija o permita un período de retención más largo, por razones legales, impositivas o regulatorias, u otros fines comerciales legítimos y legales.`,
          `Si tiene preguntas sobre nuestra retención para un propósito o contexto específico, puede comunicarse como la información de contacto a continuación.`,
        ],
      },
      {
        title: 'ENLACES DE TERCEROS',
        subsections: [
          `Nuestros sitios web pueden proporcionar enlaces o la capacidad de conectarse con sitios web, servicios, redes sociales, aplicaciones o funciones de redes sociales de terceros, como los botones "Compartir" o "Me gusta". Visitar esos sitios o hacer clic en esos enlaces puede permitir que un tercero recopile y use información sobre usted. Recomendamos que revise los avisos de privacidad y los términos de uso de dichos terceros antes de interactuar con ellos o proporcionarles su información personal. Evolution no controla el uso de su información personal por parte de estos terceros.</span>`,
        ],
      },
      {
        title: 'CAMBIOS Y ACTUALIZACIONES AL AVISO DE PRIVACIDAD',
        subsections: [
          `Nos reservamos el derecho de cambiar, modificar, agregar o eliminar partes de este Aviso de vez en cuando ya nuestro exclusivo criterio, pero le avisaremos de que se han realizado cambios al indicar en este Aviso la fecha de la última actualización. Cuando visita nuestros sitios web o nos proporciona su información personal en las circunstancias cubiertas por este Aviso, está aceptando la versión actual de este Aviso tal como se publicó en ese momento. Le recomendamos que vuelva a visitar este Aviso ocasionalmente para conocer cualquier cambio.`,
        ],
      },
      {
        title: 'IDENTIDAD DEL CONTROLADOR; CÓMO CONTACTARNOS',
        subsections: [
          `Evolution Ventures, LLC es el controlador de la información personal que recopilamos y procesamos.`,
          `Puede ponerse en contacto con nosotros sobre asuntos relacionados con este Aviso en lo siguiente:`,
          `Por correo electrónico: <a href="mailto:info@evolutionv.com">info@evolutionv.com</a>`,
          `Por correo:`,
          `Attn: Evolution Ventures, LLC`,
          `1 North State Street, Suite 1500`,
          `Chicago, IL 60614`,
          `561-400-4370`,
        ],
      },
    ],
  },
  PropertyAdmin: {
    Organizations: {
      property: 'Propiedad',
      organizations: 'Organizaciones',
      name: 'Nombre',
      type: 'Tipo',
      removeAssociation: 'Eliminar Asociación',
      addNewAssociation: 'Agregar Nueva Asociación',
      associate: 'Asociada',
      Form: {
        successRemove: 'La asociación fue eliminada con éxito',
        successAssociate: 'La asociación se agregó con éxito.',
        errorDeveloper: 'Una propiedad debe contener al menos una asociación de corretaje',
      },
    },
    PointsOfInterest: {
      title: 'Puntos de interés',
      address: 'Habla a',
      remove: 'Eliminar punto de interés',
      associate: 'Punto de interés asociado',
      add: 'Agregar nuevo punto de interés',
      addSuccess: 'Nuevo punto de interés agregado con éxito',
      addError: 'Error al agregar su punto de interés',
      removeSuccess: 'Punto de interés eliminado con éxito',
      removeError: 'Error al eliminar tu punto de interés',
      addButton: 'Agregar punto de interés',
    },
  },
  SuperAdmin: {
    Agents: {
      title: 'Agentes',
      search: 'Buscar agentes',
      invite: 'Invitar agente',
      details: 'Ingrese los detalles del agente',
    },
    Organization: {
      organization: 'Organización',
      organizations: 'Organizaciones',
      name: 'Nombre',
      type: 'Tipo',
      manageUsers: 'Administrar usuarios',
      edit: 'Editar',
      delete: 'Eliminar',
      newOrganization: 'Nueva Organización',
      detailsNewOrganizations: 'Ingrese los detalles de la Organización a continuación',
      selectOrganizationType: 'Seleccionar tipo de Organización',
      description: 'Descripción',
      website: 'Website',
      addressLine1: 'Dirección Línea 1',
      addressLine2: 'Dirección Línea 2',
      postalCode: 'Código postal',
      state: 'Estado',
      city: 'Ciudad',
      phone: 'Teléfono',
      save: 'Salvar',
      addAddress: 'Añadir dirección',
      addPhone: 'Añadir teléfono',
      removeAddress: 'Eliminar dirección',
      removePhone: 'Eliminar teléfono',
      logo: 'logo',
      user: 'Usuario',
      manageUsersDescription: 'Agregar o eliminar usuarios en la organización',
      add: 'Añadir',
      addNewUser: 'Agregar Nuevo Usuario',
      remove: 'Eliminar',
      deletedSuccess: 'Organización eliminada con éxito',
      addedSuccess: 'Organización agregada con éxito',
      editedSuccess: 'Organización editada con éxito',
      removedUserSuccess: 'Usuario eliminado con éxito',
      addedUserSuccess: 'Usuario agregado con éxito',
    },
    OrganizationTypes: {
      organizationTypes: 'Tipos de organización',
      organizationsUsingIt: 'En uso',
      newOrganizationType: 'Nuevo tipo de organización',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewOrganizationType: 'Ingrese los detalles del tipo de organización a continuación',
      save: 'Nombre',
      name: 'Name',
      deletedSuccess: 'Tipos de organización eliminada con éxito',
      addedSuccess: 'Tipos de organización agregada con éxito',
      editedSuccess: 'Tipos de organización editada con éxito',
    },
    Tag: {
      tag: 'Etiqueta',
      tags: 'Etiquetas',
      newTag: 'Nueva etiqueta',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewTag: 'Ingrese los detalles de la etiqueta a continuación',
      save: 'Salvar',
      selectTagGroupType: 'Seleccionar tipo de grupo de etiquetas',
      name: 'Nombre',
      deletedSuccess: 'Etiqueta eliminada con éxito',
      addedSuccess: 'Etiqueta agregada con éxito',
      editedSuccess: 'Etiqueta editada con éxito',
    },
    TagGroup: {
      tagGroup: 'Grupo de etiquetas',
      tagGroups: 'Grupos de etiquetas',
      agents: 'Agentes',
      newTagGroup: 'Nuevo grupo de etiquetas',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewTagGroups: 'Ingrese los detalles del grupo de etiquetas a continuación',
      save: 'Salvar',
      name: 'Nombre',
      deletedSuccess: 'Grupo de etiquetas eliminada con éxito',
      addedSuccess: 'Grupo de etiquetas agregada con éxito',
      editedSuccess: 'Grupo de etiquetas editada con éxito',
    },
    LocaleTypes: {
      localeTypes: 'Tipos de configuración regional',
      localesUsingIt: 'En uso',
      newLocaleType: 'Nuevo tipo de configuración regional',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewLocaleType: 'Ingrese los detalles del tipo de configuración regional a continuación',
      save: 'Salvar',
      name: 'Nombre',
      deletedSuccess: 'Tipos de configuración regional eliminada con éxito',
      addedSuccess: 'Tipos de configuración regional agregada con éxito',
      editedSuccess: 'Tipos de configuración regional editada con éxito',
    },
    AddressTypes: {
      addressTypes: 'Tipos de dirección',
      addresssUsingIt: 'Direcciones que lo usan',
      newAddressType: 'Nuevo tipo de dirección',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewAddressType: 'Ingrese los detalles del tipo de dirección a continuación',
      save: 'Salvar',
      name: 'Nombre',
      deletedSuccess: 'Tipos de dirección eliminada con éxito',
      addedSuccess: 'Tipos de dirección agregada con éxito',
      editedSuccess: 'Tipos de dirección editada con éxito',
    },
    View: {
      view: 'Tipo de vista',
      views: 'Ver tipos',
      agents: 'Agentes',
      newView: 'Nuevo tipo de vista',
      edit: 'Editar',
      delete: 'Eliminar',
      detailsNewViews: 'Ingrese los detalles del tipo de vista a continuación',
      save: 'Salvar',
      name: 'Nombre',
      deletedSuccess: 'Tipo de vista eliminada con éxito',
      addedSuccess: 'Tipo de vista agregada con éxito',
      editedSuccess: 'Tipo de vista editada con éxito',
    },
  },
  ClientSurvey: {
    saveSurvey: 'Guardar encuesta',
    occupationTitle: '¿Cuál es tu ocupación?',
    occupationSubtitle: 'Escriba en su ocupación abajo',
    occupationPlaceholder: 'Ocupación aquí...',
    roleTitle: 'Rol en la empresa?',
    roleSubtitle: 'Escribe en tu papel abajo',
    rolePlaceholder: 'Papel aquí...',
    industryTitle: 'En que industria estás?',
    industrySubtitle: 'Escriba en su industria abajo',
    industryPlaceholder: 'Industria aquí...',
    firstTitle: 'Comprador de casa por primera vez',
    selectSubtitle: 'Seleccione una de las opciones a continuación.',
    yes: 'Si',
    no: 'No',
    ownershipTitle: '¿Actualmente alquila, posee o ambos?',
    rent: 'Alquila',
    own: 'Posee',
    both: 'Ambos',
    lookingTitle: '¿Estás buscando activamente?',
    primaryTitle: '¿Residencia principal o segunda residencia?',
    primary: 'Principal',
    secondary: 'Segunda',
    developmentTitle: '¿Prefieres nuevos desarrollos o existentes?',
    newLabel: 'Nuevos',
    existing: 'Existentes',
    brokerTitle: 'Preferencias de comunicación del corredor',
    email: 'Email',
    phone: 'Teléfono',
    emailTitle: 'Preferencias de alerta por correo electrónico',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    timezone: 'Zona horaria',
    referralTitle: '¿Cómo te enteraste de nosotros?',
    title: 'Título',
    notes: 'Notas',
    uploadTitle: 'Sube tu foto a continuación.',
    uploadSubtitle: 'Selecciona una foto para tu perfil.',
    bedrooms: '¿Cuántas habitaciones prefieres?',
    bathrooms: '¿Cuántos baños prefieres?',
    addBuildings: 'Buscar para agregar edificios...',
    addCities: 'Buscar para agregar ciudades...',
    citiesTitle: '¿En que ciudades estas interesado?',
    citiesSubtitle: 'Escriba la entrada a continuación para encontrar ciudades, puede agregar varias ciudades.',
    buildingsTitle: 'Edificios preferidos?',
    buildingsSubtitle:
      'Escriba la entrada a continuación para encontrar edificios específicos, puede agregar varios edificios.',
    addBuildingsButton: 'Agregar edificio',
    addCitiesButton: 'Agregar ciudad',
    priceTitle: 'Rango de precio preferido',
    priceSubtitle: 'Arrastre para seleccionar el rango de precios.',
    sizeTitle: 'Rango de tamaño preferido',
    sizeSubtitle: 'Arrastre para seleccionar el rango de tamaño.',
  },
  ImagePicker: {
    title: 'Selecionar Mídia',
    done: 'Listo',
    cancel: 'Cancelar',
  },
  ImageCropper: {
    crop: 'Cortar',
    cancel: 'Cancelar',
    editImage: 'Editar Imagen',
    zoom: 'Enfoque',
    rotation: 'Rotación',
  },
  Media: {
    title: 'Media',
    removeTitle: '¿Estás seguro de que deseas eliminar este medio?',
    removeSubTitle: 'Esto eliminará este media del sistema.',
    cancel: 'Cancelar',
    confirm: 'Eliminar Media',
    deleteSuccess: 'Tus medias han sido eliminadas',
  },
} as unknown) as Translation;

export default es;
