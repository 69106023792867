import { ButtonBase, IButtonBase } from './ButtonBase';

import React from 'react';
import { Touchable } from '../';

interface IButton extends IButtonBase {
  onPress: () => void;
  testID: string;
  width?: number;
}

export const Button = (props: IButton) => {
  const { onPress, testID, ...buttonBaseProps } = props;
  return (
    <Touchable onPress={onPress} testID={testID} width={props.width} disabled={props.disabled}>
      <ButtonBase {...buttonBaseProps}>{props.children}</ButtonBase>
    </Touchable>
  );
};
