import { Unit } from '../../types';
import api from '../../utils/api';

export const batchCreateUnits = (propertyId: number, units: Array<Unit>) =>
  api.post({
    url: `/property/${propertyId}/unit/batch`,
    variables: {
      units,
    },
  });
