import React from 'react';
import PageError from './PageError';

const supportedBrowsers = [
  { name: 'Edge', url: 'https://www.microsoft.com/en-us/edge' },
  { name: 'Chrome', url: 'https://www.google.com/chrome/' },
  { name: 'Safari', url: 'https://support.apple.com/downloads/safari' },
  { name: 'Firefox', url: 'https://www.mozilla.org/en-US/firefox/new/' },
] as Array<{ name: string; url: string }>;

export const BrowserGuard = () => (
  <PageError
    title="Sorry, your browser is not supported."
    description="For the best experience using this app, we recommend switching to one of the following browsers:"
    content={
      <ul>
        {supportedBrowsers.map((browser, index) => (
          <li key={index}>
            - <a href={browser.url}>{browser.name}</a>
          </li>
        ))}
      </ul>
    }
  />
);

export default BrowserGuard;
