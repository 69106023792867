import { useApi, gql } from './Provider';
import { CreateClient, CreateClientVariables } from './__generated__/CreateClient';

const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
    }
  }
`;

export const useCreateClient = () => {
  const { useMutation } = useApi();
  return useMutation<CreateClient, CreateClientVariables>(CREATE_CLIENT);
};
