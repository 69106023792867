import { PASSWORD_MINIMUM } from '../utils/constants';

const en = {
  LanguagePicker: {
    header: 'Choose a language',
  },
  Nav: {
    developers: 'For Developers',
  },
  ErrorPage: {
    title: 'Oops, something went wrong...',
    description: 'We’re not quite sure what went wrong. Please feel free to contact us if you need a hand.',
    notFoundTitle: '404',
    notFoundDescription: 'File Not Found',
  },
  Login: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    createAccount: 'Create New Account',
    tagline: 'Login to your account.',
    accessAccount: 'Access your account',
    forgotPassword: 'Forgot password?',
    forgotPasswordTitle: 'Forgot Password',
    remindMe: 'Help accessing my account',
    accountDoesntExist: "Don't Have an Account Yet?",
    verification: 'We have confirmed your account. Logging you in.',
    resendVerificationError: 'There was an error resending your verification email.',
    resendVerificationSuccess: 'Your verification email was sent.',
    resendVerificationEmail: 'Resend verification email',
    welcomeBack: 'Welcome Back! Login to Vysta',
  },
  RegisterDeveloper: {
    title: 'Create a Developer Account',
    subtitle: 'Get Started',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    password2: 'Confirm Password',
    createAccount: 'Create Account',
    login: 'Login',
    success: 'Account created. Logging you in!',
  },
  RegisterClient: {
    resend: 'Resend',
    invitationSend: 'Invitation was sent',
    invitationError: 'There was an error sending your invite',
    inviteExists: '*This email is already in our system. Please check your inbox for your invitation. Can’t find it?',
    userExists: '*This email is already in our system.',
    forgotPassword: 'Did you forgot your password?',
  },
  Register: {
    iHaveReadAndAccepted: 'I have read and accepted the',
    termsOfService: 'Terms of Service',
    and: 'and',
    privacyPolicy: 'Privacy Policy',
    alreadyHaveAccount: 'Already have an account?',
    login: 'Login Here',
    createAccount: 'Create Account',
    updateAccount: 'Update Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Confirm Password',
    title: 'Create an Agent Account',
    titleClient: 'Create an account to view properties.',
    messageClient:
      "I am excited to share with you South Florida's leading new developments in an exciting new way. Sign up, search and let me know your thoughts. Through VYSTA, I work directly with all of the developers you will find here.  I can answer your questions, arrange in person and online tours, and help you find the perfect home for you and your family. Let's get started!",
    subtitle: 'Get Started',
    accountExists: 'Already have an account?',
    passwordRequirement: {
      length: `At least ${PASSWORD_MINIMUM} characters in length`,
      uppercase: 'At least one uppercase character',
      lowercase: 'At least one lowercase character',
      numeric: 'At least one numeric character',
      email: 'Does not contain email identifier',
      special: 'At least one special character',
    },
    error: 'There was an error creating your account',
    confirm:
      'Thanks for signing up! To complete the registration process please check your email and follow the instructions provided.',
    clientConfirm: 'Thanks for signing up! You are now verified and setup. Logging you in!',
    userInviteConfirm: 'Thanks for setting your password! You are now verified and setup. Logging you in!',
    clientRegisterConfirm: 'Thanks for signing up! You have been sent an email to verify your email address.',
    usernameAlreadyExists: 'This email address is already registered, please login using your email and password.',
    invalidPassword: 'Your password seems to be invalid. Make sure you are not re-using a currently used password.',
    experience: 'Experience',
    exploreThe: 'Explore the',
    propertyProfile: 'Property Profile',
    shareThe: 'Share the',
    propertyProfileWithYourClients: 'Property Profile with your Clients',
    seeYourClientActivity: 'See your client activity through our Reports and Clients List in the top right menu',
    learnMoreAboutVysta: 'Learn more about Vysta »',
    explore: 'Explore',
    createAPasswordForYourAccount: 'Create a password for your account',
    loginNow: 'Login Now',
    about1Label: 'Explore',
    about2Label: 'Market',
    about3Label: 'Share',
    about1: 'Discover South Florida luxury new developments through gorgeous immersive 3D experiences.',
    about2: 'Promote your branded profile with your favorite listings to luxury agents and buyers.',
    about3: 'Share VYSTA with clients and track behavior through sophisticated reports.',
  },
  ForgotPassword: {
    readyToLogin: 'Ready to login?',
    confirm: 'Please follow the instructions in the email that has been sent to you to reset your password.',
    invalidEmail: 'This email is not in our system',
  },
  PasswordReset: {
    title: 'Password Reset',
    verifyTitle: 'Set Password to Complete Verification',
    confirm: 'Your password has been successfully reset. Logging you in.',
    verifyConfirm: 'We have confirmed your account. Logging you in.',
  },
  Verification: {
    expired:
      'Your confirmation link has expired. We are automatically re-sending you a new confirmation link. Please check your email and follow the instructions provided.',
    badCode: 'Validation code does not appear to be correct. We are resending you a new verification email.',
  },
  ClientHome: {
    buyerSurvey: 'Buyer Survey',
    newAroundHere: 'New around here?',
    fillOutSurvey:
      'to fill out your buyer survey so we can make the best recommendations tailored to your preferences.',
    myPreferences: 'My Preferences',
    emailPreferences: 'Email Preferences',
    occupation: 'Occupation',
    employer: 'Employer',
    timeZone: 'Time Zone',
    brokerage: 'Brokerage',
    receiveCriteria: 'Receive information on properties that fit my search criteria',
    none: 'None',
    address: 'Current Address',
    details: 'User Details',
    editDetails: 'Edit User Details',
    myAgent: 'My Agent',
    realEstate: 'Real Estate Preferences',
    surveyResults: 'Client Survey Results',
    retake: 'Retake Survey',
    firstTimeBuyer: 'First time home buyer?',
    activelyLooking: 'Are you actively looking?',
    industry: 'Industry',
    priceRange: 'Preferred Price Range',
    ownershipStatus: 'Currently Rent/Own or Both?',
    sizeRange: 'Preferred Size Range',
    communicationPreference: 'Broker Communication Preferences?',
    language: 'Language',
    bedroomNumber: 'How many bedrooms do you prefer?',
    bathroomNumber: 'How many bathrooms do you prefer?',
  },
  ClientInvite: {
    title: 'Invite Client',
    subtitle: 'Enter details of your client below',
    confirm: 'The invitation to your client has been sent successfully.',
    confirmCreate: 'The invitation to your client has been created successfully.',
    sendInvite: 'Send client invitation email',
  },
  Common: {
    knowledgeBase: 'Help Center',
    errorsOnly: 'Errors Only',
    dataPolicy: 'Data Policy',
    contactVysta: 'Contact VYSTA',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    poweredBy: 'powered by',
    map: 'Map',
    list: 'List',
    myAccount: 'My Account',
    aboutVysta: 'About Vysta',
    vystaInc: 'Vysta, Inc.',
    evolutionVirtual: 'Evolution Virtual',
    copyright: 'Copyright',
    bulkDelete: 'Bulk Delete',
    at: 'at',
    removeRows: 'Remove Rows',
    insertRow: 'Insert Row',
    bulkUpdate: 'Bulk Update',
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    login: 'Login Now',
    logout: 'Logout',
    join: 'Join Now',
    createAccount: 'Create Account',
    searchHomes: 'Search Homes',
    contactUs: 'Contact Us',
    contact: 'Contact',
    forMoreInformation: 'for more information',
    home: 'Home',
    welcome: 'Welcome',
    submit: 'Submit',
    gdpr:
      'We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content, to analyze our website traffic, and to understand where our visitors are coming from. By browsing our website, you consent to our use of cookies and other tracking technologies.',
    gdprPolicies:
      'You can view our <a href="https://www.getvysta.com/privacy-policy">Privacy Policy</a> and <a href="/cookies-policy">Cookies Policy</a> for more details.',
    gdprAccept: 'I Accept',
    recommendedListings: 'Recommended Developments',
    from: 'From',
    clickHere: 'Click Here',
    myFavorite: 'My Favorite',
    features: 'Features',
    listings: 'Developments',
    recentlyViewed: 'Recently Viewed',
    add: 'Add',
    sendInvite: 'Send Invite',
    createInvite: 'Create Invite',
    serverError: 'We are currently experiencing technical difficulties. Please try again shortly.',
    backToSearch: 'Back to search',
    backTo: 'Back to',
    contactUsForMoreInformation: 'Contact Us for More Information',
    contactAgent: 'Contact Agent',
    learnMoreAbout: 'Learn More About',
    message: 'Message',
    messages: 'Messages',
    more: 'More',
    download: 'Download',
    fullScreen: 'Full Screen',
    save: 'Save',
    addNewBrokerage: 'Add New Brokerage',
    profile: 'Profile',
    help: 'Help',
    exportButton: 'Export CSV',
    importButton: 'Import CSV',
    clearImage: 'Clear Image',
    title: 'Vysta',
    pricing: 'Pricing',
    search: 'Search',
    contactPhone: '(561) 400-4370',
    contactEmail: 'info@getvysta.com',
  },
  Forms: {
    setPasswordToContinue: 'Set your password to enter VYSTA.',
    requestTourSuccess: 'Your tour has been requested',
    charactersRemaining: 'characters remaining',
    bulkSuccess: 'Your data has been saved',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    password: 'Password ',
    confirmpassword: 'Confirm Password',
    newPassword: 'New Password',
    oldPassword: 'Current Password',
    confirmNewPassword: 'Confirm New Password',
    vanitystub: 'Your desired VYSTA URL link, i.e. /johndoe',
    example: 'Example',
    johnDoe: 'johndoe',
    vanitystubShort: 'Portal URL',
    license: 'Real Estate License #',
    brokerageName: 'Brokerage',
    brokerageOfficeName: 'Brokerage Office Name',
    brokeragePrimaryOfficeEmail: 'Primary Office Email',
    brokerageWebsite: 'Input your brokerage URL',
    brokerageWebsitePlaceholder: 'example: www.brokerage.com',
    brokeragePhone: 'Brokerage Phone',
    brokerageAddress: 'Brokerage Address',
    brokerageAddressLine1: 'Brokerage Address Line 1',
    brokerageAddressLine2: 'Brokerage Address Line 2',
    brokerageAddressCity: 'Brokerage Address City',
    brokerageAddressState: 'Brokerage Address State',
    brokerageAddressPostalCode: 'Brokerage Address Postal Code',
    organizationName: 'Organization Name',
    bio: 'Bio',
    workPhone: 'Work Phone',
    homePhone: 'Home Phone',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    state: 'State',
    postalCode: 'Postal Code',
    location: 'Location',
    locationPlaceholder: 'Add cities or neighborhoods...',
    saveNewBrokerage: 'Please save the new brokerage before creating an account',
    authorizedRepresentativeBrokerage: 'I am an authorized representative of this brokerage.',
    acceptTermsAndPolicies:
      'I have read and accept the <a href="https://www.getvysta.com/terms-of-use" target="_blank">Terms and Conditions</a> and <a href="https://www.getvysta.com/privacy-policy" target="_blank">Privacy Policy</a>',
    selectImage: 'Select image',
    selectImageLong: 'Select accolade image type',
    addAccolade: 'Add Accolade',
    removeAccolade: 'Remove Accolade',
    accoladeText: 'Accolade Text',
  },
  CreateBrokerage: {
    save: 'Add New Brokerage',
    inputYourUrl: 'Input your brokerage URL',
    noBrokeragesFound: 'No brokerages found. Manually add your brokerage below',
    name: 'Name of Brokerage',
    postalCode: 'Office Zip Code',
    addNewBrokerage: 'Add Brokerage',
    associatedBrokeragesFound: 'Associated brokerages found',
    addBrokerage: 'Add Brokerage',
  },
  FormErrors: {
    passwordsNotEqual: "Passwords Don't match",
    duplicateClient:
      'This client has already been invited by an agent in VYSTA. For more information email support@getvysta.com',
    duplicateUser: 'This user is already in our system',
    bulkDuplicates: 'There are multiple rows with the same ID. Unable to perform an import',
    notWholeNumber: 'Please enter a whole number',
    required: 'This field is required',
    email: 'A valid email is required',
    passwordConfirm: 'Password do not match',
    validEmail: 'Must be a valid email address',
    validPhoneNumber: 'Must be a valid phone number including an area code',
    validWebsite: 'Must be a valid website',
    urlParam: 'Must contain only lower case characters and/or numbers',
    doesNotMeetRequirements: 'Password does not meet minimum requirements',
    passwordRequirementsLength: `At least ${PASSWORD_MINIMUM} characters in length`,
    passwordRequirementsUppercase: 'At least one lowercase character',
    passwordRequirementsLowercase: 'At least one uppercase character',
    passwordRequirementsNumeric: 'At least one numeric character',
    passwordRequirementsEmail: 'Does not contain email identifier',
    notConfirmed: 'User has not verified their email address yet.',
    passwordResetRequired: 'Password reset is required. Please visit the Forgot Password page.',
    incorrectCredentials: 'Invalid email or password.',
    usernameExists: 'Email address is already in use.',
    usernameAlreadyConfirm: 'User has already been confirmed. Please proceed to Login page.',
    serverError: 'An unknown server error has occurred. An administrator has been notified. Please try again shortly.',
    invalidFormat: 'Must contain only alphanumeric characters.',
    invalidLength: (size: number) => `Must not be more than ${size} characters in length.`,
    generic: 'An error occurred',
    genericBulk: 'We were unable to process your data. Please fix any errors and try again.',
    associationExists: 'This organization is already associated',
    tooShortString: 'This field must be at least {{number}} characters',
    tooLongString: 'This field must be {{number}} characters or less',
    tooShortNumber: 'The minimum value for this field is {{number}}',
    tooLongNumber: 'The maximum value for this field is {{number}}',
    csvUploadError: 'There was an error reading your CSV. Please fix your syntax and try again.',
    userExists: 'This user is already associated with this organization',
    invalidCurrency: 'Invalid currency format',
    uuid: 'Invalid UUID',
    tooManySelectedMedia: 'You may have no more than {{limit}} media items selected.',
    invalidPostalCode: 'Invalid postal code',
    brokerageRequired: 'Please select a brokerage from the list or create a new brokerage below.',
  },
  Home: {
    title: 'Explore new development projects and homes in',
    title2: ' immersive luxury.',
    searchTitle: 'Find your next luxury home.',
    searchPlaceholder: 'Enter property name, city, state or zip code',
    sellTitle: 'Sell your next luxury home.',
    sellSubTitle: 'Get your own white label site and share beautiful listings with your buyers.',
    agents: 'Agents',
    agentsText:
      'Build a better relationship with your buyers and sell more new development homes. Create a free white-label site and share gorgeous 3D experiences that clients can not find anywhere else. ',
    agentsText2: 'Beta begins Fall 2020. Apply now to be a Beta User.',
    agentsButton: 'Learn More',
    buyers: 'Buyers',
    buyersText:
      "Want to search South Florida's only complete new development database through gorgeous 3D immersive experiences? Ask your agent to join. ",
    buyersText2: 'Not working with a real estate agent? ',
    buyersText3: "Join Vysta's portal to try it out.",
    buyersButton: 'Share With Your Agent',
    developers: 'Developers',
    developersText:
      'Share your new development with top agents and their buyers around the world, for free. We will create a beautiful, visually impressive listing for your property. Contact us to begin the process.',
    developersButton: 'Contact Us',
    createAccount: 'Create Account',
    askAgent: 'Looking for a home? Ask your agent to join Vysta',
    shareWithAgent: 'Share Vysta With Your Agent',
    learn: 'Learn about Vysta',
    listingTitle: 'Beautiful Listings',
    listingSubTitle: 'Showcase exciting new developments from around the world',
  },
  About: {
    title: 'About Vysta',
    contact: 'Contact',
    descTitle: 'The World’s Most Powerful New Development Platform',
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    question: 'Have A Question?',
    questionSubtitle: 'Some subtext helping to validate the above header and the value of each of these plans below.',
    aboutUs: 'About Us',
    about:
      'Over 25 years, Jonathan has built successful products and businesses in technology, financial services, pharmaceuticals, marketing, publishing and alternative energy at organizations like NASA, Intel, Goldman Sachs, Precision Therapies and Eli Lilly.<br/><br/>Before co-founding Evolution Ventures, Jonathan worked as Vice President of Strategic Planning at Woolbright Development, where he was responsible for raising $250 million in institutional capital and designing technology solutions to improve leasing, acquisitions and operations. At Goldman Sachs, Jonathan designed and issued new securities and derivatives to enable REITs and corporations to more efficiently access capital. His other experiences have ranged from salvaging a $1 Billion osteoporosis drug at Eli Lilly to creating a market-leading online research and branding firm, LucidView, which he sold to Jungle Media. Jonathan holds a BS in Computer Science from MIT and an MBA from The Wharton School at the University of Pennsylvania. He is a licensed real estate sales associate in the State of Florida.',
    freeTitle: 'Free for agents and their buyers',
    freeSubtitle: 'Brokerages and developers, please contact us to access advanced tools.',
    contactFormSubtitle: 'Fill out the details below and we will get touch with you.',
    answer: 'Answer',
    faq: [
      {
        key: 0,
        question: 'How do I add a property?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 1,
        question: 'How do I favorite a property?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 2,
        question: 'How do I view my recently viewed properties?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 3,
        question: 'What does my Vysta account allow me to do?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 4,
        question: 'Do I have direct access to real estate agents via Vysta?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      {
        key: 5,
        question: 'How do I contact a property about a unit?',
        answer:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
    ],
    ourOffices: 'Location',
    corporateOffices: 'Office',
    email: 'Email',
    phone: 'Phone',
    termsOfServices: 'Terms of Services',
    privacyPolicy: 'Privacy Policy',
  },
  AgentPortal: {
    marketing: 'Marketing',
    defaultTemplate: 'Default Template',
    select: 'Select',
    edit: 'Edit',
    emailTemplate: 'Client Email Template',
    recentActivity: 'Recent Activity',
    sendInvite: 'Send Invitation',
    pendingInvitation: 'Invite Created',
    admin: 'Admin',
    inviteResentSuccess: 'The user invite has been successfully sent.',
    inviteResentBlocked: 'The user has already been verified. No invitation was re-sent.',
    inviteResentError: 'We failed to send the user invite, an administrator has been contacted about the error.',
    email: 'Email',
    clientName: 'Client Name',
    status: 'Status',
    lastLogin: 'Last Login',
    location: 'Location',
    emailsSent: 'Emails Sent',
    inviteSent: 'Invite Sent',
    inviteCreated: 'Invite Created',
    viewClient: 'View Client',
    totalFavorites: 'Favorites',
    timeSpent: 'Time Spent',
    searchClients: 'Search Clients',
    newClient: 'New Client',
    inviteClient: 'Invite Client',
    bulkClientSuccess: 'User invitations have been successfully sent',
    sortBy: 'Sort by',
    mostRecent: 'Most Recent',
    leastRecent: 'Least Recent',
    emptyListClients: 'No clients',
    pendingVerification: 'Invite Sent',
    active: 'Account Active',
    verified: 'Account Pending',
    resendInvite: 'Resend invite',
    myClients: 'Clients',
    myAccount: 'My Account',
    myListings: 'My Listings',
    myMessages: 'My Messages',
    userDetails: 'User Details',
    settings: 'Settings',
    profile: 'Profile',
    password: 'Password',
    preferences: 'Preferences',
    portalDesign: 'Portal Design',
    customClientSurvey: 'Custom Client Survey',
    searchUsers: 'Search users',
    userManagement: 'User Management',
    userManagementRoles: 'Edit user roles',
    phone: 'Phone',
    visitCount: '# of Visits',
    inviteStatus: 'Invite Status',
    reportsTitle: 'Reports',
    reportsSummaryTitle: 'Summary',
    allClientsData: 'All Client Data',
    allPropertyData: 'All Property Data',
    viewBuyerSurveyResults: 'View Buyer Survey Results',
    CREATED: 'Invite Created',
    INVITED: 'Invite Sent',
    ACCEPTED: 'Accepted',
    PENDING_INVITATION: 'Invite Created',
    PENDING_VERIFICATION: 'Invite Sent',
    VERIFIED: 'Account Pending',
    ACTIVE: 'Account Active',
  },
  AgentReports: {
    totalVisits: 'Total Visits',
    propertiesViewed: 'Properties Viewed',
    unitsViewewd: 'Units Viewed',
    thisWeek: 'This Week',
    thisMonth: 'This Month',
    thisYear: 'This Year',
    clientLoginsTitle: 'Client Logins/Visits',
    filterTitle: 'Filter By',
    mostActiveClientsTitle: 'Most Active Clients',
    portalActivityTitle: 'Portal Activity',
    topPropertyViews: 'Top Property Views',
  },
  AgentHome: {
    newAroundHere: 'New around here?',
    surveyDescription: 'Fill out your buyer survey for recommendations tailored to your preferences.',
    completeSurvey: 'Complete Buyer Survey',
    emptyFavoriteListings: 'No favorite listings yet. Start searching to add favorites!',
    emptyRecommendedListings: 'This agent has not recommended any developments yet.',
    emptyRecentListings: 'No recently viewed yet. When you view a property, it will show up here!',
    emptyFavoriteFeatures: 'No favorite features yet. Start searching to add favorites!',
    agentHome: 'About Vysta',
    featuredDevelopments: 'Featured Developments',
    listingsTitle: 'Stunning Listings',
    listingsDescription:
      'Vysta’s 3D immersion technology allows you to explore properties like never before, discovering everything from amenities to floorplans and more. Vysta works directly with premiere developers to bring you the best homes available.',
    propertiesTitle: 'Favorite Properties',
    propertiesDescription:
      'Keep track of all your favorite buildings and units in Vysta. Your broker can review all of your likes and make great recommendations for you.',
    communicateTitle: 'Communicate & Share',
    communicateDescription:
      'Directly message me, your broker, with questions and tour requests. When I find a listing that fits your profile, I will share it with you right to your inbox.',
  },
  ChangePassword: {
    success: 'Your password has been successfully updated',
  },
  Profile: {
    changePhoto: 'Change Profile Photo',
    changeLogo: 'Change Logo',
    changeBanner: 'Change Homepage Image',
    updateSuccess: 'User profile has been successfully updated',
    updateError: 'Failed to update the user profile',
  },
  Search: {
    editProperty: 'Edit Property',
    searchingForProperties: 'Searching for properties',
    reset: 'Reset',
    all: 'All',
    any: 'Any',
    in: 'in',
    mobileHeader: 'Filter Search Results',
    price: 'Price',
    priceRange: 'Price Range',
    luxury: 'Luxury',
    ultraLuxury: 'Ultra Luxury',
    status: 'Status',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    bedsBaths: 'Beds/Baths',
    advancedFilters: 'Advanced Filters',
    applyFilters: 'Apply Filters',
    resetFilters: 'Reset Filters',
    chooseParameters: 'Choose your parameters below.',
    beds: 'Beds',
    bed: 'Bed',
    baths: 'Baths',
    bathAbr: 'ba',
    bedAbr: 'bd',
    useExactMatch: 'Use Exact Match',
    noResults: 'Your search returned no results',
    results: 'Results',
    result: 'Result',
    searchBuildings: 'Search Buildings',
    searchUnits: 'Search Units',
    for: 'for',
    units: 'Units',
    available: 'Available',
    stories: 'Stories',
    addToFavorites: 'Add to Favorites',
    removeFromFavorites: 'Remove from Favorites',
    contactAgent: 'Contact Agent',
    toggleListView: 'Toggle List View',
    toggleGridView: 'Toggle Grid View',
    startingAt: 'Starting At',
    filterNotice: 'Remove filters or select another location to view more results.',
    min: 'Min',
    max: 'Max',
  },
  Generic: {
    ok: 'OK',
    cancel: 'Cancel',
  },
  PropertyUnit: {
    floorplan: 'Floorplan',
    viewFullGallery: 'View Full Gallery',
    pricePerSqft: 'PRICE/SQFT',
    photoGallery: 'Gallery',
    video: 'Video',
    unitViews: 'Unit Views',
    launch3dTour: 'Launch 3D Tour',
    tour3D: '3D Tour',
    totalLivingArea: 'Living Area',
    totalArea: 'Total Area',
    type: 'Type',
    removeFavorite: 'UnFavorite',
    favorite: 'Favorite',
    contact: 'Contact',
    shareUnit: 'Share Unit',
    requestTour: 'Request Tour',
    subject: 'Vysta Tour Request',
    wouldLikeATourOf: 'would like a tour of',
    backToList: 'Back to List',
    about: 'About',
    gallery: 'Gallery',
    views: 'Views',
    details: 'Details',
    contactAgent: 'Contact Agent',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    unitSize: 'Unit Size',
    pricing: 'Pricing',
    features: 'Features',
    finishes: 'Finishes',
    sqft: 'sq ft',
    unitDetails: 'Unit Details',
    favoriteThisUnit: 'Add To Favorites',
    unFavoriteThisUnit: 'Remove From Favorites',
    requestATour: 'Request A Tour',
    sendMessage: 'Send Message',
    available: 'Available',
    unit: 'Unit',
    downloadFloorplan: 'Download Floorplan',
  },
  ContactSheet: {
    requestTourSuccess: 'Your tour has been requested',
    requestATour: 'Request A Tour',
    contactSalesCenter: 'Contact Sales Center',
    contactAgent: 'Contact Agent',
    shareWithClient: 'Share Property With Client',
    favoriteProperty: 'Favorite This Property',
    unFavoriteProperty: 'UnFavorite This Property',
    messageSalesCenter: 'Message Sales Center',
    questions: 'Have Questions? Message the sales team here.',
    subject: 'Subject',
    message: 'Message',
    salesCenterSent: 'Message sent',
    messageSent: 'Message sent successfully',
    share: 'Share',
    withClient: 'With Client',
    favoriteThisUnit: 'Favorite This Unit',
    unFavoriteThisUnit: 'UnFavorite This Unit',
    sendMessage: 'Send Message',
    favoriteUnit: 'Favorite Unit',
    unFavoriteUnit: 'UnFavorite Unit',
    descriptions: {
      message: 'Got a question for me? Send me a message!',
      unit: `Have questions about Unit {{unit}}? Ask them here, and I'll get back to you as soon as possible.`,
      property: "Got a question about this property? Send me a message and I'll get back to you asap.",
      residence:
        "Need help choosing a residence? Let me know what you're looking for and I'll send you some suggestions.",
    },
  },
  Property: {
    mute: 'Mute Video',
    unmute: 'Unmute Video',
    editProperty: 'Edit Property',
    comingSoon: 'Coming Soon',
    property: 'Property',
    sendToClients: 'Send Property To Clients',
    skip: 'Continue to Property Details',
    searchResidences: 'Search Residences',
    viewDetails: 'View Details',
    pointsOfInterest: 'Points of Interest',
    viewAmenityList: 'View Amenity List',
    viewUnitList: 'View Unit List',
    contact: 'Contact',
    backToSearchResults: 'Back to Search Results',
    bedsAndBaths: 'Beds/Baths',
    status: 'Status',
    explore3d: 'Explore 3D',
    about: 'About',
    amenities: 'Amenities',
    availableUnits: 'Units Available',
    learnMore: 'Message Agent',
    any: 'Any',
    available: 'Available',
    buildingOverview: 'Overview',
    propertyTour: 'Property Tour',
    exploreIn3d: 'Explore in 3D',
    requestATour: 'Request A Tour',
    share: 'Share',
    shareProperty: 'Share Property',
    contactSalesCenter: 'Contact Sales Center',
    sendMessageTitle: 'Got a question about this property? Send',
    me: 'me',
    aMesage: 'a message!',
    sendMessage: 'Send Message',
    message: 'Message',
    subject: 'Subject',
    buildingType: 'Building Type',
    introduction: 'Introduction',
    tour: 'Property Tour',
    threeDTour: 'Property Tour',
    search: 'Residences',
    residences: 'Residences',
    models: 'Models',
    back: 'View More Properties',
    backToBuilding: 'Back to Building',
    description: 'Description',
    halfBaths: 'Half Baths',
    interiorDesign: 'Interior Design',
    seeIn3D: 'Click here to see in 3D',
    unitsAvailable: 'Units Available',
    unit: 'Unit',
    units: 'Units',
    size: 'Size',
    floors: 'Floors',
    built: 'Built',
    details: 'Details',
    neighborhood: 'Neighborhood',
    articlesPress: 'Articles & Press',
    salesMarketing: 'Sales & Marketing',
    developer: 'Developer',
    landscapeDesign: 'Landscape Design',
    architect: 'Architect',
    interior: 'Interior',
    all: 'All',
    exterior: 'Exterior',
    concierge: 'Concierge',
    fitnessCenter: 'Fitness Center',
    kitchen: 'Kitchen',
    greatRoom: 'Great Room',
    downtown: 'Downtown',
    imaginationSpace: 'Imagination Space',
    rooftopTerrace: 'Rooftop Terrace',
    saltwaterPool: 'Saltwater Pool',
    viewFullGallery: 'View Full Gallery',
    buildingAmenities: 'Building Amenities',
    floorPlans: 'Floor Plans',
    similarBuildings: 'Similar Buildings in',
    otherRecommendedUnits: 'Other Recommended Units in',
    otherUnits: 'Other Units in',
    foodAndDrink: 'Food & Drink',
    culture: 'Culture',
    fitness: 'Fitness',
    name: 'Name',
    bed: 'Bed',
    beds: 'Beds',
    bedroom: 'Bedroom',
    bedrooms: 'Bedrooms',
    bedAbr: 'bd',
    bathroom: 'Bathroom',
    bathrooms: 'Bathrooms',
    bath: 'Bath',
    baths: 'Baths',
    bathAbr: 'ba',
    view: 'View',
    views: 'Views',
    nOfBedrooms: '# of Bedrooms',
    sqft: 'sq ft',
    emptySimilarBuildings: 'There are no similar buildings to the one you are searching for.',
    emptyOtherRecommendedUnits: 'There are no other recommended units to the one you are searching for.',
    emptyOtherUnits: 'There are no other units to the one you are searching for.',
    price: 'Price',
    unitInfo: 'Unit Info',
    emptyFloorPlans: 'There are no floor plans within this property with this filter.',
    emptyAvailableUnits: 'There are no available units within this property with this filter.',
    floorPlan: 'Floorplan',
    residence: 'Residence',
    videos: 'Videos',
    video: 'Video',
    detailsFor: 'Details for ',
    intro: 'Introduction',
    gallery: 'Property Gallery',
    startingAt: 'Starting At',
    scrollForMore: 'View Property Details',
    factSheet: 'View Fact Sheet',
    brochure: 'View Brochure',
    min: 'Min',
    max: 'Max',
    reset: 'Reset Filters',
  },
  CreateProperty: {
    title: 'Create Property',
    successMessage: 'Your property has been created',
  },
  UpdateProperty: {
    title: 'Update Property',
    successMessage: 'Your property has been updated',
    removeTitle: 'Are you sure you want to delete this property?',
    removeSubTitle: 'This will remove this property from the system.',
    cancel: 'Cancel',
    confirm: 'Delete Property',
    deleteSuccess: 'Your property has been deleted',
  },
  PropertyForm: {
    landingPageMedia: 'Landing Page Background',
    landingPageName: 'Short Name',
    propertyName: 'Property Name',
    propertyType: 'Property Type',
    salesStatus: 'Sales Status',
    visibility: 'Visibility',
    constructionStatus: 'Construction Status',
    unitStatuses: 'Unit Statuses',
    tagline: 'Tagline',
    description: 'Description',
    saveButton: 'Save Property',
    image: 'Hero Image',
    address: 'Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    removeButton: 'Delete Property',
    developer: 'Developer',
    externalId: 'External ID',
    externalIds: 'Orbit Reel External IDs',
    label: 'Label',
    isPublished: 'Is Published',
    tags: 'Tags',
    videos: 'Video Gallery',
    hasOrbitReel: 'Has Orbit Reel',
    heroImages: 'Hero Images',
    factSheet: 'Fact Sheet',
    brochure: 'Brochure',
    pricingOptions: 'Pricing Options',
    startingAt: 'Starting At: Starting At $100,000',
    range: 'Range: $100,000 - $200,000',
    salesCenterEmail: 'Sales Center Email',
    salesCenterEmailInstructions: 'Add email address of sales center to activate contact form for agents',
    featureVideo: 'Feature Video',
    orbitReelTooLongError: 'All external ids must be less than 37 characters',
    orbitReelRequiredError: 'All external ids are required',
    orbitReelInvalidError: 'All external ids must be valid UUIDs',
  },
  ListProperties: {
    title: 'My Listings',
    addPropertyButton: 'Add New Property',
  },
  Contact: {
    header: 'Contact Us',
    title: 'Have a Question?',
    subtitle: "We'd love to hear from you. Send us a message and we'll respond as soon as possible.",
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone',
    brokerage: 'Brokerage',
    mlsId: 'Agent MLS ID',
    message: 'Message',
    success: 'Message sent successfully',
    failure: 'There was an error sending the message, Please try again later',
    required: 'Required',
  },
  TermsOfServices: {
    title: 'Terms & Conditions',
    sections: [
      {
        title: 'Your Acceptance of Terms',
        subsections: [
          `The following Terms of Use (“TOU”) are between you and Evolution Ventures, LLC (“Evolution”) and constitute a legal agreement that governs your use of the Vysta product, software, services and websites (together, the “Service”). You must agree to these TOU before you can use the Service. You agree to these TOU by actually using the Service. If you do not agree to any of the following terms, do not use the Service. You should print or otherwise save a copy of these TOU for your records.`,
          `Evolution’s Complete Control of the Services. Evolution reserves the right to do any of the following, at any time, without notice: (1) to modify, suspend or terminate operation of or access to the Service, or any portion of the Service, for any reason; (2) to modify or change the Service, or any portion of the Service, and any applicable policies or terms; and (3) to interrupt the operation of the Service, or any portion of the Service, as necessary to perform routine or non-routine maintenance, error correction, or other changes.`,
          `Updates. Evolution may update or change these TOU from time to time and recommends that you review the TOU on a regular basis. You can review the most current version of the TOU at any time at <a href="https://www.getvysta.com/terms-of-use">Terms of Use</a>​. If Evolution makes a change to the TOU, it will post the revised TOU on our website at the link noted above. You understand and agree that your continued use of the Service after the TOU has changed constitutes your acceptance of the TOU as revised.`,
        ],
      },
      {
        title: 'The Service',
        subsections: [
          `General Description of the Service. In general, the Service allows you to search and explore real estate-related information such as building information, available residential units and other information within a three-dimensional rendered environment of a geographic area. The Service allows you to access information provided by developers or their representatives and, at times, MLS/IDX data providers. The Service provides information only on a subset of buildings and available units within a particular geographic area. ​<span class="red-text"/>Evolution does not provide any real estate, brokerage, appraisal or other real estate- or real property-related services, and does not hold itself out as being registered, licensed or otherwise authorized to perform any such services. Evolution does not provide any advice regarding real property, real estate, real estate transactions, brokerages, appraisals or any other real estate- or real property-related matters. Evolution does not endorse, recommend or approve of any real estate agent or real property-related service provider who uses the Service. You should use your own judgment regarding the merits of any individual, entity or information that you find on or through the Service.</span>`,
          `System Requirements. Use of the Service requires the use of resource-intensive three dimensional rendering systems, certain software and Internet access for which separate service provider fees may apply. Some portions of the Service require ​ <b>either Google Chrome version___, Microsoft Explorer version__, Mozilla Firefox version __or Safari version _____</b> ​or later, available separately. Use of the Service may be affected by certain combinations of hardware, software and/or Internet access. High speed Internet access is strongly recommended.`,
          `Limitations on Use. You agree to use the Service only for purposes as permitted by these TOU and any applicable law, regulation, or generally accepted practice in the applicable jurisdiction.`,
          `Availability of the Service. The Service, or any feature or part thereof, may not be available in all countries and Evolution makes no representation that the Service, or any feature or part thereof, is appropriate or available for use in any particular location. To the extent you choose to access and use the Service, you do so at your own initiative and are responsible for compliance with any applicable laws, including, but not limited to, any applicable local laws. `,
        ],
      },
      {
        title: 'Your Use of the Service and Software',
        subsections: [
          `User Account. You must register on a Vysta Agent Portal to use the Service. Registration requires that you select a unique Subscriber ID (which may be your email address) and provide your name, a valid email address, and a password.`,
          `Account Security. Upon creating your Subscriber ID you will be required to select a password. Please choose a strong password and do not reveal your password to others. You agree that you are responsible for keeping your password confidential and secure, and further understand that you are solely responsible and liable for any activities that occur under your Subscriber ID.`,
          `No Conveyance. Nothing in these TOU shall be construed to convey to you any interest, title, or license in a Subscriber ID, domain name or similar resource used by you in connection with the Service.`,
          `Use of Location-based Services. Evolution may provide certain features or services through the Service that rely upon device-based location information. To provide such features or services, where available, Evolution may collect, use, transmit, process and maintain your location data, including the real-time geographic location of your device, and you hereby agree and consent to Evolution's and its partners' and licensors' collection, use, transmission, processing and maintenance of such location data to provide such services. In addition, by enabling or using any location-based services or features within the Service, you agree and
        consent to Evolution collecting, using, processing and maintaining information related to your account, and any devices registered thereunder, for purposes of providing such location-based service or feature to you. Such information may include, but is not limited to, your Subscriber ID, device ID and name, device type and real-time geographic location of your device at time of your request. You may withdraw this consent at any time by not using the location-based features or by turning off the location-based feature settings (as applicable) on your device. When using third party services that use or provide location data as part of the Service, you are subject to and should review such third party's terms and privacy policy on use of location data by such third party services. Neither Evolution nor any of its content providers guarantees the availability, accuracy, completeness, reliability, or timeliness of location data or any other data displayed by the Service.`,

          `Public Display: You may not use this Service as a public display or permanent display such as would be set up in an office, sales office or similar venue without prior written consent from Evolution. Please contact Evolution at <a href="mailto:​info@evolutionv.com">​info@evolutionv.com</a> or 561-400-4370 to learn more about Public Display packages available for purchase.`,
          `Client Announcements. Evolution reserves the right to publish new customer signups and positive feedback at our website and other public forums, such as newspapers, news websites, flyers, purchased print and electronic media, blogs and other media outlets. If you do not want Evolution to quote your positive experiences or announce your use of the Service you must notify Evolution in advance. You shall indemnify and hold harmless Evolution and its third party vendors against any and all claims, losses, damages, liabilities, costs and expenses (including reasonable attorneys fees) arising out of or relating to these announcements.`,
        ],
      },
      {
        title: 'Evolution Privacy Policy',
        subsections: [
          `You understand that by using the Service, you consent and agree to the collection and use of certain information about you and your use of the Service in accordance with Evolution’s Privacy Policy, as may be amended from time to time. You further consent and agree that Evolution may collect, use, transmit, process and maintain information related to your account, and any devices registered thereunder, for purposes of providing the Service, and any features therein, to you. Information collected by Evolution when you use the Service may also include technical or diagnostic information related to your use that may be used by Evolution to maintain, improve and enhance the Service. You further understand and agree that this information may be transferred to the United States and/or other countries for storage, processing and use by Evolution and/or its affiliates.`,
          `By using the Service, you acknowledge and agree that Service may access, preserve, and disclose your account information and any content associated with that account if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably
          necessary to: (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce the TOU, including investigation of potential violations hereof, (c) detect, prevent, or otherwise address fraud, security or technical issues (including, without limitation, the filtering of spam), or (d) protect against imminent harm to the rights, property or safety of the Service, its users or the public as required or permitted by law.`,
        ],
      },
      {
        title: 'Content and Your Conduct',
        subsections: [
          `Content. "Content" means any information that may be generated or encountered through use of the Service, such as data files, written text, software, graphics, photographs, images, sounds, videos, messages and other similar materials, user interfaces, visual interfaces, trademarks, logos, music, artwork, building models and their segmentation into units and computer code, including but not limited to the design, structure, selection, coordination, expression, “look and feel” and arrangement of the same. All Content associated with the Service is owned, controlled or licensed by or to Evolution, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws, as well as these TOU.`,
          `Limitations on Use of Content. Except as expressly provided in the TOU, no part of the Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, website or other medium for publication or distribution or for any commercial enterprise, without Evolution’s express prior written consent. You may use information made available to you only for your personal, non-commercial informational purpose. You may not copy or post such information on any networked computer or broadcast it in any media, and make no modifications to any such information or remove any notifications relating to the rights associated with such information.`,
          `You may not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Service, or in any way reproduce or circumvent the navigational structure or presentation of the Service or any Content, to obtain or attempt to obtain any information through any means not purposely made available through the Service. Evolution reserves the right to bar any such activity.`,
          `You may not attempt to gain unauthorized access to any portion or feature of the Service, by hacking, password “mining” or any other illegitimate means.`,
          `You may not probe, scan or test the vulnerability of the Service or any network associated with the Service, nor breach the security or authentication measures on the Service or any network associated with the Service. You may not reverse look-up, trace or seek to trace any
       information on any other user of the Service, or any other customer of Evolution, including any Evolution account not owned by you, to its source, or exploit information made available or offered by or through the Service, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Service.`,
          `You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure associated with the Service or Evolution’s systems or networks.`,
          `You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Service or any transaction being conducted on the Service, or with any other person’s use of the Service.`,
          `You may not use the Service or any Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Evolution or others.`,
          `This Service may include content from the Multiple Listing Service (MLS) and Internet Data Exchange (IDX), developers and their representatives and content generated by individual users on listings including including pictures, text, video, 360​o pictures, and other media. You understand that by using the Service you may encounter Content that you may find offensive, indecent, or objectionable, and that you may expose others to Content that they may find objectionable. Evolution does not control the Content posted via the Service, nor does it guarantee the accuracy, integrity or quality of such Content. You understand and agree that your use of the Service and any Content is solely at your own risk.`,
          `Your Conduct. You agree that you will NOT use the Service to:

          a. post, send, transmit or otherwise make available any unsolicited or unauthorized email messages, advertising, promotional materials, junk mail, spam, or chain letters, including, without limitation, bulk commercial advertising and informational announcements;

          b. upload, download, post, email, transmit, store or otherwise make available any Content that is unlawful, harassing, threatening, harmful, tortious, defamatory, libelous, abusive, violent, obscene, vulgar, invasive of another’s privacy, hateful, racially or ethnically offensive, or otherwise objectionable;

          c. pretend to be anyone, or any entity, you are not — you may not impersonate or misrepresent yourself as another person (Evolution reserves the right to reject or block any Subscriber ID which could be deemed to be an impersonation or misrepresentation of your identity, or a misappropriation of another person's name or identity);
          d. engage in any copyright infringement or other intellectual property infringement, or disclose any trade secret or confidential information in violation of a confidentiality, employment, or nondisclosure agreement;

          e. forge any TCP-IP packet header or any part of the header information in an email or a news group posting, or otherwise putting information in a header designed to mislead recipients as to the origin of any Content transmitted through the Service (“spoofing");

          f. upload, post, email, transmit, store or otherwise make available any material that contains viruses or any other computer code, files or programs designed to harm, interfere or limit the normal operation of the Service (or any part thereof), or any other computer software or hardware;

          g. interfere with or disrupt the Service (including accessing the Service through any automated means, like scripts or web crawlers), or any servers or networks connected to the Service, or any policies, requirements or regulations of networks connected to the Service (including any unauthorized access to, use or monitoring of data or traffic thereon);

          h. plan or engage in any illegal activity; or

          i. gather and store personal information on any other users of the Service to be used in connection with any of the foregoing prohibited activities.`,
          `Removal of Content. You acknowledge that Evolution is not responsible or liable in any way for any Content provided by others and has no duty to pre-screen such Content. However, Evolution reserves the right at all times to determine whether Content is appropriate and in compliance with these TOU, and may pre-screen, move, refuse, modify or remove Content at any time, without prior notice and in its sole discretion, if such Content is found to be in violation of these TOU or is otherwise objectionable.`,
          `Backup Your Content. You are responsible for backing up, to your own computer or other device, any important documents, images or other Content that you store or access via the Service. Evolution does not guarantee or warrant that any Content you may store or access through the Service will not be subject to inadvertent damage, corruption or loss.`,
          `Access to Your Account and Content. You acknowledge and agree that Evolution may access, use, preserve or disclose your account information and Content if legally required to do so or if we have a good faith belief that such access, use, disclosure, or preservation is reasonably necessary to: (a) comply with legal process or request; (b) enforce these TOU, including investigation of any potential violation thereof; (c) detect, prevent or otherwise address security, fraud or technical issues; or (d) protect the rights, property or safety of Evolution, its users or the public as required or permitted by law.`,
        ],
      },
      {
        title: 'Third Party Materials',
        subsections: [
          `Multiple Listing Service. Aspects of the Service may contain information provided in whole or in part by third-party Multiple Listing Services (“MLS”). Your use of such information is
              subject to the terms and conditions set forth by each individual MLS supplier, including MLS membership requirements. Evolution is not responsible for the accuracy, timeliness, completeness, functionality, usability or availability of information provided by any MLS data supplier.`,
          `New Development Listing Content. Listing content for new developments on this website is provided by real estate professionals or developers and/or their representatives. Prices, details and availability may change; please verify any information with a real estate professional or the developer. Evolution is not responsible for any errors or omissions. This is not an offer to sell.`,
          `Public Tax Information. Aspects of the Service may contain information gathered in whole or in part from publicly available Tax Appraisal resources. Evolution is not responsible for the accuracy, timeliness, completeness, or availability of such information.`,
        ],
      },
      {
        title: 'Disclaimer of Warranties',
        subsections: [
          `SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, AS SUCH, TO THE EXTENT SUCH EXCLUSIONS ARE SPECIFICALLY PROHIBITED BY APPLICABLE LAW, SOME OF THE EXCLUSIONS SET FORTH BELOW MAY NOT APPLY TO YOU.`,
          `YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. EVOLUTION AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. IN PARTICULAR, EVOLUTION AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS; (II) YOUR USE OF THE SERVICE WILL BE TIMELY, UNINTERRUPTED, SECURE OR ERROR-FREE; (III) ANY INFORMATION OBTAINED BY YOU AS A RESULT OF THE SERVICE WILL BE ACCURATE OR RELIABLE; AND (IV) ANY DEFECTS OR ERRORS IN THE SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICE WILL BE CORRECTED.`,
          `ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR LOSS OF
          DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM EVOLUTION OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOU.`,
          `THE THREE DIMENSIONAL ENVIRONMENT, TERRAIN AND BUILDINGS DEPICTED WITHIN THE SERVICE ARE ARTISTIC REPRESENTATIONS AND MAY DIFFER FROM THE ACTUAL ENVIRONMENT, TERRAIN AND BUILDINGS. THE LOCATION OF UNITS, AMENITIES OR FIXTURES WITHIN THE BUILDING ARE APPROXIMATE AND BUILDINGS, LANDMARKS AND UNITS MAY BE MISSING ALTOGETHER FROM THE SERVICE OR MAY BE CHANGED WITHIN THE SERVICE WITHOUT WARNING OR NOTICE TO YOU. NO PURCHASE DECISIONS SHOULD BE MADE BASED ON INFORMATION FROM THIS SERVICE.`,
          `EVOLUTION DOES NOT REPRESENT OR WARRANT THAT ANYONE ACTING AS A REALTOR® OR LICENSED REAL ESTATE PROFESSIONAL ACTUALLY HAS THOSE QUALIFICATIONS.`,
        ],
      },
      {
        title: 'Limitation of Liability',
        subsections: [
          `SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, AS SUCH, TO THE EXTENT SUCH EXCLUSIONS OR LIMITATIONS ARE SPECIFICALLY PROHIBITED BY APPLICABLE LAW, SOME OF THE EXCLUSIONS OR LIMITATIONS SET FORTH BELOW MAY NOT APPLY TO YOU. YOU EXPRESSLY UNDERSTAND AND AGREE THAT EVOLUTION AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES , INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSSES (EVEN IF EVOLUTION HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR INABILITY TO USE THE SERVICE (II) ANY CHANGES MADE TO THE SERVICE OR ANY TEMPORARY OR PERMANENT CESSATION OF THE SERVICE OR ANY PART THEREOF; (III) THE UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE AND/OR SEND OR RECEIVE YOUR TRANSMISSIONS OR DATA ON OR THROUGH THE SERVICE; (V) STATEMENTS OR CONDUCT OF ANY THIRD
              PARTY ON THE SERVICE; AND (VI) ANY OTHER MATTER RELATING TO THE SERVICE.`,
        ],
      },
      {
        title: 'Indemnity',
        subsections: [
          `You agree to defend, indemnify and hold Evolution, its affiliates, subsidiaries, directors, officers, employees, agents, partners and licensors harmless from any claim or demand, including reasonable attorneys’ fees, made by a third party, relating to or arising from: (a) any Content you submit, post, transmit, or otherwise make available through the Service; (b) your use of the Service; (c) any violation by you of these TOU; or (d) your violation of any rights of another. This obligation shall survive the termination or expiration of these TOU or your use of the Service. You acknowledge that you are responsible for all use of the Service using your account, including any use by Sub-accounts, and that these TOU apply to any and all usage of your account, including any use by Sub-accounts. You agree to comply with these TOU and to defend, indemnify and hold harmless Evolution from and against any and all claims and demands arising from usage of your account or any Sub-account, whether or not such usage is expressly authorized by you.`,
        ],
      },
      {
        title: 'Notices',
        subsections: [
          `Evolution may provide you with notices regarding the Service, including changes to these TOU, by email, by regular mail, or by postings on our website or the Service.`,
        ],
      },
      {
        title: 'Governing Law',
        subsections: [
          `Except to the extent expressly provided in the following paragraph, these TOU and the relationship between you and Evolution shall be governed by the laws of the State of Florida, and its conflicts of law provisions. You and Evolution agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Miami-Dade, Florida, to resolve any dispute or claim arising from these TOU.`,
        ],
      },
      {
        title: 'General',
        subsections: [
          `These TOU constitute the entire agreement between you and Evolution, govern your use of the Service and completely replace any prior agreements between you and Evolution in relation to the Service. Notwithstanding the foregoing, in the event of any ambiguity or inconsistency between the TOU and the terms of any terms of service (“TOS”) agreement between you and Evolution, the terms of such TOS shall govern. You may also be subject to additional terms and conditions that may apply when you use affiliate services, third-party content, or third-party software. If any part of these TOU is held invalid or unenforceable, that portion shall be
          construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect. The failure of Evolution to exercise or enforce any right or provision of these TOU shall not constitute a waiver of such right or provision.`,
        ],
      },
    ],
  },
  PrivacyPolicy: {
    title: 'Privacy Policy',
    sections: [
      {
        title: '',
        subsections: [
          `<em>Effective: June 1, 2020</em>`,
          `The Vysta website getvysta.com and its subdomains (collectively, the “Website”) is published and maintained by Evolution Ventures, LLC and its subsidiaries and affiliates (collectively referred to in this Privacy Policy as “the Company”, “we”, “us”, “our”, or other similar pronouns).`,
          `
          We respect the privacy of each User of the Website (referred to as “you”, or “a User”). This Privacy Policy gives you specific information about how we protect your privacy, how we treat information we collect on the Website that identifies an individual User, and how we use aggregated information.`,
          `This Privacy Policy governs your use of the Website on computer and mobile devices. By visiting this Website, providing personal information, and/or using the services offered on or through this Website (the “Services”), you agree to the terms of this Privacy Policy as they may be amended from time to time.`,
          `As we update and expand our services, this Privacy Policy may change. When we make changes to this Privacy Policy, we will post the new Privacy Policy here and will update the Effective Date above. Please check back to this page from time to time. This Privacy Policy is incorporated into, and part of, the Company’s Website Terms and Conditions of Use, which governs your use of this Website in general.`,
          `You may opt in or opt out of our sharing of your Personal Information with third parties for their marketing purposes by following the directions in the “Marketing Preferences” section below.`,
        ],
      },
      {
        title: 'Purpose',
        subsections: [
          `The Company takes your privacy very seriously. In order to make your visits to our Website and use of the Services available through our Website as beneficial as possible, we may ask you for Personal
           Information and we may collect certain information about you and your interaction with this Website from you and your computer each time you visit our website. “Personal Information” includes, for example, your name; title; home and/or business address; e-mail address; home and/or business telephone, mobile and/or fax number; demographic information; your location; and/or other information that may identify you as an individual or allow online or offline contact with you as an individual.`,
          `This Privacy Policy explains, in general, what Personal Information and other information is collected on our Website, how the information is used, and with whom we may share such information. Please take a few minutes to read our Privacy Policy so that you understand how the Company treats your information.`,
        ],
      },
      {
        title: 'Marketing Preferences',
        subsections: [
          `You have the right to “opt in” and to “opt out” of certain uses of your Personal Information and may change your preferences regarding such uses at any time. For example, at the time you are requested to provide Personal Information on this Website, you may have the opportunity to elect whether or not to: (1) receive commercial correspondence from us (either on our behalf or on behalf of a third party), or (2) have your Personal Information shared with other entities (outside of the Company) for their marketing purposes. You may also opt-out of Company promotional e-mails by clicking on the opt-out link within the e-mail you receive, or of Company postal mailings by following the directions in such mailings. Please be aware that it may take up to 10 business days for Company to update your preferences. During that time, you may continue to receive messages from us.`,
          `Please understand that if you opt-out of receiving promotional correspondence from us, we may still contact you in connection with your relationship, activities, transactions and communications with us (including, for example and without limitation, alerts, favorites and market condition reports, in connection with your use of the Website (as defined above). You may control how you receive such
          non-promotional correspondence from us by updating your account preferences. Also, a request to have us stop sharing your Personal Information with other entities for marketing purposes will only apply as of the date on which we receive your request, and we will not be responsible for any communications that you may receive from affiliates that received your Personal Information prior to such request. In these cases, please contact those affiliates directly.`,
        ],
      },
      {
        title: 'Information of Children',
        subsections: [
          `Our Website and Services are not intended to be used by children, nor do we knowingly collect or process any personal information of Users under the age of 16. If you believe we may have collected information of anyone under the age of 16, please notify us at <a href="mailto:​​info@getvysta.com​">​info@getvysta.com​</a> so that we may delete the information.`,
        ],
      },
      {
        title: 'What Information Is Collected On This Website?',
        subsections: [
          `<u>User-Provided Information</u>`,
          `We collect Personal Information that Users voluntarily provide through our Website, for example, through such Users’ activities, transactions and completion of online forms on our Website. You may choose not to provide certain information; however, certain features of the Website may not function properly. We collect the following information:`,
          `<ul>`,
          `<li> When you sign up for an account on our Website, we collect your name, email address, and your user-created password. If you choose to create an account via Facebook, we collect your Facebook username. We may also collect other account information, such as your property search criteria or which properties you viewed or saved. </li>`,
          `<li> If you contact us using our online contact form, we collect your name, email address, telephone number, and the content of your message.</li>`,
          `<li> We collect information about your social media account when you share our Website or comment on our blog via social media. You may be able to send information about our Services to your friends and family members through our Website by clicking on a “Share” or similar button on our Website or in an e-mail that we have sent you. In some of these
  cases (unless you simply forward our e-mail on your own), we may collect the e-mail address of your friend or family member. </li>`,
          `</ul>`,
          `<u>Cookies</u>`,
          `If you have permitted your computer to accept “cookies,” we may use “cookies” to keep, and sometimes track, information about you. Cookies are small data files that are sent to your browser or related software and apps, from a Web server and stored on your mobile device, or computer’s hard drive. Cookies track where you travel on our Website and what you look at, transact and favorite. They may store the information in your account, and/or your Username and/or password, and we may use them to deliver specific messages to you (for example, to announce a new property that was added since the last time you visited the Website). A cookie may enable us to link your use of our Website to other information about you, including your Personal Information. All of these purposes serve to improve and personalize your experience on our Website.`,
          `Most Web browsers can be set to inform you when a cookie has been sent to you and provide you with the opportunity to refuse that cookie. However, refusing a cookie may, in some cases, preclude you from using, or negatively impact the display or function of, the Website or certain areas or features of the Website.`,
          `<u>Access Device and Browser Information</u>`,
          `Even if you do not register with us, when you access the Website from a mobile phone, or other mobile device, we may collect information from that device about your browser type, location, and Internet Protocol (IP) address, as well as the pages you visit on our Website and other websites you visit before and after interacting with our Website. On a mobile device, we may utilize client device-generated identifiers, statistical IDs, and your unique login.`,
          `We may aggregate your anonymous Website usage data with the data of other Users in order to determine trends and improve our Website.`,
        ],
      },
      {
        title: 'How We Use Your Information',
        subsections: [
          `<ul>`,
          `<li><u>Provide Services to You</u>. ​We use your information to provide the Services specifically requested by you, create and manage your User account, display properties you saved, provide property information you requested, provide our home valuation and mortgage calculator tools, allow you to share property information, and provide other services offered through our Website.</li>`,
          `<li><u>Improve Our Website</u>. ​We use your personal and anonymous information to diagnose technical problems, audit use of, customize, and improve our Website.</li>`,
          `<li><u>Communicate With You</u>. We use your personal information to send you information about our Website and Services, provide notice of any updates to or Website or Services, send you newsletters, and respond to any communications you send to us..</li>`,
          `<li><u>Marketing</u>. We may use your information to send you advertisements about new products or services we offer or new features of our Websites or Services. You may be able to opt-out of marketing communications from us. See the “Marketing Preferences” section of this Privacy Policy above.</li>`,
          `<li><u>Targeted Advertising</u>. We may use your information to provide advertisements for third-party products or services that we think might be of interest to you based on your browsing history. See the <u>Targeted​ Advertising; Tracking</u> ​section of this Privacy Policy for more information.</li>`,
          `<li>We may use your information to analyze the security of our Website and to help detect and resolve a security incident.</li>`,
          `<li>We use your information to audit compliance with or to enforce our Terms of Use, other Company policy, or applicable law.</li>`,
          `</ul>`,
        ],
      },
      {
        title: 'Disclosure of Your Personal Information to Third Parties',
        subsections: [
          `We disclose your personal information to third-parties, including our affiliates and unaffiliated third-parties, for legitimate business purposes. We may disclose your personal information:`,
          `<ul>`,
          `<li>to organizations that provide Services on our behalf, such as sending you email, analyzing data, providing marketing, providing search results and links, displaying content on our Website, tracking Website analytics, and providing customer service. These organizations are only provided the personal information necessary to carry out intended service.</li>`,
          `<li>to legal authorities when necessary to comply with a court order or subpoena.</li>`,
          `<li>to protect our Website and our rights under our Terms of Use, or to detect fraudulent or unauthorized activity on our Website.</li>`,
          `<li>in connection with a sale, merger, acquisition, or reorganization of our Company.</li>`,
          `</ul>`,
        ],
      },
      {
        title: 'Third Party Websites',
        subsections: [
          `When you are on our Website, you may have the opportunity to visit, or link to, other sites not operated by the Company, including other Websites operated by Company affiliates, or by unaffiliated third parties. These sites may collect Personal Information about you. The Company does not control sites that are operated by these entities and is not responsible for the information practices of these sites. This Privacy Policy does not address the information practices of those other Websites. We encourage you to read the Privacy Policies of any third-party websites you visit. Questions about the information collection practices of a third-party website should be directed to the third-party website administrator.`,
        ],
      },
      {
        title: 'Targeted Advertising; Online Tracking',
        subsections: [
          `We may, or we may allow third-parties to display targeted advertising (also sometimes referred to as behavioral advertising) on our Website. Using cookies, JavaScript, clear gifs, flash cookies, or similar technology, we gather information about your browsing activity over time and across third party sites and use that information to display personalized targeted advertising for products or services that we think may be of interest to you. In order to provide you with relevant advertising, our Website does not respond to “Do Not Track” browser settings.`,
        ],
      },
      {
        title: 'Our Privacy Commitment',
        subsections: [
          `<li>We don’t sell your Personal Information to anyone for any purpose. Period.</li>`,
          `<li>We keep your Personal Information in our business records while you are a User or Customer, or until it is no longer needed for business, tax or legal purposes. When your Personal Information is no longer needed, we will delete or anonymize your information if permitted by law.</li>`,
          `<li>We will keep your information safe using industry standard physical and technical safeguards, such as encryption or other appropriate security controls. However, you should know that no transmission of data over the internet is 100% secure. Although we do our best to protect your information, we cannot guarantee the security of any personal information you transmit to us, and you do so at your own risk. You are encouraged to keep your account password secure and confidential in order to help prevent against any unauthorized use of your account.</li>`,
        ],
      },
      {
        title: 'International Users',
        subsections: [
          `Our Website is operated from our facility within the United States of America (USA) but may be accessed from anywhere in the world. We make no representation and take no responsibility for any applicable laws and regulations outside the USA. Data security laws in the USA may not offer as much protection as the laws in your country of residence. By providing any information to us, you consent to the transfer of your information to, and the storage of your information on, servers located in the USA. Persons who choose to access the Website from locations outside the USA do so at their own risk.`,
        ],
      },
      {
        title: 'California Privacy Rights',
        subsections: [
          `Under California law, California residents have certain rights with respect to their personal information.`,

          `<li>California residents have the right to request in writing from businesses with whom they have an established business relationship: (1) a list of categories of personal information, such as name, address, e-mail address, and the type of services provided to the User, that a business has disclosed to third parties [including affiliates that are separate legal entities] during the immediately preceding calendar year for the third parties’ direct marketing purposes and (2) the names and addresses of all such third parties. We will respond to such requests within thirty (30) days of receipt. Please note that we are only required to respond to each User once per calendar year.</li>`,
          `<li>California residents may also request from businesses with whom they have an established business relationship, for the preceding twelve (12) month period: (1) the
        categories of information that a business has collected about the User, (2) the categories of sources from whom the information was collected, (3) the business purposes for collecting the User’s information, (4) the categories of third parties with whom the information has been shared, and (5) the specific pieces of information the business collected.</li>`,
          `We will respond to verified requests within forty-five (45) days of receipt. Please note that we are only required to respond to each User twice per twelve (12) month period. You can request that we delete your personal information. If no legal basis exists for us to keep your information, we will delete it within forty-five (45) days of receiving your verifiable request.`,
          `<li>To make any of the above requests, please contact us using the contact information provided at the end of this Privacy Policy. The information will be provided to you free of charge. We will not discriminate against you for exercising your rights under this section.</li>`,
        ],
      },
      {
        title: 'Agreement to policy',
        subsections: [
          `
          By using our Website or sending us any Personal Information, you consent to this Privacy Policy, including your consent to our use and disclosure of information about you in the manner described in this Privacy Policy.
          `,
          `Please note that your use of our Website is also governed by our <a href="https://www.getvysta.com/terms-of-use">Terms​ of Use</a>​ and <a href="https://www.getvysta.com/cookies-policy">Cookie​ Policy</a>​.`,
        ],
      },
      {
        title: 'Update Your Information',
        subsections: [
          `If you wish to verify or change the information in our files, you may contact us at <a href="mailto:​​info@getvysta.com​">info@getvysta.com</a>.​Your request must include sufficient details to help us locate your information.`,
        ],
      },
      {
        title: 'Contact Us',
        subsections: [
          `You may also send mail to us with any comments or questions, or to make a request as permitted by this Privacy Policy, at:`,
          `<em>Evolution Ventures, LLC </em>`,
          `<em>1 North State Street, Suite 904 </em>`,
          `<em>Chicago, IL 60614 </em>`,
          `<em>(561)-400-4370 </em>`,
        ],
      },
    ],
  },
  CookiesPolicy: {
    title: 'Cookies Policy',
    sections: [
      {
        title: 'INTRODUCTION',
        subsections: [
          `Evolution Ventures, LLC (“Evolution” or “we”) is a real estate services website. We provide a 3D-enhanced search and marketing for residential properties.`,
          `This GDPR Privacy Notice (this “Notice”) provides information about how we collect, use, and share your personal information as required by the European Union’s General Data Protection Regulation (the “GDPR”) including advising you of certain rights and choices you have with respect to our handling of that information. This includes through websites we operate that link to this Notice, in our email, phone and other communications with you, and in providing our services to you and operation of our business generally. Personal information means information that identifies you or that pertains and can be linked to you.`,
          `This Notice does <u>not</u> ​apply to any other website or application not linking to this Notice. This Notice applies to individuals whose personal information is governed by the GDPR, typically permanent residents of the European Economic Area.`,
          `It is important that you read this Notice together with any other privacy policy or notice we may provide on our websites or on specific occasions when we are collecting or processing personal information about you so that you are fully aware of how and why we are using your information. This Notice supplements other notices and privacy policies and is not intended to override them.`,
        ],
      },
      {
        title: 'HOW WE COLLECT PERSONAL INFORMATION',
        subsections: [
          `<u>We collect information that you give us​</u> through our websites, mobile apps, over the phone, via email, and through other correspondence or communications. For such directly and deliberately submitted information, you generally decide how much information to share with us, but not providing certain information may prevent us from fulfilling your request or otherwise performing the purpose for which the information is collected.`,
          `<u>We may obtain information about you from other sources.​</u> For example, we may receive personal information from an Agent listing you as a client or from publicly available sources. And, when you create an account on our website using your Facebook credentials, we collect certain information from Facebook as set by your permissions for our websites within your Facebook account. Additionally, our websites may permit you to share our posts and other content through your accounts on social media sites such as Facebook, LinkedIn, Twitter and others in which case we may be notified of such activity. You should review the privacy policy of those services, which governs your sharing activity through their service.`,
          `<u>We collect information automatically.</u>​ By visiting and interacting with our websites and mobile applications, we collect certain technical data by automated means. In most cases, this information is collected through web server logs or through the use of cookies and related technologies. You can read about how we use cookies and your choices in limiting our use of them in our general <a href="https://www.getvysta.com/privacy-policy">Privacy Policy</a>.`,
        ],
      },
      {
        title: 'TYPES OF PERSONAL INFORMATION WE COLLECT',
        subsections: [
          `In general, we collect limited amounts of technical information to optimize and allow our websites to function, your name and contact information, information you share about your property interests and search criteria.`,
          `More specifically, we collect the following personal information:`,
          `<li>When you visit our websites [or download and use or mobile applications], we collect technical data such as your IP address or other device identifiers, browser type and version, operating system, and analytic information about your activity on the website [or application].</li>`,
          `<li>When you register on one or more of our websites so that, for example, you may save your property searches or receive notifications of new listings or when you request other information, we collect your basic contact information such as your first and last name and email address and such other contact information you share with us as well as the password you provide (which is hashed or encrypted). In conducting or saving a property search on our websites, you provide us with information about your search criteria and interests.</li>`,
          `<li>When we collect your personal information in the context of operation of our business, such as when we collect your information as a billing contact.</li>`,
          `Personal information we collect from third parties such as from an Agent or in the course of brokering a Transaction is consistent with the information described above.`,
        ],
      },
      {
        title: 'PURPOSES FOR PROCESSING YOUR INFORMATION',
        subsections: [
          `When you visit our websites [or use our mobile applications], we process automatically collected technical data in order to make the websites function and optimized for your device and also to collect aggregated analytical data about usage of our websites by using Google Analytics, although we have enabled Google Analytics’ anonymizing feature. You can read our general Privacy Policy for more information about how we use cookies.`,
          `When you search for properties or request notifications of listings based on certain criteria, sign up for our newsletter or blogs or make similar requests for information, we process the information collected in order to provide you with the requested service or information. Where we receive your consent to do so, we may also use this information to send you marketing information, including property listings, we believe may be of interest to you.`,
          `When you register with our website, we collect your information including a password for purposes of establishing your online account. In certain cases when you request information, we may automatically generate an account and issue a temporary password, which we email to the email address you provide.`,
          `When we serve as a broker in your Transaction, we use the information collected for the purpose of performing our services as broker in connection with the Transaction, including performing any legal and regulatory obligations we may have.`,
          `Additionally, we may use your personal information in the operation of our business generally, which may include the following:`,
          `<li>To respond to and process your specific inquiries including support services and to provide you with information and access to resources that you have requested from us;</li>`,
          `<li>To administer, protect, operate, and maintain our websites and our systems and networks;</li>`,
          `<li>To improve the navigation and content of our websites, system administration and security;</li>`,
          `<li>To compile aggregated statistics about usage of our websites and to better understand the preferences of website visitors;</li>`,
          `<li>To help personalize and optimize the experience on our websites;</li>`,
          `<li>To help deliver information about our offerings to our clients and prospective clients;</li>`,
          `<li>To update you about changes in our privacy practices, terms of use and other matters pertaining to our relationship with you;</li>`,
          `<li>To disseminate our blogs, newsletters and similar communications;</li>`,
          `<li>To analyze and develop new products, services, and websites or improve existing ones;</li>`,
          `<li>To perform accounting, auditing, billing, reconciliation, collection, and similar administrative activities;</li>`,
          `<li>To prevent, detect, identify, investigate, respond to, and protect against potential or actual claims, liabilities, prohibited behavior, and criminal activity; and</li>`,
          `<li>To comply with and enforce applicable legal requirements, agreements, and policies.</li>`,
        ],
      },
      {
        title: 'LEGAL BASIS FOR PROCESSING YOUR INFORMATION',
        subsections: [
          `Our legal basis for collecting and using your personal information will depend on the personal information concerned, the specific context in which we collect it and the purposes for which it is used.`,
          `However, we will normally collect and use your personal information only where we have your consent to do so (typically in order to send you marketing communications), where we need the personal information to create or perform a contract with you (for example, where we serve as broker for your Transaction or where you have agreed to our Terms of Use in using our websites), or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms (for most of our activities not covered by consent or creation or performance of a contract). In some very rare cases, we may also have a legal obligation to collect or use your personal information to protect your vital interests or those of another person.`,
          `If you have specific questions about or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided under the “How to Contact Us” heading below.`,
        ],
      },
      {
        title: 'IF YOU FAIL TO PROVIDE PERSONAL INFORMATION',
        subsections: [
          `Where we need to collect personal information by law, or under the terms of a contract we have with you, and you fail to provide that information when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to serve as a broker in connection with your Transaction). In this case, we may have to cancel our services to you, but we will notify you if this is the case at the time. Please note that sales of properties are often subject to strict deadlines and timeframes. Failure to provide necessary personal information when required could cause a Transaction to fail to be consummated.`,
        ],
      },
      {
        title: 'HOW WE SHARE YOUR INFORMATION',
        subsections: [
          `Where applicable, we share your personal information in the follow ways:`,

          `<li>Within our group of affiliated companies (e.g. parent, subsidiaries) consistent with this Notice.</li>`,
          `<li>With our contracted service providers who perform services for us such as web hosting, cloud applications and infrastructure providers, communications, and technical services, marketing and relations management services, investigations and accounting services, customer support, and bill collection services. Such providers are obligated to only use the information as necessary to provide the contracted services to us.</li>`,
          `<li>If we believe disclosure is necessary and appropriate to prevent physical, financial, or other harm, injury, or loss, including to protect against fraud or credit risk or to pursue collections efforts or other legal rights and interests.</li>`,
          `<li>With legal, governmental, or judicial authorities, as instructed or required by those authorities or applicable laws, or in relation to a legal activity, such as in response to a subpoena or investigating suspected illicit activity. We reserve the right to report to law enforcement agencies activities that we in good faith believe to be illegal.</li>`,
          `<li>In connection with, or during negotiations of, an acquisition, merger, asset sale, or other similar business transfer that involves substantially all of our assets or functions where personal information is transferred or shared as part of the business assets (provided that such party agrees to use such personal information in a manner that is consistent with this Notice).</li>`,
        ],
      },
      {
        title: 'COOKIES',
        subsections: [
          `We use cookies, which are small text files placed on your device, to enable you to create and log in to your account on our websites. These are session cookies and are automatically deleted when you close your browser. You can find out more about how we use cookies in our Privacy Policy.`,
        ],
      },
      {
        title: 'PERSONAL INFORMATION IS PROCESSED IN THE UNITED STATES',
        subsections: [
          `Evolution has its headquarters in the United States and information we collect from you in other countries is transferred to and processed in the United States. We endeavor to apply suitable safeguards to protect the privacy and security of your personal information consistent with the practices described in this Notice.`,
        ],
      },
      {
        title: 'YOUR PRIVACY RIGHTS AND CHOICES',
        subsections: [
          `You may avoid our collection and use of manually submitted personal information by not providing it to us, although this may prevent us from providing the information and services you request.`,
          `Within your account profile on our websites, you may add and edit personal information associated with your account, manage certain email subscriptions, save and manage your property searches and favorite properties, connect your account to your Facebook account, and manage other information that you choose to store within your account, which generally will pertain to your property searches.`,
          `You may unsubscribe from our email communications and information services by following the instructions or unsubscribe mechanism in the e-mail message or by contacting us at the contact information provided below. Note, if you choose to opt out of such communications, we may still retain and use your personal information for transactional, administrative or other purposes.`,
          `You have the right to <u>access</u> or <u>correct</u> the personal information we maintain about you and, in some circumstances, you may require that we <u>erase</u> the information, or you may <u>object</u> to or request that we <u>restrict</u> our processing of the information. You have the right to <u>withdraw consent</u> where we rely on your consent for use of your personal information. In certain cases, you may have the right to have us <u>transfer​ your personal</u> information to you or a third party in a common, useable electronic format.`,
          `Additionally, you may have the right to <u>lodge a complaint</u> with your national Data Protection Authority or equivalent regulatory body.`,
          `You may exercise certain of these rights, such as correcting certain of your personal information, by doing so through your account on our websites. You may <u>withdraw​ consent to marketing</u>​ and certain automated email communications by clicking the unsubscribe link or following the unsubscribe instructions in the communication or in certain cases, by adjusting your preferences within your website account.`,
          `For other exercise of your rights or to ask questions or convey any complaints or concerns about our collection and use of your personal information, you may contact us at the contact information below.`,
        ],
      },
      {
        title: 'SECURITY OF YOUR INFORMATION',
        subsections: [
          `We maintain reasonable and appropriate security measures to protect your personal information from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.`,
        ],
      },
      {
        title: 'OUR RETENTION OF YOUR PERSONAL INFORMATION',
        subsections: [
          `We will retain your personal information for the period necessary to fulfill the purposes outlined in this Notice unless a longer retention period is required or permitted by law, for legal, tax or regulatory reasons, or other legitimate and lawful business purposes.`,
          `If you have questions about our retention for a specific purpose or context, you may contact as the contact information below.`,
        ],
      },
      {
        title: 'THIRD PARTY LINKS',
        subsections: [
          `Our websites may provide links to or the ability to connect with third party websites, services, social networks, applications or social media features such as “Share” or “Like” buttons. Visiting those sites or clicking on those links may allow the third party to collect and use information about you. We recommend that you review the privacy notices and terms of use of such third parties prior to interacting with them or providing them with your personal information. Evolution does not control the use of your personal information by these third parties.`,
        ],
      },
      {
        title: 'CHANGES AND UPDATES TO THE PRIVACY NOTICE',
        subsections: [
          `We reserve the right to change, modify, add or remove portions of this Notice from time to time and in our sole discretion, but will alert you that changes have been made by indicating on this Notice the date it was last updated. When you visit our websites or provide us your personal information under circumstances covered by this Notice, you are accepting the current version of this Notice as posted at that time. We recommend that you revisit this Notice on occasion to learn of any changes.`,
        ],
      },
      {
        title: 'IDENTITY OF CONTROLLER; HOW TO CONTACT US',
        subsections: [
          `Evolution Ventures, LLC is the controller of the personal information we collect and process.`,
          `You may contact us about matters pertaining to this Notice at the following:`,
          `By Email: <a href="mailto:​​info@evolutionv​.com">info@evolutionv​.com</a>`,
          `By Mail:`,
          `Attn: Evolution Ventures, LLC`,
          `1 North State Street, Suite 1500`,
          `Chicago, IL 60614`,
          `561-400-4370`,
        ],
      },
    ],
  },
  PostingTermsAndConditions: {
    myCompany: 'My Company',
    title: 'VYSTA Posting Terms & Conditions',
    subsections: [
      `1.  I, as a representative of <b>__COMPANY_NAME__</b> ("my company/organization"), the developer/broker for <b>__BUILDING_NAME__</b>, have legal authority to give Evolution and its VYSTA platform permission to use marketing materials for <b>__BUILDING_NAME__</b>, including descriptions, images, floor plans, photos, video, data, text, inventory details, availability, pricing and other content (“Property Materials”). `,

      `2.  Approval. I have reviewed the VYSTA Property Posting for <b>__BUILDING_NAME__</b> and approve its use.  Furthermore, I grant Evolution permission to use, copy, distribute, transmit, publicly display, publicly perform and translate the Property Materials for <Property> in connection with the VYSTA service.  Evolution may remove the VYSTA Property Posting or Property Materials at any time.  `,

      `3. Representation. For all Property Materials that are within the VYSTA Property Posting for <b>__BUILDING_NAME__</b>, I represent and warrant that I or my company/organization is the creator and owner of the Property Materials, or have the necessary licenses, rights, consents, and permissions (including all permissions required under applicable privacy and intellectual property law) to authorize Evolution and its users to access and use the Property Materials within the VYSTA platform. `,

      `4. Edits and Removal. Edits to or removal of a Property Posting can be requested at any time through an email to your VYSTA Project Manager (<a href="mailto:​info@getvysta.com">​info@getvysta.com</a>).  Edits or Removal will be done on a first come, first serve basis as  resources are available.  Removals will occur within 10 business days of written request.  The approval I give today will apply to the Property Posting even after Evolution implements any changes I direct and provide in the future.  `,

      `5. Indemnification. I indemnify and hold harmless Evolution from and against any costs and liabilities incurred by Evolution resulting from or arising out of third-party claims alleging that any element of Property Materials infringes any trademark, copyright, patent, trade secret or other intellectual property right.  `,

      `6.  Governing Law.  This Agreement is made subject to and shall be construed under the laws of the State of Illinois, without regard to its conflicts of laws principles. `,

      `7. Waiver of Jury Trial. I HEREBY WAIVE MY RIGHT TO A JURY TRIAL IN CONNECTION WITH ANY DISPUTE OR LEGAL PROCEEDING ARISING OUT OF THIS AGREEMENT OR THE SUBJECT MATTER HEREOF.`,
    ],
  },
  PropertyAdmin: {
    Organizations: {
      property: 'Property',
      organizations: 'Organizations',
      name: 'Name',
      type: 'Types',
      removeAssociation: 'Remove Association',
      addNewAssociation: 'Add New Association',
      associate: 'Associate',
      Form: {
        successRemove: 'The association was removed with success',
        errorDeveloper: 'A property must contain at least one developer association',
        successAssociate: 'The organization has been associated',
      },
    },
    PointsOfInterest: {
      title: 'Points of Interest',
      address: 'Address',
      remove: 'Remove Point of Interest',
      associate: 'Associate Point of Interest',
      add: 'Add new Point of Interest',
      update: 'Update Point of Interest',
      addSuccess: 'New point of interest added successfully',
      addError: 'Error adding your point of interest',
      removeSuccess: 'Point of interest deleted successfully',
      removeError: 'Error deleting your point of interest',
      addButton: 'Add Point of interest',
      updateButton: 'Update Point of interest',
      name: 'Name',
      category: 'Category',
      removeTitle: 'Remove Point of Interest?',
      removeSubTitle: 'Are you sure you want to remove this?',
      updateSuccess: 'Point of interest updated successfully',
      updateError: 'There was an error updating your point of interest',
    },
  },
  CreateUnitModel: {
    title: 'Create Unit Model',
    success: 'Your unit model has been added',
  },
  UpdateUnitModel: {
    title: 'Update Unit Model',
    success: 'Your unit model has been saved',
  },
  UnitModelForm: {
    title: 'Create Unit Model',
    saveButton: 'Save Unit Model',
    name: 'Unit Model Name',
    bathrooms: 'Bathrooms',
    bedrooms: 'Bedrooms',
    description: 'Description',
    exteriorSquarefeet: 'Exterior Size',
    interiorSquarefeet: 'Interior Size',
    maximumPrice: 'Maximum Price',
    minimumPrice: 'Minimum Price',
    derivePrice: 'Derive Price',
    derivePriceFromUnits: 'Derive Price from Associated Units (will override min and max above)',
    view: 'View',
    success: 'Your unit model has been added',
    component: 'Component',
    amenities: 'Amenities',
    images: 'Images',
    ceilingHeight: 'Ceiling Height',
    halfBathrooms: 'Half Baths',
    floorPlan: 'Floor Plan',
  },
  CreateUnit: {
    title: 'Create Unit',
    success: 'Your unit has been added',
  },
  UpdateUnit: {
    title: 'Update Unit',
    success: 'Your unit has been saved',
  },
  UnitForm: {
    id: 'ID',
    title: 'Create Unit',
    saveButton: 'Save Unit',
    name: 'Unit Name',
    success: 'Your unit has been added',
    property: 'Property',
    floor: 'Floor',
    unitModel: 'Unit Model',
    unitStatus: 'Unit Status',
    isPublished: 'Is Published',
    price: 'Price',
    images: 'Images',
    unitViews: 'Unit Views',
    walkThroughUrl: '3D Walkthrough URL',
    walkThrough: '3D Walkthrough',
    videos: 'Videos',
    panoramics: 'Panoramic',
  },
  ListUnits: {
    title: 'Units',
    createButton: 'Create Unit',
    empty: 'There are no units for this property',
    name: 'Name',
    removeTitle: 'Are you sure you want to remove this unit?',
    removeSubTitle: 'This cannot be undone',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your unit has been removed',
    removeTitlePlural: 'Are you sure you want to remove these units?',
    deleteSuccessPlural: 'Your units have been removed',
    searchPlaceholder: 'Search by name',
  },
  PropertiesAdmin: {
    approvedMessage: 'Your Listing has been approved and will now be part of VYSTA search results.',
    report: 'Property Report',
    back: 'Back to Listings',
    preview: 'Preview Property',
    unitModels: 'Unit Models',
    components: 'Components',
    property: 'Property Details',
    keyplans: 'Key Plans',
    units: 'Units',
    amenities: 'Amenities',
    floors: 'Floors',
    media: 'Media',
    propertyReport: 'Property Report',
    approval: 'Posting Approval',
    approvalSubtitle: [
      'As a property administrator, you must formally approve your listing and agree to Vysta’s posting terms and conditions',
      'Please click on the “Approve Listing” button below to grant posting permission.',
    ],
    approvalAgreement: {
      start: 'I, ',
      approve: ' approve the VYSTA Property Posting for ',
      permission: ' and give permission for Evolution to make it available for all VYSTA users to see as per the ',
      conditions: 'VYSTA Property Posting Terms & Conditions.',
    },
    approvalButton: 'Approve Listing',
    members: 'Members',
    inviteDeveloper: 'Invite Developer',
    inviteMember: 'Invite Member',
    invite: 'Invite',
    peopleYouCan: 'Users you can invite to this property.',
    noExistingUsers: 'No existing users found matching',
    listingAdmin: 'Listing Administrator',
    noMembers: 'There are no members asociated with this property. Invite a user to get started.',
  },
  ListUnitModels: {
    id: 'ID',
    title: 'Unit Models',
    createButton: 'Create Unit Model',
    empty: 'There are no unit models for this property',
    name: 'Name',
    bedrooms: 'Bedrooms',
    bathrooms: 'Bathrooms',
    removeTitle: 'Are you sure you want to remove this unit model?',
    removeSubTitle: 'This will remove this unit model from the system.',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your unit model has been removed',
    deleteSuccessPlural: 'Your unit models have been removed',
  },
  SuperAdmin: {
    marketing: 'Marketing',
    Locales: {
      locales: 'Locales',
    },
    Category: {
      categories: 'Categories',
      newCategory: 'New Category',
      edit: 'Edit',
      delete: 'Delete',
      removeSubTitle: 'This will remove this category from the system',
      cancel: 'Cancel',
      confirm: 'Confirm',
      formInstructions: 'Enter details of the category below',
      name: 'Name',
      save: 'Save',
      removeSuccess: 'Your category has been removed',
      removeError: 'There was an error removing your category',
    },
    Agents: {
      title: 'Agents',
      search: 'Search Agents',
      invite: 'Invite Agent',
      details: 'Enter details of the agent below',
      selectTemplate: 'Select Template',
      send: 'Send',
    },
    Developers: {
      property: 'Property',
      title: 'Developers',
      search: 'Search Developers',
      invite: 'Invite Developer',
      details: 'Enter details of the developer below',
      name: 'Name',
      email: 'Email',
      status: 'Status',
      lastLogin: 'Last Login',
      never: 'Never',
      sendEmailError: 'There was an error sending your email',
      sendEmailSuccess: 'The invitation email was successfully sent',
      inviteDeveloperError: 'There was an error inviting this developer',
      inviteDeveloperSuccess: 'Developer successfully created',
      inviteDeveloper: 'Invite Developer',
      inviteDeveloperSubtitle: 'Enter details of the develper below',
      firstName: 'First Name',
      lastName: 'Last Name',
      emailAddress: 'Email Address',
      associateProperty: 'Associate Property with Developer',
      associateOrganization: 'Associate Organization with Developer',
      sendInvite: 'Send Invite Email',
      Status: {
        PENDING_VERIFICATION: 'Pending Verification',
        VERIFIED: 'Verified',
        PENDING_INVITATION: 'Pending Invitation',
      },
    },
    Organization: {
      addOrganizationType: 'Add Organization Type',
      organization: 'Organization',
      organizations: 'Organizations',
      name: 'Name',
      type: 'Types',
      manageUsers: 'Manage Users',
      edit: 'Edit',
      delete: 'Delete',
      newOrganization: 'New Organization',
      detailsNewOrganizations: 'Enter details of the organization below',
      selectOrganizationType: 'Select Organization Types',
      description: 'Description',
      website: 'Website',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      postalCode: 'Postal Code',
      state: 'State',
      city: 'City',
      phone: 'Phone',
      save: 'Save',
      addAddress: 'Add Address',
      addPhone: 'Add Phone',
      removeAddress: 'Remove Address',
      removePhone: 'Remove Phone',
      logo: 'Logo',
      user: 'User',
      manageUsersDescription: 'Add or remove user in the organization',
      add: 'Add',
      addNewUser: 'Add New User',
      remove: 'Remove',
      deletedSuccess: 'Organization deleted with success',
      addedSuccess: 'Organization added with success',
      editedSuccess: 'Organization edited with success',
      removedUserSuccess: 'Removed user with success',
      addedUserSuccess: 'Added user with success',
    },
    OrganizationTypes: {
      organizationTypes: 'Organization Types',
      organizationsUsingIt: 'In use',
      newOrganizationType: 'New Organization Type',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewOrganizationType: 'Enter details of the organization type below',
      save: 'Save',
      name: 'Name',
      deletedSuccess: 'Organization type deleted with success',
      addedSuccess: 'Organization type added with success',
      editedSuccess: 'Organization type edited with success',
    },
    Tag: {
      tag: 'Tag',
      tags: 'Tags',
      newTag: 'New Tag',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewTag: 'Enter details of the tag below',
      save: 'Save',
      selectTagGroupType: 'Select Tag Group Type',
      name: 'Name',
      deletedSuccess: 'Tag deleted with success',
      addedSuccess: 'Tag added with success',
      editedSuccess: 'Tag edited with success',
    },
    TagGroup: {
      tagGroup: 'Tag Group',
      tagGroups: 'Tag Groups',
      agents: 'Agents',
      newTagGroup: 'New Tag Group',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewTagGroups: 'Enter details of the tag group below',
      save: 'Save',
      name: 'Name',
      deletedSuccess: 'Tag group deleted with success',
      addedSuccess: 'Tag group added with success',
      editedSuccess: 'Tag group edited with success',
    },
    LocaleTypes: {
      localeTypes: 'Locale Types',
      localesUsingIt: 'In use',
      newLocaleType: 'New Locale Type',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewLocaleType: 'Enter details of the locale type below',
      save: 'Save',
      name: 'Name',
      deletedSuccess: 'Locale type deleted with success',
      addedSuccess: 'Locale type added with success',
      editedSuccess: 'Locale type edited with success',
    },
    AddressTypes: {
      addressTypes: 'Address Types',
      addressesUsingIt: 'Addresses using it',
      newAddressType: 'New Address Type',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewAddressType: 'Enter details of the address type below',
      save: 'Save',
      name: 'Name',
      deletedSuccess: 'Address type deleted with success',
      addedSuccess: 'Address type added with success',
      editedSuccess: 'Address type edited with success',
    },
    View: {
      view: 'View Type',
      views: 'View Types',
      agents: 'Agents',
      newView: 'New View Type',
      edit: 'Edit',
      delete: 'Delete',
      detailsNewViews: 'Enter details of the view type below',
      save: 'Save',
      name: 'Name',
      deletedSuccess: 'View deleted with success',
      addedSuccess: 'View added with success',
      editedSuccess: 'View edited with success',
    },
    Users: {
      users: 'Users',
    },
  },
  ListComponent: {
    title: 'Components',
    createButton: 'Create Component',
    empty: 'There are no components for this property',
    name: 'Name',
    city: 'City',
    state: 'State',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    postalCode: 'Zip',
    builtOn: 'Built On',
    removeTitle: 'Are you sure you want to remove this component?',
    removeSubTitle: 'This will remove this component from the system.',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your component has been removed',
  },
  ComponentForm: {
    saveButton: 'Save Component',
    name: 'Component Name',
    builtOn: 'Built On',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    state: 'State',
    postalCode: 'Zip',
    externalId: 'External ID',
    hasOrbitReel: 'Has Orbit Reel',
    numberOfFloors: 'Number of Floors',
  },
  CreateComponent: {
    title: 'Create Component',
    success: 'Your component has been added',
  },
  UpdateComponent: {
    title: 'Update Component',
    success: 'Your component has been saved',
  },
  ListAmenity: {
    title: 'Amenities',
    createButton: 'Create Amenity',
    empty: 'There are no amenities for this property',
    removeTitle: 'Are you sure you want to remove this amenity?',
    removeSubTitle: 'This will remove this amenity from the system.',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your amenity has been removed',
  },
  AmenityForm: {
    heroImage: 'Hero Image',
    visibility: 'Visibility',
    saveButton: 'Save Amenity',
    name: 'Amenity Name',
    description: 'Description',
    images: 'Images',
    showOnPage: 'Show on Page',
    id: 'ID',
  },
  CreateAmenity: {
    title: 'Create Amenity',
    success: 'Your amenity has been added',
  },
  UpdateAmenity: {
    title: 'Amenity Details',
    success: 'Your amenity has been saved',
  },
  ClientSurvey: {
    saveAndExit: 'Save & Exit',
    next: 'Next',
    back: 'Back',
    preferences: 'Preferences',
    buyingProcess: 'Buying Process',
    finalQuestions: 'Final Questions',
    occupationTitle: 'What is your occupation?',
    occupationSubtitle: 'Write in your occupation below',
    occupationPlaceholder: 'Occupation here...',
    roleTitle: 'Role at Company?',
    roleSubtitle: 'Write in your role below.',
    rolePlaceholder: 'Role here...',
    industryTitle: 'What industry are you in?',
    industrySubtitle: 'Write in your industry below',
    industryPlaceholder: 'Industry here...',
    firstTitle: 'First time home buyer?',
    languageTitle: 'What Language do you speak?',
    english: 'English',
    spanish: 'Spanish',
    selectSubtitle: 'Select one of the options below.',
    yes: 'Yes',
    no: 'No',
    ownershipTitle: 'Do you currently Rent, Own or Both?',
    rent: 'Rent',
    own: 'Own',
    both: 'Both',
    lookingTitle: 'Are you actively looking?',
    primaryTitle: 'Primary Residence or Second home?',
    primary: 'Primary',
    secondary: 'Secondary',
    developmentTitle: 'Do you prefer new development or existing?',
    newLabel: 'New',
    existing: 'Existing',
    brokerTitle: 'Broker Communication Preferences',
    email: 'Email',
    phone: 'Phone',
    emailTitle: 'Email Alert Preferences',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    timezone: 'Time Zone',
    referralTitle: 'How did you hear about us?',
    title: 'Title',
    notes: 'Notes',
    uploadTitle: 'Upload your photo below.',
    uploadSubtitle: 'Select a photo for your profile.',
    bedrooms: 'How many bedrooms do you prefer?',
    bathrooms: 'How many bathrooms do you prefer?',
    addBuildings: 'Search to add buildings...',
    addCities: 'Search to add cities...',
    addAmenity: 'Search to add amenities...',
    citiesTitle: 'What Cities are you interested in?',
    citiesSubtitle: 'Type in the input below in order to find cities, you can add multiple cities.',
    buildingsTitle: 'Preferred Buildings?',
    buildingsSubtitle: 'Type in the input below in order to find specific buildings, you can add multiple buildings.',
    addBuildingsButton: 'Add Building',
    addCitiesButton: 'Add City',
    priceTitle: 'Preferred Price Range',
    priceSubtitle: 'Drag to select the price range.',
    sizeTitle: 'Preferred Size Range',
    sizeSubtitle: 'Drag to select the size range.',
    residenceType: 'Preferred Residence Type',
    lifestyleType: 'Preferred Lifestyle Type',
    floorPlan: 'Preferred Floor Plan',
    amenities: 'Preferred Amenities',
    amenitiesSubtitle: 'Type in the input below in order to find specific amenities, you can add multiple amenities.',
    multiselectSubtitle: 'Select one or more of the options below.',
    addAmenityButton: 'Add Amenity',
    visitTitle: 'Type of Visit?',
    visitDreaming: 'Dreaming',
    visitBuy: 'Ready to buy',
    visitBrowsing: 'Browsing',
    moveInDate: 'Desired Move-in Date',
  },
  KeyPlanForm: {
    name: 'Name',
    saveButton: 'Save Key Plan',
    image: 'Image',
    imagePlaceholder: 'Upload a keyplan image',
  },
  CreateKeyPlan: {
    title: 'Create Key Plan',
    success: 'Your key plan has been added',
  },
  UpdateKeyPlan: {
    title: 'Update Key Plan',
    success: 'Your key plan has been saved',
  },
  ListKeyPlans: {
    title: 'Key Plans',
    createButton: 'Create Key Plan',
    empty: 'There are no key plans for this property',
    name: 'Name',
    removeTitle: 'Are you sure you want to remove this key plan?',
    removeSubTitle: 'This cannot be undone',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your key plan has been removed',
    csvUploadError: 'There was an error reading your CSV. Please fix your syntax and try again.',
  },
  FloorForm: {
    floorNumber: 'Floor Number',
    saveButton: 'Save Floor',
    keyPlan: 'Key Plan',
    component: 'Component',
    isPublished: 'Published',
  },
  CreateFloor: {
    title: 'Create Floor',
    success: 'Your floor has been added',
  },
  UpdateFloor: {
    title: 'Update Floor',
    success: 'Your floor has been saved',
  },
  ListFloors: {
    title: 'Floors',
    createButton: 'Create Floor',
    empty: 'There are no key floors for this property',
    removeTitle: 'Are you sure you want to remove this floor?',
    removeSubTitle: 'This will remove the floor from the system',
    cancel: 'No',
    confirm: 'Yes',
    deleteSuccess: 'Your key floor has been removed',
    csvUploadError: 'There was an error reading your CSV. Please fix your syntax and try again.',
  },
  ForbiddenPage: {
    title: 'Forbidden',
    description: 'You do not have permission to access this page',
  },
  ImagePicker: {
    title: 'Select Media',
    done: 'Done',
    cancel: 'Cancel',
  },
  ImageCropper: {
    save: 'Save',
    cancel: 'Cancel',
    editImage: 'Edit Image',
    zoom: 'Zoom',
    rotation: 'Rotation',
    reset: 'Reset',
    sixteenByNine: '16:9',
    oneByOne: '1:1',
    fourByThree: '4:3',
    twoByThree: '2:3',
    threeByOne: '3:1',
    threeByTwo: '3:2',
    custom: 'Custom',
  },
  Media: {
    yes: 'Yes',
    no: 'No',
    title: 'Media',
    editTitle: 'Multi-Image Upload Edit',
    editSubTitle: 'Would you like to edit these images?',
    removeTitle: 'Are you sure you want to delete this media?',
    removeSubTitle: 'This will remove this meda from the system.',
    cancel: 'Cancel',
    confirm: 'Delete Media',
    deleteSuccess: 'Your media has been deleted',
    searchPlaceholder: 'Search Media',
    all: 'All',
    photos: 'Photos',
    videos: 'Videos',
    uploadAssets: 'Upload Assets',
    noCategory: 'No Category',
    pdfs: 'PDFs',
    noResults: 'There are no results for your search',
    selected: 'Selected',
    chooseCategory: 'Choose Category',
    crop: 'Crop',
    delete: 'Delete',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    removeCategories: 'Remove Categories',
    categorized: 'Your categories have been added to your media',
    deCategorized: 'All categories have been removed from your selected media',
    removed: 'Your media has been removed',
  },
  OrbitReel: {
    backToProperty: 'Back to Property',
  },
  Feedback: {
    title: 'Send Feedback',
    message: 'Message',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    send: 'Send',
    success: 'Your feedback has been received. Thanks!',
    error: 'There was an error sending your feedback',
  },
  MyClients: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    bulkSendSuccess: 'Your invites were successfully sent',
    bulkCreateSuccess: 'Your invites were successfully created',
    sendInvites: 'Send Invites',
    createInvites: 'Save',
  },
  AgentCard: {
    myAgent: 'My Agent',
    workPhone: 'Work Phone',
    brokerage: 'Brokerage',
    searchBrokerages: 'Search Brokerages',
    editBrokerSuccess: 'Your broker was successfully updated',
    editBrokerError: 'Something went wrong and we were not able to update your broker',
  },
  UserCard: {
    userDetails: 'User Details',
    workPhone: 'Work Phone',
    cellPhone: 'Cell Phone',
  },
  ClientSettings: {
    myAccount: 'My Account',
    settings: 'Settings',
    password: 'Password',
    buyerSurvey: 'Buyer Survey',
  },
  UserSettings: {
    brokerInformation: 'Broker Information',
    personalInformation: 'Personal Information',
    contactInformation: 'Contact Information',
    primary: 'Primary',
    secondary: 'Secondary',
    save: 'Save',
  },
  UserPassword: {
    password: 'Password',
    changePassword: 'Change Password',
  },
  AgentUnauthenticatedHeader: {
    signUp: 'Sign Up',
    login: 'Login',
  },
  UnauthenticatedHeader: {
    joinNow: 'Join Now',
    forAgents: 'For Agents',
    forDevelopers: 'For Developers',
    aboutVysta: 'About Vysta',
    vysta: 'Vysta',
  },
  AgentHero: {
    title: 'Find your next luxury home.',
    subTitle: 'Sign up to explore new development residences like never before.',
    authenticatedSubTitle: 'Search listings in South Florida, New York, and Los Angeles.',
    exploreProperties: 'Explore Properties',
    welcome: 'Welcome',
    retakeSurvey: 'Retake Survey',
  },
  MyFavoriteListings: {
    title: 'My Favorites',
    noFavorites: 'No favorite listings yet. Start searching to add favorites!',
    loadMore: 'Load More',
  },
  CurrentUser: {
    title: 'My Account',
  },
  ShareWithAgent: {
    title: 'Share Vysta With Your Agent',
    subtitle: 'Get Started',
    description:
      "Want to search South Florida's only complete new development database through gorgeous 3D immersive experiences? Ask your agent to join.",
    name: 'Your Name',
    email: 'Your Email',
    agentName: "Agent's Name",
    agentEmail: "Agent's Email",
    message: 'Message (Optional)',
    contactUs: 'Not working with a real estate agent?',
    contactUs2: 'Contact us',
    contactUs3: 'to be introduced to one we trust.',
    button: 'Invite Agent',
    success: 'Your agent has been invited.',
  },
  SellingPoints: {
    title: 'Vysta: ',
    title2: 'Revolutionary Real Estate Search',
    better: 'Better',
    data: 'Data',
    dataText:
      'The most complete, accurate and insightful new development search on earth. We work directly with the developers, brokerage firms and marketing companies to build listings that are incredibly detailed.',
    search: 'Search',
    searchText:
      'We tag developers, locations, properties, agents and buyers with over 250 tags to provide a next-generation, Al-powered search platform that can see and leverage preferences better - matching properties with buyers faster and more effectively than other systems.',
    experience: 'Experience',
    experienceText:
      'We go beyond just the facts to build immersive experiences that let buyers feel how life will be like. We believe each property has a story to tell and nowhere is it told better than Vysta.',
    results: 'Results',
    resultsText:
      'Agents and Buyers search longer and more often for their dream homes and interact more often with developers and their sales agents to purchase more homes.',
  },
  ReadMore: {
    readMore: 'Read More',
    readLess: 'Read Less',
  },
  ForAgents: {
    title: 'Impress your clients.',
    title2: 'Increase your sales.',
    signUpNow: 'Sign Up Now',
    captivateTitle1: 'Discover',
    captivateTitle2: 'a new way',
    captivateTitle3: 'to captivate your buyers.',
    captivate1: 'Share listings',
    captivate2: 'your way.',
    captivate3:
      'Create a customized portal, branded with your logo and name, to share with your buyers. Our platform listings have',
    captivate4: 'incredible visual capabilities',
    captivate5:
      ', including 3D map search, a rotating 360 degree building unit search, interactive amenity maps, and more. Give your buyers the best experience and sell your next luxury home today.',
    showStopperTitle: 'Showstopper Listing & Sale',
    showStopperTitle2: 'Management Features',
    showStopperDescription: 'Our platform combines all of the',
    showStopperDescription2: 'most important',
    showStopperDescription3: 'features you need to sell a home.',
    stunningVisualsTitle: 'Stunning Visuals',
    stunningVisualsDescription:
      'Showcase each listing in the best way possible. Our one-of-a-kind listing capabilities let potential buyers explore in 360 degrees, discovering everything from amenities to floorplans and more.',
    leadGenerationTitle: 'Lead Generation',
    leadGenerationDescription:
      'Proprietary 3D search functionality drives enhanced, high-quality lead generation, all from your client’s screen.',
    privacyGuaranteeTitle: 'Privacy Guarantee',
    privacyGuarantee:
      'The founders of VYSTA has been trusted by thousands of agents, brokerages, developers and buyers around the world.  Over the last decade, we have been trusted with developer inventory, agent and buyer data and other types of sensitive information for over $100 Billion in successful transactions.  <b>Your buyers belong to you.  Their data is yours and yours alone.</b>  You control how anyone else can interact with them on VYSTA. ',
    dataProtectionTitle: 'Data Protection',
    dataProtectionLines: [
      'When you upload your buyers, we encrypt them so that no one else (not even VYSTA employees) can know who they are.',
      'Any correspondance with them is controlled by you, the agent.',
      'If you leave the service, your buyer data is erased as well.',
      `You approve all sharing of buyer contact infomration.  So, for example, if you wish to use VYSTA's auto-registration feature to register your buyers at more developments, you will approve each and every registration, specifying which data can be released.`,
    ],
    willNotTitle: 'We will not:',
    willNotLines: [
      'Contact your buyers without your permission,',
      `Give, sell or release in any way your buyers information to any other entity without your express written permission.  We are on agent's side to create a better way to connect buyers and developers through you, the trusted, knowledgeable agent. `,
    ],
  },
  AgentWebsite: {
    agentAccolade: 'Agent Accolade',
    noAccolade: 'No Accolade',
  },
  PropertyReports: {
    propertyVisitsTitle: 'Property Visits',
    timesShown: 'Times Shown in Search Results',
    propertyViews: 'Property Views',
    unitsFavorited: 'Units Favorited',
    propertyFavorited: 'Property Favorited',
    agentActivity: 'Agent Activity',
  },
  ShareProperty: {
    title: 'Share Property',
    unitTitle: 'Share Unit',
    sendToClient: 'Send to Client',
    inputPlaceholder: 'Type name or email...',
    messagePrompt: '+ Add Personal Note',
    send: 'Send',
    copyLink: 'Copy Link',
    share: 'Share',
    notFoundError: 'No client found. Please check spelling or enter an email address to share via email.',
    success: 'Property shared with client!',
    unitSuccess: 'Unit shared with client!',
    separateMessage: 'Separate multiple addresses with a comma.',
  },
};

export default en;
