import { Media, MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface MediaResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Media>;
  metadata: MetaData;
  refetch: () => void;
}

export const useGetPropertyMedia = (propertyId: number | string) => {
  const [media, refetch] = useApi.get({
    url: `/property/${propertyId}/media`,
  });

  return formatApiResult(media, refetch) as MediaResponse;
};
