import React, { useRef, useState } from 'react';

import { DownShiftSuggestions } from '../components/AutoSuggestion/DownShiftSuggestions';
import { font } from '../utils/styles';
import styled from 'styled-components';
import { useAutocomplete } from '../hooks/useAutocomplete';

interface SearchBarProps {
  isRemote?: boolean;
  placeholder?: string;
  filters: {
    localeId?: string;
  };
  onRemoveFilter: (index?: number) => void;
  onSetFilter: (filter: Record<string, any>) => void;
  id?: string;
  value?: string;
  onReset: () => void;
}

export const SearchBar = (props: SearchBarProps) => {
  const { onSetFilter, onRemoveFilter, onReset } = props;
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const { suggestions, fetchSuggestions } = useAutocomplete();
  const [isFocused, setIsFocused] = useState(false);
  const searchRef = useRef(null);
  const componentTestId = 'searchbar';

  const suggestionSelected = (selection, stateAndHelpers) => {
    if (!selection) {
      return;
    }
    onSetFilter(selection);
    stateAndHelpers.closeMenu();
    setAutocompleteValue('');
    onRemoveFilter();
  };

  const handleReset = () => {
    onReset();
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  return (
    <SearchInputContainer id={props.id}>
      <Search>
        {props.value && (
          <ValueLabel>
            <ValueText data-testid={`${componentTestId}-value`}>{props.value}</ValueText>
            <ClearButton data-testid={`${componentTestId}-reset`} onClick={handleReset} />
          </ValueLabel>
        )}
        <DownShiftSuggestions
          ref={searchRef}
          suggestions={suggestions}
          onSelection={suggestionSelected}
          loadSuggestions={fetchSuggestions}
          placeholder={props.value ? (isFocused ? props.placeholder : ' ') : props.placeholder}
          clearOnBlur={true}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <FlexGrow />
        <img
          alt="search"
          src="/icons/search-1.svg"
          id="search-btn"
          onClick={() => {
            const val = autocompleteValue;
            setAutocompleteValue('');
            if (val) {
              onSetFilter({ location: val });
            }
          }}
        />
      </Search>
    </SearchInputContainer>
  );
};

const ClearButton = styled.button`
  background-image: url('/icons/close-white.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  text-align: right;
  padding: 5px 5px !important;
  margin-right: -7px;
`;

const ValueText = styled.div`
  width: 149px;
  height: 23px;
  border-right: 1px solid #0c0e13;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
`;

const FlexGrow = styled.div`
  flex: 1;
`;

const ValueLabel = styled.div`
  border: 1px solid #000000;
  border-radius: 2px;
  background-color: #3f424a;
  display: flex;
  padding: 7px 14px;
  position: relative;
  left: -11px;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
  color: #ffffff;
  height: 23px;
  max-width: 156px;
`;

export const SearchBarContainer = styled.div`
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px 0px;
  padding: 10px 0px;
  border-top: 1px solid rgb(245, 245, 245);
  border-bottom: 3px solid rgb(245, 245, 245);
`;

const SearchInputContainer = styled.div`
  position: relative;
  margin: 0px 10px;
`;

const Search = styled.div`
  max-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 208px;
  color: rgb(116, 122, 126);
  font-family: ${font.regular};
  font-size: 14px;
  line-height: 16px;
  box-sizing: border-box;
  background-color: transparent;
  padding: 12px 15px;
  border-radius: 0px;
  border: 1px solid #000000;

  .react-autosuggest__container {
    width: 100%;
  }

  input {
    flex-grow: 1;
    background-color: transparent;
    font-family: inherit;
    font-size: 14px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
    padding: 0px;

    ::placeholder {
      font-family: ${font.regular};
      color: #000 !important;
      font-size: 12px;
    }
  }

  img {
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 100ms ease-in-out 0s;
  }
`;

export const DropdownButton = styled.button`
  position: relative;
  min-height: auto;
  box-shadow: none;
  border: 1px solid #cecece;
  padding: 10px 20px;
  width: auto;
  min-width: auto;
  background-color: white;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #0f232b;
  font-family: ${font.regular};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.38px;
  cursor: pointer;
`;

export const DropdownButtonInner = styled.div`
  color: #0f232b;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
