import { font } from '../utils/styles';
import { prop } from 'lodash/fp';
import styled from 'styled-components';

interface TagProps {
  color: string;
}

export const Tag = styled.div<TagProps>`
  font-family: ${font.regular};
  border: solid 1px ${prop('color')};
  background-color: ${prop('color')};
  color: #fff;
  padding: 1px 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 8px;
  margin-right: 2px;
  text-rendering: optimizelegibility;
`;
