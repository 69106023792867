import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Styled = {
  Avatar: styled.img`
    max-width: 100%;
    max-height: 100%;
  `,
};

interface IAvatar {
  src: string;
  size?: number;
}

export const Avatar = (props: IAvatar) => {
  const [avatarRef, setAvatarRef] = useState(null);
  const [src, setSrc] = useState(props.src);

  useEffect(() => {
    if (avatarRef) {
      avatarRef?.addEventListener('error', (err) => {
        setSrc('/user-profile.svg');
      });
    }
  }, [avatarRef]);

  return (
    <Styled.Avatar
      ref={setAvatarRef}
      src={src}
      style={{
        height: props.size,
        width: props.size,
      }}
    />
  );
};
