import React, { Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { getItem, setItem } from './utils/localStorage';

import GdprBanner from './components/GdprBanner';
import { MobileContactSheetProvider } from './views/MobileContactSheet';
import PageLoader from './components/PageLoader';
import { getPathRoot } from './utils/routeHelpers';
import { trackPageView } from './track';
import { useAuthorization } from './hooks/useAuthorization';
import { useSession } from './hooks/useSession';
import useTranslations from './translations';
import { useZendesk } from './hooks/useZendesk';

// Marketing Link
const MarketingLink = React.lazy(() => import(/* webpackChunkName: "marketingLink" */ './views/MarketingLink'));

// Shared
const RegisterDeveloper = React.lazy(
  () => import(/* webpackChunkName: "shared-registerdev" */ './views/RegisterDeveloper'),
);
const RegisterAgent = React.lazy(() => import(/* webpackChunkName: "shared-registeragent" */ './views/RegisterAgent'));
const ForgotPassword = React.lazy(
  () => import(/* webpackChunkName: "shared-forgotpassword" */ './views/ForgotPassword'),
);
const PasswordReset = React.lazy(() => import(/* webpackChunkName: "shared-resetpassword" */ './views/PasswordReset'));
const PageError = React.lazy(() => import(/* webpackChunkName: "shared-pageerror" */ './views/PageError'));

// Client
const LoginPage = React.lazy(() => import(/* webpackChunkName: "client-login" */ './views/Login/LoginPage'));
const Search = React.lazy(() => import(/* webpackChunkName: "client-search" */ './views/Search'));
const Property = React.lazy(() => import(/* webpackChunkName: "client-property" */ './views/Property'));
const Client = React.lazy(() => import(/* webpackChunkName: "client-client" */ './views/Client'));
const AgentHome = React.lazy(() => import(/* webpackChunkName: "client-home" */ './views/Agent/Home'));

// Admin
const PropertiesAdmin = React.lazy(() => import(/* webpackChunkName: "admin-property" */ './views/PropertiesAdmin'));
const Agent = React.lazy(() => import(/* webpackChunkName: "admin-agent" */ './views/Agent'));

// Super Admin
const SuperAdmin = React.lazy(() => import(/* webpackChunkName: "super-admin" */ './views/SuperAdmin'));

function Layout() {
  const location = useLocation();
  const history = useHistory();
  const translations = useTranslations();
  const [showCookiesBanner, setShowCookiesBanner] = useState(getItem('cookies') !== 'accepted');

  const { currentUser, authorized, loading, getHomeRoute } = useAuthorization();

  useEffect(() => {
    const unregister = history.listen((location) => {
      trackPageView(location.pathname);
    });
    return () => {
      unregister();
    };
  }, [history]);

  // Scroll to top on each route change
  useLayoutEffect(() => {
    const skipScroll = ['/contact/register', '/register'];
    const shouldSkipScroll = skipScroll.reduce((prev, current) => {
      return location.pathname.includes(current) || prev;
    }, false);

    if (shouldSkipScroll) {
      return;
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { activate } = useZendesk();

  useEffect(() => {
    activate(currentUser, authorized);
  }, [activate, currentUser, authorized]);

  useSession(currentUser);

  const ProtectedRoute = useMemo(
    () => ({ component: Component, path, location }: RouteProps) => {
      const pathRoot = getPathRoot(location.pathname);

      if (authorized === null) {
        return <PageLoader />;
      }

      if (!authorized) {
        if (!getItem('redirectTo')) {
          setItem('redirectTo', `${location.pathname}${location.search}`);
        }

        return <Redirect to={`${pathRoot}/login`} />;
      }

      return <Route component={Component} path={path} />;
    },
    [authorized],
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <MobileContactSheetProvider>
      <Suspense fallback={<PageLoader />}>
        {showCookiesBanner && (
          <GdprBanner
            onAccept={() => setShowCookiesBanner(false)}
            text={translations.Common.gdpr}
            policy={translations.Common.gdprPolicies}
            accept={translations.Common.gdprAccept}
          />
        )}
        <Switch>
          <Route exact path="/">
            <Redirect to={authorized ? getHomeRoute() : '/login'} />
          </Route>
          {['create', 'register', 'login'].map((key) => (
            <Route key={key} path={`/agent/:agentId/${key}`} component={AgentHome} />
          ))}

          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/developer-register"
            render={() => (
              <RegisterDeveloper
                onClose={() => {
                  history.push('/');
                }}
              />
            )}
          />
          <Route
            path="/reset-password"
            render={() => (
              <PasswordReset
                onClose={() => {
                  setTimeout(() => {
                    history.push('/login');
                  }, 3500);
                }}
              />
            )}
          />
          <Route
            path="/confirm-signup"
            render={() => (
              <PasswordReset
                onClose={() => {
                  setTimeout(() => {
                    history.push('/login');
                  }, 3500);
                }}
              />
            )}
          />
          <Route path="/_/:slug" component={MarketingLink} />
          <Route path="/register" component={RegisterAgent} />
          <Route path="/login" component={LoginPage} />
          <Route path="/agent/:agentId" component={AgentHome} />
          <ProtectedRoute path={['/preferences', '/buyer']} component={Client} />
          <ProtectedRoute path="/search" component={Search} />
          <ProtectedRoute path="/property/:id" component={Property} />
          <ProtectedRoute
            path={['/clients', '/clients/:id', '/account/:page', '/listings', '/reports', '/properties']}
            component={Agent}
          />
          <ProtectedRoute path="/admin/properties/:propertyId" component={PropertiesAdmin} />
          <ProtectedRoute path="/super-admin/:page?" component={SuperAdmin} />
          <Route component={PageError} />
        </Switch>
      </Suspense>
    </MobileContactSheetProvider>
  );
}

export default Layout;
