import api from '../../utils/api';

interface DeCategorizeMediaProps {
  mediaIds: Array<string>;
}

export const deCategorizeMedia = (variables: DeCategorizeMediaProps) =>
  api.delete({
    url: `/category/media`,
    variables,
  });
