import { useApi, gql } from './Provider';
import {
  UnAssignUserEmailTemplate,
  UnAssignUserEmailTemplateVariables,
} from './__generated__/UnAssignUserEmailTemplate';

const UNASSIGN_USER_EMAIL_TEMPLATE = gql`
  mutation UnAssignUserEmailTemplate($id: BigInt!) {
    unAssignUserEmailTemplate(id: $id) {
      id
      name
      templateId
      user {
        id
      }
    }
  }
`;

export const useUnAssignUserEmailTemplate = () => {
  const { useMutation } = useApi();
  return useMutation<UnAssignUserEmailTemplate, UnAssignUserEmailTemplateVariables>(UNASSIGN_USER_EMAIL_TEMPLATE);
};
