import { Floor } from '../../types';
import api from '../../utils/api';

export const batchCreateFloors = (floors: Array<Floor>) =>
  api.post({
    url: `/floor/batch`,
    variables: {
      floors,
    },
  });
