import { ContactSheetAgentExpanded, SetShareProperty } from './components/ContactSheetAgentExpanded';
import React, { useState } from 'react';

import { ContactSheet } from './types';
import { ContactSheetAgentCollapsed } from './components/ContactSheetAgentCollapsed';
import { ContactSheetClientCollapsed } from './components/ContactSheetClientCollapsed';
import { ContactSheetClientExpanded } from './components/ContactSheetClientExpanded';
import { SharePropertyModal } from '../ShareProperty';
import { useResponsiveSizes } from '../../hooks/useResponsiveSizes';

interface MobileContactSheetProps {
  contactSheet: ContactSheet;
  hasPropertyScope: boolean;
}

export const MobileContactSheet = (props: MobileContactSheetProps) => {
  const { isMedium } = useResponsiveSizes();
  const [shareProperty, setShareProperty] = useState<SetShareProperty | null>(null);

  return (
    <>
      {(() => {
        // Not Mobile
        if (!isMedium) {
          return null;
        }

        switch (props.contactSheet.type) {
          case 'hidden':
            return null;
          case 'agentCollapsed':
            return <ContactSheetAgentCollapsed contactSheet={props.contactSheet} />;
          case 'agentExpanded':
            return <ContactSheetAgentExpanded contactSheet={props.contactSheet} setShareProperty={setShareProperty} />;
          case 'clientCollapsed':
            return (
              <ContactSheetClientCollapsed
                contactSheet={props.contactSheet}
                hasPropertyScope={props.hasPropertyScope}
              />
            );
          case 'clientExpanded':
            return <ContactSheetClientExpanded contactSheet={props.contactSheet} />;
        }
      })()}
      {!!shareProperty && (
        <SharePropertyModal
          unitId={shareProperty.unitId && +shareProperty.unitId}
          propertyId={+shareProperty.propertyId}
          onClose={() => setShareProperty(null)}
          onComplete={() => setShareProperty(null)}
        />
      )}
    </>
  );
};
