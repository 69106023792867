import { IIconType, Icon, Touchable } from '../';
import React, { ReactChild, ReactChildren } from 'react';

import { Modal as NativeModal } from 'react-native';
import styled from 'styled-components/native';

export enum ModalSize {
  small = 466,
}

interface IModal {
  children: ReactChildren | ReactChildren[] | ReactChild | ReactChild[];
  width?: number;
  onDismiss?: () => void;
  visible: boolean;
  testID: string;
  size?: ModalSize;
  hideCloseIcon?: boolean;
}

interface IStyledModal {
  size?: ModalSize;
}

const Styled = {
  Container: styled.View`
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  ContainerMask: styled.TouchableOpacity`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  `,
  Modal: styled.View<IStyledModal>`
    background-color: #fff;
    border-color: #eee;
    border-width: 1;
    margin: auto;
    width: ${(props) => (props.size ? props.size : ModalSize.small)}px;
    max-width: 100%;
    min-height: 200px;
    max-height: 100%;
    position: relative;
    padding: 24px;
  `,
  CloseButton: styled.View`
    position: absolute;
    right: 24px;
    top: 18px;
  `,
};

export const Modal = (props: IModal) => {
  return (
    <NativeModal
      transparent
      onDismiss={props.onDismiss}
      visible={props.visible}
      onAccessibilityEscape={props.onDismiss}
    >
      <Styled.Container>
        <Styled.ContainerMask onPress={props.onDismiss} />
        <Styled.Modal size={props.size}>
          {props.children}
          {!props.hideCloseIcon && (
            <Styled.CloseButton>
              <Touchable testID={`${props.testID}-close-button`} onPress={props.onDismiss}>
                <Icon type={IIconType.Close} size="lg" />
              </Touchable>
            </Styled.CloseButton>
          )}
        </Styled.Modal>
      </Styled.Container>
    </NativeModal>
  );
};
