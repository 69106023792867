import {
  ResendClientInvitationEmail,
  ResendClientInvitationEmailVariables,
} from './__generated__/ResendClientInvitationEmail';
import { gql, useApi } from './Provider';

const RESEND_EMAIL_INVITE = gql`
  mutation ResendClientInvitationEmail($id: Int!) {
    resendClientInvitationEmail(id: $id) {
      id
      emailAddress
      user {
        id
        fullName
        status
      }
    }
  }
`;

export const useResendClientInvite = () => {
  const { useMutation } = useApi();
  return useMutation<ResendClientInvitationEmail, ResendClientInvitationEmailVariables>(RESEND_EMAIL_INVITE);
};
