import React, { useState } from 'react';
import { Media, UnitModel, Tag } from '../types';
import styled from 'styled-components';
import { Grid, Col } from 'react-flexbox-grid';
import { font } from '../utils/styles';
import { ButtonWrapper } from './ButtonWrapper';
import { Modal } from './Modal';
import { Button } from './Button';
import { PdfPreview } from './PdfPreview';
import Lightbox from 'react-image-lightbox';
import useTranslations from '../translations/useTranslations';
import AAsStyledButton from './AAsStyledButton';
import { useGetSignedMedia } from '../hooks/useEndpoint';
import { formatImage } from '../utils/formatImage';
import { formatNumber } from '../utils/format';
import { compose, always } from 'lodash/fp';

interface FloorPlanProps {
  floorPlans: Array<Media>;
  unitModel: UnitModel;
  tags: Array<Tag>;
  keyPlanLocation?: string;
}

export const FloorPlan = (props: FloorPlanProps) => {
  const { floorPlans, unitModel, tags, keyPlanLocation } = props;
  const translations = useTranslations();
  const [fullScreenPlan, setFullScreenPlan] = useState(false);
  const [fullScreenKeyPlan, setFullScreenKeyPlan] = useState(false);
  const mediaLocation = floorPlans ? floorPlans[0]?.location : '';
  const location = (mediaLocation || '').split('/uploads/')[1];
  const extension = (floorPlans ? floorPlans[0]?.mediaType?.name || '' : '').split('/')[1];
  const fileName = (floorPlans ? floorPlans[0]?.name || '' : '').split('.')[0];
  const signedMediaResponse = useGetSignedMedia(location, {
    disposition: `attachment; filename="${fileName}.${extension}"`,
  });

  if (!signedMediaResponse) {
    return null;
  }

  return (
    <Styled.UnitInfoContainer>
      {fullScreenKeyPlan && (
        <Lightbox
          mainSrc={props.keyPlanLocation || ''}
          onCloseRequest={compose(setFullScreenKeyPlan, always(false))}
        ></Lightbox>
      )}
      <Styled.UnitPlanGrid>
        <Styled.UnitPlanCol>
          <Styled.UnitPlanBox>
            {!!floorPlans?.length && (
              <Styled.UnitPlan>
                {fullScreenPlan &&
                  (floorPlans[0]?.mediaType?.name?.includes('pdf') ? (
                    <Modal
                      variant="center"
                      isOpen={true}
                      onClose={() => setFullScreenPlan(false)}
                      renderContent={() => (
                        <Styled.PdfFullscreenContainer>
                          <PdfPreview location={floorPlans[0]?.location} />
                        </Styled.PdfFullscreenContainer>
                      )}
                    />
                  ) : (
                    <Lightbox mainSrc={mediaLocation || ''} onCloseRequest={() => setFullScreenPlan(false)}></Lightbox>
                  ))}
                {floorPlans[0]?.mediaType?.name?.includes('pdf') ? (
                  <Styled.PdfPreviewContainer>
                    <PdfPreview location={mediaLocation} />
                  </Styled.PdfPreviewContainer>
                ) : (
                  <ButtonWrapper onClick={() => setFullScreenPlan(true)}>
                    <Styled.ImageDetailedPlan
                      src={formatImage(mediaLocation, {
                        width: 950,
                        height: 530,
                      })}
                      alt="image-detailed-plant"
                    />
                  </ButtonWrapper>
                )}
                <Styled.UnitPlanButtons>
                  <Styled.ButtonSeparator>
                    <Button
                      color="black"
                      icon="expand-5"
                      colorIcon="white"
                      width="159px"
                      justifyContent="space-evenly"
                      onClick={() => setFullScreenPlan(true)}
                    >
                      {translations.Common.fullScreen}
                    </Button>
                  </Styled.ButtonSeparator>
                  <AAsStyledButton
                    color="black"
                    icon="download-circle"
                    colorIcon="white"
                    width="159px"
                    justifyContent="space-evenly"
                    download
                    href={signedMediaResponse?.data}
                  >
                    {translations.Common.download}
                  </AAsStyledButton>
                </Styled.UnitPlanButtons>
              </Styled.UnitPlan>
            )}
            <Styled.UnitDetails
              style={{
                width: floorPlans && floorPlans[0]?.location ? '35%' : '100%',
              }}
            >
              <Styled.UnitDetailsTitle>
                {translations.Property.floorPlan} #{unitModel.name}
              </Styled.UnitDetailsTitle>
              <Styled.UnitDetailsDescription>{unitModel.description}</Styled.UnitDetailsDescription>
              <Styled.UnitDetailsProperties>
                <Styled.UnitDetailsProperty>
                  <Styled.UnitDetailsPropertyTitle>
                    {unitModel?.bedrooms === 1 ? translations.Property.bedroom : translations.Property.bedrooms}
                  </Styled.UnitDetailsPropertyTitle>
                  <Styled.UnitDetailsPropertyValue>{unitModel?.bedrooms}</Styled.UnitDetailsPropertyValue>
                </Styled.UnitDetailsProperty>
                <Styled.UnitDetailsProperty>
                  <Styled.UnitDetailsPropertyTitle>{translations.Property.bathrooms}</Styled.UnitDetailsPropertyTitle>
                  <Styled.UnitDetailsPropertyValue>{unitModel?.bathrooms}</Styled.UnitDetailsPropertyValue>
                </Styled.UnitDetailsProperty>
                <Styled.UnitDetailsProperty>
                  <Styled.UnitDetailsPropertyTitle>{translations.Property.halfBaths}</Styled.UnitDetailsPropertyTitle>
                  <Styled.UnitDetailsPropertyValue>{unitModel?.halfBathrooms || 0}</Styled.UnitDetailsPropertyValue>
                </Styled.UnitDetailsProperty>
                {!!unitModel?.exteriorSquarefeet && (
                  <Styled.UnitDetailsProperty>
                    <Styled.UnitDetailsPropertyTitle>{translations.Property.exterior}</Styled.UnitDetailsPropertyTitle>
                    <Styled.UnitDetailsPropertyValue>
                      {formatNumber(`${unitModel?.exteriorSquarefeet}`)} sq ft
                    </Styled.UnitDetailsPropertyValue>
                  </Styled.UnitDetailsProperty>
                )}
                {!!tags?.length && (
                  <Styled.UnitDetailsPropertyFeatures>
                    <Styled.UnitDetailsPropertyTitle>{translations.Common.features}</Styled.UnitDetailsPropertyTitle>
                    <Styled.UnitDetailsFeaturesList>
                      {tags.map((tag: Tag) => (
                        <li key={tag.id}>
                          <Styled.UnitDetailsPropertyValue>{tag.name}</Styled.UnitDetailsPropertyValue>
                        </li>
                      ))}
                    </Styled.UnitDetailsFeaturesList>
                  </Styled.UnitDetailsPropertyFeatures>
                )}
                <Styled.UnitDetailsProperty>
                  <Styled.UnitDetailsPropertyTitle>{translations.Property.interior}</Styled.UnitDetailsPropertyTitle>
                  <Styled.UnitDetailsPropertyValue>
                    {formatNumber(`${unitModel?.interiorSquarefeet}`)} {translations.Property.sqft}
                  </Styled.UnitDetailsPropertyValue>
                </Styled.UnitDetailsProperty>
              </Styled.UnitDetailsProperties>
              {keyPlanLocation && (
                <Styled.KeyPlanContainer>
                  <ButtonWrapper onClick={compose(setFullScreenKeyPlan, always(true))}>
                    <Styled.KeyPlan src={keyPlanLocation} />
                  </ButtonWrapper>
                </Styled.KeyPlanContainer>
              )}
            </Styled.UnitDetails>
          </Styled.UnitPlanBox>
        </Styled.UnitPlanCol>
      </Styled.UnitPlanGrid>
    </Styled.UnitInfoContainer>
  );
};

const Styled = {
  UnitInfoContainer: styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    background-color: #f0f0f0;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-top: -3em;
    }
  `,
  UnitPlanGrid: styled(Grid)`
    @media (max-width: 1400px) {
      width: auto;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  `,
  UnitPlanCol: styled(Col)`
    margin-bottom: 1.5rem;
  `,
  UnitPlanBox: styled.div`
    max-width: 1240px;
    margin-top: -142px;
    background: #ffffff;
    padding: 20px;
    display: flex;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
    h5 {
      color: #000000;
      font-family: ${font.title};
      font-size: 22px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      font-weight: 500;
    }
    h6 {
      font-weight: 500;
      color: #949494;
      font-family: ${font.regular};
      font-size: 17px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      margin-bottom: 20px;
    }
  `,
  UnitPlan: styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-right: 2em;
    border-right: 1px solid #dadada;
    @media (max-width: 1200px) {
      width: 100%;
      border-bottom: 1px solid #dadada;
      margin-bottom: 1em;
      border-right: 0px solid #dadada;
    }
  `,
  UnitDetails: styled.div`
    display: flex;
    width: 35%;
    flex-direction: column;
    @media (max-width: 1200px) {
      width: 100%;
    }
  `,
  PdfPreviewContainer: styled.div`
    width: 100%;
    height: 400px;
  `,
  PdfFullscreenContainer: styled.div`
    width: 100%;
    height: 85vh;
  `,
  ImageDetailedPlan: styled.img`
    max-height: 675px;
    max-width: 100%;
    @media (max-width: 1200px) {
      margin: auto;
    }
  `,
  ImagePlan: styled.img`
    max-height: 202px;
    max-width: 301px;
    margin-top: 5em;
    @media (max-width: 1200px) {
      display: flex;
      align-self: center;
    }
  `,
  UnitPlanButtons: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.1em;
    align-self: center;
    @media (max-width: 1200px) {
      margin-bottom: 1em;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      width: 100%;

      > *,
      button {
        width: 100%;
        text-align: center;
        justify-content: center;
      }

      button {
        margin-bottom: 14px;
      }
    }
  `,
  UnitDetailsTitle: styled.div`
    display: flex;
    font-family: ${font.title};
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 1em;
  `,
  UnitDetailsDescription: styled.div`
    display: flex;
    font-family: ${font.regular};
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 1em;
  `,
  UnitDetailsProperty: styled.div`
    display: flex;
    width: 50%;
    margin-bottom: 10px;
    @media (max-width: 450px) {
      width: 100%;
    }
  `,
  UnitDetailsPropertyFeatures: styled.div`
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    @media (min-width: 450px) {
      height: 10px;
    }
  `,
  UnitDetailsPropertyTitle: styled.span`
    display: flex;
    font-family: ${font.title};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    width: 55%;
    text-transform: uppercase;
  `,
  UnitDetailsPropertyValue: styled.span`
    display: flex;
    font-family: ${font.regular};
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    color: #747a7e;
  `,
  UnitDetailsProperties: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
  `,
  UnitDetailsFeaturesList: styled.ul`
    margin-left: 17px;
    list-style-type: disc;
    color: #747a7e;
  `,
  ButtonSeparator: styled.div`
    margin-right: 3em;

    @media (max-width: 450px) {
      margin-right: 0px;
    }
  `,
  KeyPlanContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 100px;
  `,
  KeyPlan: styled.img`
    width: 200px;
  `,
};
