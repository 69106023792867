import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from '../hooks/useOutsideClick';
import useTranslation from '../translations/useTranslations';
import { is } from '../utils/validation';
import Icon from './Icon';

interface BadgeItem {
  id: number;
  name: string;
  email: string;
}
interface ContactsTagInputProps {
  possibleItems: Array<BadgeItem>;
  handleSetItems: (items: Array<BadgeItem>) => void;
  handleSetError: (error: string) => void;
  placeholder?: string;
  newEmails: Array<string>;
}
interface DropdownContainerProps {
  top: number;
}

export const ContactsTagInput = (props: ContactsTagInputProps) => {
  const [items, setItems] = useState<Array<BadgeItem>>([]);
  const [value, setValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const translations = useTranslation();
  const inputRef = useRef(null);
  const tagContainerRef = useRef(null);
  const componentTestId = 'contacts-tag-input';

  useOutsideClick(inputRef, () => {
    setShowDropdown(false);
  });

  const filterValue = value.toLowerCase();
  const updateItemList = (items) => {
    setItems(items);
    props.handleSetItems(items);
  };
  const processInput = () => {
    if (!filterValue) {
      return;
    }
    const foundItem = props.possibleItems.find(
      (possible) => possible.email.toLowerCase() === filterValue || possible.name.toLowerCase() === filterValue,
    );
    if (foundItem) {
      if (items.find((item) => foundItem.id === item.id)) {
        setValue('');
      } else {
        updateItemList([...items, foundItem]);
        setValue('');
        props.handleSetError(null);
      }
    } else {
      // check to see if the value is an email.
      if (is.email()(filterValue)) {
        if (!props.newEmails.includes(filterValue)) {
          updateItemList([
            ...items,
            {
              id: null,
              name: filterValue,
              email: filterValue,
            },
          ]);
        }

        setValue('');
        props.handleSetError(null);
        return;
      }

      props.handleSetError(translations.ShareProperty.notFoundError);
    }
  };

  const handleKeyDown = (event) => {
    setShowDropdown(true);
    if (['Enter', 'Tab', ','].includes(event.key)) {
      processInput();
      event.preventDefault();
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    props.handleSetError(null);
  };

  const suggestions = props.possibleItems.filter((client) => {
    return (
      (!value ||
        client.name.toLocaleLowerCase().includes(filterValue) ||
        client.email.toLocaleLowerCase().includes(filterValue)) &&
      !items.find((i) => i.id === client.id) &&
      client.email
    );
  });

  return (
    <Styled.Container>
      <Styled.ContactsInputContainer
        onClick={() => {
          inputRef?.current.focus();
          setShowDropdown(true);
        }}
      >
        <Styled.TagInputContainer ref={tagContainerRef}>
          <Styled.ItemsContainer>
            {items.map((item, index) => (
              <Styled.Tag title={item.name.trim() || item.email} className="tag-item" key={index}>
                <Styled.TagName>{item.name.trim() || item.email}</Styled.TagName>
                <Styled.CloseIcon>
                  <Icon
                    onClick={() => {
                      updateItemList(items.filter((inListItem) => inListItem.email !== item.email));
                    }}
                    color="#000"
                    type="close"
                    size={12}
                  />
                </Styled.CloseIcon>
              </Styled.Tag>
            ))}

            <input
              ref={inputRef}
              value={value}
              placeholder={!items.length ? props.placeholder : ''}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onBlur={() => {
                if (!suggestions.length) {
                  processInput();
                }
              }}
            />
          </Styled.ItemsContainer>

          <Styled.SearchIcon
            onClick={() => processInput()}
            type="search"
            color="#CCC"
            data-testid={`${componentTestId}-search-button`}
          />
        </Styled.TagInputContainer>
      </Styled.ContactsInputContainer>
      {suggestions.length && value && showDropdown ? (
        <Styled.DropdownContainer top={tagContainerRef?.current?.clientHeight || 0}>
          {suggestions.map((client) => (
            <Styled.DropdownItem
              key={client.id}
              onClick={() => {
                setValue('');
                updateItemList([...items, { ...client }]);
              }}
            >
              <Styled.ClientInfo>
                {client.name} <br /> {client.email}
              </Styled.ClientInfo>

              <Styled.AddIcon src="/icons/add.svg" />
            </Styled.DropdownItem>
          ))}
        </Styled.DropdownContainer>
      ) : null}
    </Styled.Container>
  );
};
const Styled = {
  AddIcon: styled.img`
    height: 18px;
    width: 18px;
  `,
  DropdownContainer: styled.div<DropdownContainerProps>`
    cursor: pointer;
    border: 1px solid #dadada;
    max-height: 250px;
    overflow: auto;
    position: absolute;
    background: #fff;
    top: ${(props) => (props.top ? `calc(132px + ${props.top}px)` : '0px')};
    width: 330px;
  `,
  ClientInfo: styled.div`
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  AddClient: styled.div`
    display: flex;
    cursor: pointer;
    background: #c9985e;
    font-size: 12px;
    color: #fff;
  `,
  DropdownItem: styled.div`
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #dadada;
    &:hover {
      font-weight: 400;
    }
    &:last-child {
      border-bottom: 0px;
    }
    &:nth-child(even) {
      background: rgba(0, 0, 0, 0.02);
    }
  `,
  SearchIcon: styled(Icon)`
    cursor: pointer;
  `,
  TagName: styled.div`
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  CloseIcon: styled.div`
    margin-left: 5px;
    cursor: pointer;
    display: flex;
    .material-icons {
      font-size: 20px;
    }
  `,
  Tag: styled.div`
    height: 30px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 12px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    justify-content: space-between;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ContactsInputContainer: styled.div`
    height: auto;
    border: 1px solid #dadada;
    justify-content: center;
    padding: 5px;
  `,
  TagInputContainer: styled.div`
    display: flex;
    input {
      flex-grow: 1;
      border: none;
      &:focus {
        outline: none;
      }
    }
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `,
  ItemsContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  Error: styled.div``,
};
