import { CustomIcon } from './CustomIcon';
import Icon from './Icon';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import React from 'react';
import { font } from '../utils/styles';
import styled from 'styled-components';
import { zIndexValues } from '../utils/styles';

interface IMenuItemTextProps {
  color?: string;
}

const Styled = {
  StyledLeftNavBar: styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: #0c0e13;
    width: 280px;
    z-index: ${zIndexValues.navLeft};
  `,
  TopLeft: styled.div`
    flex-shrink: 0;
    border-bottom: 1px solid #a5a5a5;
    height: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Bottom: styled.div`
    height: 100vh;
    flex-grow: 1;
    overflow-y: auto;
  `,
  Menu: styled.ul`
    padding: 2rem;
  `,
  MenuItem: styled.li`
    cursor: pointer;
    justify-content: flex-start;
    display: flex;
    line-height: 18px;
    font-family: ${font.regular};
    font-size: 1.1rem;
    align-items: center;
    padding-bottom: 2rem;
  `,
  MenuItemText: styled.span<IMenuItemTextProps>`
    padding-left: 1rem;
    letter-spacing: 0.25px;
    color: ${(props) => props.color || '#fff'};
  `,
};

export interface NavLink {
  id: string;
  name?: string;
  iconFamily?: string;
  icon: string;
  size?: number;
  color?: string;
  isActive?: boolean;
  link: string;
  testId?: string;
  customIcon?: string;
  newTab?: boolean;
}

interface LeftNavBarProps {
  linkColor?: string;
  activeLinkColor?: string;
  navLinks: Array<NavLink>;
  title: string;
}

const defaultProps: LeftNavBarProps = {
  linkColor: '#fff',
  activeLinkColor: '#CDA263',
  navLinks: [],
  title: 'VYSTA',
};

export const LeftNavBar = (props: LeftNavBarProps = defaultProps) => {
  const { linkColor, activeLinkColor } = props;

  return (
    <Styled.StyledLeftNavBar>
      <Styled.TopLeft>
        <Logo variant="accent" />
      </Styled.TopLeft>
      <Styled.Bottom>
        <Styled.Menu>
          {props.navLinks &&
            props.navLinks.map((link: NavLink) => (
              <Link
                data-testid={`dashboard:${link.testId}`}
                to={link.link}
                key={link.id}
                target={link.newTab === true ? '_blank' : '_self'}
              >
                <Styled.MenuItem>
                  {link.customIcon ? (
                    <CustomIcon type={link.customIcon} color={link.isActive ? 'primary' : 'white'} />
                  ) : (
                    <Icon
                      family={link.iconFamily}
                      type={link.icon}
                      size={28}
                      color={link.isActive ? activeLinkColor : linkColor}
                    />
                  )}
                  <Styled.MenuItemText color={link.isActive ? activeLinkColor : linkColor}>
                    {link.name}
                  </Styled.MenuItemText>
                </Styled.MenuItem>
              </Link>
            ))}
        </Styled.Menu>
      </Styled.Bottom>
    </Styled.StyledLeftNavBar>
  );
};
