import * as React from 'react';
import styled from 'styled-components/native';
import { Text, Padding, Button, Spacer, Flex, Icon, IIconType } from '../';
import { useTranslation } from '../../hooks/useTranslation';
import * as translations from './translations';

const Styled = {
  DropArea: styled.View`
    width: 100%;
    height: 300px;
    border: dashed 2px ${(props) => props.theme.color.gray1};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export const DropArea = () => {
  const componentName = 'drop-area';
  const t = useTranslation<typeof translations.en>(translations);

  return (
    <Styled.DropArea>
      <Padding size={24}>
        <Flex direction="column" alignItems="center" justify="center">
          <Icon type={IIconType.CloudUpload} size="xxl" color="gray1" />
          <Spacer height={14} />
          <Text color="gray" weight="bold" size="xl">
            {t.dragAndDropFileHere}
          </Text>
          <Spacer height={14} />
          <Text color="gray" weight="bold" size="sm">
            {t.or}
          </Text>
          <Spacer height={14} />
          <Button size="lg" color="gray" testID={`${componentName}-browse`} onPress={() => {}}>
            {t.browseFiles}
          </Button>
        </Flex>
      </Padding>
    </Styled.DropArea>
  );
};
