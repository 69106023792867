import * as S from './styles';

import React, { useState } from 'react';

import Downshift from 'downshift';
import { has } from 'lodash';
import useTranslations from '../../translations';

export let isToggled = false;

export interface DownShiftSuggestionsProps {
  suggestions: any;
  onSelection: Function;
  loadSuggestions: Function;
  placeholder?: string;
  clearOnBlur?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const DownShiftSuggestions = React.forwardRef(
  (props: DownShiftSuggestionsProps, ref: React.RefObject<HTMLInputElement>) => {
    const translations = useTranslations();
    const { suggestions, onSelection, loadSuggestions, clearOnBlur, onFocus, onBlur } = props;
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const componentTestId = 'autosuggest';

    const stateReducer = (state, changes) => {
      isToggled = true;

      switch (changes.type) {
        // hack to allow arrow key up down to go to proper index between sections
        case Downshift.stateChangeTypes.keyDownArrowUp:
          return {
            ...changes,
            highlightedIndex:
              changes.highlightedIndex === 999 ? suggestions[0].options.length - 1 : changes.highlightedIndex,
          };
        case Downshift.stateChangeTypes.keyDownArrowDown:
          return {
            ...changes,
            highlightedIndex:
              changes.highlightedIndex === suggestions[0].options.length ? 1000 : changes.highlightedIndex,
          };
        // close the suggestions when input = ""
        // default to first entry in Locations section
        case Downshift.stateChangeTypes.changeInput:
          return {
            ...changes,
            highlightedIndex: 0, // trying to set this dynamically to sections[0].options.length is buggy
          };
        case Downshift.stateChangeTypes.blurInput:
          isToggled = false;
          return {
            ...state,
            inputValue: '',
          };
        case Downshift.stateChangeTypes.keyDownEnter:
          return {
            ...state,
            ...(state?.inputValue && {
              inputValue: has(changes.selectedItem, 'name') ? changes.selectedItem.name : changes.selectedItem,
            }),
          };
        case Downshift.stateChangeTypes.mouseUp: // dont close suggestions on mouseup unless input = ""
          return {
            ...changes,
            inputValue: state.inputValue,
            highlightedIndex: state.highlightedIndex,
          };
        case Downshift.stateChangeTypes.clickItem:
          isToggled = false;
          return {
            ...changes,
            inputValue: has(changes.selectedItem, 'name') ? changes.selectedItem.name : changes.selectedItem,
          };
        default:
          return changes;
      }
    };

    const handleFocus = () => {
      isToggled = true;
      if (onFocus) {
        onFocus();
      }
      setShowSuggestions(true);
    };

    const handleBlur = () => {
      if (clearOnBlur) {
        setSearchVal('');
      }
      if (onBlur) {
        onBlur();
      }
      isToggled = false;
    };

    return (
      <Downshift
        itemToString={(item) => (item ? item.value : '')}
        onInputValueChange={(value) => {
          loadSuggestions(value);
          setSearchVal(value);
        }}
        isOpen={showSuggestions}
        onOuterClick={() => {
          setShowSuggestions(false);
          isToggled = false;
        }}
        stateReducer={stateReducer}
        inputValue={searchVal}
        onSelect={(selection, stateAndHelpers) => onSelection(selection, stateAndHelpers)}
      >
        {({ getRootProps, getInputProps, getItemProps, getMenuProps, highlightedIndex }) => (
          <S.AutoSuggestInputContianer {...getRootProps()}>
            <S.AutoSuggestInput
              data-testid={`${componentTestId}-input`}
              {...getInputProps()}
              ref={ref}
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              placeholder={props.placeholder || translations.Home.searchPlaceholder}
            />
            {suggestions.length > 0 && showSuggestions ? (
              <S.AutoSuggestItemContainer>
                {suggestions
                  .filter((section) => !!section.options?.length)
                  .map((section, sectionIndex) => {
                    return (
                      <S.AutoSuggestSection key={section.title + sectionIndex} {...getMenuProps()}>
                        <S.SectionTitle>{section.title}</S.SectionTitle>
                        {section.options.map((item, index) => {
                          const sectionItemIndex = sectionIndex * 1000 + index;
                          return (
                            <S.AutoSuggestItem
                              data-testid={`${componentTestId}-${section.title.toLowerCase()}-${item.id}`}
                              key={`${item.id}-${sectionItemIndex}`}
                              {...getItemProps({
                                index: sectionItemIndex,
                                item,
                                style: {
                                  backgroundColor: highlightedIndex === sectionItemIndex ? '#F5F5F5' : 'transparent',
                                },
                              })}
                            >
                              <S.AutoSuggestItemContent>
                                <S.ItemTitle
                                  data-testid={`${componentTestId}-${section.title.toLowerCase()}-${item.id}-title`}
                                >
                                  {item.name ? item.name : item}
                                </S.ItemTitle>
                                {item.address && item.address}
                              </S.AutoSuggestItemContent>
                            </S.AutoSuggestItem>
                          );
                        })}
                      </S.AutoSuggestSection>
                    );
                  })}
              </S.AutoSuggestItemContainer>
            ) : null}
          </S.AutoSuggestInputContianer>
        )}
      </Downshift>
    );
  },
);
