import * as React from 'react';

import { DateTime } from './DateTime';
import { findDOMNode } from 'react-dom';

interface DateEditorProps {
  value?: string;
  onCommit: Function;
}

interface DateEditorState {
  date?: string;
}

export const GridDateEditor = (field: string) =>
  class extends React.Component<DateEditorProps, DateEditorState> {
    constructor(props: DateEditorProps) {
      super(props);
      this.state = { date: props.value };
    }

    getValue() {
      return { [field]: this.state.date };
    }

    getInputNode() {
      const $el = findDOMNode(this) as any;
      if ($el && $el.getElementsByTagName) {
        return $el.getElementsByTagName('input')[0];
      }
    }

    handleChangeComplete = (date: string) => {
      this.setState({ date: date ? date.split('T')[0] : date }, () => this.props.onCommit());
    };

    render() {
      return <DateTime type="date" value={this.state.date} onChange={this.handleChangeComplete} />;
    }
  };
