import { createBrowserHistory } from 'history';
import { page } from './utils/analytics';

export const browserHistory = createBrowserHistory();

browserHistory.listen((route) => {
  page(route.pathname);

  return route;
});
