import { QueryPropertyPageHero, QueryPropertyPageHeroVariables } from './__generated__/QueryPropertyPageHero';
import { gql, useApi } from './Provider';

export const QUERY_PROPERTY_PAGE_HERO = gql`
  query QueryPropertyPageHero($propertyId: Int!) {
    property(id: $propertyId) {
      id
      name
      address
      tagline
      heroImages {
        id
        url
      }
    }
  }
`;

export const usePropertyHero = (variables: QueryPropertyPageHeroVariables) => {
  const { useQuery } = useApi();
  return useQuery<QueryPropertyPageHero, QueryPropertyPageHeroVariables>(QUERY_PROPERTY_PAGE_HERO, {
    variables,
  });
};
