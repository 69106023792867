import * as React from 'react';

import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

type SelectValue = string | number | undefined;

interface SelectOption {
  id: SelectValue;
  name: string;
}

interface SelectProps {
  minWidth?: number;
  id?: string;
  name?: string;
  value?: SelectValue;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onValueChange?: (value: string) => void;
  options: Array<SelectOption>;
  error?: boolean;
  testId?: string;
  disabled?: boolean;
  placeholder?: string;
  emptyValue?: string;
  hideBlankOption?: boolean;
  onBlur?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

const S = {
  Select: styled.select`
    padding-left: 10px;
    background-color: #ffffff;
    border: 1px solid #cecece;
    height: 48px;
    margin-bottom: 15px;
    border-radius: 6px;
    font-size: 14px;
    width: 100%;
    display: block;
    color: #000000;
    font-family: ${font.regular};

    &.error {
      border: solid 1px red;
    }

    &:disabled {
      background-color: #ddd;
    }
  `,
};

export const Select = (props: SelectProps) => {
  const emptyVal = props.emptyValue || '';

  return (
    <S.Select
      value={!props.value ? emptyVal : props.value}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
        if (props.onValueChange) {
          props.onValueChange(e.target.value);
        }
      }}
      onBlur={props.onBlur}
      id={props.id}
      name={props.name}
      className={props.error ? ' error' : ''}
      data-testid={props.testId || props.name}
      disabled={props.disabled}
      style={{
        minWidth: props.minWidth,
      }}
    >
      {props.hideBlankOption ? null : (
        <option disabled value={emptyVal}>
          {props.placeholder || ''}
        </option>
      )}

      {props.options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </S.Select>
  );
};
