import { Unit } from '../../types';
import api from '../../utils/api';

export const batchUpdateUnitModels = (propertyId: string, unitModels: Array<Unit>) => {
  return api.put({
    url: `/property/${propertyId}/unit-model/batch`,
    variables: {
      unitModels,
    },
  });
};
