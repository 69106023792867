import { useEffect } from 'react';

interface callbackFunc {
  (): any;
}

const useOnScroll = (isListening: boolean, onScroll: callbackFunc) => {
  useEffect(() => {
    if (isListening) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isListening, onScroll]);
};

export default useOnScroll;
