import * as React from 'react';
import Modal from './Modal';

import { ImageUploader } from './ImageUploader';
import useImageUpload from '../hooks/useImageUploader';

export const ImageUploadModal: React.FC<any> = ({
  isOpen,
  onClose,
  onUpload,
  allowMultiple,
  acceptedTypes,
  dropTypeText,
  uploadType,
}) => {
  const { inputRef, handleClickUpload, isDragging, setIsDragging, initiateUpload } = useImageUpload(
    onUpload,
    allowMultiple,
    uploadType,
  );

  return (
    <Modal
      width={600}
      variant="center"
      isOpen={isOpen || isDragging}
      onClose={() => {
        onClose();
        setIsDragging(false);
      }}
      renderContent={() =>
        isOpen ? (
          <ImageUploader
            isDragging={isDragging}
            inputRef={inputRef}
            handleClickUpload={handleClickUpload}
            initiateUpload={initiateUpload}
            allowMultiple={allowMultiple}
            acceptedTypes={acceptedTypes}
            dropTypeText={dropTypeText}
          />
        ) : null
      }
    />
  );
};
