import * as React from 'react';

import { View } from 'react-native';

interface ISpacer {
  height?: number;
  width?: number;
}

export const Spacer = (props: ISpacer) => {
  return (
    <View
      style={{
        height: props.height || 1,
        width: props.width || 1,
      }}
    ></View>
  );
};
