import { ENABLE_ZENDESK, RESPONSIVE_HEIGHT } from '../utils/constants';
import { useCallback, useEffect, useState } from 'react';

import { useAuthorization } from '../hooks/useAuthorization';
import { useCreateZendeskToken } from '../api';

// @ts-nocheck
declare global {
  interface Window {
    $zopim?: any;
    zE?: any;
    zESettings?: any;
  }
}

const zenStyle = (() => {
  const element = document.createElement('style');
  element.setAttribute('type', 'text/css');
  element.setAttribute('id', 'zenStyle');
  document.head.appendChild(element);
  return element;
})();

export const useZendesk = () => {
  const [createZendeskToken] = useCreateZendeskToken();
  const [loaded, setLoaded] = useState(false);
  const { currentUser } = useAuthorization();

  const setMobileFooter = (footerRef, display: boolean) => {
    if (!ENABLE_ZENDESK) {
      return;
    }

    if (footerRef.current && display) {
      const position =
        footerRef.current.getBoundingClientRect().top - footerRef.current.getBoundingClientRect().height + 10;
      zenStyle.innerHTML = `
        #launcher { 
          display: block;
          right: -19px !important;
          top: ${position}px !important;
        }
      `;
    } else {
      zenStyle.innerHTML = `
        #launcher { 
          display: block;
          top: 90% !important;
        }
    `;
    }
  };

  const activate = useCallback((myUser, isAuthorized) => {
    if (!ENABLE_ZENDESK) {
      return;
    }

    if (isAuthorized) {
      zenStyle.innerHTML = `
          @media (max-height: ${RESPONSIVE_HEIGHT.MEDIUM}) {
            #launcher {
              height: 100% !important;
              top: 85% !important;
              min-height: 100vh !important;
              max-height: 100% !important;
              min-height: -webkit-fill-available !important;
            }
          }
          #launcher { 
            display: block;
          }
        `;
    }
  }, []);

  useEffect(() => {
    if (!ENABLE_ZENDESK || !currentUser?.id || loaded) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=5393b1ab-f55c-42e9-a0f5-372f8311b1ac');
    script.setAttribute('id', 'ze-snippet');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      setLoaded(true);

      const primaryEmail = currentUser.emails.find((email) => email?.emailType?.name === 'Primary');
      window.zE(() => {
        window?.$zopim(() => {
          window?.$zopim.livechat.setName(`${currentUser.firstName} ${currentUser.lastName}`);
          window?.$zopim.livechat.setEmail(primaryEmail?.address);

          window.$zopim.livechat.authenticate({
            jwtFn: async (callback) => {
              const result = await createZendeskToken();
              const jwt = result.data.createZendeskToken.token;
              callback(jwt);
            },
          });
        });
      });
    });
  }, [loaded, currentUser, createZendeskToken]);

  return { activate, setMobileFooter, loaded };
};
