import * as React from 'react';

import styled from 'styled-components';
import { IUpload } from './types';

const Styled = {
  Wrapper: styled.div`
    height: 100%;
    position: relative;
  `,
  Upload: styled.input`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
  `,
};

export const Upload = (props: IUpload) => {
  return (
    <Styled.Wrapper>
      <Styled.Upload
        type="file"
        test-id={props.testID}
        onChange={(event) => {
          if (event.target.files) {
            props.onUpload(event);
          }
        }}
      />
      {props.children}
    </Styled.Wrapper>
  );
};
