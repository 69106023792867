import * as React from 'react';

import { formatImage } from '../utils/formatImage';
import styled from 'styled-components';

interface BannerContainerProps {
  image?: string;
  children: any;
}

const Styled = {
  HeroImageBlank: styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    background-color: #fff;

    max-width: 1170px;

    @media (max-width: 1400px) {
      max-width: 1240px;
    }

    align-self: center;

    * {
      color: #000 !important;
    }
  `,
  HeroImage: styled.div<BannerContainerProps>`
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 810px;
    background-color: #ddd;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5214460784313726) 0%,
        rgba(1, 0, 20, 0) 15%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 83%,
        rgba(0, 0, 0, 0.5746673669467788) 100%
      )
      ${(props) =>
        props.image
          ? `,url(${formatImage(props.image, {
              width: 1800,
            })})`
          : ''};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  `,
};

export const HeroImage = (props: BannerContainerProps) => {
  return props.image ? (
    <Styled.HeroImage {...props}>{props.children}</Styled.HeroImage>
  ) : (
    <Styled.HeroImageBlank>{props.children}</Styled.HeroImageBlank>
  );
};
