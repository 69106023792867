import * as React from 'react';

import styled from 'styled-components';

interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  onValueChange?: (e: boolean) => void;
  onChange?: Function;
  id?: string;
  name?: string;
  value?: any;
}

const S = {
  Input: styled.input`
    appearance: checkbox;
    cursor: pointer;
  `,
};

export const Checkbox = (props: CheckboxProps) => (
  <S.Input
    disabled={props.disabled}
    id={props.id}
    name={props.name}
    data-testid={`checkbox:${props.name}`}
    type="checkbox"
    checked={props.checked}
    value={props.value}
    onChange={(e) => {
      if (props.onValueChange) {
        props.onValueChange(!props.checked);
      }

      if (props.onChange) {
        props.onChange(e);
      }
    }}
  />
);
