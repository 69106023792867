import { RemoteComponent, MetaData, RequestState, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface ComponentsResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<RemoteComponent>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetPropertyComponents = (
  propertyId: number | string,
  variables: RequestVariables = defaultVariables,
) => {
  const [components, refetch] = useApi.get({
    url: `/property/${propertyId}/components`,
    variables,
  });

  return formatApiResult(components, refetch) as ComponentsResponse;
};
