import * as React from 'react';

import { IText } from '../../types';
import styled from 'styled-components/native';

const TEXT_WEIGHT = {
  black: 600,
  bold: 500,
  medium: 400,
  regular: 300,
  light: 200,
  thin: 100,
};

interface ITextProps extends IText {
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}

const StyledText = styled.Text<IText>`
  font-family: '${(props) => {
    return `body${TEXT_WEIGHT[props.weight || 'regular']}${props.italic ? 'Italic' : ''}`;
  }}';
  font-size: ${(props) => props.theme.fontSize[props.size || 'md']}px;
  color: ${(props) => props.theme.color[props.color || 'black']};
  ${(props) => (props.underlined ? `text-decoration: underline ${props.theme.color[props.color || 'black']};` : '')}
  text-align: ${(props) => props.textAlign || 'left'};
  ${(props) =>
    props.singleLine
      ? `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  `
      : ''}
`;

export const Text = (props: ITextProps) => {
  const { children, ...textProps } = props;
  return <StyledText {...textProps}>{children}</StyledText>;
};
