import { MetaData, RequestState, Unit, RequestVariables, UnitSortBy } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

export interface UnitsResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Unit>;
  metadata: MetaData;
  refetch: () => void;
  setData: (data: Array<Unit>) => void;
}

interface GetPropertyVariables extends RequestVariables {
  sortBy?: UnitSortBy;
}

const defaultVariables = {
  offset: 0,
  limit: 10,
};

export const useGetPropertyUnits = (
  propertyId: number | string,
  variables: GetPropertyVariables = defaultVariables,
) => {
  const [units, refetch, setData] = useApi.get({
    url: `/admin/property/${propertyId}/units`,
    variables,
    options: {
      cachePolicy: 3,
    },
  });

  return formatApiResult(units, refetch, setData) as UnitsResponse;
};
