import { MetaData, Floor, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface FloorResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Floor;
  metadata: MetaData;
}

export const useGetFloor = (id: number | string) => {
  const [floor] = useApi.get({
    url: `/floor/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(floor) as FloorResponse;
};
