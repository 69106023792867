import * as React from 'react';

import { ContactSheetAgentCollapsed as ContactSheetAgentCollapsedType } from '../types';
import { font } from '../../../utils/styles';
import styled from 'styled-components';
import { useMobileContactSheet } from '../useMobileContactSheet';
import useTranslations from '../../../translations/useTranslations';

interface ContactSheetAgentCollapsedProps {
  contactSheet: ContactSheetAgentCollapsedType;
}

const Styled = {
  Container: styled.div`
    position: fixed;
    width: 100vw;
    height: 50px;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    z-index: 9997;
    padding: 0px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    justify-content: space-between;
  `,
  Left: styled.div`
    width: calc(50% - 14px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 14px;
  `,
  Button: styled.button`
    background-color: #c9985e;
    width: 50%;
    height: 100%;
    margin: 0px;
    color: #fff;
    font-size: 15px;
    font-family: ${font.regular};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;

    img {
      height: 10px;
      margin: 8px;
    }
  `,
  Title: styled.div`
    font-family: ${font.title};
    font-size: 20px;
    font-weight: 600;
    height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  SubTitle: styled.div`
    font-family: ${font.regular};
    font-size: 10px;
  `,
};

export const ContactSheetAgentCollapsed = (props: ContactSheetAgentCollapsedProps) => {
  const { setContactSheet } = useMobileContactSheet();
  const translations = useTranslations();

  return (
    <Styled.Container>
      <Styled.Left>
        <Styled.Title>{props.contactSheet.propertyName}</Styled.Title>
        <Styled.SubTitle>{props.contactSheet.developerName}</Styled.SubTitle>
      </Styled.Left>
      <Styled.Button
        test-id="mobile-contact-toggle"
        onClick={() => {
          setContactSheet({
            ...props.contactSheet,
            type: 'agentExpanded',
          });
        }}
      >
        {translations.ContactSheet.contactSalesCenter} <img src="/icons/drop-down-arrow.svg" alt="chevron" />
      </Styled.Button>
    </Styled.Container>
  );
};
