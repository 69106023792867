import * as React from 'react';

import styled from 'styled-components';
import { useGetSignedMedia } from '../hooks/useEndpoint';

const Styled = {
  PdfIframe: styled.iframe`
    width: 100%;
    height: 100%;
    background: #ccc;
    overflow: auto;
  `,
  PdfBlank: styled.div`
    width: 100%;
    height: 100%;
    background: #ccc;
  `,
};

interface PdfPreviewProps {
  location?: string | null;
  hideToolbar?: boolean;
}

export const PdfPreview = (props: PdfPreviewProps) => {
  const location = (props.location || '').split('/uploads/')[1];
  const signedMediaResponse = useGetSignedMedia(location);

  return signedMediaResponse?.data?.length ? (
    <Styled.PdfIframe src={`${signedMediaResponse?.data}${props.hideToolbar ? '#toolbar=0' : ''}`}></Styled.PdfIframe>
  ) : (
    <Styled.PdfBlank />
  );
};
