import { gql, useApi } from './Provider';

import { AdminMarketingLinks } from './__generated__/AdminMarketingLinks';

const ADMIN_MARKETING_LINKS = gql`
  query AdminMarketingLinks {
    adminMarketingLinks {
      id
      name
      slug
      type
      status
      visitsCount
    }
  }
`;

export const useGetAdminMarketingLinks = () => {
  const { useQuery } = useApi();
  return useQuery<AdminMarketingLinks>(ADMIN_MARKETING_LINKS, {});
};
