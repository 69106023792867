import { format, isToday } from 'date-fns';

export const numberWithCommas = (x) => {
  try {
    return x
      .toString()
      .replace(/^0/, '') //remove leading zero
      .replace(/[^0-9]/g, '') //replace not numbers
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); //add commas in proper places
  } catch (e) {
    return '';
  }
};

export const numberWithoutCommas = (x: number): number => {
  try {
    return parseInt(
      x
        .toString()
        .replace(/^0/, '') //remove leading zero
        .replace(/[^0-9]/g, ''), //replace not numbers
    );
  } catch (e) {
    return 0;
  }
};

export const abbreviateNumber = (value): string => {
  let newValue = +value;
  if (newValue >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.ceil(('' + value).length / 3 - 1);
    const shortValue = parseFloat((suffixNum !== 0 ? newValue / Math.pow(1000, suffixNum) : newValue).toPrecision(3));
    let shortValueStr = '';

    if (shortValue % 1 !== 0) {
      shortValueStr = shortValue.toFixed(1);
    } else {
      shortValueStr = shortValue.toString();
    }

    return shortValueStr + suffixes[suffixNum];
  }
  return newValue.toString();
};

export const formatAddress = (addr: any) =>
  [
    `${addr.addressLine1 || ''}${addr.addressLine2 ? ` ${addr.addressLine2}` : ''}`,
    `${addr.city || ''}${addr.state ? ` ${addr.state}` : ''}`,
    `${addr.postalCode || ''}`,
  ]
    .filter(Boolean)
    .join(', ');

export const formatAddressCard = (addr: any = {}) =>
  [
    `${addr.addressLine1 || ''}${addr.addressLine2 ? ` ${addr.addressLine2}` : ''}`,
    `${addr.city || ''}`,
    `${addr.state || ''}${addr.postalCode ? ` ${addr.postalCode}` : ''}`,
  ]
    .filter(Boolean)
    .join(', ');

export const formatCurrency = (val: number, round: boolean = true) =>
  // navigator.language is imperfect as it doesn't actually report the browsers set language (translators)
  // a better solution would be to surface the locale that we store in cognito.
  new Intl.NumberFormat(navigator.language, {
    ...(round ? { maximumSignificantDigits: 3 } : {}),
    style: 'currency',
    currency: 'USD',
  }).format(val);

export const formatCurrencyAndRoundDown = (val: number, round = true) => {
  const valueRoundeDown = Math.floor(val);
  const maximumSignificantDigits = valueRoundeDown.toString().length;
  // navigator.language is imperfect as it doesn't actually report the browsers set language (translators)
  // a better solution would be to surface the locale that we store in cognito.
  return new Intl.NumberFormat(navigator.language, {
    ...(round ? { maximumSignificantDigits } : {}),
    style: 'currency',
    currency: 'USD',
  }).format(valueRoundeDown);
};

export const formatLargeCurrency = (val: number) => {
  if (val < 1000) {
    return formatCurrency(val);
  }

  if (val < 1000000) {
    return `${formatCurrency(val / 1000)}K`;
  }

  return `${formatCurrency(val / 1000000)}M`;
};

export const getPricePerSqFt = (price: number, area: number) => {
  if (price && area) {
    return formatCurrency(price / area, false);
  }
  return '';
};

export const formatDate = (date: Date) => format(new Date(date), 'M-dd-yy');

export const formatDateTime = (date: Date) => format(new Date(date), "M-dd-yy 'at' h:mma")?.toLocaleLowerCase();

export const formatNumber = (number: string) =>
  number ? `${number}`.replace('null', '0').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number;

export const formatMoney = (number: number) => {
  const fixedNumber = formatNumber(Number(number).toFixed(2));
  return `$${fixedNumber}`;
};

export const formatReportDate = (dateString: Date): string => {
  const date = new Date(dateString);
  if (isToday(date)) {
    return 'Today at ' + format(date, 'h:mma').toLocaleLowerCase();
  }
  return format(new Date(date), "M/dd/yy 'at' h:mma")?.toLocaleLowerCase();
};
