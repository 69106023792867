import { getItem, nuke, removeItem, setItem } from '../utils/localStorage';

import api from '../utils/api';
import { handle } from '../utils/handle';

const DEFAULT_TIMEOUT_LIMIT = 60000 * 60 * 4; // 4 hours

// if there is an env set, use that limit, or else use the default.
export const getTimeoutLimit = (): number =>
  process.env.REACT_APP_TIMEOUT_LIMIT ? parseInt(process.env.REACT_APP_TIMEOUT_LIMIT) : DEFAULT_TIMEOUT_LIMIT;

export const endSessionAudit = async (endType: 'LOGOUT' | 'TIMEOUT' = 'LOGOUT') => {
  const auditSessionId = getItem('vysta:auditSessionId');
  if (auditSessionId) {
    await handle(
      api.put({
        url: `/audits/session/${auditSessionId}`,
        variables: {
          endType: endType,
        },
      }),
    );
    removeItem('vysta:auditSessionId');
  }
};

export const startSessionAudit = async () => {
  const auditSessionId = getItem('vysta:auditSessionId');

  if (!auditSessionId) {
    const [res] = await handle(
      api.post({
        url: `/audits/session`,
      }),
    );
    const auditSession = res?.data?.data?.auditSession;

    if (auditSession) {
      setItem('vysta:auditSessionId', auditSession.id);
    } else {
      await endSession();
    }
  }
};

export const endSession = async () => {
  await endSessionAudit('TIMEOUT');
  nuke();
  window.location.href = '/login';
};

export const startSessionTimer = () => {
  const oldTimerId = getItem('timerId');
  if (oldTimerId) {
    window.clearTimeout(parseInt(oldTimerId));
  }
  if (getItem('vysta:authToken')) {
    const timeoutLimit = getTimeoutLimit();
    const timerId = window.setTimeout(() => {
      // if the same timerId is still in local storage, then end the session.
      // this will handle the cases of many windows/tabs
      const curTimerId = getItem('timerId');
      if (timerId.toString() === curTimerId) {
        endSession();
      }
    }, timeoutLimit);
    setItem('timerId', timerId.toString());
  }
};
