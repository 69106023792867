import { useApi, gql } from './Provider';
import { SyncAgentsWithSendGrid } from './__generated__/SyncAgentsWithSendGrid';

const SYNC_AGENTS_WITH_SENDGRID = gql`
  mutation SyncAgentsWithSendGrid {
    syncAgentsWithSendGrid {
      success
    }
  }
`;

export const useSyncAgentsWithSendgrid = () => {
  const { useMutation } = useApi();
  return useMutation<SyncAgentsWithSendGrid>(SYNC_AGENTS_WITH_SENDGRID);
};
