import React, { ReactChild, ReactChildren } from 'react';

import styled from 'styled-components/native';

export type FlexJustify = 'space-between' | 'flex-start' | 'flex-end' | 'space-around' | 'space-evenly' | 'center';

export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type FlexAlignItems = 'flex-start' | 'center' | 'flex-end';

interface IFlex {
  direction?: FlexDirection;
  justify?: FlexJustify;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  spacing?: number;
  alignItems?: FlexAlignItems;
  zIndex?: number;
  width?: number;
}

interface IStyledFlex {
  direction: FlexDirection;
  justify: FlexJustify;
  spacing: number;
  alignItems: FlexAlignItems;
  zIndex?: number;
}

const Styled = {
  Flex: styled.View<IStyledFlex>`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${(props) => props.justify};
    position: relative;
    align-items: ${(props) => props.alignItems};
    flex-direction: ${(props) => props.direction};
  `,
};

export const Flex = (props: IFlex) => {
  return (
    <Styled.Flex
      zIndex={props.zIndex}
      direction={props.direction || 'row'}
      justify={props.justify || 'flex-start'}
      spacing={props.spacing || 0}
      alignItems={props.alignItems || 'flex-start'}
    >
      {props.children}
    </Styled.Flex>
  );
};
