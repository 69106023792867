import { RESPONSIVE } from '../utils/constants';
import { useMediaQuery } from 'react-responsive';

export const useResponsiveSizes = () => {
  const isXXXSmall = useMediaQuery({ query: `(max-width: ${RESPONSIVE.XXX_SMALL})` });
  const isXXSmall = useMediaQuery({ query: `(max-width: ${RESPONSIVE.XX_SMALL})` });
  const isExtraSmall = useMediaQuery({ query: `(max-width: ${RESPONSIVE.EXTRA_SMALL})` });
  const isSmall = useMediaQuery({ query: `(max-width: ${RESPONSIVE.SMALL})` });
  const isMedium = useMediaQuery({ query: `(max-width: ${RESPONSIVE.MEDIUM})` });
  const isLarge = useMediaQuery({ query: `(max-width: ${RESPONSIVE.LARGE})` });
  const isExtraLarge = useMediaQuery({ query: `(max-width: ${RESPONSIVE.EXTRA_LARGE})` });

  return {
    isXXXSmall,
    isXXSmall,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
  };
};
