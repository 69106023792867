import { useApi, gql } from './Provider';

import { GetClient, GetClientVariables } from './__generated__/GetClient';

const GET_CLIENT = gql`
  query GetClient($id: Int!, $takeActivities: Int) {
    user(id: $id) {
      id
      activeAt
      verifiedAt
      fullName
      recentActivities(take: $takeActivities) {
        description
        occurredAt
      }
      primaryEmail {
        id
        address
      }
      primaryAddress {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
      }
      workPhone {
        id
        number
      }
      homePhone {
        id
        number
      }
      createdAt
      lastLogin
      favorites {
        properties {
          id
          heroImages {
            id
            url
          }
          organizations {
            id
            name
            organizationTypes {
              id
              name
            }
          }
          isFavorite
          name
          minimumPrice
          maximumPrice
          unitsCount
          unitsAvailable: unitsCount(unitStatusName: "Available")
          floorsCount
          address
          displayPriceStartAt
          hasOrbitReel
          salesStatus {
            id
            name
          }
          constructionStatus {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGetClient = (variables: GetClientVariables) => {
  const { useQuery } = useApi();
  return useQuery<GetClient, GetClientVariables>(GET_CLIENT, {
    variables,
  });
};
