import * as React from 'react';

import { ChangeEvent } from 'react';
import { font } from '../utils/styles';
import styled from 'styled-components';

type RadioValue = string | number | undefined;

interface RadioProps {
  minWidth?: number;
  id?: string;
  name: string;
  label?: string;
  value?: RadioValue;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  error?: boolean;
  testId?: string;
  disabled?: boolean;
  isSelected?: boolean;
}

const S = {
  RadioContainer: styled.div`
    margin: 5px 0;
    font-family: ${font.regular};

    @keyframes ripple {
      0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
      }
      50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
      }
      100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
      }
    }
  `,
  Radio: styled.input`
    display: none;

    &:checked + label:before {
      border-color: rgb(51, 122, 183);
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  `,
  Label: styled.label`
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &.error {
      border: solid 1px red !important;
    }

    &:disabled {
      background-color: #ddd !important;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 4px;
      width: 10px;
      height: 10px;
      border: 2px solid rgba(0, 0, 0, 0.54);
    }

    &:after {
      top: 4px;
      left: 0;
      width: 10px;
      height: 10px;
      transform: scale(0);
      background: rgb(51, 122, 183);
    }
  `,
};

export const Radio = (props: RadioProps) => (
  <S.RadioContainer>
    <S.Radio
      id={props.id}
      name={props.name}
      className={props.error ? ' error' : ''}
      data-testid={props.testId || props.name}
      disabled={props.disabled}
      value={props.value || undefined} // Note(Jesse): The console was complaining about this being null.. so we pass undefined instead... Javascript ladies and gentlemen..
      type="radio"
      checked={props.isSelected}
      onChange={() => {
        if (props.onValueChange) {
          props.onValueChange(props.name);
        }
      }}
    />
    <S.Label htmlFor={props.id}>{props.label}</S.Label>
  </S.RadioContainer>
);
