import * as React from 'react';
import * as S from './styles';

interface Props {
  iconName?: string;
  label: string;
  isActive?: boolean;
  testId?: string;
  onChange?(active: boolean): void;
}

const ToggleButton = (props: Props) => {
  const { iconName, label, isActive, testId, onChange } = props;

  const hasIcon = iconName ? true : false;

  const handleSelection = () => {
    if (onChange) {
      onChange(!isActive);
    }
  };

  return (
    <S.Container data-testid={testId} hasIcon={hasIcon} selected={isActive || false} onClick={handleSelection}>
      {iconName && <img alt={iconName} src={iconName} />}
      <span>{label}</span>
    </S.Container>
  );
};

export default ToggleButton;
