const uriCache = {} as any;

export const decodeUrlSearch = (search: string): any => {
  if (uriCache[search]) {
    return uriCache[search];
  }
  const splitSearch = search.replace('?', '').split('&') || [];

  uriCache[search] = splitSearch.reduce((prev, current) => {
    const [key, value] = current.split('=');
    if (key === 'geo') {
      return {
        ...prev,
        [key]: JSON.parse(decodeURIComponent(value)),
      };
    }
    return value
      ? {
          ...prev,
          [key]: decodeURIComponent(value),
        }
      : prev;
  }, {});

  return uriCache[search];
};

export const encodeUrlSearch = (search: any): string => {
  const searchString = Object.keys(search)
    .map((key) => {
      return `${key}=${encodeURIComponent(search[key])}`;
    })
    .join('&');

  return searchString ? `?${searchString}` : '';
};
