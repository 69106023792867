import { MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface SignedUrlResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: string;
  metadata: MetaData;
  refetch: () => void;
}

interface SignedUrlVariables {
  disposition?: string;
}

export const useGetSignedMedia = (location: string, variables?: SignedUrlVariables) => {
  if (!location) {
    return {
      data: '',
    };
  }

  const [url, refetch] = useApi.get({
    url: `/media/signed/${location}`,
    variables,
  });

  return formatApiResult(url, refetch) as SignedUrlResponse;
};
