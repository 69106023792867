import { IStyledImage } from './IconTypes';

export const getIconSize = (props: IStyledImage) =>
  props.exactSize
    ? props.exactSize
    : props.size === 'xxl'
    ? 52
    : props.size === 'xl'
    ? 36
    : props.size === 'lg'
    ? 22
    : props.size === 'sm'
    ? 16
    : props.size === 'xs'
    ? 14
    : props.size === 'xxs'
    ? 10
    : props.size === 'xxxs'
    ? 6
    : 20;
