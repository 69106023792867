import { Floor, MetaData, RequestState, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface FloorResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<Floor>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetPropertyFloors = (propertyId: number | string, variables: RequestVariables = defaultVariables) => {
  const [floors, refetch] = useApi.get({
    url: `/property/${propertyId}/floors`,
    variables,
  });

  return formatApiResult(floors, refetch) as FloorResponse;
};
