import { Button, Form, Input, Spacer } from '../../../components';
import { Avatar } from '../../../components/Avatar';
import React, { useState, useEffect } from 'react';
import { useFavoriteUnit, useGetPropertyUnitLazy, useUnFavoriteUnit, useGetMe, useMessageAgent } from '../../../api';

import { ContactSheetClientExpanded as ContactSheetClientExpandedType } from '../types';
import { font } from '../../../utils/styles';
import { handle } from 'cartesian-js';
import styled from 'styled-components';
import { useFavoriteProperty } from '../../../hooks/useFavoriteProperty';
import { useMobileContactSheet } from '../useMobileContactSheet';
import { useToasts } from 'react-toast-notifications';
import useTranslations from '../../../translations/useTranslations';

export interface SetShareProperty {
  propertyId: string;
  unitId?: string;
}

interface ContactSheetClientExpandedProps {
  contactSheet: ContactSheetClientExpandedType;
}

interface ContainerProps {
  height: number;
}

const Styled = {
  Container: styled.div<ContainerProps>`
    position: fixed;
    width: 100vw;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    z-index: 9999;
    padding: 0px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    flex-direction: column;
    height: ${(props) => props.height}px;

    input {
      height: 45px;
      padding-left: 12px;
    }

    textarea {
      height: 195px;
    }
  `,
  ContainerInner: styled.div`
    padding: 14px;
    position: relative;
    padding-top: 44px;
  `,
  Close: styled.button`
    position: absolute;
    top: 10px;
    right: 10px;

    img {
      height: 20px;
    }
  `,
  Title: styled.div`
    font-family: ${font.title};
    font-size: 24px;
    font-weight: 600;
    height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
  `,
  SubTitle: styled.div`
    font-family: ${font.regular};
    font-size: 10px;
    width: 100%;
    text-align: center;
  `,
  Background: styled.div`
    position: fixed;
    z-index: 9997;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.6;
  `,
  Questions: styled.div`
    font-family: ${font.regular};
    font-size: 16px;
    width: 100%;
    text-align: center;
  `,
  Avatar: styled.div`
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: calc(50% - 30px);
  `,
};

export const ContactSheetClientExpanded = (props: ContactSheetClientExpandedProps) => {
  const { addToast } = useToasts();
  const [fetchPropertyUnit, { data }] = useGetPropertyUnitLazy();
  const [messageAgent] = useMessageAgent();
  const [showErrors, setShowErrors] = useState(false);
  const [isFavorite, setIsFavorite] = useState(props.contactSheet.isFavorite);
  const { toggleFavorite: toggleFavoriteProperty } = useFavoriteProperty();
  const [favoriteUnit] = useFavoriteUnit();
  const [unfavoriteUnit] = useUnFavoriteUnit();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const { setContactSheet } = useMobileContactSheet();
  const translations = useTranslations();
  const { data: myData } = useGetMe();
  const close = () => {
    setContactSheet({
      ...props.contactSheet,
      type: 'clientCollapsed',
    });
  };
  const t = translations.ContactSheet;

  useEffect(() => {
    if (props.contactSheet.unitId) {
      const fetchUnit = async () => {
        await fetchPropertyUnit({
          variables: {
            unitId: +props.contactSheet.unitId,
          },
        });
      };

      fetchUnit();
    }
  }, [props.contactSheet.unitId, fetchPropertyUnit]);

  const unitName = data?.unit?.name || '';

  const handleSendMessage = async () => {
    setShowErrors(true);

    if (!subject || !message) {
      return;
    }

    const [error] = await handle(
      messageAgent({
        variables: {
          input: {
            propertyId: +props.contactSheet.propertyId,
            unitId: props.contactSheet.unitId && +props.contactSheet.unitId,
            inquiry: props.contactSheet.unitId ? 'Unit' : 'PropertyTour',
            subject,
            message,
            messageType: 'ContactForm',
          },
        },
      }),
    );

    if (error) {
      return addToast(translations.FormErrors.generic, {
        appearance: 'error',
      });
    }

    addToast(t.messageSent, {
      appearance: 'success',
    });

    close();
  };

  const handleShare = async () => {
    close();

    const [error] = await handle(
      messageAgent({
        variables: {
          input: {
            subject: `Vysta Tour Request`,
            inquiry: 'PropertyTour',
            messageType: 'ScheduleTour',
            propertyId: +props.contactSheet.propertyId,
            unitId: props.contactSheet.unitId && +props.contactSheet.unitId,
          },
        },
      }),
    );

    if (error) {
      return addToast(translations.FormErrors.generic, {
        appearance: 'error',
      });
    }

    addToast(t.requestTourSuccess, {
      appearance: 'success',
    });
  };

  const handleFavorite = async () => {
    if (!props.contactSheet.unitId) {
      toggleFavoriteProperty(props.contactSheet.propertyId, isFavorite);
      setIsFavorite((isFavorite) => !isFavorite);
      return;
    }

    if (data?.unit?.isFavorite) {
      return await handle(
        unfavoriteUnit({
          variables: {
            unitId: +props.contactSheet.unitId,
          },
        }),
      );
    }

    await handle(
      favoriteUnit({
        variables: {
          unitId: +props.contactSheet.unitId,
        },
      }),
    );
  };

  return (
    <>
      <Styled.Background onClick={close} />
      <Styled.Container height={props.contactSheet.messageType === 'message' ? 464 : 607}>
        <Styled.ContainerInner>
          <Styled.Title>{`${myData?.me?.agent?.firstName || ''} ${myData?.me?.agent?.lastName || ''}`}</Styled.Title>
          <Styled.SubTitle>
            {(myData?.me?.agent?.organizations || [])
              .filter((org) => org.organizationTypes.find((type) => type.name === 'Brokerage'))
              .map((org) => org.name)
              .join(', ')}
          </Styled.SubTitle>
          {props.contactSheet.messageType !== 'message' && (
            <>
              <Spacer height={18} />
              <Button
                type="button"
                width="100%"
                testId="share-button"
                height="45px"
                fontSize="16px"
                onClick={handleFavorite}
                icon="favorite-light"
                iconType="svg"
                iconSize={24}
              >
                {props.contactSheet.unitId
                  ? !data?.unit?.isFavorite
                    ? `${t.favoriteUnit} ${unitName}`
                    : `${t.unFavoriteUnit} ${unitName}`
                  : isFavorite
                  ? t.unFavoriteProperty
                  : t.favoriteProperty}
              </Button>
              <Spacer height={8} />
              <Button
                width="100%"
                testId="share-button"
                height="45px"
                fontSize="16px"
                onClick={handleShare}
                icon="request-tour"
                iconType="svg"
                iconSize={24}
              >
                {t.requestATour}
              </Button>
            </>
          )}
          <Form onSubmit={handleSendMessage}>
            <Spacer height={22} />
            <Styled.Questions>
              {t.descriptions[props.contactSheet.messageType].replace('{{unit}}', unitName)}
            </Styled.Questions>
            <Spacer height={22} />
            <Input
              type="text"
              placeholder={t.subject}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              error={showErrors && !subject}
            />
            <Spacer height={13} />
            <Input
              error={showErrors && !message}
              type="textArea"
              placeholder={t.message}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Spacer height={13} />
            <Button
              type="submit"
              width="100%"
              color="#000"
              testId="share-button"
              height="45px"
              fontSize="16px"
              icon="chat"
              iconType="svg"
              iconSize={24}
            >
              {t.sendMessage}
            </Button>
          </Form>
          <Styled.Avatar>
            <Avatar size={60} src={myData?.me?.agent?.profileAvatarUrl} />
          </Styled.Avatar>
          <Styled.Close test-id="mobile-contact-close" onClick={close}>
            <img src="/icons/close.svg" alt="close" />
          </Styled.Close>
        </Styled.ContainerInner>
      </Styled.Container>
    </>
  );
};
