import { useFavoriteProperty as useFavoritePropertyApi, useUnFavoriteProperty } from '../api';

// @deprecated
export const useFavoriteProperty = () => {
  const [favoriteProperty] = useFavoritePropertyApi();
  const [unFavoriteProperty] = useUnFavoriteProperty();

  const toggleFavorite = async (propertyId: string, isFavorite: boolean) => {
    if (isFavorite) {
      return await unFavoriteProperty({
        variables: {
          propertyId: +propertyId,
        },
      });
    }

    return await favoriteProperty({
      variables: {
        propertyId: +propertyId,
      },
    });
  };

  return {
    toggleFavorite,
  };
};
