import { ReSendInvite, ReSendInviteVariables } from './__generated__/ReSendInvite';
import { gql, useApi } from './Provider';

const RESEND_INVITE = gql`
  mutation ReSendInvite($emailAddress: String!) {
    reSendInvite(emailAddress: $emailAddress) {
      success
    }
  }
`;

export const useReSendInvite = () => {
  const { useMutation } = useApi();
  return useMutation<ReSendInvite, ReSendInviteVariables>(RESEND_INVITE);
};
