declare global {
  interface Window {
    analytics?: any;
  }
}

interface IIdentity {
  name: string;
  email: string;
}

interface ITrack {
  [key: string]: string;
}

let identity = null;
let currentPage = null;

export const page = (name: string) => {
  if (name === currentPage) {
    return;
  }

  if (window.analytics) {
    currentPage = name;
    window.analytics.page(name);
  }
};

export const identify = (id: string, options: IIdentity) => {
  // Prevent multiple identity records
  if (id === identity) {
    return;
  }

  if (window.analytics) {
    identity = id;
    window.analytics.identify(id, options);
  }
};

export const track = (eventName: string, options: ITrack) => {
  if (window.analytics) {
    window.analytics.track(eventName, options);
  }
};
