import { Editors } from 'react-data-grid-addons';

export const getDropdownOptions = (options: Array<{ id: string; name: string }>) => {
  const optionsWithEmpty = [
    {
      id: '',
      name: '',
    },
    ...options,
  ];

  return optionsWithEmpty
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((option) => ({
      id: option.id,
      value: option.id,
      title: option.name,
      text: option.name,
    }));
};

export const GridDropDownEditor = Editors.DropDownEditor;
