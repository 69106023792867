import { useApi, gql } from './Provider';
import {
  ContactSalesCenter as ContactSalesCenterType,
  ContactSalesCenterVariables,
} from './__generated__/ContactSalesCenter';

const CONTACT_SALES_CENTER = gql`
  mutation ContactSalesCenter($input: ContactSalesCenterInput!) {
    contactSalesCenter(input: $input) {
      id
    }
  }
`;

export const useContactSalesCenter = () => {
  const { useMutation } = useApi();
  return useMutation<ContactSalesCenterType, ContactSalesCenterVariables>(CONTACT_SALES_CENTER);
};
