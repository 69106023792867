import React, { HTMLAttributes } from 'react';

import styled from 'styled-components';

interface IconProps extends HTMLAttributes<HTMLImageElement> {
  family?: 'regular' | 'bold' | 'light';
  color?: 'black' | 'white' | 'primary';
  type?: string;
  size?: 10 | 15 | 18 | 24 | 48 | 96 | 512;
  cssHeight?: string;
  cssWidth?: string;
  onClick?: () => void;
  id?: string;
}

const defaultProps: IconProps = {
  onClick: () => {},
};

export const CustomIcon = (props: IconProps = defaultProps) => {
  const { family, color, type, size, onClick, id } = props;

  const iconSize = size < 24 ? 24 : size;
  const root = 'https://vysta.io';

  return (
    <StyledIcon
      id={id}
      {...props}
      src={`${root}/gh-icons/${family}/${color}/${iconSize}/${type}.png`}
      onClick={onClick}
    />
  );
};

CustomIcon.defaultProps = {
  family: 'regular',
  color: 'black',
  size: 24,
};

const StyledIcon = styled.img<IconProps>`
  display: inline-block;
  width: ${props => (props.cssWidth ? props.cssWidth : props.size)}px;
  height: ${props => (props.cssHeight ? props.cssHeight : props.size)}px;
`;
