import { GetMe_me, GetMe_me_agent, GetMe_me_userRoles } from '../api/__generated__/GetMe';
import { PROPERTY_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../utils/constants';
import { endSessionAudit, startSessionAudit } from '../audits/session';
import { getItem, removeItem, setItem } from '../utils/localStorage';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetMe, useLoginWithEmail, useRefreshToken } from '../api';

import LogRocket from 'logrocket';
import { debug } from '../utils/debug';
import { identify } from '../utils/analytics';
import { queryStringToObject } from '../utils/url';
import { useLocation } from 'react-router-dom';

export type CurrentUser = GetMe_me | null;

export type CurrentAgent = GetMe_me_agent | CurrentUser;

export type UserRole = GetMe_me_userRoles | null;

interface ILogin {
  email: string;
  password: string;
  redirectTo?: string;
}

interface IRefreshToken {
  refreshToken: string;
  deviceKey: string;
}

interface UseAuthorizationResponse {
  currentUser: GetMe_me | null;
  loading: boolean;
  canShowLogin: boolean;
  authorized: boolean | null;
  logout: () => void;
  refetchCurrentUser: () => void;
  loginWithEmail: (input: ILogin) => Promise<boolean>;
  hasOneOfRoles: (roles: Array<string>) => boolean;
  getHomeRoute: (user?: GetMe_me) => string;
  refreshToken: (input: IRefreshToken) => Promise<boolean>;
}

let previousUser = null;

export const useAuthorization = (): UseAuthorizationResponse => {
  const redirectAfterLogout = useRef(false);
  const [doLoginWithEmail] = useLoginWithEmail();
  const [doRefreshToken] = useRefreshToken();
  const location = useLocation();

  const { error, data, loading, refetch: refetchMe } = useGetMe();
  const [canShowLogin, setCanShowLogin] = useState(false);

  const currentUser = data?.me;

  const hasOneOfRoles = useCallback(
    (roles: Array<string>) => {
      if (!currentUser?.userRoles?.length) {
        return false;
      }

      return currentUser?.userRoles?.reduce((prev, userRole) => {
        return roles.includes(userRole?.role?.baseName) || prev;
      }, false);
    },
    [currentUser?.userRoles],
  );

  const getLoginRoute = () =>
    hasOneOfRoles([SUPER_ADMIN_ROLE, PROPERTY_ADMIN_ROLE])
      ? '/login'
      : currentUser?.vanityStub || currentUser?.agent?.vanityStub
      ? `/agent/${currentUser?.vanityStub || currentUser?.agent?.vanityStub}`
      : '/login';

  const getHomeRoute = useCallback(
    (authUser) => {
      const user = authUser || currentUser;
      const params = queryStringToObject(location.search);
      const emulateUser = params?.emulateUser;
      const redirect =
        params?.redirect || (params?.propertyId ? `/property/${params?.propertyId}` : getItem('redirectTo') || null);
      const isAdmin = !!user?.userRoles?.find((userRole) => userRole.role.baseName === SUPER_ADMIN_ROLE);
      const isDeveloper = !!user?.userRoles?.find((userRole) => userRole.role.baseName === PROPERTY_ADMIN_ROLE);

      const url = emulateUser
        ? null
        : redirect
        ? (redirect as string)
        : isAdmin || isDeveloper
        ? '/search'
        : user?.vanityStub || user?.agent?.vanityStub
        ? `/agent/${user?.vanityStub || user?.agent?.vanityStub}`
        : '/search';

      return url;
    },
    [currentUser, location.search],
  );

  useEffect(() => {
    setCanShowLogin(error || (!loading && !data?.me) ? true : false);
  }, [error, loading, data, canShowLogin]);

  useEffect(() => {
    if (!currentUser) {
      previousUser = null;
    }

    if (currentUser && !previousUser) {
      previousUser = currentUser;

      debug('auth:currentUser')(currentUser);

      identify(`${currentUser.id}`, {
        name: currentUser.fullName,
        email: currentUser.emails[0]?.address,
      });

      LogRocket.identify(currentUser.identityId, {
        name: currentUser?.fullName,
        email: currentUser?.emails.find((email) => email.emailType?.name === 'Primary')?.address,
      });
    }
  }, [currentUser]);

  const loginWithEmail = useCallback(
    async (input: ILogin) => {
      debug('auth:login')({
        email: input.email,
      });

      if (input.redirectTo) {
        setItem('redirectTo', input.redirectTo);
      }

      const result = await doLoginWithEmail({
        variables: {
          input: {
            email: input.email,
            password: input.password,
          },
        },
      });

      if (result?.data) {
        setItem('vysta:authToken', result?.data?.loginWithEmail?.accessToken);
        setItem('vysta:refreshToken', result?.data?.loginWithEmail?.refreshToken);
        setItem('vysta:deviceKey', result?.data?.loginWithEmail?.deviceKey);

        await startSessionAudit();
      }

      const homeRoute = getHomeRoute(result?.data?.loginWithEmail?.me);

      removeItem('redirectTo');

      window.location.href = homeRoute;

      return true;
    },
    [doLoginWithEmail, getHomeRoute],
  );

  const refreshToken = useCallback(
    async (input: IRefreshToken) => {
      const result = await doRefreshToken({
        variables: {
          input: {
            refreshToken: input.refreshToken,
            deviceKey: input.deviceKey,
          },
        },
      });

      if (result?.data) {
        setItem('vysta:authToken', result?.data?.refreshToken?.accessToken);
        setItem('vysta:idToken', result?.data?.refreshToken?.idToken);
        setItem('vysta:token:expiresIn', result?.data?.refreshToken?.expiresIn);

        await startSessionAudit();
      }

      return true;
    },
    [doRefreshToken],
  );

  const logout = async () => {
    redirectAfterLogout.current = true;
    debug('auth:logout')(currentUser);
    await endSessionAudit('LOGOUT');
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('vysta:')) {
        localStorage.removeItem(key);
      }
    });
    window.location.href = getLoginRoute();
  };

  return {
    currentUser,
    loading,
    canShowLogin,
    authorized: !!data?.me,
    loginWithEmail,
    logout,
    refetchCurrentUser: refetchMe,
    hasOneOfRoles,
    getHomeRoute,
    refreshToken,
  };
};
