import styled from 'styled-components';
import { font } from '../../utils/styles';

export const InputField = styled.input`
  background-color: #fff;
  border: 1px solid #000000;
  font-size: 13px;
  line-height: 32px;
  width: 100%;
  display: block;
  color: #000000;
  font-family: ${font.regular};
  &::-webkit-input-placeholder {
    /* Edge */
    color: #000000 !important;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000000 !important;
  }

  &::placeholder {
    color: #000000 !important;
  }

  &.error {
    border: solid 1px red;
  }

  &:disabled {
    background: #ddd;
  }
`;

export const InputTextArea = styled.textarea`
  resize: vertical;
  min-height: 47px;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 10px 15px;
  font-size: 13px;
  width: 100%;
  display: block;
  color: #000000;
  font-family: ${font.regular};
  &::-webkit-input-placeholder {
    /* Edge */
    color: #000000 !important;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000000 !important;
  }

  &::placeholder {
    color: #000000 !important;
  }

  &.error {
    border: solid 1px red;
  }
`;
