import * as React from 'react';
import * as translations from './translations';

import { Button, Flex, Input, SortTh, Spacer, createTestId, useTranslation } from '../../';
import { compose, constant } from 'lodash/fp';

type IFilter = string | Array<string>;

interface ISortFilterTh {
  hidden?: boolean;
  testID: string;
  name: string;
  label: string;
  desc: boolean;
  sortBy: string;
  value: string;
  handleSort: () => void;
  addFilter: (key: string) => (value: IFilter) => void;
  removeFilter: (key: string) => () => void;
  filters: {
    [key: string]: IFilter;
  };
}

export const SortFilterTh = (props: ISortFilterTh) => {
  const testId = createTestId(props.testID);
  const t = useTranslation<typeof translations.en>(translations);

  return (
    <SortTh
      hidden={props.hidden}
      testID={testId(`sort-by-${props.name}`)}
      onPress={props.handleSort}
      label={props.label}
      selected={props.sortBy === props.name}
      desc={props.desc}
      filterActive={!!props.filters[props.name]}
      renderFilter={(setShowFilters) => (
        <Flex>
          <Input
            name={testId(`filter-by-${props.name}`)}
            value={props.value}
            onValueChange={props.addFilter(props.name)}
            placeholder={t.filterBy}
          />
          <Spacer height={7} />
          <Button
            color="gray1"
            size="sm"
            testID={testId(`clear-filter-by-${props.name}`)}
            onPress={compose(setShowFilters, constant(false), props.removeFilter(props.name))}
          >
            {t.clear}
          </Button>
        </Flex>
      )}
    />
  );
};
