import * as React from 'react';
import { Media } from '../types';
import styled from 'styled-components';
import { Icon } from './Icon';

interface VideoThumbnailProps {
  video: Media;
}

const Styled = {
  Container: styled.div`
    background: #ccc;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Thumbnail: styled.img``,
};

export const VideoThumbnail = (_props: VideoThumbnailProps) => {
  return (
    <Styled.Container>
      <Icon type="videocam" size={44} color="#999" />
    </Styled.Container>
  );
};
