import { PASSWORD_MINIMUM } from './constants';

type StringType = string | null | undefined;
type LengthType = string | null | undefined | Array<any>;

type ValidateFunc = (value: any, fieldValues: Record<string, any>) => boolean;
type MatchFunc = ValidateFunc;

export const is = {
  match: (testFn: MatchFunc, message = '') => (value: any, fieldValues: Record<string, any>) =>
    !testFn(value, fieldValues) && message,

  required: () => (value: any) => isNilOrEmptyString(value) && 'This field is required',

  minLength: (min: number) => (value: LengthType) =>
    !!value && value.length < min && `Must be at least ${min} characters`,

  maxLength: (max: number) => (value: LengthType) =>
    !!value && value.length > max && `Must be at most ${max} characters`,

  notEmptyArray: () => (value: Array<any>) => value.length === 0 && 'Please add at least one item',

  email: () => (value: StringType) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !value || emailRegex.test(value);
  },

  phoneNumber: () => (value: StringType) =>
    !value ||
    /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/.test(value),

  url: () => (value: StringType) =>
    !!value &&
    // eslint-disable-next-line no-useless-escape
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value) &&
    'Must be a valid URL',

  uri: (value: string) => {
    const regexp = /^[(http|https)://]?.*\.[a-z]{1,3}(\/.*)*$/;
    return regexp.test(value);
  },

  html: (string) => {
    var a = document.createElement('div');
    a.innerHTML = string;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType === 1) return true;
    }
    return false;
  },
};

const isNilOrEmptyString = (value: StringType) => value === undefined || value === null || value === '';

export const generateErrors = (fieldValues: Record<string, any>, fieldValidators: Record<string, ValidateFunc>) => {
  const errors: Record<string, string> = {};

  Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
    [validators].flat().forEach((validator) => {
      const errorMessage = validator(fieldValues[fieldName], fieldValues);
      if (errorMessage && !errors[fieldName]) {
        errors[fieldName] = errorMessage;
      }
    });
  });
  return errors;
};

export const checkPasswordRequirements = (
  password: string,
): { passes: boolean; passwordRequirements: Record<string, boolean> } => {
  const passwordRequirements: Record<string, boolean> = {};
  let passes = true;
  if (password.length >= PASSWORD_MINIMUM) {
    passwordRequirements['length'] = true;
  } else {
    passes = false;
  }

  return { passes, passwordRequirements };
};
