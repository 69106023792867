import * as React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';
import { Media } from '../types';
import { useGetSignedMedia } from '../hooks/useEndpoint';

const Styled = {
  Container: styled.div`
    background: #ccc;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  `,
  PdfIframe: styled.embed`
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
};

interface PdfThumbnailProps {
  pdf: Media;
}

export const PdfThumbnail = (props: PdfThumbnailProps) => {
  const location = (props?.pdf?.location || '').split('/uploads/')[1];
  const signedMediaResponse = useGetSignedMedia(location);

  return (
    <Styled.Container>
      {signedMediaResponse?.data ? (
        <Styled.PdfIframe type="application/pdf" src={signedMediaResponse?.data}></Styled.PdfIframe>
      ) : (
        <Icon type="picture_as_pdf" size={44} color="#999" />
      )}
    </Styled.Container>
  );
};
