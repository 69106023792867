import React from 'react';
import styled from 'styled-components';

interface IconProps {
  className?: string;
  family?: string;
  color?: string;
  type?: string;
  name?: string;
  size?: number;
  left?: number;
  top?: number;
  clickable?: boolean;
  onClick?: (event: React.MouseEvent) => any;
}

const defaultProps: IconProps = {
  className: undefined,
  clickable: false,
  size: 16,
  left: 0,
  top: 0,
};

export const Icon = (props: IconProps = defaultProps) => {
  const { className, family, color, type, size, left, top, clickable, name, onClick } = props;
  return (
    <StyledIcon
      className={`${className ? className : ''} material-icons${family ? `-${family}` : ''}`}
      name={name}
      onClick={onClick}
      color={color}
      size={size}
      left={left}
      top={top}
      clickable={clickable}
    >
      {type}
    </StyledIcon>
  );
};

export const StyledIcon = styled.i<IconProps>`
  display: inline-block;
  font-size: ${(props) => `${props.size}px`};
  ${(props) => (props.clickable ? `cursor: pointer;` : '')}
  color: ${(props) => (props.color ? `${props.color}` : '#fff')};
  ${(props) => (props.left || props.top ? `transform: translate(${props.left}px, ${props.top}px);` : '')}
`;

export default Icon;
