import * as React from 'react';
import { Button, Spinner } from './';
import styled from 'styled-components';
import { font } from '../utils/styles';
import ReactPaginate from 'react-paginate';

const LIMIT_TABLE = 25;

interface Item {
  name: string;
  id: number | string;
}

interface SuperShellProps {
  isLoading: boolean;
  title: string;
  newText: string;
  editText: string;
  deleteText: string;
  name: string;
  handleCreate: () => void;
  handleEdit: (item: Item) => void;
  handleDelete: (item: Item) => void;
  data: Array<Item>;
  totalRecords: number;
  page: number;
  setPage: (page: number) => void;
  perPage?: number;
}

export const SuperShell = (props: SuperShellProps) => (
  <Styled.PortalPage>
    {props.isLoading && (
      <Styled.SpinnerDiv>
        <Spinner size={40} />
      </Styled.SpinnerDiv>
    )}
    <Styled.PageHeader>
      <Styled.PageHeaderTitle>{props.title}</Styled.PageHeaderTitle>
      <Button
        color="#CDA263"
        onClick={props.handleCreate}
        width="116px"
        height="40px"
        data-testid={`${props.name}:add`}
      >
        {props.newText}
      </Button>
    </Styled.PageHeader>
    <Styled.DetailsContainer>
      <Styled.ContentContainer>
        <Styled.List>
          {props.data.map((item) => (
            <Styled.Row key={item.id}>
              <Styled.Item>{item.name}</Styled.Item>
              <div>
                <Styled.Edit onClick={() => props.handleEdit(item)}>{props.editText}</Styled.Edit>
                <Styled.Delete onClick={() => props.handleDelete(item)}>{props.deleteText}</Styled.Delete>
              </div>
            </Styled.Row>
          ))}
        </Styled.List>
      </Styled.ContentContainer>
    </Styled.DetailsContainer>
    <Styled.Paginate>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        pageCount={Math.ceil(props.totalRecords / props.perPage || LIMIT_TABLE)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => {
          props.setPage(selected);
        }}
        activeClassName={'active'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        forcePage={props.page}
        containerClassName={
          Math.ceil(props.totalRecords / props.perPage || LIMIT_TABLE) <= 1 ? 'pagination-hidden' : 'pagination'
        }
      />
    </Styled.Paginate>
  </Styled.PortalPage>
);

const Styled = {
  PortalPage: styled.div`
    background: #fafafa;
    width: 100%;
    height: 100%;
    padding: 1.5rem 2rem;
  `,
  PageHeader: styled.div`
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #dadada;
    padding-bottom: 1em;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  `,
  PageHeaderTitle: styled.h1`
    font-family: ${font.title};
    white-space: nowrap;
    color: #000;
    font-size: 1.75rem;
  `,
  DetailsContainer: styled.div`
    padding: 1rem;
    border-radius: 6px;
    background: white;
    border: 1px solid #dadada;
    min-width: 715px;
    margin-bottom: 1.5rem;
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ItemTitle: styled.div`
    font-size: 1.3rem;
    font-weight: bold;
    color: #000;
    font-family: ${font.title};
    margin-bottom: 0.5rem;
    margin-top: 0.125rem;
  `,
  Row: styled.div`
    display: flex;
    height: 30px;
    margin: auto;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 5px;
    flex-wrap: wrap;
    &:hover {
      background: #dadada;
    }
  `,
  List: styled.div``,
  Item: styled.div`
    font-size: 1rem;
    font-family: ${font.regular};
  `,
  Edit: styled.span`
    margin-right: 2rem;
    font-family: ${font.regular};
    font-size: 0.8rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `,
  Delete: styled.span`
    font-family: ${font.regular};
    font-size: 0.8rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `,
  SpinnerDiv: styled.div`
    height: 102%;
    width: 82%;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    align-items: center;
    padding: -27px;
    margin: -32px;
  `,
  Paginate: styled.div`
    padding-bottom: 100px;

    .pagination {
      display: flex;
      justify-content: center;
    }

    .page-link {
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      border: 1px solid #ddd;
    }

    .active {
      background-color: #c9985e;
      color: white;
    }

    .disabled {
      background-color: #ddd;
    }

    .disabled .page-link {
      cursor: not-allowed;
    }

    .pagination li:hover:not(.active) {
      background-color: #ddd;
      cursor: pointer;
    }

    .pagination li:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .pagination li:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  `,
};
