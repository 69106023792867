import { useApi, gql } from './Provider';
import { UnFavoriteUnit, UnFavoriteUnitVariables } from './__generated__/UnFavoriteUnit';

const UNFAVORITE_UNIT = gql`
  mutation UnFavoriteUnit($unitId: BigInt!) {
    unFavoriteUnit(unitId: $unitId) {
      id
      isFavorite
    }
  }
`;

export const useUnFavoriteUnit = () => {
  const { useMutation } = useApi();
  return useMutation<UnFavoriteUnit, UnFavoriteUnitVariables>(UNFAVORITE_UNIT);
};
