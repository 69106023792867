import { gql, useApi } from './Provider';

import { AgentMarketingLinks } from './__generated__/AgentMarketingLinks';

const AGENT_MARKETING_LINKS = gql`
  query AgentMarketingLinks {
    me {
      id
      marketingLinks {
        id
        name
        slug
        enableNotifications
        redirectionLocation
        status
        type
        visitsCount
      }
    }
  }
`;

export const useGetAgentMarketingLinks = () => {
  const { useQuery } = useApi();
  return useQuery<AgentMarketingLinks>(AGENT_MARKETING_LINKS, {});
};
