import { RESPONSIVE } from '../utils/constants';
import { font } from '../utils/styles';
import styled from 'styled-components';

const P = styled.p`
  margin: 0px;
  padding: 0px;
`;

export const Medium = styled(P as any)`
  font-family: ${font.regular};
  font-size: 16px;
  letter-spacing: 0;
  color: #31313b;
  font-weight: 300;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 16px;
  }
`;

export const MediumLight = styled(Medium as any)`
  color: #fff;
`;

export const MediumSemiBold = styled(Medium as any)`
  font-weight: 500;
`;

export const MediumBold = styled(Medium as any)`
  font-weight: bold;
`;

export const Small = styled(P as any)`
  font-family: ${font.regular};
  font-weight: normal;
  font-size: 0.75rem;
  color: #747a7e;
  ${(props) => (props.strike ? 'text-decoration: line-through;' : '')}
  a, a:visited {
    color: #cda263;
    text-decoration: none;
  }
`;

export const SmallBold = styled(P as any)`
  font-family: ${font.regular};
  font-weight: 600;
  font-size: 0.8rem;
  color: #000;
  a,
  a:visited {
    color: #cda263;
    text-decoration: none;
  }
`;

export const MediumAccentBold = styled(MediumBold as any)`
  color: #cda263;
`;

export const SmallAccentBold = styled(SmallBold as any)`
  color: #cda263;
`;

export const Title = styled.h1`
  font-family: ${font.title};
  color: #000;
  font-size: 32px;
  padding: 0px;
  margin: 0px;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 21px;
  }
`;

export const TitleLight = styled(Title as any)`
  color: #fff;
`;

export const MonoSmallLight = styled(P as any)`
  font-family: ${font.regular};
  font-weight: normal;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
`;

export const MonoSmallAccent = styled(MonoSmallLight as any)`
  color: #cda263;
`;

export const SubTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: bold;
  color: #000;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 12px;
  }
`;

export const NotResponsiveSubTitle = styled.h3`
  font-size: 21px;
  font-weight: bold;
  color: #000;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
`;

export const LargeTitle = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color: #000;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
`;

export const LargeTitleLight = styled(LargeTitle as any)`
  color: #fff;
`;

export const MediumLargeTitleLight = styled.h2`
  font-size: 42px;
  font-weight: bold;
  color: #fff;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
`;

export const ExtraLargeTitleLight = styled.h2`
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 36px;
  }
`;

export const SmallTitleLight = styled.h2`
  font-size: 16px;
  font-weight: regular;
  color: #fff;
  font-family: ${font.title};
  padding: 0px;
  margin: 0px;
  @media (max-width: ${RESPONSIVE.MEDIUM}) {
    font-size: 16px;
  }
`;

export const Emphasis = styled.span`
  font-style: italic;
  font-family: ${font.title};
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Accent = styled.span`
  color: #cda263;
  font-weight: bold;
  font-style: italic;
  font-family: ${font.title};
`;
