import { useEffect } from 'react';

import { useApi, gql } from './Provider';

import { GetClients, GetClientsVariables, GetClients_me_clients_users } from './__generated__/GetClients';

const GET_AGENT_CLIENTS = gql`
  query GetClients($skip: Int, $take: Int) {
    me {
      id
      clients(skip: $skip, take: $take) {
        pagination {
          take
          total
          skip
        }
        users {
          id
          inviteStatus
          numberOfFavorites
          numberOfVisits
          fullName
          primaryEmail {
            id
            address
          }
          homePhone {
            id
            number
          }
          primaryAddress {
            id
            city
            state
          }
          status
          lastLogin
        }
      }
    }
  }
`;

export const useGetAgentClients = () => {
  const take = 50;
  const { useQuery } = useApi();

  const { data, loading, fetchMore, refetch } = useQuery<GetClients, GetClientsVariables>(GET_AGENT_CLIENTS, {
    variables: {
      skip: 0,
      take,
    },
  });

  const pagination = data?.me?.clients?.pagination;

  useEffect(() => {
    if (pagination?.skip + take < pagination?.total) {
      fetchMore({
        variables: {
          skip: pagination?.skip + take,
          take,
        },
      });
    }
  }, [pagination, fetchMore]);

  return {
    data,
    loading,
    refetch,
  };
};

export type IAgentClients = GetClients_me_clients_users;
