import * as React from 'react';
import { Media, MediaTypeName } from '../types';
import { Col, Grid, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { Modal } from './Modal';
import useTranslations from '../translations/useTranslations';
import { useGetPropertyMedia } from '../hooks/useEndpoint';
import { Button } from './Button';
import { Spacer } from './Spacer';
import { VideoThumbnail } from './VideoThumbnail';
import { PdfThumbnail } from './PdfThumbnail';
import { formatImage } from '../utils/formatImage';
import { font } from '../utils/styles';
import { useToasts } from 'react-toast-notifications';

interface ImageGaleryProps {
  onComplete: (media: Array<Media>) => void;
  onClose: () => void;
  propertyId: number;
  excludeMediaIds: Array<number>;
  singleSelection?: boolean;
  mediaTypes?: Array<MediaTypeName>;
  limit?: number;
}

export const Styled = {
  MediaImage: styled.img`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    position: absolute;
  `,
  Container: styled.button`
    position: relative;
    display: flex;
    word-break: break-word;
    flex-direction: column;
    border: solid 1px #ccc;
    width: 100%;
    border-radius: 4px;
    align-items: center;
    height: 150px;
    margin-bottom: 14px;
  `,
  ButtonContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
  `,
  NoContent: styled.p`
    padding: 16px;
    font-size: 14px;
    font-family: ${font.regular};
  `,
  Title: styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    bottom: 0px;
    padding: 7px;
    color: #fff;
    font-family: ${font.regular};
    font-size: 12px;
    width: 100%;
    cursor: default;
  `,
};

export const ImageGallery = (props: ImageGaleryProps) => {
  const translations = useTranslations();
  const mediaResults = useGetPropertyMedia(props.propertyId);
  const [selectedMedia, setSelectedMedia] = React.useState<Array<number>>([]);
  const mediaTypes = props.mediaTypes || ['image'];
  const { addToast } = useToasts();

  const mediaImages = (mediaResults.data || [])
    .filter((mediaImage) => !props.excludeMediaIds.includes(mediaImage.id))
    .filter((media) =>
      mediaTypes.reduce((prev: any, mediaType: any) => {
        return prev || (media?.mediaType?.name || '').includes(mediaType);
      }, false),
    );

  return (
    <Modal
      width={800}
      variant="center"
      isOpen={true}
      title={translations.ImagePicker.title}
      onClose={props.onClose}
      renderContent={() => {
        return (
          <>
            {!mediaResults.isLoading && !mediaImages.length ? (
              <Styled.NoContent>There is no available media.</Styled.NoContent>
            ) : null}
            <Grid>
              <Row>
                {mediaImages.map((media) => (
                  <Col key={media.id} sm={6} md={4} lg={3} xl={2}>
                    <Styled.Container
                      onClick={() => {
                        const isAdding = !selectedMedia.includes(media.id);
                        const totalCount = selectedMedia.length + (props.excludeMediaIds || []).length;

                        if (isAdding && props.limit && totalCount >= props.limit) {
                          addToast(
                            translations.FormErrors.tooManySelectedMedia.replace('{{limit}}', `${props.limit}`),
                            {
                              appearance: 'error',
                              autoDismiss: true,
                            },
                          );
                          return;
                        }

                        if (props.singleSelection) {
                          setSelectedMedia(isAdding ? [media.id] : []);
                          return;
                        }

                        setSelectedMedia(
                          isAdding
                            ? [...selectedMedia, media.id]
                            : selectedMedia.filter((mediaId) => mediaId !== media.id),
                        );
                      }}
                      style={{
                        border: selectedMedia.includes(media.id) ? 'solid 2px black' : undefined,
                        opacity: selectedMedia.includes(media.id) ? undefined : '0.8',
                      }}
                    >
                      {(media?.mediaType?.name || '').includes('video') ? (
                        <VideoThumbnail video={media} />
                      ) : (media?.mediaType?.name || '').includes('pdf') ? (
                        <PdfThumbnail pdf={media} />
                      ) : (
                        <Styled.MediaImage
                          src={formatImage(media.location, {
                            width: 280,
                            height: 280,
                          })}
                        />
                      )}
                      <Styled.Title>{media.name}</Styled.Title>
                    </Styled.Container>
                  </Col>
                ))}
              </Row>
            </Grid>
            <Styled.ButtonContainer>
              <Button color="gray" type="button" onClick={props.onClose}>
                {translations.ImagePicker.cancel}
              </Button>
              <Spacer width={14} />
              <Button
                color="black"
                type="button"
                onClick={() => {
                  const selected = mediaImages.filter((mediaImage) => selectedMedia.includes(mediaImage.id));
                  props.onComplete(selected);
                }}
              >
                {translations.ImagePicker.done}
              </Button>
            </Styled.ButtonContainer>
          </>
        );
      }}
    />
  );
};
