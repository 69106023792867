import * as React from 'react';

import { ITBody } from './types';
import styled from 'styled-components';

const Styled = {
  TBody: styled.tbody`
    z-index: 1;
    position: relative;
  `,
};

export const TBody = (props: ITBody) => {
  return <Styled.TBody>{props.children}</Styled.TBody>;
};
