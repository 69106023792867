import * as React from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

interface TagGroupProps {
  children: React.ReactNode;
  title: string;
}

const Styled = {
  Container: styled.div`
    border-radius: 7px;
    background-color: #fafafa;
    width: 100%;
    margin-bottom: 14px;
    padding: 12px;
  `,
  Title: styled.div`
    font-family: ${font.regular};
    font-weight: bold;
    padding-bottom: 10px;
    color: #33;
  `,
  Tags: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
};

export const TagGroup = (props: TagGroupProps) => {
  return (
    <Styled.Container>
      <Styled.Title>{props.title}</Styled.Title>
      <Styled.Tags>{props.children}</Styled.Tags>
    </Styled.Container>
  );
};
