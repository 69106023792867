import { MutableRefObject, useRef } from 'react';
import { isEqual } from 'lodash';

const useDeepCompareMemoize = <T>(value: T | undefined): T | undefined => {
  const valueRef: MutableRefObject<T | undefined> = useRef<T>();

  if (!isEqual(value, valueRef.current)) {
    valueRef.current = value;
  }
  return valueRef.current;
};

export default useDeepCompareMemoize;
