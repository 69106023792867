import { MetaData, RequestState, View, RequestVariables } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface ViewsResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<View>;
  metadata: MetaData;
  refetch: () => void;
}

const defaultVariables = {
  offset: 0,
  limit: -1,
};

export const useGetViewTypes = (variables: RequestVariables = defaultVariables) => {
  const [views, refetch] = useApi.get({
    url: `/view-types`,
    variables,
  });

  return formatApiResult(views, refetch) as ViewsResponse;
};
