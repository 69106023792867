import * as React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid';

import { Icon } from './Icon';
import { ImageGallery } from './ImageGallery';
import { Media } from '../types';
import { font } from '../utils/styles';
import { formatImage } from '../utils/formatImage';
import styled from 'styled-components';

interface SingleImagePickerProps {
  value?: Media;
  onValueChange: (media: Media | null) => void;
  propertyId: number;
  height?: number;
  skipCrop?: boolean;
}

interface ContainerProps {
  height?: number;
}

const Styled = {
  MediaImage: styled.img`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  `,
  Container: styled.div<ContainerProps>`
    position: relative;
    display: flex;
    word-break: break-word;
    flex-direction: column;
    border: solid 1px #ccc;
    width: 100%;
    border-radius: 4px;
    align-items: center;
    height: ${(props) => props.height || 150}px;
    margin-bottom: 14px;
  `,
  RemoveButton: styled.button`
    position: absolute;
    right: 7px;
    top: 7px;
    color: #fff;
    cursor: pointer;
    font-family: ${font.regular};
    font-weight: bold;
    background: #000;
    padding: 1px;
    height: 18px;
    width: 18px;
    border-radius: 45px;

    &:focus,
    &hover {
      color: #ccc;
    }
  `,
  AddButton: styled.button`
    cursor: pointer;
    background: #eee;
    flex: 1;
    width: 100%;
  `,
  Title: styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    bottom: 0px;
    padding: 7px;
    color: #fff;
    font-family: ${font.regular};
    font-size: 12px;
    width: 100%;
    cursor: default;
  `,
};

export const SingleImagePicker = (props: SingleImagePickerProps) => {
  const [isSelecting, setIsSelecting] = React.useState(false);

  return (
    <>
      {isSelecting ? (
        <ImageGallery
          singleSelection={true}
          onClose={() => setIsSelecting(false)}
          excludeMediaIds={[]}
          onComplete={(newMedia) => {
            props.onValueChange(newMedia && newMedia.length ? newMedia[0] : null);
            setIsSelecting(false);
          }}
          propertyId={props.propertyId}
        />
      ) : null}
      <Grid>
        <Row>
          <Col sm={6} md={4} lg={3} xl={2}>
            <Styled.Container height={props.height}>
              {props.value ? (
                <>
                  <Styled.MediaImage
                    src={
                      props.skipCrop
                        ? props.value.location
                        : formatImage(props.value.location, {
                            width: 280,
                            height: 280,
                          })
                    }
                  />
                  <Styled.Title>{props.value.name}</Styled.Title>
                  <Styled.RemoveButton
                    type="button"
                    onClick={() => {
                      props.onValueChange(null);
                    }}
                  >
                    <Icon type="remove_circle" color="white" size={16} />
                  </Styled.RemoveButton>
                </>
              ) : (
                <Styled.AddButton
                  type="button"
                  onClick={() => {
                    setIsSelecting(true);
                  }}
                >
                  <Icon type="perm_media" color="#CCC" size={48} />
                </Styled.AddButton>
              )}
            </Styled.Container>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
