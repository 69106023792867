import * as React from 'react';

import { Button } from './Button';
import { ButtonWrapper } from './ButtonWrapper';
import { Icon } from './Icon';
import styled from 'styled-components';
import { font } from '../utils/styles';

interface DialogProps {
  title: string;
  subtitle: string;
  onConfirm: Function;
  onCancel: Function;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmColor?: string;
  cancelColor?: string;
  visible: boolean;
}

const Styled = {
  Backdrop: styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  `,
  Modal: styled.div`
    max-width: 90%;
    width: 350px;
    background: #fff;
    padding: 14px;
    position: relative;
  `,
  Title: styled.div`
    font-family: ${font.regular};
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 14px;
  `,
  SubTitle: styled.div`
    padding-bottom: 24px;
    font-family: ${font.light};
    font-size: 1rem;
  `,
  Buttons: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CloseIcon: styled.div`
    position: absolute;
    top: -24px;
    right: -42px;
  `,
};

export const Dialog = (props: DialogProps) => {
  return (
    <Styled.Backdrop
      style={{
        display: props.visible ? 'flex' : 'none',
      }}
    >
      <Styled.Modal>
        <Styled.CloseIcon>
          <ButtonWrapper onClick={() => props.onCancel()}>
            <Icon color="#FFF" type="close" size={42} />
          </ButtonWrapper>
        </Styled.CloseIcon>
        <Styled.Title>{props.title}</Styled.Title>
        <Styled.SubTitle>{props.subtitle}</Styled.SubTitle>
        <Styled.Buttons>
          <Button onClick={props.onCancel} color={props.cancelColor || '#CCC'} data-testid="cancel-button">
            {props.cancelLabel || 'Cancel'}
          </Button>
          <Button onClick={props.onConfirm} color={props.confirmColor || 'red'} data-testid="confirm-button">
            {props.confirmLabel || 'Okay'}
          </Button>
        </Styled.Buttons>
      </Styled.Modal>
    </Styled.Backdrop>
  );
};
