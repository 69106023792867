import * as React from 'react';

import { Icon } from './Icon';
import { UploadWrapper } from './UploadWrapper';
import styled from '../styled-components';
import useTranslations from '../translations/useTranslations';
import { font } from '../utils/styles';

interface ImageUploadAreaProps {
  value?: any;
  placeholderText?: string;
  onChange: (image: any) => void;
  height?: number;
}

const Styled = {
  Container: styled.div`
    position: relative;
    &:focus,
    &:hover {
      .preview {
        background-color: #eee;
      }
    }
  `,
  Empty: styled.div<{ height?: number }>`
    width: 100%;
    height: ${(props) => `${props.height || 300}px`};
    border: dashed 2px #ccc;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  Preview: styled.div<{ value?: string; height?: number }>`
    background-size: cover;
    background-position: center;
    background-color: #eee;
    width: 100%;
    height: ${(props) => `${props.height || 300}px`};
    margin-bottom: 14px;
    background-image: ${(props) => `url("${props.value}");`};
  `,
  Placeholder: styled.div`
    font-family: ${font.regular};
    font-size: 18px;
    font-weight: bold;
    color: #aaa;
    margin-top: 14px;
  `,
  Clear: styled.button`
    text-align: left;
    padding: 0px;
    display: flex;
    z-index: 18;
    color: #bbb;
    font-family: ${font.regular};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 22px;
    cursor: pointer;
    align-items: center;

    &:hover,
    &:focus {
      color: #aaa;
    }
  `,
};

export const ImageUploadArea = (props: ImageUploadAreaProps) => {
  const [value, setValue] = React.useState(props.value);
  const translations = useTranslations();

  React.useEffect(() => {
    if (!props.value || typeof props.value === 'string') {
      setValue(props.value);
      return;
    }

    const reader = new FileReader();

    reader.onloadend = function () {
      setValue(reader.result);
    };

    reader.readAsDataURL(props.value);
  }, [props.value]);

  return (
    <Styled.Container>
      <UploadWrapper
        onChange={(event) => {
          props.onChange(event.target.files[0]);
        }}
      >
        {value ? (
          <Styled.Preview value={value} height={props.height} className="preview" />
        ) : (
          <Styled.Empty height={props.height} className="preview">
            <Icon type="collections" color="#CCC" size={80} />
            {props.placeholderText ? <Styled.Placeholder>{props.placeholderText}</Styled.Placeholder> : null}
          </Styled.Empty>
        )}
      </UploadWrapper>
      {value ? (
        <Styled.Clear
          onClick={() => {
            props.onChange(null);
          }}
        >
          <Icon type="clear" color="#CCC" size={18} />
          {translations.Common.clearImage}
        </Styled.Clear>
      ) : null}
    </Styled.Container>
  );
};
