import * as translations from './translations';

import { IIconType, Icon, MouseEvents, Padding, Spacer, Text, Title, Tooltip, Touchable } from '..';
import React, { useState } from 'react';

import { Image } from '../Image';
// @TODO: Start pulling some of these utils out into a new package
import { formatLargeCurrency } from '../../../utils/format';
import styled from 'styled-components/native';
import { useResponsive } from '../../hooks/useResponsive';
import { useTranslation } from '../../hooks/useTranslation';

interface IProperty {
  hasOrbitReel: boolean;
  image: string | null;
  title: string;
  address: string;
  minPrice: number;
  maxPrice: number;
  unitsCount: number;
  storiesCount: number;
  availableUnits: number;
  constructionStatus: string;
  salesStatus: string;
  displayPriceStartAt: boolean;
}

interface IPropertyCard {
  property: IProperty;
  testID: string;
  isActive?: boolean;
  isLiked: boolean;
  handleLike: () => void;
  handleUnLike: () => void;
  handleEdit?: () => void;
  handleNavigate: () => void;
  handleMouseOver?: () => void;
  handleMouseOut?: () => void;
}

interface IContainer {
  isActive: boolean;
  isMobile: boolean;
}

interface IStyledIconContainer {
  isMobile: boolean;
}

interface IStyledImageContainer {
  isMobile: boolean;
}

const Styled = {
  Container: styled.View<IContainer>`
    width: 100%;
    position: relative;
    ${(props) => (props.isActive ? `background-color: ${props.theme.color.gray2}` : '')}
    ${(props) => (props.isMobile ? '' : 'height: 235px;')}
  `,
  ImageTouchable: styled.TouchableOpacity`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
  `,
  ImageContainer: styled.View<IStyledImageContainer>`
    width: 100%;
    ${(props) => (props.isMobile ? 'padding-top: 48.5%;' : 'height: 135px;')}
    position: relative;
  `,
  Icons: styled.View`
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
  `,
  IconContainer: styled.View<IStyledIconContainer>`
    background-color: rgba(0, 0, 0, 0.9);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
      props.isMobile
        ? `
      width: 30px;
      height: 30px;
    `
        : ''}
  `,
  OrbitReel: styled.View`
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0px;
    left: 10px;
    height: 43px;
    width: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
};

export const PropertyCard = (props: IPropertyCard) => {
  const [isLiked, setIsLiked] = useState(props.isLiked || false);
  const t = useTranslation<typeof translations.en>(translations);
  const isMobile = useResponsive()('sm');

  return (
    <Styled.Container isActive={!!props.isActive} isMobile={isMobile}>
      <MouseEvents onMouseOver={props.handleMouseOver} onMouseOut={props.handleMouseOut}>
        <Padding size={14} bottom={isMobile ? 34 : 14}>
          <Styled.ImageContainer isMobile={isMobile}>
            <Styled.ImageTouchable
              testID={`${props.testID}-image-button`}
              onPress={props.handleNavigate}
              activeOpacity={0.75}
            >
              <Image
                lowRes={{
                  uri: props.property.image && `${props.property.image}/50`,
                }}
                source={{
                  uri: props.property.image && `${props.property.image}/564`,
                }}
              />
            </Styled.ImageTouchable>
            {props.property.hasOrbitReel && (
              <Styled.OrbitReel>
                <Icon type={IIconType.Building3D} color="white" exactSize={26} />
                <Text color="white" size="xs" weight="bold">
                  {t.threeD}
                </Text>
              </Styled.OrbitReel>
            )}
            <Styled.Icons>
              <Tooltip value={isLiked ? t.removeFromFavorites : t.addToFavorites}>
                <Touchable
                  testID={`${props.testID}-favorite-button`}
                  onPress={() => {
                    if (isLiked) {
                      props.handleUnLike();
                    } else {
                      props.handleLike();
                    }

                    setIsLiked(!isLiked);
                  }}
                >
                  <Styled.IconContainer isMobile={isMobile}>
                    <Icon type={isLiked ? IIconType.FavoriteFilled : IIconType.Favorite} color="white" exactSize={18} />
                  </Styled.IconContainer>
                </Touchable>
              </Tooltip>
              {props.handleEdit && (
                <>
                  <Spacer width={3} />
                  <Tooltip value={t.editProperty}>
                    <Touchable testID={`${props.testID}-edit`} onPress={props.handleEdit}>
                      <Styled.IconContainer isMobile={isMobile}>
                        <Icon type={IIconType.Edit} color="white" exactSize={18} />
                      </Styled.IconContainer>
                    </Touchable>
                  </Tooltip>
                </>
              )}
            </Styled.Icons>
          </Styled.ImageContainer>
          <Spacer height={isMobile ? 16 : 7} />
          <Touchable testID={`${props.testID}-property-title`} onPress={props.handleNavigate}>
            <Title size={isMobile ? 'xxl' : 'xl'} weight="bold" singleLine>
              {props.property.title}
            </Title>
          </Touchable>
          <Spacer height={isMobile ? 2 : 0} />
          <Text size={isMobile ? 'md' : 'sm'} weight="medium" singleLine>
            {props.property.address}
          </Text>
          <Spacer height={isMobile ? 2 : 0} />
          <Text size="sm" weight="medium" singleLine>
            {props.property.displayPriceStartAt
              ? `${t.startingAt} ${formatLargeCurrency(props.property.minPrice)}`
              : `${formatLargeCurrency(props.property.minPrice)} ${t.to} ${formatLargeCurrency(
                  props.property.maxPrice,
                )}`}
            &nbsp; | {props.property.unitsCount} {t.units} | {props.property.storiesCount} {t.stories}
          </Text>
          <Spacer height={isMobile ? 5 : 3} />
          <Text size={'xxs'} weight="black" singleLine>
            {props.property.availableUnits} {t.availableUnits} {props.property.salesStatus}{' '}
            {props.property.constructionStatus}
          </Text>
        </Padding>
      </MouseEvents>
    </Styled.Container>
  );
};
