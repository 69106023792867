interface FormatImageProps {
  width: number;
  height?: number;
  quality?: number;
}

export const formatImage = (location: string | null | undefined, attributes?: FormatImageProps) => {
  if (!location) {
    return '';
  }

  if (!location.includes('://')) {
    return location;
  }

  const cachedLocation = 'https://' + location.replace('.s3-website.us-east-1.amazonaws.com', '').split('://')[1];

  if (!attributes) {
    return cachedLocation;
  }

  const { width, height, quality } = attributes;

  if (!height) {
    return `${cachedLocation}/${width}`;
  }

  return `${cachedLocation}/${width}x${height}x${quality || 80}`;
};
