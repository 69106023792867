import * as React from 'react';

import { IText } from '../../types';
import styled from 'styled-components/native';

interface ITitleProps extends IText {
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}

const TITLE_WEIGHT = {
  black: 900,
  bold: 800,
  medium: 700,
  regular: 600,
  light: 400,
  thin: 300,
};

const StyledTitle = styled.Text<IText>`
  font-family: '${(props) => {
    return `title${TITLE_WEIGHT[props.weight || 'regular']}${props.italic ? 'Italic' : ''}`;
  }}';
  font-size: ${(props) => props.theme.fontSize[props.size || 'md']}px;
  color: ${(props) => props.theme.color[props.color || 'black']};
  text-align: ${(props) => props.textAlign || 'left'};
  ${(props) =>
    props.singleLine
      ? `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  `
      : ''}
`;

export const Title = (props: ITitleProps) => {
  const { children, ...textProps } = props;
  return <StyledTitle {...textProps}>{children}</StyledTitle>;
};
