import { KeyPlan } from '../../types';
import api from '../../utils/api';

export const batchCreateKeyPlans = (keyPlans: Array<KeyPlan>) =>
  api.post({
    url: `/keyplan/batch`,
    variables: {
      keyPlans,
    },
  });
