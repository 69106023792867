import * as React from 'react';

import { Label } from './Label';
import styled from 'styled-components';
import useTranslations from '../translations';
import { font } from '../utils/styles';

interface FieldProps {
  label: string;
  children?: React.ReactNode;
  error?: string;
  charactersRemaining?: number | undefined;
  className?: string;
}

const S = {
  Container: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    &.hasError {
      input,
      textarea,
      select {
        border: solid 1px red;
        margin-bottom: 0px;
      }
    }
  `,
  Error: styled.div`
    color: #ff4646;
    font-family: ${font.regular};
    font-size: 14px;
    position: relative;
    padding-top: 3px;
    padding-bottom: 12px;
  `,
  Remaining: styled.div`
    font-family: ${font.regular};
    font-size: 14px;
    position: relative;
    top: -7px;
    color: #aaa;
    margin-bottom: 14px;
  `,
};

export const Field = (props: FieldProps) => {
  const translations = useTranslations();
  const enabledCharactersRemaining = typeof props.charactersRemaining === 'number';
  return (
    <S.Container className={`${props.error ? 'hasError' : ''} ${props.className}`}>
      <Label>{props.label}</Label>
      {props.children}
      {props.error ? (
        <S.Error>{props.error}</S.Error>
      ) : enabledCharactersRemaining ? (
        <S.Remaining>
          {props.charactersRemaining} {translations.Forms.charactersRemaining}
        </S.Remaining>
      ) : null}
    </S.Container>
  );
};
