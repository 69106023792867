import { LoginWithEmail, LoginWithEmailVariables } from './__generated__/LoginWithEmail';
import { gql, useApi } from './Provider';

const LOGIN_WITH_EMAIL = gql`
  mutation LoginWithEmail($input: LoginWithEmailInput!) {
    loginWithEmail(input: $input) {
      accessToken
      refreshToken
      deviceKey
      identityId
      me {
        id
        vanityStub
        fullName
        firstName
        lastName
        agent {
          id
          vanityStub
        }
        userRoles {
          id
          role {
            id
            baseName
          }
        }
      }
    }
  }
`;

export const useLoginWithEmail = () => {
  const { useMutation } = useApi();
  return useMutation<LoginWithEmail, LoginWithEmailVariables>(LOGIN_WITH_EMAIL);
};
