import React, { ReactChild, ReactChildren } from 'react';

import { IColor } from '../../theme';
import { Text } from '../';
import styled from 'styled-components/native';

type ISize = 'sm' | 'md' | 'lg';

interface IStyledButton {
  size: ISize;
  color: IColor;
  width?: number;
  disabled?: boolean;
}

export interface IButtonBase {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  size?: ISize;
  color?: IColor;
  startIcon?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  endIcon?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  width?: number;
  disabled?: boolean;
}

const Styled = {
  Button: styled.View<IStyledButton>`
    height: ${(props) => (props.size === 'lg' ? 50 : props.size === 'sm' ? 24 : 37)}px;
    background: ${(props) => props.theme.color[props.color]};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0px ${(props) => (props.size === 'lg' ? 24 : props.size === 'sm' ? 10 : 12)}px;
    ${(props) => (props.width ? `width: ${props.width}px;` : '')}
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  `,
  StartIcon: styled.View`
    padding-right: 8px;
  `,
  EndIcon: styled.View`
    padding-left: 8px;
  `,
};

export const ButtonBase = (props: IButtonBase) => {
  const textColor = props.color === 'white' || props.color === 'gray1' || props.color === 'gray2' ? 'black' : 'white';

  return (
    <Styled.Button
      size={props.size || 'md'}
      color={props.color || 'black'}
      width={props.width}
      disabled={props.disabled}
    >
      <>
        {!!props.startIcon && <Styled.StartIcon>{props.startIcon}</Styled.StartIcon>}
        <Text color={textColor} size={props.size === 'lg' ? 'lg' : props.size === 'sm' ? 'xs' : 'sm'}>
          {props.children}
        </Text>
        {!!props.endIcon && <Styled.EndIcon>{props.endIcon}</Styled.EndIcon>}
      </>
    </Styled.Button>
  );
};
