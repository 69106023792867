import React from 'react';
import styled from 'styled-components';
import useTranslations from '../translations/useTranslations';
import { Title, SubTitle, MediumAccentBold, Small, Medium, Spacer } from './';
import { prettyPhoneNumber } from '../utils/phone';
import { RESPONSIVE } from '../utils/constants';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';
import { Avatar } from '../components/Avatar';
import { Brokerage } from './CreateBrokerageForm';

interface AgentCardProps {
  title?: string;
  image?: string;
  renderAvatar?: () => React.ReactNode;
  name: string;
  email: string;
  vanityUrl: string;
  phone: number;
  brokerage: Brokerage;
  userId?: string;
}

export const AgentCard = (props: AgentCardProps) => {
  const translations = useTranslations();
  const { isSmall, isMedium } = useResponsiveSizes();
  const t = translations.AgentCard;
  const renderContacts = () => (
    <Styled.Contacts>
      {props.phone && (
        <Styled.Contact>
          <Small>{t.workPhone}</Small>
          <Medium>{prettyPhoneNumber(props.phone)}</Medium>
        </Styled.Contact>
      )}
      {props.brokerage && (
        <Styled.Contact>
          <Small>{t.brokerage}</Small>
          <Medium>{props.brokerage?.name}</Medium>
        </Styled.Contact>
      )}
    </Styled.Contacts>
  );
  return (
    <Styled.Container>
      <SubTitle>{props.title || t.myAgent}</SubTitle>
      <Styled.Card>
        {props.renderAvatar ? (
          props.renderAvatar()
        ) : (
          <Styled.Avatar>
            <Avatar src={props.image} />
          </Styled.Avatar>
        )}

        <Styled.Details>
          <Title>{props.name}</Title>
          {!isMedium && <Spacer height={7} />}
          <MediumAccentBold>{props.email}</MediumAccentBold>
          {!isMedium && <Spacer height={7} />}
          {!!props.vanityUrl && <MediumAccentBold>{props.vanityUrl}</MediumAccentBold>}
          {!isSmall && renderContacts()}
        </Styled.Details>
      </Styled.Card>
      {isSmall && renderContacts()}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    @media (max-width: ${RESPONSIVE.SMALL}) {
      padding-top: 1rem;
    }
  `,
  Card: styled.div`
    display: flex;
    padding-top: 14px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      flex-wrap: wrap;
    }
  `,
  Avatar: styled.div`
    width: 120px;
    height: 120px;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.25);
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      width: 54px;
      height: 54px;
      margin-right: 10px;
    }
  `,
  Details: styled.div`
    padding-left: 54px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      padding-left: 0;
    }
  `,
  Contacts: styled.div`
    padding-top: 14px;
    position: relative;
  `,
  Contact: styled.div`
    padding-right: 14px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      width: 135px;
    }
  `,
};
