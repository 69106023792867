import { AGENT_ROLE, ENABLE_ZENDESK, PROPERTY_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../utils/constants';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { intersection, kebabCase } from 'lodash';

import React from 'react';
import { UserRole } from '../hooks/useAuthorization';
import styled from 'styled-components';
import useTranslations from '../translations/useTranslations';

interface HeaderLink {
  to: string;
  title: string;
  allowRoles: Array<string>;
}

interface NavLinkProps extends LinkProps {
  active: 'true' | 'false';
}

const links = [
  { to: '/reports', title: 'Reports', allowRoles: [AGENT_ROLE] },
  { to: '/listings', title: 'Listings', allowRoles: [SUPER_ADMIN_ROLE, PROPERTY_ADMIN_ROLE] },
  { to: '/clients', title: 'Clients', allowRoles: [AGENT_ROLE] },
];

const getHeaderLinks = (userRoles: Array<UserRole>): Array<HeaderLink> => {
  // will compare the allowRoles with the current user's roles to determine which links should show
  return links.filter(
    (l) =>
      !!intersection(
        userRoles.map((userRole) => userRole.role.baseName),
        l.allowRoles,
      ).length,
  );
};

export const HeaderLinks = (props: { userRoles: Array<UserRole> }) => {
  const location = useLocation();
  const isActive = (to: string): boolean => location.pathname.includes(to);
  const { userRoles = [] } = props;
  const translations = useTranslations();

  return (
    <Styled.HeaderLinks>
      {ENABLE_ZENDESK ? (
        <Styled.ExternalNavLink href="/zendesk/auth" target="_blank" rel="noreferrer">
          {translations.Common.knowledgeBase}
        </Styled.ExternalNavLink>
      ) : null}
      {getHeaderLinks(userRoles).map((link, index: number) => (
        <Styled.NavLink
          id={`${kebabCase(link.title)}-link`}
          key={'link-' + index}
          to={link.to}
          active={isActive(link.to) ? 'true' : 'false'}
        >
          {link.title}
        </Styled.NavLink>
      ))}
    </Styled.HeaderLinks>
  );
};

const Styled = {
  HeaderLinks: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ExternalNavLink: styled.a`
    color: #000;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-decoration: none;
    cursor: pointer;
    padding-right: 10px;
    margin-bottom: 10px;
    display: block;
  `,
  NavLink: styled(Link)`
    color: ${(props: NavLinkProps) => (props.active === 'true' ? '#CDA263' : '#000')};
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-decoration: none;
    cursor: pointer;
    padding-right: 10px;
    margin-bottom: 10px;
  `,
};
