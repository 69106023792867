import React, { ReactChild, ReactChildren } from 'react';

import styled from 'styled-components/native';

export type GridJustify = 'space-between' | 'flex-start' | 'flex-end' | 'space-around' | 'space-evenly' | 'center';

export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type GridAlignItems = 'flex-start' | 'center' | 'flex-end';

interface IGrid {
  direction?: GridDirection;
  justify?: GridJustify;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  spacing?: number;
  alignItems?: GridAlignItems;
  zIndex?: number;
  width?: number;
}

interface IStyledGrid {
  zIndex?: number;
  width?: number;
}

interface IStyledGridInner {
  direction: GridDirection;
  justify: GridJustify;
  spacing: number;
  alignItems: GridAlignItems;
}

const Styled = {
  Grid: styled.View<IStyledGrid>`
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : '')};
  `,
  GridInner: styled.View<IStyledGridInner>`
    width: calc(100% + ${(props) => props.spacing * 2}px);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${(props) => props.justify};
    position: relative;
    left: -${(props) => props.spacing}px;
    align-items: ${(props) => props.alignItems};
    flex-direction: ${(props) => props.direction};
  `,
};

export const Grid = (props: IGrid) => {
  return (
    <Styled.Grid zIndex={props.zIndex} width={props.width}>
      <Styled.GridInner
        direction={props.direction || 'row'}
        justify={props.justify || 'flex-start'}
        spacing={props.spacing || 0}
        alignItems={props.alignItems || 'flex-start'}
      >
        {props.children}
      </Styled.GridInner>
    </Styled.Grid>
  );
};

export const Row = Grid;
