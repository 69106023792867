import { Media, MetaData, RequestState } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface MediaResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Media;
  metadata: MetaData;
}

export const useGetMedia = (id: number) => {
  const [media] = useApi.get({
    url: `/media/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(media) as MediaResponse;
};
