import { CurrentUser } from '../hooks/useAuthorization';
import { DOMAIN_NAME } from './constants';

const PLACEHOLDER_AVATAR = '/icons/placeholder-avatar.png';

export function getAvatarUrl(user?: CurrentUser): string {
  return user.profileAvatarUrl || PLACEHOLDER_AVATAR;
}

export const getPrimaryAddress = (user) => user.addresses.find((address) => address?.addressType?.name === 'Primary');

export const getUserName = (user) => `${user.firstName} ${user.lastName}`;

export const getUserAddress = (user) => {
  const primaryAddress = getPrimaryAddress(user);

  return primaryAddress ? `${primaryAddress.city}, ${primaryAddress.state} USA ${primaryAddress.postalCode || ''}` : '';
};

export const getUserWorkPhone = (user) => {
  return user?.phoneNumbers?.find((phone: any) => !!phone.number && phone.phoneNumberType?.name === 'Work')?.number;
};

export const getUserHomePhone = (user) => {
  const homePhone = user.phoneNumbers?.find((phone: any) => !!phone.number && phone.phoneNumberType?.name === 'Home')
    ?.number;
  return homePhone ? homePhone : '';
};
export const getUserEmail = (user) =>
  user?.emails
    ? user?.emails?.length > 0 && user?.emails.find((e: any) => e.emailType.name === 'Primary')?.address
    : '';

export const getUserBrokerage = (user) => {
  return getFullUserBrokerage(user)?.name;
};

export const getFullUserBrokerage = (user) => {
  return (user?.organizations || []).find((organization) =>
    organization.organizationTypes.map((organizationType) => organizationType.name).includes('Brokerage'),
  );
};

export const getUserUrl = (user) => (user.vanityStub ? `${DOMAIN_NAME}/agent/${user.vanityStub}` : null);
