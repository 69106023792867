import React, { useEffect, useRef } from 'react';
import { IMouseEvents } from './types';
import styled from 'styled-components';

const Styled = {
  Div: styled.div`
    position: relative;
  `,
};

export const MouseEvents = (props: IMouseEvents) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = ref.current;

    if (props.onMouseDown) {
      current.addEventListener('mousedown', props.onMouseDown);
    }

    return () => {
      if (props.onMouseDown) {
        current.removeEventListener('mousedown', props.onMouseDown);
      }
    };
  }, [props.onMouseDown]);

  useEffect(() => {
    const current = ref.current;

    if (props.onMouseUp) {
      current.addEventListener('mouseup', props.onMouseUp);
    }

    return () => {
      if (props.onMouseUp) {
        current.removeEventListener('mouseup', props.onMouseUp);
      }
    };
  }, [props.onMouseUp]);

  useEffect(() => {
    const current = ref.current;

    if (props.onMouseOver) {
      current.addEventListener('mouseover', props.onMouseOver);
    }

    return () => {
      if (props.onMouseOver) {
        current.removeEventListener('mouseover', props.onMouseOver);
      }
    };
  }, [props.onMouseOver]);

  useEffect(() => {
    const current = ref.current;

    if (props.onMouseOut) {
      current.addEventListener('mouseout', props.onMouseOut);
    }

    return () => {
      if (props.onMouseOut) {
        current.removeEventListener('mouseout', props.onMouseOut);
      }
    };
  }, [props.onMouseOut]);

  useEffect(() => {
    const current = ref.current;

    if (props.onMouseMove) {
      current.addEventListener('mousemove', props.onMouseMove);
    }

    return () => {
      if (props.onMouseMove) {
        current.removeEventListener('mousemove', props.onMouseMove);
      }
    };
  }, [props.onMouseMove]);

  return <Styled.Div ref={ref}>{props.children}</Styled.Div>;
};
