import React from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

interface ISpinnerProps {
  size?: number;
  color?: string;
  message?: string;
}

export const Spinner = (props: ISpinnerProps) => (
  <>
    <Loader size={props.size}>
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
      <Cube color={props.color} />
    </Loader>
    {props.message && <Message>{props.message}</Message>}
  </>
);

interface LoaderProps {
  size?: number;
}

const Loader = styled.div<LoaderProps>`
  width: ${(props) => (props.size ? `${props.size}px` : '40px')};
  height: ${(props) => (props.size ? `${props.size}px` : '40px')};
  margin: 20px auto;
`;

const Cube = styled.div`
  width: 33%;
  height: 33%;
  background-color: ${(props) => props.color || '#c9985e'};
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  :nth-child(1) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  :nth-child(2) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  :nth-child(3) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  :nth-child(4) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  :nth-child(5) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  :nth-child(6) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  :nth-child(7) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  :nth-child(8) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  :nth-child(9) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
  @keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }
    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
`;

const Message = styled.h1`
  margin: 15px 0;
  color: #000000;
  font-family: ${font.title};
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
`;

export default Spinner;
