import { useMediaQuery } from 'react-responsive';
import { RESPONSIVE } from '../theme';

type Size = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const useResponsive = () => {
  const sizes = {
    xxxs: useMediaQuery({ query: `(max-width: ${RESPONSIVE.xxxs}px)` }),
    xxs: useMediaQuery({ query: `(max-width: ${RESPONSIVE.xxs}px)` }),
    xs: useMediaQuery({ query: `(max-width: ${RESPONSIVE.xs}px)` }),
    sm: useMediaQuery({ query: `(max-width: ${RESPONSIVE.sm}px)` }),
    md: useMediaQuery({ query: `(max-width: ${RESPONSIVE.md}px)` }),
    lg: useMediaQuery({ query: `(max-width: ${RESPONSIVE.lg}px)` }),
    xl: useMediaQuery({ query: `(max-width: ${RESPONSIVE.xl}px)` }),
    xxl: useMediaQuery({ query: `(max-width: ${RESPONSIVE.xxl}px)` }),
  };

  return (query: Size) => sizes[query];
};
