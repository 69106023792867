import * as React from 'react';

import { IPagination } from './types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const Styled = {
  Paginate: styled.div`
    ul {
      list-style: none;
    }

    * {
      color: ${(props) => props.theme.color.black};
      font-size: 14px;
      font-family: body400;
    }

    .pagination {
      display: flex;
      justify-content: center;
      padding-top: 32px;
      padding-bottom: 100px;
    }

    .page-link {
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      border: 1px solid ${(props) => props.theme.color.gray2};
    }

    .active {
      background-color: ${(props) => props.theme.color.gold};
      color: white;
    }

    .disabled {
      background-color: ${(props) => props.theme.color.gray2};
    }

    .disabled .page-link {
      cursor: not-allowed;
    }

    .pagination li:hover:not(.active) {
      background-color: ${(props) => props.theme.color.gray2};
      cursor: pointer;
    }

    .pagination li:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .pagination li:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .page-0 {
      display: none;
    }
  `,
};

export const Pagination = (props: IPagination) =>
  props.pageCount > 1 ? (
    <Styled.Paginate>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={props.pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={(page) => {
          props.onPageChange(page.selected);
        }}
        activeClassName={'active'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        forcePage={props.page}
        containerClassName={'pagination'}
      />
    </Styled.Paginate>
  ) : null;
