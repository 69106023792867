import { GetAgentReport, GetAgentReportVariables } from './__generated__/GetAgentReport';
import { gql, useApi } from './Provider';
import { UserStatus } from '../views/_Shared/types';

const AGENT_REPORT = gql`
  query GetAgentReport($id: Int!) {
    user(id: $id) {
      id
      fullName
      clientsCount
      unInvitedClientsCount: clientsCount(status: "${UserStatus.PENDING_INVITATION}")
      pendingClientsCount: clientsCount(status: "${UserStatus.PENDING_VERIFICATION}")
      verifiedClientsCount: clientsCount(status: "${UserStatus.VERIFIED}")
      activeClientsCount: clientsCount(status: "${UserStatus.ACTIVE}")
      clientPropertyVisitsCount
      onePropertyVisit: clientPropertyVisitsCount(min: 1, max: 1)
      twoPropertyVisits: clientPropertyVisitsCount(min: 2, max: 2)
      threePropertyVisits: clientPropertyVisitsCount(min: 3, max: 3)
      fourPropertyVisits: clientPropertyVisitsCount(min: 4, max: 4)
      fivePlusPropertyVisits: clientPropertyVisitsCount(min: 5)
      clientPropertyVisits {
        id
        count
        property {
          id
          name
        }
      }
      salesCenterContacts: messagesSent(type: "SalesCenter", take: 20) {
        id
        createdAt
        property {
          id
          name
        }
      }
      tourRequests: messagesReceived(type: "ScheduleTour", take: 20) {
        id
        createdAt
        from {
          id
        }
        property {
          id
          name
        }
      }
      agentShares: messagesSent(type: "ShareProperty", take: 20) {
        id
        createdAt
        to {
          id
        }
        property {
          id
          name
        }
      }
    }
  }
`;

export const useGetAgentReport = (id: number) => {
  const { useQuery } = useApi();
  return useQuery<GetAgentReport, GetAgentReportVariables>(AGENT_REPORT, {
    variables: {
      id,
    },
  });
};
