import React from 'react';
import { ITooltip } from './types';
import ReactTooltip from 'react-tooltip';

export const Tooltip = (props: ITooltip) => {
  return (
    <>
      <ReactTooltip />
      <div data-place={props.place || 'top'} data-tip={props.value}>
        {props.children}
      </div>
    </>
  );
};
