import React, { useState } from 'react';
import { Button, SelectOptions, Icon, IIconType, Grid, Constrain, Modal, Spacer, Upload, DropArea } from '../..';
import { useTranslation } from '../../hooks/useTranslation';
import * as translations from './translations';

interface IBulkActions {
  handleExportCsv: () => void;
  handleDownloadTemplate: () => void;
  handleImportCsv: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCustomAction?: (actionType: string) => void;
  testID: string;
  zIndex?: number;
  customActions?: Array<{
    key: string;
    value: string;
  }>;
}

export const BulkActions = (props: IBulkActions) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [show, setShow] = useState(false);
  const t = useTranslation<typeof translations.en>(translations);
  const width = 160;

  return (
    <Constrain width={width} zIndex={props.zIndex}>
      <Modal onDismiss={() => setShowUploadModal(false)} visible={showUploadModal} testID={`${props.testID}-modal`}>
        <Spacer height={34} />
        <Upload
          testID={`${props.testID}-upload`}
          onUpload={(e) => {
            setShowUploadModal(false);
            props.handleImportCsv(e);
          }}
        >
          <DropArea />
        </Upload>
      </Modal>
      <Grid direction="column">
        <SelectOptions
          width={width}
          show={show}
          setShow={setShow}
          onValueChange={(value) => {
            if (value === 'export') {
              props.handleExportCsv();
              return;
            }

            if (value === 'downloadTemplate') {
              props.handleDownloadTemplate();
              return;
            }

            if (value === 'import') {
              setShowUploadModal(true);
              return;
            }

            if (props.handleCustomAction) {
              props.handleCustomAction(value);
            }
          }}
          options={[
            {
              key: 'downloadTemplate',
              value: t.downloadTemplate,
            },
            {
              key: 'export',
              value: t.exportCsv,
            },
            {
              key: 'import',
              value: t.importCsv,
            },
            ...(props.customActions || []),
          ]}
        >
          <Button
            width={width}
            testID={`${props.testID}-bulk-actions`}
            onPress={() => {
              setShow(!show);
            }}
            size="lg"
            endIcon={<Icon color="white" type={IIconType.Chevron} size="xxs" />}
          >
            {t.bulkActions}
          </Button>
        </SelectOptions>
      </Grid>
    </Constrain>
  );
};
