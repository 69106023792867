import { MetaData, RequestState, UnitModel } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface UnitModelResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: UnitModel;
  metadata: MetaData;
}

export const useGetUnitModel = (id: number | string) => {
  const [unitModel] = useApi.get({
    url: `/unit-model/${id}`,
    options: {
      cachePolicy: 2,
    },
  });

  return formatApiResult(unitModel) as UnitModelResponse;
};
