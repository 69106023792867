import { CreateZendeskToken } from './__generated__/CreateZendeskToken';

import { useApi, gql } from './Provider';

const CREATE_ZENDESK__TOKEN = gql`
  mutation CreateZendeskToken {
    createZendeskToken {
      token
    }
  }
`;

export const useCreateZendeskToken = () => {
  const { useMutation } = useApi();
  return useMutation<CreateZendeskToken>(CREATE_ZENDESK__TOKEN);
};
