import { InviteClientToSystem, InviteClientToSystemVariables } from './__generated__/InviteClientToSystem';
import { gql, useApi } from './Provider';

export const INVITE_CLIENT_TO_SYSTEM = gql`
  mutation InviteClientToSystem($input: InviteClientInput!) {
    inviteClient(input: $input) {
      id
      emailAddress
      user {
        id
        fullName
      }
    }
  }
`;

export const useInviteClientToSystem = () => {
  const { useMutation } = useApi();
  return useMutation<InviteClientToSystem, InviteClientToSystemVariables>(INVITE_CLIENT_TO_SYSTEM);
};
