import * as React from 'react';

import { Avatar } from '../../../components/Avatar';
import { ContactSheetClientCollapsed as ContactSheetClientCollapsedType } from '../types';
import { font } from '../../../utils/styles';
import styled from 'styled-components';
import { useGetMe } from '../../../api';
import { useMobileContactSheet } from '../useMobileContactSheet';
import useTranslations from '../../../translations/useTranslations';

interface ContactSheetClientCollapsedProps {
  contactSheet: ContactSheetClientCollapsedType;
  hasPropertyScope: boolean;
}

const Styled = {
  Container: styled.div`
    position: fixed;
    width: 100vw;
    height: 50px;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    z-index: 9997;
    padding: 0px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
    justify-content: space-between;
  `,
  Left: styled.div`
    width: calc(50% - 14px);
    height: 100%;
    display: flex;
    padding-left: 14px;
    align-items: center;
  `,
  Titles: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6px;
  `,
  Button: styled.button`
    background-color: #c9985e;
    width: 50%;
    height: 100%;
    margin: 0px;
    color: #fff;
    font-size: 15px;
    font-family: ${font.regular};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 160px;

    img {
      height: 10px;
      margin: 8px;
    }
  `,
  Title: styled.div`
    font-family: ${font.title};
    font-size: 20px;
    font-weight: 600;
    height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  SubTitle: styled.div`
    font-family: ${font.regular};
    font-size: 10px;
  `,
};

export const ContactSheetClientCollapsed = (props: ContactSheetClientCollapsedProps) => {
  const { setContactSheet } = useMobileContactSheet();
  const translations = useTranslations();
  const { data } = useGetMe();

  return (
    <Styled.Container>
      <Styled.Left>
        <Avatar src={data?.me?.agent?.profileAvatarUrl} size={40} />
        <Styled.Titles>
          <Styled.Title>{`${data?.me?.agent?.firstName || ''} ${data?.me?.agent?.lastName || ''}`}</Styled.Title>
          <Styled.SubTitle>
            {(data?.me?.agent?.organizations || [])
              .filter((org) => org.organizationTypes.find((type) => type.name === 'Brokerage'))
              .map((org) => org.name)
              .join(', ')}
          </Styled.SubTitle>
        </Styled.Titles>
      </Styled.Left>
      <Styled.Button
        test-id="mobile-contact-toggle"
        onClick={() => {
          setContactSheet({
            ...props.contactSheet,
            type: 'clientExpanded',
          });
        }}
      >
        {props.hasPropertyScope ? translations.ContactSheet.requestATour : translations.ContactSheet.contactAgent}{' '}
        <img src="/icons/drop-down-arrow.svg" alt="chevron" />
      </Styled.Button>
    </Styled.Container>
  );
};
