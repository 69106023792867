import React from 'react';
import styled from 'styled-components';
import { font } from '../utils/styles';

import Icon from './Icon';

interface VerticalTabProps {
  onClick: () => void;
  testId?: string;
  isActive?: boolean;
  children: string | React.ReactNode;
  id?: string;
}

interface VerticalTabsProps {
  children: Array<React.ReactNode>;
  id?: string;
}

interface ActiveTabProps {
  isActive?: boolean;
}

export const VerticalTabs = (props: VerticalTabsProps) => (
  <Styled.Tabs>
    <>{props.children}</>
  </Styled.Tabs>
);

export const VerticalTab = (props: VerticalTabProps) => (
  <Styled.Button data-testid={props.testId} id={props.id} onClick={props.onClick}>
    <Styled.VerticalTab isActive={props.isActive}>
      <Styled.VerticalTabTitle isActive={props.isActive}>{props.children}</Styled.VerticalTabTitle>
      <Styled.VerticalTabIcon>
        <Icon type="keyboard_arrow_right" color="#7e8184" />
      </Styled.VerticalTabIcon>
    </Styled.VerticalTab>
  </Styled.Button>
);

export const VerticalTabContent = styled.div`
  padding: 0px 24px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: ${font.regular};
`;

export const VerticalTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Styled = {
  Button: styled.button`
    text-decoration: none;
    color: #000;
    padding: 0px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    outline: none;

    &:active {
      opacity: 0.5;
    }
  `,
  Tabs: styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  `,
  Tab: styled.div<ActiveTabProps>`
    cursor: pointer;
    position: relative;
    min-width: 8rem;
    min-height: 3rem;

    :not(:last-child) {
      margin-right: 3rem;
    }

    background: ${(props) => (props.isActive ? 'rgba(216, 216, 216, 0.5)' : '#fff')};
    border-bottom: ${(props) => (props.isActive ? '4px solid #CDA263' : 'none')};
  `,
  VerticalTab: styled.div<ActiveTabProps>`
    cursor: pointer;
    position: relative;
    min-width: 250px;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${(props) => (props.isActive ? '#f5f5f5' : '#fff')};
  `,
  VerticalTabTitle: styled.div<ActiveTabProps>`
    font-family: ${font.regular};
    font-weight: ${(props) => (props.isActive ? 'bold' : '300')};
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding: 0 1rem;
    color: #000;
  `,
  VerticalTabIcon: styled.span`
    float: right;
  `,
};
