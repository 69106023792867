import { FONT_FAMILY_URLS, theme } from '../../theme';
import React, { ReactChild, ReactChildren, useEffect } from 'react';

import { ThemeProvider } from 'styled-components/native';
import { ThemeProvider as ThemeProviderWeb } from 'styled-components';

interface IWrapperView {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const ComponentThemeProvider = (props: IWrapperView) => {
  useEffect(() => {
    Object.keys(FONT_FAMILY_URLS).forEach((key) => {
      if ('document' in global) {
        const style = document.createElement('style');
        style.innerText = `@font-face {
        font-family: ${key};
        src: url(${FONT_FAMILY_URLS[key]});
      }`;
        document.head.appendChild(style);
      }
    });
  }, []);

  return (
    <ThemeProviderWeb theme={theme}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeProviderWeb>
  );
};
