import { Property, RequestState } from '../../types';

import * as React from 'react';

import api from '../../utils/api';

export interface PropertyResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data?: Property | any;
  refetch: () => void;
}

export const useGetProperty = (id: number | string) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState();

  const refetch = () => {
    setIsLoading(true);
    setError(undefined);
    api
      .get({
        url: `/admin/property/${id}`,
      })
      .then((res) => {
        setData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  React.useEffect(() => {
    setIsLoading(true);
    setError(undefined);
    api
      .get({
        url: `/admin/property/${id}`,
      })
      .then((res) => {
        setData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [id]);

  return {
    data,
    isLoading,
    refetch,
    error,
    state: isLoading ? 'loading' : error ? 'error' : 'success',
  } as PropertyResponse;
};
