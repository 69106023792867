import React from 'react';
import { font } from '../utils/styles';
import styled from 'styled-components';

interface ImageUploaderProps {
  inputRef?: any;
  handleClickUpload: (e: any) => void;
  isDragging: boolean;
  allowMultiple?: boolean;
  acceptedTypes?: string;
  initiateUpload: (e: any) => void;
  dropTypeText: string;
}

interface ContainerDivProps {
  isDragging?: boolean;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const {
    inputRef,
    handleClickUpload,
    isDragging,
    initiateUpload,
    allowMultiple = true,
    dropTypeText = 'images',
    acceptedTypes = 'image/*,video/*,application/pdf',
  } = props;

  return (
    <Styled.Container onClick={initiateUpload} isDragging={isDragging}>
      <input ref={inputRef} onChange={handleClickUpload} accept={acceptedTypes} type="file" multiple={allowMultiple} />
      <Styled.InstructionsStyles>
        <svg className="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43">
          <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
        </svg>
        <p>
          <b>Drop {dropTypeText}</b> or click to upload
        </p>
      </Styled.InstructionsStyles>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div<ContainerDivProps>`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    background-color: #fff;
    input {
      display: none;
    }
    span {
      font-family: ${font.regular};
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    width: 100%;
    height: 400px;
    cursor: pointer;
    background-color: white;
    outline: 2px dashed;
    outline-offset: -10px;

    ${(props) =>
      props.isDragging &&
      `
      background: #ddd;
  `};
  `,
  InstructionsStyles: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      text-align: center;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.2rem;
      text-align: center;
    }
  `,
};
