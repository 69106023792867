import { CreateMarketingLink, CreateMarketingLinkVariables } from './__generated__/CreateMarketingLink';
import { gql, useApi } from './Provider';

const CREATE_MARKETING_LINK = gql`
  mutation CreateMarketingLink($input: CreateMarketingLinkInput!) {
    createMarketingLink(input: $input) {
      id
      slug
      redirectionLocation
      type
      status
      html
      agent {
        id
        fullName
      }
    }
  }
`;

export const useCreateMarketingLink = () => {
  const { useMutation } = useApi();
  return useMutation<CreateMarketingLink, CreateMarketingLinkVariables>(CREATE_MARKETING_LINK);
};
