import React, { useState } from 'react';
import { always, compose } from 'lodash/fp';

import { Icon } from './Icon';
import { font } from '../utils/styles';
import styled from '../styled-components';

interface MenuSection {
  type: 'section';
  display: string;
  id: string;
  hidden?: boolean;
}

interface MenuDivider {
  type: 'divider';
  hidden?: boolean;
}

interface MenuOption {
  type: 'option';
  display: string | React.ReactNode;
  section?: string;
  onClick: () => void;
  hidden?: boolean;
}

interface ComboBoxProps {
  display: string | React.ReactNode;
  options: Array<MenuSection | MenuOption | MenuDivider>;
  id?: string;
}

export const ComboBox = (props: ComboBoxProps) => {
  const [visible, setVisible] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);

  return (
    <Styled.Container id={props.id}>
      <Styled.Display type="button" onClick={compose(setVisible, always(!visible))}>
        {props.display} <Icon type="more_horiz" color="black" size={14} />
      </Styled.Display>
      {visible && (
        <Styled.Menu>
          <Styled.MenuInner>
            <Styled.Arrow />
            <Styled.Scrollable>
              {props.options
                .filter((item) => !item.hidden)
                .map((item, key) =>
                  item.type === 'divider' ? (
                    <Styled.Divider key={key} />
                  ) : item.type === 'section' ? (
                    <Styled.Section key={key} onClick={() => setExpanded(expanded === item.id ? null : item.id)}>
                      {item.display}
                      <Icon size={16} color="gray" type={expanded === item.id ? 'expand_less' : 'chevron_right'} />
                    </Styled.Section>
                  ) : (
                    <Styled.Option
                      key={key}
                      type="button"
                      onClick={() => {
                        setVisible(false);
                        item.onClick();
                      }}
                      className={!item.section || item.section === expanded ? 'visible' : 'hidden'}
                    >
                      {item.display}
                    </Styled.Option>
                  ),
                )}
            </Styled.Scrollable>
          </Styled.MenuInner>
        </Styled.Menu>
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    position: relative;
  `,
  Display: styled.button`
    font-family: ${font.regular};
    font-size: 14px;
    border: solid 1px #ccc;
    font-weight: bold;
    padding: 10px 22px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #eee;
    }
  `,
  Menu: styled.div`
    position: absolute;
    top: 54px;
    right: 0px;
    z-index: 50;
  `,
  MenuInner: styled.div`
    position: relative;
    background-color: #fff;
    border: solid 1px #ccc;
    display: flex;
    flex-direction: column;
    width: 250px;
    border-radius: 7px;
  `,
  Arrow: styled.div`
    position: absolute;
    right: 50px;
    top: -7px;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transform: rotate(45deg);
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  `,
  Divider: styled.div`
    height: 1px;
    width: 100%;
    background-color: #ccc;
  `,
  Section: styled.button`
    padding: 14px;
    text-align: left;
    margin: 0px !important;
    cursor: pointer;
    color: #000;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    &:hover {
      color: #000;
    }
  `,
  Option: styled.button`
    padding: 14px;
    color: #333;
    cursor: pointer;
    text-align: left;
    margin: 0px !important;

    &.hidden {
      display: none;
    }

    &:hover {
      color: #000;
      background-color: #eee;
    }
  `,
  Scrollable: styled.div`
    max-height: 350px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
};
