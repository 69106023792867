import { gql, useApi } from './Provider';

import { ListAllProperties } from './__generated__/ListAllProperties';

export const LIST_ALL_PROPERTIES = gql`
  query ListAllProperties {
    listProperties {
      properties {
        id
        name
      }
    }
  }
`;

export const useListAllProperties = () => {
  const { useQuery } = useApi();
  return useQuery<ListAllProperties>(LIST_ALL_PROPERTIES);
};
