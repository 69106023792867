import { MetaData, RequestState, RemoteUnitStatus } from '../../types';

import { formatApiResult } from '../../utils/formatApiResult';
import useApi from '../api';

interface UnitStatusesResponse {
  isLoading: boolean;
  state: RequestState;
  error?: string;
  data: Array<RemoteUnitStatus>;
  metadata: MetaData;
  refetch: () => void;
}

export const useGetUnitStatuses = () => {
  const [unitStatuses, refetch] = useApi.get({
    url: `/unit-status`,
  });

  return formatApiResult(unitStatuses, refetch) as UnitStatusesResponse;
};
