import { Unit } from '../../types';
import api from '../../utils/api';

export const getUnitModelUnits = async (unitModelId: number | string): Promise<Array<Unit>> =>
  await api
    .get({
      url: `/admin/unit-model/${unitModelId}/units`,
      variables: {
        offset: 0,
        limit: -1,
      },
    })
    .then((res) => res?.data?.data || []);
