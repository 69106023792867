import * as React from 'react';

import { RESPONSIVE } from '../utils/constants';
import styled from 'styled-components';

export const Form = (props) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit(e);
    }}
  >
    {props.children}
  </form>
);

export const FlexFormRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 7px;

  input,
  select {
    margin-bottom: 0px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexRowRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FlexRowExpanded = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PaddingMedium = styled.div`
  padding: 14px;
`;

export const View = styled.div<any>`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Divider = styled.hr`
  height: 1px;
  width: 100%;
  border-color: transparent;
  background-color: #ccc;
  border: 0px;
`;

export const Paper = styled.div`
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #ccc;
  width: 350px;
  margin: 5px;
  @media (max-width: ${RESPONSIVE.EXTRA_SMALL}) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const CenterAligned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const Option = styled.option``;

export const Hr = styled.hr`
  border-top: solid 1px #ccc;
`;
