import * as React from 'react';
import * as S from './styles';

import { Link } from 'react-router-dom';

const DEFAULT_COLOR = '#3051CF';

interface Detail {
  key: string;
  value: string;
}

interface ListingCardProps {
  title: string;
  subTitle: string;
  imageUrl: string | null;
  linkTo?: string;
  tagColor?: string;
  tagText?: string;
  details?: Array<Detail>;
  testId?: string;
}

export const ListingCard = (props: ListingCardProps) => {
  const Image = (
    <S.Image
      style={{
        backgroundImage: props.imageUrl ? `url("${props.imageUrl}")` : undefined,
      }}
    />
  );

  const Tag = props.tagText ? (
    <S.Tag
      style={{
        backgroundColor: props.tagColor || DEFAULT_COLOR,
      }}
    >
      {props.tagText}
    </S.Tag>
  ) : null;

  return (
    <S.Container>
      <S.Title>{props.title}</S.Title>
      <S.SubTitle>{props.subTitle}</S.SubTitle>
      {props.linkTo ? (
        <Link to={props.linkTo} data-testid={props.testId}>
          {Image}
        </Link>
      ) : (
        Image
      )}
      {props.linkTo ? <Link to={props.linkTo}>{Tag}</Link> : Tag}
      {props.details && (
        <S.Details>
          {props.details.map((detail) => (
            <S.Detail key={detail.key}>
              <S.DetailKey>{detail.key}: </S.DetailKey>
              <S.DetailValue>{detail.value}</S.DetailValue>
            </S.Detail>
          ))}
        </S.Details>
      )}
    </S.Container>
  );
};
