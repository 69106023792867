import * as React from 'react';
import useTranslations from '../translations/useTranslations';

interface LogoProps {
  variant: 'dark' | 'light' | 'mark' | 'accent';
  height?: number;
}

const Logo = ({ variant, height }: LogoProps) => {
  const translations = useTranslations();

  return (
    <img
      src={`/icons/vysta-logo-${variant}.svg`}
      alt={translations.Common.title}
      style={{
        height,
      }}
    />
  );
};

export default Logo;
