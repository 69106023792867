import * as React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

interface NumberFieldProps {
  disabled?: boolean;
  id?: string;
  name?: string;
  value?: string | number;
  onValueChange: (value: string) => void;
}

export const NumberField = (props: NumberFieldProps) => (
  <Styled.Container>
    <NumberFormat
      thousandSeparator={true}
      disabled={props.disabled}
      id={props.id}
      name={props.name}
      onValueChange={({ value }) => {
        if (props.onValueChange) {
          props.onValueChange(value);
        }
      }}
      value={props.value}
    />
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    width: 100%;

    input {
      border: solid 1px #ccc;
      height: 47px;
      border-radius: 6px;
      padding: 10px 15px;
      width: 100%;

      &:disabled {
        background-color: #ddd;
      }
    }
  `,
};
