import { RESPONSIVE } from '../../utils/constants';
import { font } from '../../utils/styles';
import styled from 'styled-components';

interface IToggleButton {
  selected: boolean;
  hasIcon: boolean;
}

export const Container = styled.div<IToggleButton>`
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #dadada;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  margin-top: 5px;
  :not(:last-of-type) {
    margin-right: 10px;
  }

  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#dadada' : '#FFFFFF')};

  > span {
    color: #747a7e;
    font-family: ${font.regular};
    font-size: 16px;
    font-weight: 300;
    margin-left: ${(props) => (props.hasIcon ? '6px' : 0)};
  }

  img {
    width: 20px;
    height: 20px;
    color: #747a7e;
  }

  @media max-width(300px) {
    > span {
      font-size: 13px;
    }
  }

  @media max-width(576px) {
    > span {
      font-size: 14px;
    }
  }

  @media (max-width: ${RESPONSIVE.SMALL}) {
    min-width: auto !important;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
