import React, { useCallback, useState, useMemo } from 'react';

import { ContactSheet } from './types';
import { MobileContactSheet } from './MobileContactSheet';
import { MobileContactSheetContext } from './MobileContactSheetContext';

interface MobileContactSheetProviderProps {
  children: React.ReactNode;
}

export const MobileContactSheetProvider = (props: MobileContactSheetProviderProps) => {
  const [value, setValue] = useState<ContactSheet>({
    type: 'hidden',
  });

  const [hasPropertyScope, setHavingPropertyScope] = useState<boolean>(false);

  const hideContactSheet = useCallback(() => {
    setValue({
      type: 'hidden',
    });
  }, []);

  const showContactSheet = useCallback(
    (contactSheet: ContactSheet) => {
      if (value.type === 'agentExpanded' && contactSheet.type === 'agentCollapsed') {
        return setValue({
          ...contactSheet,
          type: value.type,
        });
      }

      if (value.type === 'clientExpanded' && contactSheet.type === 'clientCollapsed') {
        return setValue({
          ...contactSheet,
          type: value.type,
        });
      }

      setValue(contactSheet);
    },
    [value.type],
  );

  const setHasPropertyScope = useCallback((hasScope: boolean) => {
    setHavingPropertyScope(hasScope);
  }, []);

  const valueStore = useMemo(() => {
    return {
      contactSheet: value,
      setContactSheet: setValue,
      showContactSheet,
      hideContactSheet,
      setHasPropertyScope,
    };
  }, [hideContactSheet, setHasPropertyScope, showContactSheet, value]);

  return (
    <MobileContactSheetContext.Provider value={valueStore}>
      <>
        {props.children}
        <MobileContactSheet contactSheet={value} hasPropertyScope={hasPropertyScope} />
      </>
    </MobileContactSheetContext.Provider>
  );
};
