import { useApi, gql } from './Provider';
import { AssignUserEmailTemplate, AssignUserEmailTemplateVariables } from './__generated__/AssignUserEmailTemplate';

const ASSIGN_USER_EMAIL_TEMPLATE = gql`
  mutation AssignUserEmailTemplate($input: CreateEmailTemplateInput!) {
    assignUserEmailTemplate(input: $input) {
      id
      name
      templateId
      user {
        id
      }
    }
  }
`;

export const useAssignUserEmailTemplate = () => {
  const { useMutation } = useApi();
  return useMutation<AssignUserEmailTemplate, AssignUserEmailTemplateVariables>(ASSIGN_USER_EMAIL_TEMPLATE);
};
