import { DropDown, Flex, IIconType, Icon, Spacer, Text, Th, Touchable } from '../';
import React, { useState } from 'react';
import { compose, constant } from 'lodash/fp';

interface ISortTh {
  label: string;
  testID: string;
  selected: boolean;
  desc: boolean;
  hidden?: boolean;
  onPress: () => void;
  filterActive?: boolean;
  filterWidth?: number;
  filterHeight?: number;
  renderFilter?: (
    setShowFilters: (showFilters: boolean) => void,
  ) => React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[];
}

export const SortTh = (props: ISortTh) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Th hidden={props.hidden}>
      <Touchable testID={props.testID} onPress={props.onPress}>
        <Flex alignItems="center">
          <Text weight="black" size="sm">
            {props.label}
          </Text>{' '}
          <Spacer width={7} />
          {props.selected && <Icon size="xxs" type={IIconType.Chevron} rotate={props.desc ? 180 : 0} />}
        </Flex>
      </Touchable>
      {props.renderFilter && (
        <>
          <Spacer width={7} />
          <Touchable testID={`filter-${props.testID}`} onPress={compose(setShowFilters, constant(!showFilters))}>
            <Icon size="xxs" type={IIconType.Filter} color={props.filterActive ? 'danger' : 'gray1'} />
          </Touchable>
          <DropDown
            show={showFilters}
            setShow={setShowFilters}
            width={props.filterWidth || 200}
            height={props.filterHeight || 44}
          >
            {props.renderFilter(setShowFilters)}
          </DropDown>
        </>
      )}
    </Th>
  );
};
