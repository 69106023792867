import { Unit, RequestVariables, UnitSortBy } from '../../types';
import api from '../../utils/api';

interface GetPropertyVariables extends RequestVariables {
  sortBy?: UnitSortBy;
}

const defaultVariables = {
  offset: 0,
  limit: 10,
};

export const getPropertyUnits = async (
  propertyId: number | string,
  variables: GetPropertyVariables = defaultVariables,
): Promise<Array<Unit>> =>
  await api
    .get({
      url: `/admin/property/${propertyId}/units`,
      variables,
    })
    .then((res) => res?.data?.data || []);
