import * as React from 'react';

import { IThead } from './types';
import styled from 'styled-components';

const Styled = {
  Thead: styled.thead`
    z-index: 2;
    position: relative;
  `,
};

export const THead = (props: IThead) => {
  return <Styled.Thead>{props.children}</Styled.Thead>;
};
