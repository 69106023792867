import React, { useState } from 'react';
import { Form, InputField, ButtonWrapper, Icon } from './';
import styled from 'styled-components';

interface SearchInputProps {
  placeholder?: string;
  value?: string;
  setValue: (search: string) => void;
  onClearSearch?: () => void;
  showClearSearch?: boolean;
}

export const SearchInput = (props: SearchInputProps) => {
  const [search, setSearch] = useState(props.value || '');

  return (
    <Form
      onSubmit={() => {
        props.setValue(search);
      }}
    >
      <Styled.Container>
        <Styled.Input
          placeholder={props.placeholder}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Styled.SearchButton>
          <Icon type="search" color="#CCC" />
        </Styled.SearchButton>
        {props.showClearSearch && (
          <Styled.ClearSearch
            onClick={() => {
              setSearch('');
              props.onClearSearch && props.onClearSearch();
            }}
          >
            Clear Search
          </Styled.ClearSearch>
        )}
      </Styled.Container>
    </Form>
  );
};

const Styled = {
  Container: styled.div`
    position: relative;
  `,
  Input: styled(InputField)`
    width: 250px;
    height: 40px;
  `,
  SearchButton: styled(ButtonWrapper)`
    position: absolute;
    right: 14px;
    top: 8px;
  `,
  ClearSearch: styled.div`
    text-align: right;
    cursor: pointer;
  `,
};
