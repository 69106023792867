export const en = {
  to: 'to',
  units: 'Units',
  stories: 'Stories',
  availableUnits: 'Available Units',
  addToFavorites: 'Add to Favorites',
  removeFromFavorites: 'Remove Favorite',
  editProperty: 'Edit Property',
  startingAt: 'Starting At',
  threeD: '3D',
};
