import React, { ReactChild, ReactChildren } from 'react';

import { RESPONSIVE } from '../../theme';
import styled from 'styled-components';

export enum ColSize {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 9,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
}

const sizeMap = {
  12: '100%',
  11: '91.6%',
  10: '83.3%',
  9: '75%',
  8: '66.6666%',
  7: '58.3%',
  6: '50%',
  5: '41.6%',
  4: '33.3333%',
  3: '25%',
  2: '16.6%',
  1: '8.3%',
};

interface IStyledCol {
  size: ColSize;
  spacing?: number;
  zIndex?: number;
  xxxs?: ColSize;
  xxs?: ColSize;
  xs?: ColSize;
  sm?: ColSize;
  md?: ColSize;
  lg?: ColSize;
  xl?: ColSize;
  xxl?: ColSize;
  hidden?: boolean;
}

interface ICol extends IStyledCol {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  hidden?: boolean;
}

const Styled = {
  Col: styled.div<IStyledCol>`
    position: relative;
    ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : '')};
    margin: ${(props) => props.spacing}px;
    width: calc(
      ${(props) => {
          return sizeMap[props.size];
        }} - ${(props) => props.spacing * 2}px
    );
    ${(props) =>
      props.xxl
        ? `@media (max-width: ${RESPONSIVE.xxl}px) { 
          width: calc(${sizeMap[props.xxl]} - ${props.spacing * 2}px); 
    }`
        : ''}
    ${(props) =>
      props.xl
        ? `@media (max-width: ${RESPONSIVE.xl}px) {
          width: calc(${sizeMap[props.xl]} - ${props.spacing * 2}px);
      }`
        : ''}
    
      ${(props) =>
      props.lg
        ? `@media (max-width: ${RESPONSIVE.lg}px) {
          width: calc(${sizeMap[props.lg]} - ${props.spacing * 2}px);
      }`
        : ''}
      ${(props) =>
      props.md
        ? `@media (max-width: ${RESPONSIVE.md}px) {
          width: calc(${sizeMap[props.md]} - ${props.spacing * 2}px);
      }`
        : ''}
      ${(props) =>
      props.sm
        ? `@media (max-width: ${RESPONSIVE.sm}px) {
          width: calc(${sizeMap[props.sm]} - ${props.spacing * 2}px);
      }`
        : ''}
      ${(props) =>
      props.xs
        ? `@media (max-width: ${RESPONSIVE.xs}px) { 
          width: calc(${sizeMap[props.xs]} - ${props.spacing * 2}px);
      }`
        : ''}
      ${(props) =>
      props.xxs
        ? `@media (max-width: ${RESPONSIVE.xxs}px) {
          width: calc(${sizeMap[props.xxs]} - ${props.spacing * 2}px);
      }`
        : ''}
        ${(props) => (props.hidden ? 'display: none;' : '')}
  `,
};

export const Col = (props: ICol) => {
  return (
    <Styled.Col
      xxxs={props.xxxs}
      xxs={props.xxs}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.lg}
      xxl={props.xxl}
      zIndex={props.zIndex}
      size={props.size}
      spacing={props.spacing || 0}
      hidden={props.hidden}
    >
      {props.children}
    </Styled.Col>
  );
};
