import styled from 'styled-components';
import Spinner from '../Spinner';
import { ButtonProps } from './ButtonProps';
import { font } from '../../utils/styles';

export const StyledButton = styled.a<ButtonProps>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.height ? `${props.height}` : '50.33px')};
  min-width: ${(props) => (props.width ? `${props.width}` : '150px')};
  border-radius: 6px;
  background-color: ${(props) => (props.color ? `${props.color}` : '#c9985e')};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-family: ${font.regular};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: ${(props) => (props.letterSpacing ? `${props.letterSpacing}` : '-0.38px')};
  cursor: pointer;

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
`;

export const StyledSpinner = styled(Spinner)`
  position: relative;
  top: 1px;
`;

export const Text = styled.div`
  padding-left: 7px;
`;
