import { GetAgentFromSlug, GetAgentFromSlugVariables } from './__generated__/GetAgentFromSlug';
import { gql, useApi } from './Provider';

export const GET_AGENT_FROM_SLUG = gql`
  query GetAgentFromSlug($vanityStub: String!) {
    agentUser(vanityStub: $vanityStub) {
      id
    }
  }
`;

export const useGetAgentFromSlug = (vanityStub: string) => {
  const { useQuery } = useApi();
  return useQuery<GetAgentFromSlug, GetAgentFromSlugVariables>(GET_AGENT_FROM_SLUG, {
    variables: {
      vanityStub,
    },
  });
};
