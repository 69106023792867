import { useApi, gql } from './Provider';
import { FavoriteUnit, FavoriteUnitVariables } from './__generated__/FavoriteUnit';

const FAVORITE_UNIT = gql`
  mutation FavoriteUnit($unitId: BigInt!) {
    favoriteUnit(unitId: $unitId) {
      id
      isFavorite
    }
  }
`;

export const useFavoriteUnit = () => {
  const { useMutation } = useApi();
  return useMutation<FavoriteUnit, FavoriteUnitVariables>(FAVORITE_UNIT);
};
