import React, { ReactChild, ReactChildren } from 'react';

interface IIf {
  condition: boolean;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const If = (props: IIf) => {
  return props.condition ? (
    <>
      {props.children}
      {null /* So Codacy won't complain about there only being one child of the fragment */}
    </>
  ) : null;
};
