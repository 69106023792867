import { UnitModel } from '../../types';
import api from '../../utils/api';

export const batchCreateUnitModels = (propertyId: string, unitModels: Array<UnitModel>) =>
  api.post({
    url: `/property/${propertyId}/unit-model/batch`,
    variables: {
      unitModels,
    },
  });
