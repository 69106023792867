import * as React from 'react';

import { Medium, MediumAccentBold, Small, SmallBold, Spacer, SubTitle, Title } from './';

import { RESPONSIVE } from '../utils/constants';
import { prettyPhoneNumber } from '../utils/phone';
import styled from 'styled-components';
import { useResponsiveSizes } from '../hooks/useResponsiveSizes';
import useTranslations from '../translations/useTranslations';

interface UserCardProps {
  title?: string;
  name: string;
  email: string;
  address: string;
  workPhone: number;
  cellPhone: number;
}

export const UserCard = (props: UserCardProps) => {
  const translations = useTranslations();
  const t = translations.UserCard;
  const { isMedium } = useResponsiveSizes();

  return (
    <Styled.Container>
      <SubTitle>{props.title || t.userDetails}</SubTitle>
      <Styled.Card>
        <Styled.Details>
          <Title>{props.name}</Title>
          {!isMedium && <Spacer height={7} />}
          <MediumAccentBold>{props.email}</MediumAccentBold>
          {!isMedium && <Spacer height={7} />}
          <SmallBold>{props.address}</SmallBold>
          <Styled.Contacts>
            {!!props.workPhone && (
              <Styled.Contact>
                <Small>{t.workPhone}</Small>
                <Medium>{prettyPhoneNumber(props.workPhone)}</Medium>
              </Styled.Contact>
            )}
            {!!props.cellPhone && (
              <Styled.Contact>
                <Small>{t.cellPhone}</Small>
                <Medium>{prettyPhoneNumber(props.cellPhone)}</Medium>
              </Styled.Contact>
            )}
          </Styled.Contacts>
        </Styled.Details>
      </Styled.Card>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div``,
  Card: styled.div`
    display: flex;
    padding-top: 14px;
  `,
  Details: styled.div``,
  Contacts: styled.div`
    display: flex;
    padding-top: 14px;
    flex-wrap: wrap;
  `,
  Contact: styled.div`
    padding-right: 14px;
    @media (max-width: ${RESPONSIVE.MEDIUM}) {
      width: 135px;
    }
  `,
};
