import axios, { Method } from 'axios';

import { IApiProps } from '../types';
import { PUBLIC_ROUTES } from './constants';
import { getAuthToken } from './authToken';
import { getItem } from '../utils/localStorage';
import { browserHistory as history } from '../browserHistory';
import { nuke } from './localStorage';
import { objectToQueryString } from './url';
import { startSessionTimer } from '../audits/session';

const defaults = {
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:4500',
  headers: async () => ({
    'Content-Type': 'application/json',
    Authorization: `${getAuthToken()}`,
    'Audit-Session-Id': `${getItem('vysta:auditSessionId')}`,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

const api = async (method: Method, url: string, variables?: any, options?: any, shouldPushLogin = true) => {
  try {
    startSessionTimer();
    const response = await axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: (options && options.headers) || (await defaults.headers()),
      params: method === 'get' ? variables : undefined,
      data: method !== 'get' ? variables : undefined,
      paramsSerializer: objectToQueryString,
    });
    return response;
  } catch (error) {
    console.error('api error', error);
    if (error.response?.status === 401) {
      nuke();

      const redirectTo = history.location.pathname;
      const redirectToSanitized =
        localStorage.getItem('redirectTo') || redirectTo[redirectTo.length - 1] === '/'
          ? redirectTo.slice(0, redirectTo.length - 1)
          : redirectTo;

      if (shouldPushLogin && redirectTo !== '/login' && !PUBLIC_ROUTES.includes(redirectToSanitized)) {
        localStorage.setItem('redirectTo', redirectToSanitized);
        history.push('/login', { from: redirectToSanitized });
      }
      return error;
    } else {
      if (error.response) {
        throw new Error(error.response.data.error);
      } else {
        throw new Error(defaults.error as any);
      }
    }
  }
};

interface IUpdateProps {
  updatedFields: Record<string, any> | Array<Record<string, any>>;
  currentFields: Record<string, any> | Array<Record<string, any>>;
  setLocalData: (data: Record<string, any> | Array<Record<string, any>>) => void;
}

const optimisticUpdate = async (url: string, { updatedFields, currentFields, setLocalData }: IUpdateProps) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    //    toast.error(error);
  }
};

const apiUtils = {
  get: ({ url, variables }: IApiProps) => api('get', url, variables),
  post: ({ url, variables }: IApiProps) => api('post', url, variables),
  put: ({ url, variables }: IApiProps) => api('put', url, variables),
  patch: ({ url, variables }: IApiProps) => api('patch', url, variables),
  delete: ({ url, variables }: IApiProps) => api('delete', url, variables),
  optimisticUpdate,
  api,
};

export default apiUtils;
