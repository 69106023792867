import ReactGA from 'react-ga';
import { TrackingEvent } from './types';
import { getConfig } from './utils/getConfig';

const config = getConfig();

const isGAEnabled = () => {
  return config.hasGA;
};

export const initialiseAnalytics = () => {
  if (isGAEnabled()) {
    ReactGA.initialize(config.GoogleAnalyticsId);
  }
};

export const trackAction = (event: string) => {
  if (isGAEnabled()) {
    ReactGA.event({
      category: 'Action',
      action: event.replace('vysta/', ''),
    });
  }
};

export const trackEvent = (event: TrackingEvent) => {
  if (isGAEnabled()) {
    ReactGA.event({
      category: 'Event',
      action: event,
    });
  }
};

export const trackPageView = (path: string) => {
  if (isGAEnabled()) {
    ReactGA.pageview(path);
  }
};

export const setTrackedUserId = (userId: string) => {
  if (isGAEnabled()) {
    ReactGA.set({ userId: userId });
  }
};
