import React from 'react';
export class YesNoFormatter extends React.Component<{ value }, {}> {
  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    return <div title={this.props.value}>{this.props.value === 'true' ? 'Yes' : 'No'}</div>;
  }
}
